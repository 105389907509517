import { DefaultFontStyles, DefaultPalette, IStyle, mergeStyleSets } from '@fluentui/react';

/**
 * Styles used on the total kilograms per attribute horizontal stacked bar chart.
 */
export const totalKilogramsPerAttributeHorizontalBarChartStyles = () => {
    return {
        chart: {
            paddingBottom: '10px'
        } as IStyle,
        chartLabel: {
            color: DefaultPalette.black,
            ...DefaultFontStyles.large
        } as IStyle,
        chartDataText: {
            fill: DefaultPalette.black,
            fontSize: '14px',
            lineHeight: '14px',
            marginRight: '5px'
        } as IStyle,
        barWrapper: {
            strokeWidth: '0px'
        } as IStyle
    };
};

/**
 * Styles used on this page.
 */
export const pageStyles = mergeStyleSets({
    carbonFootprintNote: {
        color: 'black',
        fontSize: '14px'
    } as IStyle,
    calculateButton: {
        marginTop: '20px'
    } as IStyle,
    categoriesComboBoxStyles: {
        width: 300
    } as IStyle,
    chartCard: {
        paddingBottom: '15px'
    } as IStyle
});
