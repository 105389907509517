export interface ICompanyCode {
    code: string;
    name: string;
}

export class CompanyCode implements ICompanyCode {
    public code: string;
    public name: string;

    constructor(jsonData: ICompanyCode) {
        this.code = jsonData.code;
        this.name = jsonData.name;
    }
}
