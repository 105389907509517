import React, { useCallback, useEffect, useState } from 'react';
import {
    Checkbox,
    ConstrainMode,
    DefaultButton,
    DetailsListLayoutMode,
    IColumn,
    SelectionMode,
    Spinner,
    SpinnerSize,
    Stack,
    Text,
    TextField
} from '@fluentui/react';
import { IAdminTabProps } from '../../common/common.types';
import { commonColumnProps, useMountEffect } from '../../common/common.func';
import { clearErrorByIndex, ErrorBar } from '../../components/ErrorBar/ErrorBar';
import {
    callApiCheckIsMsRecyclingTeamMember,
    callApiLoadPrograms,
    callApiUpdateProgram,
    callApiAddProgram,
    resetApiCallState,
    IApiLoadPrograms, 
    IApiUpdateProgram, 
    IApiAddProgram,
    IApiCheckIsMsRecyclingTeamMember
} from '../../store/actions/adminTabActions/adminTabPrograms.action';
import { CallApiState } from '../../store/actions/generic.action';
import { CustomDetailsList } from '../../components/CustomDetailsList/CustomDetailsList';
import { Program } from '../../models/domainData/program';
import { commonStyles, stackTokens } from '../../common/common.styles';
import { addOrRemoveError, checkForInputError, checkItemForAnyInputError, InputError, renderColumnHeader } from './AdminCommon';
import { ProgramConfiguration } from '../../models/domainData/programConfiguration';
import { Validations } from '../../models/domainData/validations';
import { ReloadingDialog } from './ReloadingDialog';
import { useAppSelector, useAppDispatch } from '../../store/hooks';

interface IAdminTabProgramsProps extends IAdminTabProps {
}

const programNameTooltip: string = 'Business group recycling program name.';
const programTypeTooltip: string = 'One word to define the program type.';
const businessTeamMailingGroupTooltip: string = 'Security group to which emails are sent for any process errors or supplier payload failures.';
const emailNotificationTooltip: string = 'Whether to send out email or not to the Business Team Mailing Group.';
const securityGroupsTooltip: string = 'Semicolon delimited list of security groups to authorize users for the Recycling UI and PowerBI reports.';
const adminSecurityGroupTooltip: string = 'Security group of users allowed to change configurations for this program.';
const msCollectionTicketNumberTooltip: string = 'Validates the MS collection ticket number provided by suppliers.';
const collectionSiteCodeTooltip: string = 'Controls whether collection site code is mandatory in payloads.';
const checkJobExistenceTooltip: string = 'Checks whether the job exists when suppliers uploads disposition or invoice and credits.';
const checkJobUnitExistenceTooltip: string = 'Checks whetherr the job unit exists when supplier uploads the disposition or invoice and credits.';
const checkJobStatusTooltip: string = 'Validate whether the job is closed or not for further processing.';
const jobExpiryDaysTooltip: string = 'Days until the job expires, after which point the further processing is blocked.';
const apiMaxTotalUnitsTooltip: string = 'Controls how many records which can be sent via a single api call.';

export const AdminTabPrograms: React.FunctionComponent<IAdminTabProgramsProps> = (props: IAdminTabProgramsProps): JSX.Element => {
    const [errors, setErrors] = useState<string[]>([]);
    const [programs, setPrograms] = useState<Program[]>([]);
    const [inputErrors, setInputErrors] = useState<InputError[]>([]);
    const [successIndicator, setSuccessIndicator] = useState<boolean>(false);
    const [itemBeingEdited, setItemBeingEdited] = useState<Program>();
    const [itemBeingUpdatedOrAdded, setItemBeingUpdatedOrAdded] = useState<Program>();

    // Redux store selectors to get state from the store when it changes.
    const apiLoadPrograms: IApiLoadPrograms =
        useAppSelector<IApiLoadPrograms>((state) => state.adminTabProgramsReducer.apiLoadPrograms);
    const apiUpdateProgram: IApiUpdateProgram =
        useAppSelector<IApiUpdateProgram>((state) => state.adminTabProgramsReducer.apiUpdateProgram);
    const apiAddProgram: IApiAddProgram =
        useAppSelector<IApiAddProgram>((state) => state.adminTabProgramsReducer.apiAddProgram);
    const apiCheckIsMsRecyclingTeamMember: IApiCheckIsMsRecyclingTeamMember =
        useAppSelector<IApiCheckIsMsRecyclingTeamMember>((state) => state.adminTabProgramsReducer.apiCheckIsMsRecyclingTeamMember);

    // Redux store dispatch to send actions to the store.
    const dispatch = useAppDispatch();

    /**
     * Setup columns to display for programs.
     */
    const [columnsPrograms, setColumnsPrograms] = useState<IColumn[]>(
        [
            {
                ...commonColumnProps,
                key: 'column1',
                name: '',
                minWidth: 80,
                data: {
                    addActionButtons: true
                }
            },
            {
                ...commonColumnProps,
                key: 'column2',
                name: 'Program Name',
                fieldName: 'programName',
                minWidth: 150,
                data: {
                    addOnColumnRenderTextField: true,
                    pattern: /^[a-zA-Z -]{5,50}$/,
                    inputErrorMsg: 'Alpha, dashes, spaces, min 5, max 50 length'
                },
                onRenderHeader: (props) => {
                    return renderColumnHeader(props!.column.name, 'programNameTooltip', programNameTooltip)
                }
            },
            {
                ...commonColumnProps,
                key: 'column3',
                name: 'Program Type',
                fieldName: 'programType',
                minWidth: 150,
                data: {
                    addOnColumnRenderTextField: true,
                    pattern: /^[a-zA-Z]{3,20}$/,
                    inputErrorMsg: 'Alpha, min 3, max 20 length'
                },
                onRenderHeader: (props) => {
                    return renderColumnHeader(props!.column.name, 'programTypeTooltip', programTypeTooltip)
                }
            },
            {
                ...commonColumnProps,
                key: 'column4',
                name: 'Business Team Mailing Group',
                fieldName: 'businessTeamMailingGroup',
                minWidth: 150,
                data: {
                    addOnColumnRenderTextField: true,
                    pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    inputErrorMsg: 'Must be valid email'
                },
                onRenderHeader: (props) => {
                    return renderColumnHeader(props!.column.name, 'businessTeamMailingGroupTooltip', businessTeamMailingGroupTooltip)
                }
            },
            {
                ...commonColumnProps,
                key: 'column5',
                name: 'Email Notification',
                fieldName: 'emailNotification',
                minWidth: 80,
                data: {
                    addOnColumnRenderCheckbox: true
                },
                onRenderHeader: (props) => {
                    return renderColumnHeader(props!.column.name, 'emailNotificationTooltip', emailNotificationTooltip)
                }
            },
            {
                ...commonColumnProps,
                key: 'column6',
                name: 'Security Groups',
                fieldName: 'securityGroups',
                minWidth: 150,
                data: {
                    addOnColumnRenderTextField: true,
                    pattern: /^[a-zA-Z-_;]{3,100}$/,
                    inputErrorMsg: 'Alpha, dashes, underscore, semicolons, min 3, max 100 length'
                },
                onRenderHeader: (props) => {
                    return renderColumnHeader(props!.column.name, 'securityGroupsTooltip', securityGroupsTooltip)
                }
            },
            {
                ...commonColumnProps,
                key: 'column7',
                name: 'Admin Security Group',
                fieldName: 'adminSecurityGroup',
                minWidth: 150,
                data: {
                    addOnColumnRenderTextField: true,
                    pattern: /^[a-zA-Z]{3,50}$/,
                    inputErrorMsg: 'Alpha, min 3, max 50 length'
                },
                onRenderHeader: (props) => {
                    return renderColumnHeader(props!.column.name, 'adminSecurityGroupTooltip', adminSecurityGroupTooltip)
                }
            },
            {
                ...commonColumnProps,
                key: 'column8',
                name: 'MS Collection Ticket Number',
                fieldName: 'msCollectionTicketNumber',
                minWidth: 110,
                data: {
                    addOnColumnRenderCheckbox: true
                },
                onRenderHeader: (props) => {
                    return renderColumnHeader(props!.column.name, 'msCollectionTicketNumberTooltip', msCollectionTicketNumberTooltip)
                }
            },
            {
                ...commonColumnProps,
                key: 'column9',
                name: 'Collection Site Code',
                fieldName: 'collectionSiteCode',
                minWidth: 80,
                data: {
                    addOnColumnRenderCheckbox: true
                },
                onRenderHeader: (props) => {
                    return renderColumnHeader(props!.column.name, 'collectionSiteCodeTooltip', collectionSiteCodeTooltip)
                }
            },
            {
                ...commonColumnProps,
                key: 'column10',
                name: 'Check Job Existence',
                fieldName: 'checkJobExistence',
                minWidth: 80,
                data: {
                    addOnColumnRenderCheckbox: true
                },
                onRenderHeader: (props) => {
                    return renderColumnHeader(props!.column.name, 'checkJobExistenceTooltip', checkJobExistenceTooltip)
                }
            },
            {
                ...commonColumnProps,
                key: 'column11',
                name: 'Check Job Unit Existence',
                fieldName: 'checkJobUnitExistence',
                minWidth: 100,
                data: {
                    addOnColumnRenderCheckbox: true
                },
                onRenderHeader: (props) => {
                    return renderColumnHeader(props!.column.name, 'checkJobUnitExistenceTooltio', checkJobUnitExistenceTooltip)
                }
            },
            {
                ...commonColumnProps,
                key: 'column12',
                name: 'Check Job Status',
                fieldName: 'checkJobStatus',
                minWidth: 80,
                data: {
                    addOnColumnRenderCheckbox: true
                },
                onRenderHeader: (props) => {
                    return renderColumnHeader(props!.column.name, 'checkJobStatusTooltip', checkJobStatusTooltip)
                }
            },
            {
                ...commonColumnProps,
                key: 'column13',
                name: 'Job Expiry Days',
                fieldName: 'jobExpiryDays',
                minWidth: 80,
                data: {
                    addOnColumnRenderTextField: true,
                    pattern: /^[0-9]{1,5}$/,
                    inputErrorMsg: 'Numeric only, max 5 digits'
                },
                onRenderHeader: (props) => {
                    return renderColumnHeader(props!.column.name, 'jobExpiryDaysTooltip', jobExpiryDaysTooltip)
                }
            },
            {
                ...commonColumnProps,
                key: 'column14',
                name: 'Api Max Total Units',
                fieldName: 'apiMaxTotalUnits',
                minWidth: 80,
                data: {
                    addOnColumnRenderTextField: true,
                    pattern: /^[0-9]{1,5}$/,
                    inputErrorMsg: 'Numeric only, max 5 digits'
                },
                onRenderHeader: (props) => {
                    return renderColumnHeader(props!.column.name, 'apiMaxTotalUnitsTooltip', apiMaxTotalUnitsTooltip)
                }
            }
        ]
    );

    /**
     * Rerender columns by setting the columns to a new array of the same columns.
     * This will cause the columns to re-render including the action buttons column... the buttons state may
     * change such as becoming disabled or enabled, or buttons showing or hiding, etc.
     */
    const rerenderColumns = useCallback(() => {
        // Use a setTimeout to queue this on the UI thread after the current render pass.
        setTimeout(() => {
            setColumnsPrograms([...columnsPrograms]);
        });
    }, [columnsPrograms]);

    /**
     * This effect does nothing on mount, but will return a cleanup function that runs when the component unmounts.
     */
    useEffect(() => {
        return function cleanup() {
            resetApiCallState();
        }
    }, []);

    /**
     * Handle error.
     * @param errMsg Error message.
     */
    const handleError = useCallback((errMsg: string) => {
        setErrors((prevErrors) => {
            // This will prevent the same error from being displayed if already displayed.
            // ex: Multiple page data load failures might occur if the api is not working,
            // and this page makes multiple load calls for various data.
            if (!prevErrors.includes(errMsg)) {
                return [...prevErrors, errMsg];
            }
            return prevErrors;
        });
    }, []);

    /**
     * Effect for when errors occur in any api call.
     */
    useEffect(() => {
        if (apiLoadPrograms.errMsg) {
            handleError(apiLoadPrograms.errMsg);
        }
        if (apiUpdateProgram.errMsg) {
            handleError(apiUpdateProgram.errMsg);
        }
        if (apiAddProgram.errMsg) {
            handleError(apiAddProgram.errMsg);
        }
        if (apiCheckIsMsRecyclingTeamMember.errMsg) {
            handleError(apiCheckIsMsRecyclingTeamMember.errMsg);
        }
    }, [apiAddProgram.errMsg, apiCheckIsMsRecyclingTeamMember.errMsg, handleError, apiLoadPrograms.errMsg, apiUpdateProgram.errMsg]);

    /**
     * This effect is run once during page load.
     */
    useMountEffect(() => {
        dispatch(callApiLoadPrograms());
        dispatch(callApiCheckIsMsRecyclingTeamMember());
    });

    /**
     * Effect for when programs is loaded.
     */
    useEffect(() => {
        if (apiLoadPrograms.callApiState === CallApiState.DataAvailable) {
            setPrograms(apiLoadPrograms.programs || []);
        }
    }, [apiLoadPrograms.callApiState, apiLoadPrograms.programs]);

    /**
     * Effect for when program is updated or added.
     */
    useEffect(() => {
        if (apiUpdateProgram.callApiState === CallApiState.DataAvailable ||
            apiAddProgram.callApiState === CallApiState.DataAvailable) {
            setSuccessIndicator(true);
            rerenderColumns();
            // Wait for 2 seconds and then reload data.
            setTimeout(() => {
                setSuccessIndicator(false);
                // Reload the programs.
                dispatch(callApiLoadPrograms());
            }, 2000);
        }
    }, [apiAddProgram.callApiState, dispatch, rerenderColumns, apiUpdateProgram.callApiState]);

    /**
     * Edit button clicked event handler.
     * @param program Program being edited.
     */
     const editButtonClicked = useCallback((program: Program): void => {
        setItemBeingEdited(program);
        rerenderColumns();
    }, [rerenderColumns]);

    /**
     * Save (update or add) button clicked event handler.
     * @param program Program being saved.
     */
    const saveButtonClicked = useCallback((program: Program): void => {
        setItemBeingUpdatedOrAdded(program);
        if (program.isNew) {
            dispatch(callApiAddProgram(program));
        } else {
            dispatch(callApiUpdateProgram(program));
        }
        rerenderColumns();
    }, [dispatch, rerenderColumns]);

    /**
     * On column render for action buttons.
     * @param item The item (row) being rendered in the details list. 
     * @param index The index of the row.
     * @param column The column being rendered.
     */
     const onColumnRenderActionButtons = useCallback((item: Program, index: number | undefined, column: IColumn | undefined): JSX.Element => {
        return (
            <div>
                {
                    itemBeingEdited !== item && (
                        <Stack horizontal tokens={stackTokens}>
                            <DefaultButton
                                onClick={event => editButtonClicked(item)}
                                disabled={
                                    apiUpdateProgram.callApiState === CallApiState.Running ||
                                    apiAddProgram.callApiState === CallApiState.Running ||
                                    successIndicator}>
                                <span style={{ whiteSpace: 'nowrap' }}>
                                    Edit
                                </span>
                            </DefaultButton>
                        </Stack>
                    )
                }
                {
                    itemBeingEdited === item && (
                        <Stack horizontal tokens={stackTokens}>
                            <DefaultButton
                                onClick={event => saveButtonClicked(item)}
                                disabled={
                                    checkItemForAnyInputError(inputErrors, item) ||
                                    apiUpdateProgram.callApiState === CallApiState.Running ||
                                    apiAddProgram.callApiState === CallApiState.Running ||
                                    successIndicator}>
                                { itemBeingUpdatedOrAdded === item &&
                                    (apiUpdateProgram.callApiState === CallApiState.Running ||
                                    apiAddProgram.callApiState === CallApiState.Running) ? (
                                    <div style={{ display: 'flex' }}>
                                        <span style={{ marginRight: '6px', whiteSpace: 'nowrap' }}>Saving</span>
                                        <Spinner size={SpinnerSize.medium} className={commonStyles.spinner} style={{ display: 'inline-block' }} />
                                    </div>
                                ) : (
                                    <span style={{ whiteSpace: 'nowrap' }}>
                                        { itemBeingUpdatedOrAdded === item && successIndicator ? 'Saved' : 'Save' }
                                    </span>
                                )}
                            </DefaultButton>
                        </Stack>
                    )
                }
            </div>
        );
    }, [apiAddProgram.callApiState, editButtonClicked, inputErrors, itemBeingEdited, itemBeingUpdatedOrAdded, saveButtonClicked, successIndicator, apiUpdateProgram.callApiState]);

    /**
     * On text field column render.
     * @param item The item (row) being rendered in the details list. 
     * @param index The index of the row.
     * @param column The column being rendered.
     */
    const onColumnRenderTextField = useCallback((item: Program, index: number | undefined, column: IColumn | undefined): JSX.Element => {
        const fieldName: string = column!.fieldName!;
        return (
            <>
                <TextField
                    defaultValue={item[fieldName]}
                    onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                        newValue = newValue || '';
                        item[fieldName] = newValue;
                        const newInputErrors: InputError[] = addOrRemoveError(inputErrors, column!, item, fieldName, newValue);
                        setInputErrors(newInputErrors);
                        setPrograms([...programs]);
                    }}
                    disabled={
                        itemBeingEdited !== item ||
                        apiUpdateProgram.callApiState === CallApiState.Running ||
                        apiAddProgram.callApiState === CallApiState.Running ||
                        successIndicator
                    }
                    errorMessage={checkForInputError(inputErrors, item, fieldName)}>
                </TextField>
            </>
        );
    }, [apiAddProgram.callApiState, inputErrors, itemBeingEdited, programs, successIndicator, apiUpdateProgram.callApiState]);

    /**
     * On checkbox field column render.
     * @param item The item (row) being rendered in the details list. 
     * @param index The index of the row.
     * @param column The column being rendered.
     */
    const onColumnRenderCheckbox = useCallback((item: Program, index: number | undefined, column: IColumn | undefined): JSX.Element => {
        const fieldName: string = column!.fieldName!;
        return (
            <div style={{ margin: '5px 0 0 0', height: '100%' }}>
                <Checkbox
                    checked={item[fieldName]}
                    onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
                        item[fieldName] = checked || false;
                        setPrograms([...programs]);
                    }}
                    disabled={
                        itemBeingEdited !== item ||
                        apiUpdateProgram.callApiState === CallApiState.Running ||
                        apiAddProgram.callApiState === CallApiState.Running ||
                        successIndicator
                    }>
                </Checkbox>
            </div>
        );
    }, [apiAddProgram.callApiState, itemBeingEdited, programs, successIndicator, apiUpdateProgram.callApiState]);

    /**
     * Effect to update onRender handler. Otherwise the component state referenced in onRender
     * would be stale from the render pass it was created on.
     */
    useEffect(() => {
        columnsPrograms.filter(x => x.data?.addActionButtons).forEach(c => c.onRender = onColumnRenderActionButtons);
        columnsPrograms.filter(x => x.data?.addOnColumnRenderCheckbox).forEach(c => c.onRender = onColumnRenderCheckbox);
        columnsPrograms.filter(x => x.data?.addOnColumnRenderTextField).forEach(c => c.onRender = onColumnRenderTextField);
    }, [columnsPrograms, onColumnRenderActionButtons, onColumnRenderCheckbox, onColumnRenderTextField]);

    /**
     * Add button clicked event handler.
     */
    const addButtonClicked = (): void => {
        const newProgram: Program = new Program({
            programType: '',
            programName: '',
            securityGroups: '',
            adminSecurityGroup: '',
            businessTeamMailingGroup: '',
            emailNotification: true,
            configuration: '',
            programConfiguration: new ProgramConfiguration({
                validations: new Validations({
                    msCollectionTicketNumber: true,
                    checkJobExistence: true,
                    checkJobUnitExistence: true,
                    checkJobStatus: true,
                    jobExpiryDays: 365,
                    collectionSiteCode: true,
                    msCompanyCodeValidation: true,
                    assetTagNumberRegex: '',
                    serialNumberRegex: '',
                    apiMaxTotalUnits: 2500
                })
            })
        });
        newProgram.clientRowKey = programs.length.toString();
        let currentInputErrors: InputError[] = [...inputErrors];
        columnsPrograms.forEach((column) => {
            const value: string = newProgram[column.fieldName!];
            currentInputErrors = addOrRemoveError(currentInputErrors, column, newProgram, column.fieldName!, value);
        });
        setInputErrors(currentInputErrors);
        setPrograms([...programs, newProgram]);
        setItemBeingEdited(newProgram);
    }

    return (
        <>
            <ErrorBar errors={errors} onDismiss={(index: number) => {
                setErrors(clearErrorByIndex(errors, index));
            }} />

            <div className="ms-Grid" dir="ltr">
                {apiLoadPrograms.callApiState === CallApiState.Running && (
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{ margin: '12px 12px 12px 12px' }}>
                            <Spinner size={SpinnerSize.medium} className={commonStyles.spinner} />
                        </div>
                    </div>
                )}
                {apiLoadPrograms.callApiState === CallApiState.Completed && (
                    <>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{ margin: '12px 12px 12px 12px' }}>
                                <Text variant='medium'>The grid below shows the program types for which you have administrator permission.</Text>
                            </div>
                        </div>

                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <CustomDetailsList
                                    showExcelExport={true}
                                    exportExcelSheetName="Program Types"
                                    ariaLabelForGrid="Program Types"
                                    displayTotalItems={true}
                                    showPaginator={false}
                                    showPageSize={false}
                                    selectedPage={1}
                                    showNoDataFoundMsg={programs.length === 0}
                                    items={programs}
                                    compact={false}
                                    columns={columnsPrograms}
                                    selectionMode={SelectionMode.none}
                                    getKey={(item: Program) => item.clientRowKey}
                                    setKey="none"
                                    layoutMode={DetailsListLayoutMode.fixedColumns}
                                    isHeaderVisible={true}
                                    constrainMode={ConstrainMode.horizontalConstrained} />
                            </div>
                        </div>

                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <Stack horizontal wrap tokens={stackTokens}>
                                    {apiCheckIsMsRecyclingTeamMember.isMsRecyclingTeamMember && (
                                        <DefaultButton
                                            disabled={
                                                apiUpdateProgram.callApiState === CallApiState.Running ||
                                                apiAddProgram.callApiState === CallApiState.Running ||
                                                successIndicator}
                                            onClick={event => addButtonClicked()}>
                                            <span>Add</span>
                                        </DefaultButton>
                                    )}
                                </Stack>
                            </div>
                        </div>
                    </>
                )}    
            </div>

            <ReloadingDialog
                successIndicator={successIndicator} />
        </>
    );
};
