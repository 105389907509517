import React, { useEffect, useReducer, useRef } from 'react';
import {
    Text,
    Spinner,
    SpinnerSize,
    IconButton,
    Modal,
    Pivot,
    PivotItem,
    FontIcon
} from '@fluentui/react';
import { appConfig } from '../../shell/appConfig';
import { detailsFieldDisplay } from './detailsFieldDisplay';
import { SupplierSummary } from '../../models/supplier/supplierSummary';
import { commonStyles } from '../../common/common.styles';
import { RecyclingDocument } from '../../models/recyclingDetail/recyclingDocument';
import { SupplierJobDetails } from '../../models/supplier/supplierJobDetails';
import { datePlusDays, isScrollable } from '../../common/common.func';
import { controlStyles, detailsModalIconButtonStyles } from './DetailsModal.styles';
import { toLocaleStringTwoDigitFraction } from '../../common/appConstants';

export interface DetailsModalProps {
    isDetailsLoading: boolean;
    showDetails: boolean;
    activeSupplierJob: SupplierJobDetails | undefined | null;
    activeSupplierSummary: SupplierSummary | undefined | null;
    activeRecyclingDocument: RecyclingDocument | undefined | null;
    onClosed: () => void;
}

/**
 * Renders details using a modal popup.
 * @param props Details modal props.
 */
export const DetailsModal: React.FunctionComponent<DetailsModalProps> = (props: DetailsModalProps): JSX.Element => {
    const jobDivRef = useRef<HTMLTableElement | null>(null);
    const unitDivRef = useRef<HTMLTableElement | null>(null);
    const assetDivRef = useRef<HTMLTableElement | null>(null);
    const collectionDivRef = useRef<HTMLTableElement | null>(null);
    const dispositionDivRef = useRef<HTMLTableElement | null>(null);
    const invoiceDivRef = useRef<HTMLTableElement | null>(null);
    const creditDivRef = useRef<HTMLTableElement | null>(null);

    const [, forceRender] = useReducer((s) => s + 1, 0);

    /**
     * This useEffect is to cause one rerender to occur after details have loaded and details are shown.
     * Reason for needing this is to fix an accessibility issue where the divs (jobDivRef and others above)
     * containing the detail tables need to have their tabIndex set to 0 or -1 depending on if the content
     * is scrollable or not. This makes it so the scrollable area can be tabbed to or not, in order to be
     * able to use the keyboard to scroll up and down in those areas.
     */
    useEffect(() => { 
        if (props.showDetails && !props.isDetailsLoading) {
            forceRender();
        }
    }, [props.showDetails, props.isDetailsLoading])

    /**
     * Close details modal handler.
     */
    const closeDetailsModal = (): void => {
        props.onClosed();
    }

    const title: string = `Details for job id ${props.activeSupplierSummary?.supplierJobId} unit id ${props.activeSupplierSummary?.supplierUnitId}`;

    if (!props.showDetails) {
        return <></>;
    }

    return (
        <Modal
            titleAriaId='detailsTitleId'
            isOpen={props.showDetails}
            onDismiss={closeDetailsModal}
            isBlocking={true}
            scrollableContentClassName={controlStyles.detailsModalScrollable}
            containerClassName={controlStyles.detailsModalContainer}>
            <div className={controlStyles.detailsModalHeader}>
                <h2>
                    <Text id="detailsTitleId" variant="large">{title}</Text>
                </h2>
                <IconButton
                    styles={detailsModalIconButtonStyles}
                    iconProps={{ iconName: 'Cancel' }}
                    ariaLabel={'Close popup modal'}
                    onClick={closeDetailsModal}
                />
            </div>
            <div className={controlStyles.detailsModalBody}>
                {props.isDetailsLoading ?
                    <Spinner size={SpinnerSize.medium} className={commonStyles.spinner} />
                    :
                    <>
                        <Pivot>
                            <PivotItem headerText="Job">
                                <div className={controlStyles.detailTableContainer} ref={jobDivRef} tabIndex={isScrollable(jobDivRef.current) ? 0 : -1}>
                                    <table className={controlStyles.detailTable} role="presentation">
                                        <tbody>
                                            {
                                                detailsFieldDisplay.job.map((field, index) => {
                                                    const x = props.activeSupplierJob;
                                                    return x && (
                                                        <tr key={index}>
                                                            <td><Text variant="medium">{field.text}</Text></td>
                                                            {
                                                                field.isDate && (
                                                                    <td><Text variant="medium">{x[field.name] ? x[field.name]?.toLocaleDateString() : ''}</Text></td>
                                                                )
                                                            }
                                                            {
                                                                (field.isCurrency || field.needLocaleNumberFormat) && (
                                                                    <td><Text variant="medium">{x[field.name] ? x[field.name]?.toLocaleString(undefined, toLocaleStringTwoDigitFraction) : ''}</Text></td>
                                                                )
                                                            }
                                                            {
                                                                !field.isDate && !(field.isCurrency || field.needLocaleNumberFormat) && (
                                                                    <td><Text variant="medium">{x[field.name]}</Text></td>
                                                                )
                                                            }
                                                        </tr>
                                                    );
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </PivotItem>
                            <PivotItem headerText="Unit">
                                <div className={controlStyles.detailTableContainer} ref={unitDivRef} tabIndex={isScrollable(unitDivRef.current) ? 0 : -1}>
                                    <table className={controlStyles.detailTable} role="presentation">
                                        <tbody>
                                            { props.activeRecyclingDocument?.assetDetails ?
                                                <>
                                                    {
                                                        detailsFieldDisplay.unit.map((field, index) => {
                                                            const x = props.activeRecyclingDocument;
                                                            return x && (
                                                                <tr key={index}>
                                                                    <td><Text variant="medium">{field.text}</Text></td>
                                                                    {
                                                                        field.isDate && (
                                                                            <td><Text variant="medium">{x[field.name] ? x[field.name]?.toLocaleDateString() : ''}</Text></td>
                                                                        )
                                                                    }
                                                                    {
                                                                        (field.isCurrency || field.needLocaleNumberFormat) && (
                                                                            <td><Text variant="medium">{x[field.name] ? x[field.name]?.toLocaleString(undefined, toLocaleStringTwoDigitFraction) : ''}</Text></td>
                                                                        )
                                                                    }
                                                                    {
                                                                        !field.isDate && !(field.isCurrency || field.needLocaleNumberFormat) && (
                                                                            <td><Text variant="medium">{x[field.name]}</Text></td>
                                                                        )
                                                                    }
                                                                </tr>
                                                            );
                                                        })
                                                    }
                                                </>
                                                :
                                                <>
                                                    <tr>
                                                        <td><Text variant="medium">No Unit data available</Text></td>
                                                    </tr>
                                                </>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </PivotItem>
                            <PivotItem headerText="Asset">
                                <div className={controlStyles.detailTableContainer} ref={assetDivRef} tabIndex={isScrollable(assetDivRef.current) ? 0 : -1}>
                                    <table className={controlStyles.detailTable} role="presentation">
                                        <tbody>
                                            { props.activeRecyclingDocument?.assetDetails ?
                                                <>
                                                    {
                                                        detailsFieldDisplay.asset.map((field, index) => {
                                                            const x = props.activeRecyclingDocument?.assetDetails!;
                                                            return x && (
                                                                <tr key={index}>
                                                                    <td><Text variant="medium">{field.text}</Text></td>
                                                                    {
                                                                        field.isDate && (
                                                                            <td><Text variant="medium">{x[field.name] ? x[field.name]?.toLocaleDateString() : ''}</Text></td>
                                                                        )
                                                                    }
                                                                    {
                                                                        (field.isCurrency || field.needLocaleNumberFormat) && (
                                                                            <td><Text variant="medium">{x[field.name] ? x[field.name]?.toLocaleString(undefined, toLocaleStringTwoDigitFraction) : ''}</Text></td>
                                                                        )
                                                                    }
                                                                    {
                                                                        !field.isDate && !(field.isCurrency || field.needLocaleNumberFormat) && (
                                                                            <td><Text variant="medium">{x[field.name]}</Text></td>
                                                                        )
                                                                    }
                                                                </tr>
                                                            );
                                                        })
                                                    }
                                                </>
                                                :
                                                <>
                                                    <tr>
                                                        <td><Text variant="medium">No Asset data available</Text></td>
                                                    </tr>
                                                </>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </PivotItem>
                            <PivotItem headerText="Collection">
                                <div className={controlStyles.detailTableContainer} ref={collectionDivRef} tabIndex={isScrollable(collectionDivRef.current) ? 0 : -1}>
                                    <table className={controlStyles.detailTable} role="presentation">
                                        <tbody>
                                            { props.activeRecyclingDocument?.collection ?
                                                <>
                                                    <>
                                                        {
                                                            detailsFieldDisplay.collection.map((field, index) => {
                                                                const x = props.activeRecyclingDocument?.collection;
                                                                return x && (
                                                                    <tr key={index}>
                                                                        <td><Text variant="medium">{field.text}</Text></td>
                                                                        {
                                                                            field.isDate && (
                                                                                <td><Text variant="medium">{x[field.name] ? x[field.name]?.toLocaleDateString() : ''}</Text></td>
                                                                            )
                                                                        }
                                                                        {
                                                                            (field.isCurrency || field.needLocaleNumberFormat) && (
                                                                                <td><Text variant="medium">{x[field.name] ? x[field.name]?.toLocaleString(undefined, toLocaleStringTwoDigitFraction) : ''}</Text></td>
                                                                            )
                                                                        }
                                                                        {
                                                                            !field.isDate && !(field.isCurrency || field.needLocaleNumberFormat) && (
                                                                                <td><Text variant="medium">{x[field.name]}</Text></td>
                                                                            )
                                                                        }
                                                                    </tr>
                                                                );
                                                            })
                                                        }
                                                    </>
                                                    <tr>
                                                        <td><Text variant="medium">Collection SLA (days): {appConfig.current.settings.collectionSlaDays}</Text></td>
                                                        <td>
                                                            {
                                                                (props.activeRecyclingDocument.collection?.supplierReportingDate && props.activeRecyclingDocument.collection?.supplierReceivedDate) &&
                                                                props.activeRecyclingDocument.collection!.supplierReportingDate > datePlusDays(props.activeRecyclingDocument.collection!.supplierReceivedDate, appConfig.current.settings.collectionSlaDays) &&
                                                                <Text variant="medium" className={controlStyles.slaNotMet}>
                                                                    <FontIcon iconName="Warning12" style={{ paddingRight: 4, fontSize: 16 }} />
                                                                    Not Met
                                                                </Text>
                                                            }
                                                            {
                                                                (props.activeRecyclingDocument.collection?.supplierReportingDate && props.activeRecyclingDocument.collection?.supplierReceivedDate) &&
                                                                props.activeRecyclingDocument.collection!.supplierReportingDate <= datePlusDays(props.activeRecyclingDocument.collection!.supplierReceivedDate, appConfig.current.settings.collectionSlaDays) &&
                                                                <Text variant="medium" className={controlStyles.slaMet}>
                                                                    <FontIcon iconName="CheckMark" style={{ paddingRight: 4, fontSize: 16 }} />
                                                                    Met
                                                                </Text>
                                                            }
                                                            {
                                                                (!props.activeRecyclingDocument.collection?.supplierReportingDate || !props.activeRecyclingDocument.collection?.supplierReceivedDate) &&
                                                                <Text variant="medium">
                                                                    Undetermined
                                                                </Text>
                                                            }
                                                        </td>
                                                    </tr>
                                                </>
                                                :
                                                <>
                                                    <tr>
                                                        <td><Text variant="medium">No Collection data available</Text></td>
                                                    </tr>
                                                </>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </PivotItem>
                            <PivotItem headerText="Disposition">
                                <div className={controlStyles.detailTableContainer} ref={dispositionDivRef} tabIndex={isScrollable(dispositionDivRef.current) ? 0 : -1}>
                                    <table className={controlStyles.detailTable} role="presentation">
                                        <tbody>
                                            { props.activeRecyclingDocument?.disposition ?
                                                <>
                                                    <>
                                                        {
                                                            detailsFieldDisplay.disposition.map((field, index) => {
                                                                const x = props.activeRecyclingDocument?.disposition;
                                                                return x && (
                                                                    <tr key={index}>
                                                                        <td><Text variant="medium">{field.text}</Text></td>
                                                                        {
                                                                            field.isDate && (
                                                                                <td><Text variant="medium">{x[field.name] ? x[field.name]?.toLocaleDateString() : ''}</Text></td>
                                                                            )
                                                                        }
                                                                        {
                                                                            (field.isCurrency || field.needLocaleNumberFormat) && (
                                                                                <td><Text variant="medium">{x[field.name] ? x[field.name]?.toLocaleString(undefined, toLocaleStringTwoDigitFraction) : ''}</Text></td>
                                                                            )
                                                                        }
                                                                        {
                                                                            !field.isDate && !(field.isCurrency || field.needLocaleNumberFormat) && (
                                                                                <td><Text variant="medium">{x[field.name]}</Text></td>
                                                                            )
                                                                        }
                                                                    </tr>
                                                                );
                                                            })
                                                        }
                                                    </>
                                                    <tr>
                                                        <td><Text variant="medium">Disposition SLA (days): {appConfig.current.settings.dispositionSlaDays}</Text></td>
                                                        <td>
                                                            {
                                                                (props.activeRecyclingDocument.disposition?.supplierReportingDate && props.activeRecyclingDocument.collection?.supplierReceivedDate) &&
                                                                props.activeRecyclingDocument.disposition!.supplierReportingDate > datePlusDays(props.activeRecyclingDocument.collection!.supplierReceivedDate, appConfig.current.settings.dispositionSlaDays) &&
                                                                <Text variant="medium" className={controlStyles.slaNotMet}>
                                                                    <FontIcon iconName="Warning12" style={{ paddingRight: 4, fontSize: 16 }} />
                                                                    Not Met
                                                                </Text>
                                                            }
                                                            {
                                                                (props.activeRecyclingDocument.disposition?.supplierReportingDate && props.activeRecyclingDocument.collection?.supplierReceivedDate) &&
                                                                props.activeRecyclingDocument.disposition!.supplierReportingDate <= datePlusDays(props.activeRecyclingDocument.collection!.supplierReceivedDate, appConfig.current.settings.dispositionSlaDays) &&
                                                                <Text variant="medium" className={controlStyles.slaMet}>
                                                                    <FontIcon iconName="CheckMark" style={{ paddingRight: 4, fontSize: 16 }} />
                                                                    Met
                                                                </Text>
                                                            }
                                                            {
                                                                (!props.activeRecyclingDocument.disposition?.supplierReportingDate || !props.activeRecyclingDocument.collection?.supplierReceivedDate) &&
                                                                <Text variant="medium">
                                                                    Undetermined
                                                                </Text>
                                                            }
                                                        </td>
                                                    </tr>
                                                </>
                                                :
                                                <>
                                                    <tr>
                                                        <td><Text variant="medium">No Disposition data available</Text></td>
                                                    </tr>
                                                </>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </PivotItem>
                            <PivotItem headerText="Invoice">
                                <div className={controlStyles.detailTableContainer} ref={invoiceDivRef} tabIndex={isScrollable(invoiceDivRef.current) ? 0 : -1}>
                                    <table className={controlStyles.detailTable} role="presentation">
                                        <tbody>
                                            { props.activeRecyclingDocument?.invoice ?
                                                <>
                                                    <>
                                                        {
                                                            detailsFieldDisplay.invoice.map((field, index) => {
                                                                const x = props.activeRecyclingDocument?.invoice;
                                                                return x && (
                                                                    <tr key={index}>
                                                                        <td><Text variant="medium">{field.text}</Text></td>
                                                                        {
                                                                            field.isDate && (
                                                                                <td><Text variant="medium">{x[field.name] ? x[field.name]?.toLocaleDateString() : ''}</Text></td>
                                                                            )
                                                                        }
                                                                        {
                                                                            (field.isCurrency || field.needLocaleNumberFormat) && (
                                                                                <td><Text variant="medium">{x[field.name] ? x[field.name]?.toLocaleString(undefined, toLocaleStringTwoDigitFraction) : ''}</Text></td>
                                                                            )
                                                                        }
                                                                        {
                                                                            !field.isDate && !(field.isCurrency || field.needLocaleNumberFormat) && (
                                                                                <td><Text variant="medium">{x[field.name]}</Text></td>
                                                                            )
                                                                        }
                                                                    </tr>
                                                                );
                                                            })
                                                        }
                                                    </>
                                                    <tr>
                                                        <td><Text variant="medium">Invoice SLA (days): {appConfig.current.settings.invoiceSlaDays}</Text></td>
                                                        <td>
                                                            {
                                                                (props.activeRecyclingDocument.invoice?.supplierReportingDate && props.activeRecyclingDocument.collection?.supplierReceivedDate) &&
                                                                props.activeRecyclingDocument.invoice!.supplierReportingDate > datePlusDays(props.activeRecyclingDocument.collection!.supplierReceivedDate, appConfig.current.settings.invoiceSlaDays) &&
                                                                <Text variant="medium" className={controlStyles.slaNotMet}>
                                                                    <FontIcon iconName="Warning12" style={{ paddingRight: 4, fontSize: 16 }} />
                                                                    Not Met
                                                                </Text>
                                                            }
                                                            {
                                                                (props.activeRecyclingDocument.invoice?.supplierReportingDate && props.activeRecyclingDocument.collection?.supplierReceivedDate) &&
                                                                props.activeRecyclingDocument.invoice!.supplierReportingDate <= datePlusDays(props.activeRecyclingDocument.collection!.supplierReceivedDate, appConfig.current.settings.invoiceSlaDays) &&
                                                                <Text variant="medium" className={controlStyles.slaMet}>
                                                                    <FontIcon iconName="CheckMark" style={{ paddingRight: 4, fontSize: 16 }} />
                                                                    Met
                                                                </Text>
                                                            }
                                                            {
                                                                (!props.activeRecyclingDocument.invoice?.supplierReportingDate || !props.activeRecyclingDocument.collection?.supplierReceivedDate) &&
                                                                <Text variant="medium">
                                                                    Undetermined
                                                                </Text>
                                                            }
                                                        </td>
                                                    </tr>
                                                </>
                                                :
                                                <>
                                                    <tr>
                                                        <td><Text variant="medium">No Invoice data available</Text></td>
                                                    </tr>
                                                </>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </PivotItem>
                            <PivotItem headerText="Credit">
                                <div className={controlStyles.detailTableContainer} ref={creditDivRef} tabIndex={isScrollable(creditDivRef.current) ? 0 : -1}>
                                    <table className={controlStyles.detailTable} role="presentation">
                                        <tbody>
                                            { props.activeRecyclingDocument?.credit ?
                                                <>
                                                    <>
                                                        {
                                                            detailsFieldDisplay.credit.map((field, index) => {
                                                                const x = props.activeRecyclingDocument?.credit!;
                                                                return x && (
                                                                    <tr key={index}>
                                                                        <td><Text variant="medium">{field.text}</Text></td>
                                                                        {
                                                                            field.isDate && (
                                                                                <td><Text variant="medium">{x[field.name] ? x[field.name]?.toLocaleDateString() : ''}</Text></td>
                                                                            )
                                                                        }
                                                                        {
                                                                            (field.isCurrency || field.needLocaleNumberFormat) && (
                                                                                <td><Text variant="medium">{x[field.name] ? x[field.name]?.toLocaleString(undefined, toLocaleStringTwoDigitFraction) : ''}</Text></td>
                                                                            )
                                                                        }
                                                                        {
                                                                            !field.isDate && !(field.isCurrency || field.needLocaleNumberFormat) && (
                                                                                <td><Text variant="medium">{x[field.name]}</Text></td>
                                                                            )
                                                                        }
                                                                    </tr>
                                                                );
                                                            })
                                                        }
                                                    </>
                                                    <tr>
                                                        <td><Text variant="medium">Credit SLA (days): {appConfig.current.settings.creditSlaDays}</Text></td>
                                                        <td>
                                                            {
                                                                (props.activeRecyclingDocument.credit?.supplierReportingDate && props.activeRecyclingDocument.collection?.supplierReceivedDate) &&
                                                                props.activeRecyclingDocument.credit!.supplierReportingDate > datePlusDays(props.activeRecyclingDocument.collection!.supplierReceivedDate, appConfig.current.settings.creditSlaDays) &&
                                                                <Text variant="medium" className={controlStyles.slaNotMet}>
                                                                    <FontIcon iconName="Warning12" style={{ paddingRight: 4, fontSize: 16 }} />
                                                                    Not Met
                                                                </Text>
                                                            }
                                                            {
                                                                (props.activeRecyclingDocument.credit?.supplierReportingDate && props.activeRecyclingDocument.collection?.supplierReceivedDate) &&
                                                                props.activeRecyclingDocument.credit!.supplierReportingDate <= datePlusDays(props.activeRecyclingDocument.collection!.supplierReceivedDate, appConfig.current.settings.creditSlaDays) &&
                                                                <Text variant="medium" className={controlStyles.slaMet}>
                                                                    <FontIcon iconName="CheckMark" style={{ paddingRight: 4, fontSize: 16 }} />
                                                                    Met
                                                                </Text>
                                                            }
                                                            {
                                                                (!props.activeRecyclingDocument.credit?.supplierReportingDate || !props.activeRecyclingDocument.collection?.supplierReceivedDate) &&
                                                                <Text variant="medium">
                                                                    Undetermined
                                                                </Text>
                                                            }
                                                        </td>
                                                    </tr>
                                                </>
                                                :
                                                <>
                                                    <tr>
                                                        <td><Text variant="medium">No Credit data available</Text></td>
                                                    </tr>
                                                </>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </PivotItem>
                        </Pivot>
                    </>
                }
            </div>
        </Modal>
    );
}
