import {
    ApplicationInsights,
    IMetricTelemetry,
    IExceptionTelemetry,
    IEventTelemetry,
    ITraceTelemetry
} from '@microsoft/applicationinsights-web';
import { flatProperties } from '../../common/common.func';

class TelemetryService {
    private appInsights: ApplicationInsights;
    private logPrefix: string = '[UI] ';

    // A flag to determine whether or not to send telemetry to dev console.
    private logToConsole: boolean = process.env.NODE_ENV === 'development';

    constructor() {
        this.appInsights = {} as ApplicationInsights;
    }

    /**
     * Initializes the TelemetryService.
     * @param appInsightsInstrumentationKey App insights instrumentation key.
     */
    public initialize(appInsightsInstrumentationKey: string) {
        this.appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: appInsightsInstrumentationKey
            }
        });

        this.appInsights.loadAppInsights();
    }

    /**
     * Log metric.
     * Typically used to send regular reports of performance indicators.
     * @param metric A string that identifies the metric.
     * @param customProperties Additional properties to send with the metric.
     */
    public trackMetric(metric: IMetricTelemetry, customProperties?: { [key: string]: unknown }) { 
        if (this.logToConsole) {
            console.log(`${this.logPrefix}Metric: ${metric}, ${flatProperties(customProperties)}`);
        } else {
            this.appInsights.trackMetric(metric, customProperties);
        }
    }

    /**
     * Log an exception you have caught.
     * @param exception The exception to log.
     */
    public trackException(exception: IExceptionTelemetry) {
        if (this.logToConsole) {
            console.log(`${this.logPrefix}Exception: ${exception}`);
        } else {
            this.appInsights.trackException(exception);
        }
    }

    /**
     * Log a user action or other occurrence.
     * @param event The telemetry event to send.
     * @param customProperties Optional additional properties on the event.
     */
    public trackEvent(event: IEventTelemetry, customProperties?: { [key: string]: unknown }) {        
        if (this.logToConsole) {
            console.log(`${this.logPrefix}Event: ${event}, ${flatProperties(customProperties)}`);
        } else {
            this.appInsights.trackEvent(event, customProperties);
        }
    }

    /**
     * Log a diagnostic message.
     * @param trace A diagnostic trace to log.
     * @param customProperties Optional additional properties.
     */
    public trackTrace(trace: ITraceTelemetry, customProperties?: { [key: string]: unknown }) {
        if (this.logToConsole) {
            console.log(`${this.logPrefix}Trace: ${trace}, ${flatProperties(customProperties)}`);
        } else {
            this.appInsights.trackTrace(trace, customProperties);
        }
    }

    /**
     * Send telemetry data immediately.
     */
    public sendTelemetryNow() {
        this.appInsights.flush();
    }
}

export const telemetryService = new TelemetryService();
