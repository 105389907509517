import { AnyAction, Reducer } from 'redux';
import * as actionTypes from '../../actions/actionTypes';
import { IApiLoadSupplierJobTypes, IApiUpdateSupplierJobType, IApiAddSupplierJobType, IApiDeleteSupplierJobType } from '../../actions/adminTabActions/adminTabSupplierJobTypes.action';
import { CallApiState, StateUpdateOption } from '../../actions/generic.action';

export interface IAdminTabSupplierJobTypesReducerState {
    apiLoadSupplierJobTypes: IApiLoadSupplierJobTypes;
    apiUpdateSupplierJobType: IApiUpdateSupplierJobType;
    apiAddSupplierJobType: IApiAddSupplierJobType;
    apiDeleteSupplierJobType: IApiDeleteSupplierJobType;
}

const initialAdminTabSupplierReducerState: IAdminTabSupplierJobTypesReducerState = {
    apiLoadSupplierJobTypes: {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        supplierJobTypes: undefined
    },
    apiUpdateSupplierJobType: {
        callApiState: CallApiState.Initial,
        errMsg: undefined
    },
    apiAddSupplierJobType: {
        callApiState: CallApiState.Initial,
        errMsg: undefined
    },
    apiDeleteSupplierJobType: {
        callApiState: CallApiState.Initial,
        errMsg: undefined
    }
};

export const adminTabSupplierJobTypesReducer: Reducer<IAdminTabSupplierJobTypesReducerState, AnyAction> = (
    state: IAdminTabSupplierJobTypesReducerState = initialAdminTabSupplierReducerState, action: AnyAction
): IAdminTabSupplierJobTypesReducerState => {
    switch (action.type) {
        case actionTypes.API_LOAD_SUPPLIER_JOB_TYPES: {
            const payload: IApiLoadSupplierJobTypes = action.payload as IApiLoadSupplierJobTypes;
            switch (payload.stateUpdateOption) {
                case StateUpdateOption.ErrorOnly:
                    return {
                        ...state,
                        // Keep the prior payload except for the errMsg which will be set to undefined.
                        apiLoadSupplierJobTypes: {
                            ...state.apiLoadSupplierJobTypes,
                            errMsg: undefined
                        }
                    }
                default:
                    return {
                        ...state,
                        apiLoadSupplierJobTypes: { ...payload }
                    }
            }
        }
        case actionTypes.API_UPDATE_SUPPLIER_JOB_TYPE: {
            const payload: IApiUpdateSupplierJobType = action.payload as IApiUpdateSupplierJobType;
            switch (payload.stateUpdateOption) {
                case StateUpdateOption.ErrorOnly:
                    return {
                        ...state,
                        // Keep the prior payload except for the errMsg which will be set to undefined.
                        apiUpdateSupplierJobType: {
                            ...state.apiUpdateSupplierJobType,
                            errMsg: undefined
                        }
                    }
                default:
                    return {
                        ...state,
                        apiUpdateSupplierJobType: { ...payload }
                    }
            }
        }
        case actionTypes.API_ADD_SUPPLIER_JOB_TYPE: {
            const payload: IApiAddSupplierJobType = action.payload as IApiAddSupplierJobType;
            switch (payload.stateUpdateOption) {
                case StateUpdateOption.ErrorOnly:
                    return {
                        ...state,
                        // Keep the prior payload except for the errMsg which will be set to undefined.
                        apiAddSupplierJobType: {
                            ...state.apiAddSupplierJobType,
                            errMsg: undefined
                        }
                    }
                default:
                    return {
                        ...state,
                        apiAddSupplierJobType: { ...payload }
                    }
            }
        }
        case actionTypes.API_DELETE_SUPPLIER_JOB_TYPE: {
            const payload: IApiDeleteSupplierJobType = action.payload as IApiDeleteSupplierJobType;
            switch (payload.stateUpdateOption) {
                case StateUpdateOption.ErrorOnly:
                    return {
                        ...state,
                        // Keep the prior payload except for the errMsg which will be set to undefined.
                        apiDeleteSupplierJobType: {
                            ...state.apiDeleteSupplierJobType,
                            errMsg: undefined
                        }
                    }
                default:
                    return {
                        ...state,
                        apiDeleteSupplierJobType: { ...payload }
                    }
            }
        }
        default:
    }

    return state;
};
