import { DestructionType } from '../../../models/domainData/destructionType';
import { serviceApiClient } from '../../../services/api/serviceApiClient';
import { AppDispatch, reduxStore } from '../../reduxStore';
import * as actionTypes from '../actionTypes';
import { callApi, CallApiState, ICallApiBase } from '../generic.action';

/**
 * Load destruction types action payload.
 */
export interface IApiLoadDestructionTypes extends ICallApiBase {
    destructionTypes?: DestructionType[] | null;
}

/**
 * Load destruction types.
 * @returns Redux dispatch function.
 */
export const callApiLoadDestructionTypes = (programType: string): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<DestructionType[]>(
        actionTypes.API_LOAD_DESTRUCTION_TYPES,
        async () => {
            return await serviceApiClient.destructionTypes(programType) || [];
        },
        (payload: IApiLoadDestructionTypes, data) => {
            if (data) {
                // Set the clientRowKey in each object so it can be bound into the DetailsList.
                data.forEach((value: DestructionType, index: number) => {
                    value.clientRowKey = index.toString()
                });
            }

            payload.destructionTypes = data;
        }
    );
};

/**
 * Update destruction type action payload.
 */
export interface IApiUpdateDestructionType extends ICallApiBase {
    // No data returned from this api.
}

/**
 * Update destruction type.
 * @param destructionType Destruction type to update.
 * @returns Redux dispatch function.
 */
export const callApiUpdateDestructionType = (destructionType: DestructionType): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<null>(
        actionTypes.API_UPDATE_DESTRUCTION_TYPE,
        async () => {
            return await serviceApiClient.updateDestructionType(destructionType);
        },
        (payload: IApiUpdateDestructionType, data) => {
            // No data returned from this api.
        }
    );
};

/**
 * Add destruction type action payload.
 */
export interface IApiAddDestructionType extends ICallApiBase {
    // No data returned from this api.
}

/**
 * Add destruction type.
 * @param destructionType Destruction type to add.
 * @param programType Program type.
 * @returns Redux dispatch function.
 */
export const callApiAddDestructionType = (destructionType: DestructionType, programType: string): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<null>(
        actionTypes.API_ADD_DESTRUCTION_TYPE,
        async () => {
            return await serviceApiClient.addDestructionType(destructionType, programType);
        },
        (payload: IApiAddDestructionType, data) => {
            // No data returned from this api.
        }
    );
};

/**
 * Delete destruction type action payload.
 */
export interface IApiDeleteDestructionType extends ICallApiBase {
    // No data returned from this api.
}

/**
 * Delete destruction type.
 * @param destructionType Destruction type to delete.
 * @param programType Program type.
 * @returns Redux dispatch function.
 */
export const callApiDeleteDestructionType = (destructionType: DestructionType): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<null>(
        actionTypes.API_DELETE_DESTRUCTION_TYPE,
        async () => {
            return await serviceApiClient.deleteDestructionType(destructionType);
        },
        (payload: IApiDeleteDestructionType, data) => {
            // No data returned from this api.
        }
    );
};

/**
 * Reset the page action call state for all api calls.
 * This clear all data from the Redux store for these calls, including any error state for failed calls.
 * This makes it so if there was a failed call and an error was shown on the page, if the user navigates
 * to another page and back, the prior error would not be shown.
 */
export const resetApiCallState = () => {
    const apiLoadDestructionTypes: IApiLoadDestructionTypes = {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        destructionTypes: undefined
    };
    reduxStore.dispatch({
        type: actionTypes.API_LOAD_DESTRUCTION_TYPES,
        payload: apiLoadDestructionTypes
    });

    const apiUpdateDestructionTypes: IApiUpdateDestructionType = {
        callApiState: CallApiState.Initial,
        errMsg: undefined
    };
    reduxStore.dispatch({
        type: actionTypes.API_UPDATE_DESTRUCTION_TYPE,
        payload: apiUpdateDestructionTypes
    });

    const apiAddDestructionTypes: IApiAddDestructionType = {
        callApiState: CallApiState.Initial,
        errMsg: undefined
    };
    reduxStore.dispatch({
        type: actionTypes.API_ADD_DESTRUCTION_TYPE,
        payload: apiAddDestructionTypes
    });

    const apiDeleteDestructionTypes: IApiDeleteDestructionType = {
        callApiState: CallApiState.Initial,
        errMsg: undefined
    };
    reduxStore.dispatch({
        type: actionTypes.API_DELETE_DESTRUCTION_TYPE,
        payload: apiDeleteDestructionTypes
    });
};
