export interface ISupplierDocumentDetails {
    programType?: string;
    supplierId?: string;
    correlationId?: string;
    supplierReportingDate?: Date;
    supplierPoNumber?: string;
    supplierInvoiceNumber?: string;
    msCompanyCode?: string;
    fileName?: string;
    fileBlobUrl?: string;
}

export class SupplierDocumentDetails implements ISupplierDocumentDetails {
    public programType?: string;
    public supplierId?: string;
    public correlationId?: string;
    public supplierReportingDate?: Date;
    public supplierPoNumber?: string;
    public supplierInvoiceNumber?: string;
    public msCompanyCode?: string;
    public fileName?: string;
    public fileBlobUrl?: string;

    // Below are properties NOT returned from the server but are used for client side purposes.
    public clientRowKey: string = '';

    constructor(jsonData: ISupplierDocumentDetails) {
        this.programType = jsonData.programType;
        this.supplierId = jsonData.supplierId;
        this.correlationId = jsonData.correlationId;
        this.supplierReportingDate = jsonData.supplierReportingDate;
        this.supplierPoNumber = jsonData.supplierPoNumber;
        this.supplierInvoiceNumber = jsonData.supplierInvoiceNumber;
        this.msCompanyCode = jsonData.msCompanyCode;
        this.fileName = jsonData.fileName;
        this.fileBlobUrl = jsonData.fileBlobUrl;
    }
}
