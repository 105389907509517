export interface IDbdImageLink {
    fileName: string;
    fileBlobUrl: string;
}

export class DbdImageLink implements IDbdImageLink {
    public fileName: string;
    public fileBlobUrl: string;

    // Below are properties NOT returned from the server but are used for client side purposes.
    public clientRowKey: string = '';

    constructor(jsonData: IDbdImageLink) {
        this.fileName = jsonData.fileName;
        this.fileBlobUrl = jsonData.fileBlobUrl;
    }
}
