import React from 'react';
import { useNavigate } from 'react-router-dom';
import { INavLink, INavLinkGroup, Navigation } from '@coherence-design-system/navigation';
import { appConfig } from '../appConfig';
import { useAppSelector } from '../../store/hooks';
import { appConstants } from '../../common/appConstants';

export interface AppNavProps {
    isAuthorizedForProgramTypes?: () => boolean;
    isUserMemberOfRecyclingTeam?: () => boolean;
}

export const AppNav: React.FunctionComponent<AppNavProps> = (props: AppNavProps): JSX.Element => {
    // See: https://stackoverflow.com/questions/63471931/using-history-with-react-router-dom-v6
    // https://devtop.org/react-router-6-what-changed-upgrading-guide/
    // https://javascript.plainenglish.io/4-easy-steps-to-upgrade-react-router-v5-to-v6-d3016b3a8d2a
    // https://medium.com/@manishsundriyal/whats-new-in-react-router-v6-20eefe665be9
    // https://reactrouterdotcom.fly.dev/docs/en/v6/upgrading/v5
    const navigate = useNavigate();

    // Redux store selectors to get state from the store when it changes.
    const navIsOpen: boolean =
        useAppSelector<boolean>((state) => state.appReducer.navIsOpen);

    const pageIsSelected = (pagePath: string, altPagePaths: string | undefined): boolean => {
        let isMatch: boolean = false;
        const pathMatch = (checkPath: string): boolean => {
            const path: string = `${window.location.origin}${window.location.pathname}`.toLowerCase();
            const test: string = `${window.location.protocol}//${window.location.host}${appConstants.publicUrl}/${checkPath}`.toLowerCase();
            // Check to see if the current path starts with the test path. Using startsWith rather than direct equality
            // because some paths, like Edit, also have a PO number afterwards (/Edit/1234567).
            return path.startsWith(test);
        }
        if (pathMatch(pagePath)) {
            isMatch = true;
        }
        // If alternate page paths to determine if selected, check those.
        // altPagePaths can contain multiple paths delimited by |.
        if (altPagePaths) {
            const arr: string[] = altPagePaths.split('|');
            for (let i: number = 0; i < arr.length; i++) {
                if (pathMatch(arr[i])) {
                    isMatch = true;
                    break;
                }
            }
        }

        return isMatch;
    };

    const createNavLink = (pageName: string, ariaLabel: string, pagePath: string, icon: string, altPagePaths?: string): INavLink => {
        return {
            name: pageName,
            url: `${appConstants.publicUrl}/${pagePath}`,
            onClick: () => {
                navigate(`${appConstants.publicUrl}/${pagePath}`);
            },
            ariaLabel: ariaLabel,
            key: `${pageName}Key`,
            icon: icon,
            isSelected: pageIsSelected(pagePath, altPagePaths)
        } as INavLink;
    };

    const navLinks: INavLink[] = []

    if (props.isAuthorizedForProgramTypes && props.isAuthorizedForProgramTypes()) {
        navLinks.push(...[
            createNavLink('Home', 'Home', 'Home', 'Home'),
            createNavLink('Connected Data', 'Connected Data', 'ConnectedData', 'PlugConnected'),
            createNavLink('Certificates & Documents', 'Certificates & Documents', 'Certificates', 'Certificate'),
            createNavLink('Data Bearing Device', 'Data Bearing Device', 'DBD', 'HardDriveGroup'),
            createNavLink('Invoice Summary', 'Invoice Summary', 'InvoiceSummary', 'M365InvoicingLogo'),
            createNavLink('Credit Summary', 'Credit Summary', 'CreditSummary', 'Table'),
            createNavLink('Payment Notifications', 'Payment Notifications', 'PaymentNotifications', 'Money'),
            createNavLink('Transport & Packaging', 'Transport & Packaging', 'TransportAndPackaging', 'DeliveryTruck'),
            createNavLink('Carbon Footprint Calculator', 'Carbon Footprint Calculator', 'CarbonFootprint', 'Calculator'),
            createNavLink('Reports', 'Reports', 'Reports', 'ReportDocument')
        ]);
    }

    if (appConfig.current.featureFlighting.displayAdministrationPage) {
        navLinks.push(createNavLink('Administration', 'Administration', 'Administration', 'AdminALogo32'));
    }

    if (appConfig.current.settings.displayDiagnosticsPage && props.isUserMemberOfRecyclingTeam) {
        navLinks.push(createNavLink('Diagnostics', 'Diagnostics', 'Diagnostics', 'Settings'));
    }

    const navLinkGroups: INavLinkGroup[] = [
        {
            key: 'Left Nav Menu',
            links: navLinks
        } as INavLinkGroup
    ]

    return (
        <Navigation
            groups={navLinkGroups}
            isNavCollapsed={!navIsOpen}
        />
    );
}
