export interface ISupplierTrend {
    programType: string;
    supplierId: string;
    supplierName: string;
    supplierJobId: string;
    type: string;
    totalCount: number;
    reportingDate: Date;
}

export class SupplierTrend implements ISupplierTrend {
    public programType: string;
    public supplierId: string;
    public supplierName: string;
    public supplierJobId: string;
    public type: string;
    public totalCount: number;
    public reportingDate: Date;

    // Below are properties NOT returned from the server but are used for client side purposes.
    public clientRowKey: string = '';

    constructor(jsonData: ISupplierTrend) {
        this.programType = jsonData.programType;
        this.supplierId = jsonData.supplierId;
        this.supplierName = jsonData.supplierName;
        this.supplierJobId = jsonData.supplierJobId;
        this.type = jsonData.type;
        this.totalCount = jsonData.totalCount;
        this.reportingDate = new Date(jsonData.reportingDate);
    }
}
