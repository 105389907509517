import { AnyAction, Reducer } from 'redux';
import * as actionTypes from '../../actions/actionTypes';
import { IApiLoadUnitStatuses, IApiUpdateUnitStatus, IApiAddUnitStatus, IApiDeleteUnitStatus } from '../../actions/adminTabActions/adminTabUnitStatuses.action';
import { CallApiState, StateUpdateOption } from '../../actions/generic.action';

export interface IAdminTabUnitStatusesReducerState {
    apiLoadUnitStatuses: IApiLoadUnitStatuses;
    apiUpdateUnitStatus: IApiUpdateUnitStatus;
    apiAddUnitStatus: IApiAddUnitStatus;
    apiDeleteUnitStatus: IApiDeleteUnitStatus;
}

const initialAdminTabSupplierReducerState: IAdminTabUnitStatusesReducerState = {
    apiLoadUnitStatuses: {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        unitStatuses: undefined
    },
    apiUpdateUnitStatus: {
        callApiState: CallApiState.Initial,
        errMsg: undefined
    },
    apiAddUnitStatus: {
        callApiState: CallApiState.Initial,
        errMsg: undefined
    },
    apiDeleteUnitStatus: {
        callApiState: CallApiState.Initial,
        errMsg: undefined
    }
};

export const adminTabUnitStatusesReducer: Reducer<IAdminTabUnitStatusesReducerState, AnyAction> = (
    state: IAdminTabUnitStatusesReducerState = initialAdminTabSupplierReducerState, action: AnyAction
): IAdminTabUnitStatusesReducerState => {
    switch (action.type) {
        case actionTypes.API_LOAD_UNIT_STATUSES: {
            const payload: IApiLoadUnitStatuses = action.payload as IApiLoadUnitStatuses;
            switch (payload.stateUpdateOption) {
                case StateUpdateOption.ErrorOnly:
                    return {
                        ...state,
                        // Keep the prior payload except for the errMsg which will be set to undefined.
                        apiLoadUnitStatuses: {
                            ...state.apiLoadUnitStatuses,
                            errMsg: undefined
                        }
                    }
                default:
                    return {
                        ...state,
                        apiLoadUnitStatuses: { ...payload }
                    }
            }
        }
        case actionTypes.API_UPDATE_UNIT_STATUS: {
            const payload: IApiUpdateUnitStatus = action.payload as IApiUpdateUnitStatus;
            switch (payload.stateUpdateOption) {
                case StateUpdateOption.ErrorOnly:
                    return {
                        ...state,
                        // Keep the prior payload except for the errMsg which will be set to undefined.
                        apiUpdateUnitStatus: {
                            ...state.apiUpdateUnitStatus,
                            errMsg: undefined
                        }
                    }
                default:
                    return {
                        ...state,
                        apiUpdateUnitStatus: { ...payload }
                    }
            }
        }
        case actionTypes.API_ADD_UNIT_STATUS: {
            const payload: IApiAddUnitStatus = action.payload as IApiAddUnitStatus;
            switch (payload.stateUpdateOption) {
                case StateUpdateOption.ErrorOnly:
                    return {
                        ...state,
                        // Keep the prior payload except for the errMsg which will be set to undefined.
                        apiAddUnitStatus: {
                            ...state.apiAddUnitStatus,
                            errMsg: undefined
                        }
                    }
                default:
                    return {
                        ...state,
                        apiAddUnitStatus: { ...payload }
                    }
            }
        }
        case actionTypes.API_DELETE_UNIT_STATUS: {
            const payload: IApiDeleteUnitStatus = action.payload as IApiDeleteUnitStatus;
            switch (payload.stateUpdateOption) {
                case StateUpdateOption.ErrorOnly:
                    return {
                        ...state,
                        // Keep the prior payload except for the errMsg which will be set to undefined.
                        apiDeleteUnitStatus: {
                            ...state.apiDeleteUnitStatus,
                            errMsg: undefined
                        }
                    }
                default:
                    return {
                        ...state,
                        apiDeleteUnitStatus: { ...payload }
                    }
            }
        }
        default:
    }

    return state;
};
