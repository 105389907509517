import { UnitDispositionType } from '../../../models/domainData/unitDispositionType';
import { serviceApiClient } from '../../../services/api/serviceApiClient';
import { AppDispatch, reduxStore } from '../../reduxStore';
import * as actionTypes from '../actionTypes';
import { callApi, CallApiState, ICallApiBase } from '../generic.action';

/**
 * Load unit disposition types action payload.
 */
export interface IApiLoadUnitDispositionTypes extends ICallApiBase {
    unitDispositionTypes?: UnitDispositionType[] | null;
}

/**
 * Load unit disposition types.
 * @returns Redux dispatch function.
 */
export const loadUnitDispositionTypes = (programType: string): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<UnitDispositionType[]>(
        actionTypes.API_LOAD_UNIT_DISPOSITION_TYPES,
        async () => {
            return await serviceApiClient.unitDispositionTypes(programType) || [];
        },
        (payload: IApiLoadUnitDispositionTypes, data) => {
            if (data) {
                // Set the clientRowKey in each object so it can be bound into the DetailsList.
                data.forEach((value: UnitDispositionType, index: number) => {
                    value.clientRowKey = index.toString()
                });
            }

            payload.unitDispositionTypes = data;
        }
    );
};

/**
 * Update unit disposition type action payload.
 */
export interface IApiUpdateUnitDispositionType extends ICallApiBase {
    // No data returned from this api.
}

/**
 * Update unit disposition type.
 * @param unitDispositionType Unit disposition type to update.
 * @returns Redux dispatch function.
 */
export const updateUnitDispositionType = (unitDispositionType: UnitDispositionType): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<null>(
        actionTypes.API_UPDATE_UNIT_DISPOSITION_TYPE,
        async () => {
            return await serviceApiClient.updateUnitDispositionType(unitDispositionType);
        },
        (payload: IApiUpdateUnitDispositionType, data) => {
            // No data returned from this api.
        }
    );
};

/**
 * Add unit disposition type action payload.
 */
export interface IApiAddUnitDispositionType extends ICallApiBase {
    // No data returned from this api.
}

/**
 * Add unit disposition type.
 * @param unitDispositionType Unit disposition type to add.
 * @param programType Program type.
 * @returns Redux dispatch function.
 */
export const addUnitDispositionType = (unitDispositionType: UnitDispositionType, programType: string): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<null>(
        actionTypes.API_ADD_UNIT_DISPOSITION_TYPE,
        async () => {
            return await serviceApiClient.addUnitDispositionType(unitDispositionType, programType);
        },
        (payload: IApiAddUnitDispositionType, data) => {
            // No data returned from this api.
        }
    );
};

/**
 * Delete unit disposition type action payload.
 */
export interface IApiDeleteUnitDispositionType extends ICallApiBase {
    // No data returned from this api.
}

/**
 * Delete unit disposition type.
 * @param unitDispositionType Unit disposition type to delete.
 * @param programType Program type.
 * @returns Redux dispatch function.
 */
export const deleteUnitDispositionType = (unitDispositionType: UnitDispositionType): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<null>(
        actionTypes.API_DELETE_UNIT_DISPOSITION_TYPE,
        async () => {
            return await serviceApiClient.deleteUnitDispositionType(unitDispositionType);
        },
        (payload: IApiDeleteUnitDispositionType, data) => {
            // No data returned from this api.
        }
    );
};

/**
 * Reset the page action call state for all api calls.
 * This clear all data from the Redux store for these calls, including any error state for failed calls.
 * This makes it so if there was a failed call and an error was shown on the page, if the user navigates
 * to another page and back, the prior error would not be shown.
 */
export const resetApiCallState = () => {
    const apiLoadUnitDispositionTypes: IApiLoadUnitDispositionTypes = {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        unitDispositionTypes: undefined
    };
    reduxStore.dispatch({
        type: actionTypes.API_LOAD_UNIT_DISPOSITION_TYPES,
        payload: apiLoadUnitDispositionTypes
    });

    const apiUpdateUnitDispositionTypes: IApiUpdateUnitDispositionType = {
        callApiState: CallApiState.Initial,
        errMsg: undefined
    };
    reduxStore.dispatch({
        type: actionTypes.API_UPDATE_UNIT_DISPOSITION_TYPE,
        payload: apiUpdateUnitDispositionTypes
    });

    const apiAddUnitDispositionTypes: IApiAddUnitDispositionType = {
        callApiState: CallApiState.Initial,
        errMsg: undefined
    };
    reduxStore.dispatch({
        type: actionTypes.API_ADD_UNIT_DISPOSITION_TYPE,
        payload: apiAddUnitDispositionTypes
    });

    const apiDeleteUnitDispositionTypes: IApiDeleteUnitDispositionType = {
        callApiState: CallApiState.Initial,
        errMsg: undefined
    };
    reduxStore.dispatch({
        type: actionTypes.API_DELETE_UNIT_DISPOSITION_TYPE,
        payload: apiDeleteUnitDispositionTypes
    });
};
