import React, { useState, useRef, useCallback, useEffect } from 'react';
import {
    Text,
    Spinner,
    SpinnerSize,
    SelectionMode,
    DetailsListLayoutMode,
    ConstrainMode,
    IColumn,
    Separator,
    ActionButton,
    DetailsRow,
    IDetailsFooterProps,
    Stack
} from '@fluentui/react';
import { commonStyles } from '../../common/common.styles';
import { ICommonPageProps } from '../../common/common.types';
import { assignOnColumnClick, ColumnsAndItems, commonColumnProps, resetColumnSorting, sortOnColumn } from '../../common/common.func';
import { RecyclingDocument } from '../../models/recyclingDetail/recyclingDocument';
import { SupplierSummary } from '../../models/supplier/supplierSummary';
import { clearErrorByIndex, ErrorBar, prepErrorMsg } from '../../components/ErrorBar/ErrorBar';
import { CustomDetailsList } from '../../components/CustomDetailsList/CustomDetailsList';
import { ComboInputOption, ISearchCriteria, SearchFilter } from '../../components/SearchFilter/SearchFilter';
import { appConstants, toLocaleStringTwoDigitFraction } from '../../common/appConstants';
import { JobSearchSummary } from '../../models/supplier/jobSearchSummary';
import { SupplierJobDetails } from '../../models/supplier/supplierJobDetails';
import { SupplierInvoiceInfo } from '../../models/supplier/supplierInvoiceInfo';
import { SupplierPurchaseOrderInfo } from '../../models/supplier/supplierPurchaseOrderInfo';
import { DetailsModal } from './DetailsModal';
import { pageStyles } from './ConnectedDataPage.styles';
import {
    callApiLoadJobUnitDetails,
    callApiLoadSupplierSummary,
    callApiJobSearch,
    resetApiCallState,
    IApiLoadSupplierSummary, 
    IApiLoadJobUnitDetails,
    IApiJobSearch
} from '../../store/actions/pageActions/connectedDataPage.action';
import { CallApiState } from '../../store/actions/generic.action';
import { useAppSelector, useAppDispatch } from '../../store/hooks';
import { PageWrapper } from '../../components/PageWrapper/PageWrapper';

/*
The "SupplierWebMicroFrontend" project has a variation of this page. If changes are made here, consider if
equivalent changes are needed in that project as well. There are some differences between the projects, such as
localization and SupplierWeb integration in that project, so be careful when copying/integrating changes.
*/

interface IPageProps extends ICommonPageProps {
}

export const ConnectedDataPage: React.FunctionComponent<IPageProps> = (props: IPageProps): JSX.Element => {
    const [errors, setErrors] = useState<string[]>([]);
    const [showDetails, setShowDetails] = useState<boolean>(false);

    const [selectedPageJobTable, setSelectedPageJobTable] = useState<number>(1);
    const [selectedPageInvoiceTable, setSelectedPageInvoiceTable] = useState<number>(1);
    const [selectedPagePoTable, setSelectedPagePoTable] = useState<number>(1);
    const [selectedPageJobUnitTable, setSelectedPageJobUnitTable] = useState<number>(1);

    const [jobSearchSummary, setJobSearchSummary] = useState<JobSearchSummary | null>();
    const [supplierSummaries, setSupplierSummaries] = useState<SupplierSummary[] | null>();
    const [activeSupplierJob, setActiveSupplierJob] = useState<SupplierJobDetails>();
    const [activeSupplierSummary, setActiveSupplierSummary] = useState<SupplierSummary>();
    const [activeRecyclingDocument, setActiveRecyclingDocument] = useState<RecyclingDocument | null>();
    const [activeSearchCriteria, setActiveSearchCriteria] = useState<ISearchCriteria>();

    const grandTotals = useRef<{
        sumTotalUnitFees?: number;
        sumTotalUnitFeesUSD?: number;
        sumLogisticsFee?: number;
        sumLogisticsFeeUSD?: number;
        sumMinimumServiceFee?: number;
        sumMinimumServiceFeeUSD?: number;
        sumInvoiceAmount?: number;
        sumInvoiceAmountUSD?: number;
        sumTotalSupplierCommission?: number;
        sumTotalSupplierCommissionUSD?: number;
        sumTotalMsRevenueShare?: number;
        sumTotalMsRevenueShareUSD?: number;
    }>({});

    const divBelowJobUnitsScrollRef = useRef<HTMLDivElement>(null);
    const autoScrollToUnits = useRef<boolean>(true);

    // Redux store selectors to get state from the store when it changes.
    const apiLoadSupplierSummary: IApiLoadSupplierSummary =
        useAppSelector<IApiLoadSupplierSummary>((state) => state.connectedDataPageReducer.apiLoadSupplierSummary);
    const apiLoadJobUnitDetails: IApiLoadJobUnitDetails =
        useAppSelector<IApiLoadJobUnitDetails>((state) => state.connectedDataPageReducer.apiLoadJobUnitDetails);
    const apiJobSearch: IApiJobSearch =
        useAppSelector<IApiJobSearch>((state) => state.connectedDataPageReducer.apiJobSearch);

    // Redux store dispatch to send actions to the store.
    const dispatch = useAppDispatch();

    /**
     * This effect does nothing on mount, but will return a cleanup function that runs when the component unmounts.
     */
    useEffect(() => {
        return function cleanup() {
            resetApiCallState();
        }
    }, []);

    /**
     * Handle error.
     * @param errMsg Error message.
     */
    const handleError = useCallback((errMsg: string) => {
        setErrors((prevErrors) => {
            // This will prevent the same error from being displayed if already displayed.
            // ex: Multiple page data load failures might occur if the api is not working,
            // and this page makes multiple load calls for various data.
            if (!prevErrors.includes(errMsg)) {
                return [...prevErrors, errMsg];
            }
            return prevErrors;
        });
    }, []);

    /**
     * Effect for when errors occur in any api call.
     */
    useEffect(() => {
        if (apiLoadSupplierSummary.errMsg) {
            handleError(apiLoadSupplierSummary.errMsg);
        }
        if (apiLoadJobUnitDetails.errMsg) {
            handleError(apiLoadJobUnitDetails.errMsg);
        }
        if (apiJobSearch.errMsg) {
            handleError(apiJobSearch.errMsg);
        }
    }, [handleError, apiJobSearch.errMsg, apiLoadJobUnitDetails.errMsg, apiLoadSupplierSummary.errMsg]);

    /**
     * Render right aligned currency column text. Currency values will use 2 digit precision.
     * @param item Item data.
     * @param index Index of item.
     * @param column Column being rendered.
     */
     const renderRightAlignedCurrencyColumnText = (item?: any, index?: number, column?: IColumn) => {
        if (index === undefined || index === null) {
            return;
        }

        if (!item.isDetailRow) {
            // If not a detail row then it is rendering a normal row cell.
            return (
                <div style={{ textAlign: 'right' }}>{item[column!.fieldName as string]?.toLocaleString(undefined, toLocaleStringTwoDigitFraction)}</div>
            );
        } else {
            // Render a detail row with totals.
            return (
                <div style={{ textAlign: 'right', fontWeight: 'bold' }}>
                    {column?.fieldName === 'totalUnitFees' && <>{grandTotals.current.sumTotalUnitFees?.toLocaleString(undefined, toLocaleStringTwoDigitFraction)}</>}
                    {column?.fieldName === 'totalUnitFeesUSD' && <>{grandTotals.current.sumTotalUnitFeesUSD?.toLocaleString(undefined, toLocaleStringTwoDigitFraction)}</>}
                    {column?.fieldName === 'logisticsFee' && <>{grandTotals.current.sumLogisticsFee?.toLocaleString(undefined, toLocaleStringTwoDigitFraction)}</>}
                    {column?.fieldName === 'logisticsFeeUSD' && <>{grandTotals.current.sumLogisticsFeeUSD?.toLocaleString(undefined, toLocaleStringTwoDigitFraction)}</>}
                    {column?.fieldName === 'minimumServiceFee' && <>{grandTotals.current.sumMinimumServiceFee?.toLocaleString(undefined, toLocaleStringTwoDigitFraction)}</>}
                    {column?.fieldName === 'minimumServiceFeeUSD' && <>{grandTotals.current.sumMinimumServiceFeeUSD?.toLocaleString(undefined, toLocaleStringTwoDigitFraction)}</>}
                    {column?.fieldName === 'invoiceAmount' && <>{grandTotals.current.sumInvoiceAmount?.toLocaleString(undefined, toLocaleStringTwoDigitFraction)}</>}
                    {column?.fieldName === 'invoiceAmountUSD' && <>{grandTotals.current.sumInvoiceAmountUSD?.toLocaleString(undefined, toLocaleStringTwoDigitFraction)}</>}
                    {column?.fieldName === 'totalSupplierCommission' && <>{grandTotals.current.sumTotalSupplierCommission?.toLocaleString(undefined, toLocaleStringTwoDigitFraction)}</>}
                    {column?.fieldName === 'totalSupplierCommissionUSD' && <>{grandTotals.current.sumTotalSupplierCommissionUSD?.toLocaleString(undefined, toLocaleStringTwoDigitFraction)}</>}
                    {column?.fieldName === 'totalMsRevenueShare' && <>{grandTotals.current.sumTotalMsRevenueShare?.toLocaleString(undefined, toLocaleStringTwoDigitFraction)}</>}
                    {column?.fieldName === 'totalMsRevenueShareUSD' && <>{grandTotals.current.sumTotalMsRevenueShareUSD?.toLocaleString(undefined, toLocaleStringTwoDigitFraction)}</>}
                </div>
            );
        }
    };

    /**
     * Columns for the job table.
     */
     const [columnsJobTable, setColumnsJobTable] = useState<IColumn[]>(
        [
            {
                ...commonColumnProps,
                key: 'column1',
                name: 'Supplier ID',
                fieldName: 'supplierId',
                minWidth: 50
            },
            {
                ...commonColumnProps,
                key: 'column2',
                name: 'Supplier Name',
                fieldName: 'supplierName',
                minWidth: 110
            },
            {
                ...commonColumnProps,
                key: 'column3',
                name: 'Supplier Job ID',
                fieldName: 'supplierJobId',
                minWidth: 75
                // onRender is assigned for this column in a useEffect. See elsewhere in this file.
            },
            {
                ...commonColumnProps,
                key: 'column4',
                name: 'Job Type',
                fieldName: 'supplierJobType',
                minWidth: 75
            },
            {
                ...commonColumnProps,
                key: 'column5',
                name: 'Job Status',
                fieldName: 'jobStatus',
                minWidth: 60
            },
            {
                ...commonColumnProps,
                key: 'column6',
                name: 'Collection Ticket Number',
                fieldName: 'msCollectionTicketNumber',
                minWidth: 170
            },
            {
                ...commonColumnProps,
                key: 'column7',
                name: 'Collection Country',
                fieldName: 'collectionCountry',
                minWidth: 100
            },
            {
                ...commonColumnProps,
                key: 'column8',
                name: 'Collection Zip Code',
                fieldName: 'collectionZipCode',
                minWidth: 110
            },
            {
                ...commonColumnProps,
                key: 'column9',
                name: 'MS PO Number',
                fieldName: 'msPoNumber',
                minWidth: 80
            },
            {
                ...commonColumnProps,
                key: 'column10',
                name: 'MS PO Currency',
                fieldName: 'msPoCurrency',
                minWidth: 80
            },
            {
                ...commonColumnProps,
                key: 'column11',
                name: 'Supplier Billing Entity',
                fieldName: 'supplierBillingEntity',
                minWidth: 120
            },
            {
                ...commonColumnProps,
                key: 'column12',
                name: 'Billing Country',
                fieldName: 'billingCountry',
                minWidth: 80
            },
            {
                ...commonColumnProps,
                key: 'column13',
                name: 'Total Supplier Units',
                fieldName: 'totalSupplierUnits',
                minWidth: 100
            },
            {
                ...commonColumnProps,
                key: 'column14',
                name: 'Total Quantity',
                fieldName: 'totalQuantity',
                minWidth: 70
            },
            {
                ...commonColumnProps,
                key: 'column15',
                name: 'Total Weight In Kilos',
                fieldName: 'totalWeightInKilos',
                minWidth: 110
            },
            {
                ...commonColumnProps,
                key: 'column16',
                name: 'Total Unit Fees',
                fieldName: 'totalUnitFees',
                minWidth: 70,
                onRender: renderRightAlignedCurrencyColumnText
            },
            {
                ...commonColumnProps,
                key: 'column17',
                name: 'Total Unit Fees USD',
                fieldName: 'totalUnitFeesUSD',
                minWidth: 106,
                onRender: renderRightAlignedCurrencyColumnText
            },
            {
                ...commonColumnProps,
                key: 'column18',
                name: 'Total MS Revenue Share',
                fieldName: 'totalMsRevenueShare',
                minWidth: 130,
                onRender: renderRightAlignedCurrencyColumnText
            },
            {
                ...commonColumnProps,
                key: 'column19',
                name: 'Total MS Revenue Share USD',
                fieldName: 'totalMsRevenueShareUSD',
                minWidth: 166,
                onRender: renderRightAlignedCurrencyColumnText
            },
            {
                ...commonColumnProps,
                key: 'column20',
                name: 'Total Supplier Commission',
                fieldName: 'totalSupplierCommission',
                minWidth: 150,
                onRender: renderRightAlignedCurrencyColumnText
            },
            {
                ...commonColumnProps,
                key: 'column21',
                name: 'Total Supplier Commission USD',
                fieldName: 'totalSupplierCommissionUSD',
                minWidth: 186,
                onRender: renderRightAlignedCurrencyColumnText
            },
            {
                ...commonColumnProps,
                key: 'column22',
                name: 'Logistics Fee',
                fieldName: 'logisticsFee',
                minWidth: 70,
                onRender: renderRightAlignedCurrencyColumnText
            },
            {
                ...commonColumnProps,
                key: 'column23',
                name: 'Logistics Fee USD',
                fieldName: 'logisticsFeeUSD',
                minWidth: 106,
                onRender: renderRightAlignedCurrencyColumnText
            },
            {
                ...commonColumnProps,
                key: 'column24',
                name: 'Total Invoice Amount',
                fieldName: 'totalInvoiceAmount',
                minWidth: 120,
                onRender: renderRightAlignedCurrencyColumnText
            },
            {
                ...commonColumnProps,
                key: 'column25',
                name: 'Total Invoice Amount USD',
                fieldName: 'totalInvoiceAmountUSD',
                minWidth: 156,
                onRender: renderRightAlignedCurrencyColumnText
            }
        ]
    );

    /**
     * Columns for the invoice table.
     */
    const [columnsInvoiceTable, setColumnsInvoiceTable] = useState<IColumn[]>(
        [
            {
                ...commonColumnProps,
                key: 'column1',
                name: 'Supplier Invoice Number',
                fieldName: 'supplierInvoiceNumber',
                minWidth: 140
            },
            {
                ...commonColumnProps,
                key: 'column2',
                name: 'Supplier Job ID',
                fieldName: 'supplierJobId',
                minWidth: 75
            },
            {
                ...commonColumnProps,
                key: 'column3',
                name: 'MS Company Code',
                fieldName: 'msCompanyCode',
                minWidth: 100
            },
            {
                ...commonColumnProps,
                key: 'column4',
                name: 'MS PO Currency',
                fieldName: 'msPoCurrency',
                minWidth: 80
            },
            {
                ...commonColumnProps,
                key: 'column5',
                name: 'MS PO Number',
                fieldName: 'msPoNumber',
                minWidth: 80
            },
            {
                ...commonColumnProps,
                key: 'column6',
                name: 'Supplier Billing Entity',
                fieldName: 'supplierBillingEntity',
                minWidth: 120
            },
            {
                ...commonColumnProps,
                key: 'column7',
                name: 'Total Unit Fees',
                fieldName: 'totalUnitFees',
                minWidth: 70,
                onRender: renderRightAlignedCurrencyColumnText
            },
            {
                ...commonColumnProps,
                key: 'column8',
                name: 'Total Unit Fees USD',
                fieldName: 'totalUnitFeesUSD',
                minWidth: 106,
                onRender: renderRightAlignedCurrencyColumnText
            },
            {
                ...commonColumnProps,
                key: 'column9',
                name: 'Logistics Fee',
                fieldName: 'logisticsFee',
                minWidth: 70,
                onRender: renderRightAlignedCurrencyColumnText
            },
            {
                ...commonColumnProps,
                key: 'column10',
                name: 'Logistics Fee USD',
                fieldName: 'logisticsFeeUSD',
                minWidth: 106,
                onRender: renderRightAlignedCurrencyColumnText
            },
            {
                ...commonColumnProps,
                key: 'column11',
                name: 'Minimum Service Fee',
                fieldName: 'minimumServiceFee',
                minWidth: 120,
                onRender: renderRightAlignedCurrencyColumnText
            },
            {
                ...commonColumnProps,
                key: 'column12',
                name: 'Minimum Service Fee USD',
                fieldName: 'minimumServiceFeeUSD',
                minWidth: 156,
                onRender: renderRightAlignedCurrencyColumnText
            },
            {
                ...commonColumnProps,
                key: 'column13',
                name: 'Invoice Amount',
                fieldName: 'invoiceAmount',
                minWidth: 80,
                onRender: renderRightAlignedCurrencyColumnText
            },
            {
                ...commonColumnProps,
                key: 'column14',
                name: 'Invoice Amount USD',
                fieldName: 'invoiceAmountUSD',
                minWidth: 116,
                onRender: renderRightAlignedCurrencyColumnText
            }
        ]
    );

    /**
     * Columns for the PO table.
     */
    const [columnsPoTable, setColumnsPoTable] = useState<IColumn[]>(
        [
            {
                ...commonColumnProps,
                key: 'column1',
                name: 'Supplier PO Number',
                fieldName: 'supplierPoNumber',
                minWidth: 110
            },
            {
                ...commonColumnProps,
                key: 'column2',
                name: 'Supplier Job ID',
                fieldName: 'supplierJobId',
                minWidth: 75
            },
            {
                ...commonColumnProps,
                key: 'column3',
                name: 'MS Company Code',
                fieldName: 'msCompanyCode',
                minWidth: 100
            },
            {
                ...commonColumnProps,
                key: 'column4',
                name: 'Supplier PO Currency',
                fieldName: 'supplierPoCurrency',
                minWidth: 120
            },
            {
                ...commonColumnProps,
                key: 'column5',
                name: 'Billing Country',
                fieldName: 'billingCountry',
                minWidth: 80
            },
            {
                ...commonColumnProps,
                key: 'column6',
                name: 'MS Customer Number',
                fieldName: 'msCustomerNumber',
                minWidth: 120
            },
            {
                ...commonColumnProps,
                key: 'column7',
                name: 'PO Type',
                fieldName: 'poType',
                minWidth: 70
            },
            {
                ...commonColumnProps,
                key: 'column8',
                name: 'Total Supplier Commission',
                fieldName: 'totalSupplierCommission',
                minWidth: 145,
                onRender: renderRightAlignedCurrencyColumnText
            },
            {
                ...commonColumnProps,
                key: 'column9',
                name: 'Total Supplier Commission USD',
                fieldName: 'totalSupplierCommissionUSD',
                minWidth: 181,
                onRender: renderRightAlignedCurrencyColumnText
            },
            {
                ...commonColumnProps,
                key: 'column10',
                name: 'Total MS Revenue Share',
                fieldName: 'totalMsRevenueShare',
                minWidth: 134,
                onRender: renderRightAlignedCurrencyColumnText
            },
            {
                ...commonColumnProps,
                key: 'column11',
                name: 'Total MS Revenue Share USD',
                fieldName: 'totalMsRevenueShareUSD',
                minWidth: 170,
                onRender: renderRightAlignedCurrencyColumnText
            }
        ]
    );

    /**
     * Supplier unit id clicked event handler.
     * @param supplierSummary Supplier summary.
     */
    const supplierUnitIdClicked = useCallback(async (supplierSummary: SupplierSummary) => {
        setActiveSupplierSummary(supplierSummary);
        setShowDetails(true);
        dispatch(callApiLoadJobUnitDetails(supplierSummary.supplierId, supplierSummary.supplierJobId, supplierSummary.supplierUnitId));
    }, [dispatch]);

    const [columnsJobUnitTable, setColumnsJobUnitTable] = useState<IColumn[]>([]);

    /**
     * Setup columns to display for the job unit table.
     * @param showCorrelationIdAndTypeColumns Flag to indicate if the correlation id and type columns should be shown.
     */
    const setupColumnsJobUnitTable = useCallback((showCorrelationIdAndTypeColumns: boolean = true): void => {
        setColumnsJobUnitTable(
            [
                {
                    ...commonColumnProps,
                    key: 'column1',
                    name: 'Supplier Name',
                    fieldName: 'supplierName',
                    minWidth: 150
                },
                {
                    ...commonColumnProps,
                    key: 'column2',
                    name: 'Supplier ID',
                    fieldName: 'supplierId',
                    minWidth: 75
                },
                {
                    ...commonColumnProps,
                    key: 'column3',
                    name: 'Job ID',
                    fieldName: 'supplierJobId',
                    minWidth: 75
                },
                {
                    ...commonColumnProps,
                    key: 'column4',
                    name: 'Unit ID',
                    fieldName: 'supplierUnitId',
                    minWidth: 50,
                    onRender: (item: SupplierSummary) => {
                        return (
                            <ActionButton className={pageStyles.actionButtonLink} onClick={() => supplierUnitIdClicked(item)}>
                                {item.supplierUnitId}
                            </ActionButton>
                        );
                    }
                },
                {
                    ...commonColumnProps,
                    key: 'column5',
                    name: 'Unit Type',
                    fieldName: 'supplierUnitType',
                    minWidth: 90
                },
                {
                    ...commonColumnProps,
                    key: 'column6',
                    name: 'Quantity',
                    fieldName: 'quantity',
                    minWidth: 40
                },
                {
                    ...commonColumnProps,
                    key: 'column7',
                    name: 'Reporting Date',
                    fieldName: 'supplierReportingDateUtc',
                    minWidth: 90,
                    onRender: (item: SupplierSummary) => {
                        return <span>{item.supplierReportingDateUtc?.toLocaleDateString()}</span>;
                    }
                },
                {
                    ...commonColumnProps,
                    key: 'column8',
                    name: 'Unit Status',
                    fieldName: 'unitStatus',
                    minWidth: 90
                },
                {
                    ...commonColumnProps,
                    key: 'column9',
                    name: 'Disposition Date',
                    fieldName: 'dispositionDate',
                    minWidth: 90,
                    onRender: (item: SupplierSummary) => {
                        return <span>{item.dispositionDate?.toLocaleDateString()}</span>;
                    }
                },
                {
                    ...commonColumnProps,
                    key: 'column10',
                    name: 'Disposition Type',
                    fieldName: 'dispositionType',
                    minWidth: 90
                },
                {
                    ...commonColumnProps,
                    key: 'column11',
                    name: 'Return Type',
                    fieldName: 'returnType',
                    minWidth: 90
                },
                ...(showCorrelationIdAndTypeColumns ? [
                        {
                            ...commonColumnProps,
                            key: 'column12',
                            name: 'Correlation ID',
                            fieldName: 'correlationId',
                            minWidth: 230
                        },
                        {
                            ...commonColumnProps,
                            key: 'column13',
                            name: 'Type',
                            fieldName: 'type',
                            minWidth: 90
                        }
                    ] : []
                )
            ]
        )
    }, [supplierUnitIdClicked]);

    /**
     * On column click event handler for job table.
     * @param ev Mouse event.
     * @param column Column clicked.
     */
    const onColumnClickJobTable = useCallback((ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        if (jobSearchSummary && jobSearchSummary.supplierJobDetails) {
            const columnsAndItems: ColumnsAndItems<SupplierJobDetails> = sortOnColumn<SupplierJobDetails>(column, columnsJobTable, jobSearchSummary.supplierJobDetails, undefined);
            jobSearchSummary.supplierJobDetails = columnsAndItems.items;
            setJobSearchSummary(jobSearchSummary);
            setColumnsJobTable(columnsAndItems.columns);
        }
    }, [columnsJobTable, jobSearchSummary]);

    /**
     * Effect to update onColumnClick handler. Otherwise the component state referenced in onColumnClick
     * would be stale from the render pass it was created on.
     */
    useEffect(() => {
        assignOnColumnClick(columnsJobTable, onColumnClickJobTable);
    }, [columnsJobTable, onColumnClickJobTable]);

    /**
     * On column click event handler for invoice table.
     * @param ev Mouse event.
     * @param column Column clicked.
     */
    const onColumnClickInvoiceTable = useCallback((ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        if (jobSearchSummary && jobSearchSummary.supplierInvoiceSummary) {
            const columnsAndItems: ColumnsAndItems<SupplierInvoiceInfo> = sortOnColumn<SupplierInvoiceInfo>(column, columnsInvoiceTable, jobSearchSummary.supplierInvoiceSummary, undefined);
            jobSearchSummary.supplierInvoiceSummary = columnsAndItems.items;
            setJobSearchSummary(jobSearchSummary);
            setColumnsInvoiceTable(columnsAndItems.columns);
        }
    }, [columnsInvoiceTable, jobSearchSummary]);

    /**
     * Effect to update onColumnClick handler. Otherwise the component state referenced in onColumnClick
     * would be stale from the render pass it was created on.
     */
    useEffect(() => {
        assignOnColumnClick(columnsInvoiceTable, onColumnClickInvoiceTable);
    }, [columnsInvoiceTable, onColumnClickInvoiceTable]);

    /**
     * On column click event handler for PO table.
     * @param ev Mouse event.
     * @param column Column clicked.
     */
    const onColumnClickPoTable = useCallback((ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        if (jobSearchSummary && jobSearchSummary.supplierPurchaseOrderSummary) {
            const columnsAndItems: ColumnsAndItems<SupplierPurchaseOrderInfo> = sortOnColumn<SupplierPurchaseOrderInfo>(column, columnsPoTable, jobSearchSummary.supplierPurchaseOrderSummary, undefined);
            jobSearchSummary.supplierPurchaseOrderSummary = columnsAndItems.items;
            setJobSearchSummary(jobSearchSummary);
            setColumnsPoTable(columnsAndItems.columns);
        }
    }, [columnsPoTable, jobSearchSummary]);

    /**
     * Effect to update onColumnClick handler. Otherwise the component state referenced in onColumnClick
     * would be stale from the render pass it was created on.
     */
    useEffect(() => {
        assignOnColumnClick(columnsPoTable, onColumnClickPoTable);
    }, [columnsPoTable, onColumnClickPoTable]);

    /**
     * On column click event handler for job unit table.
     * @param ev Mouse event.
     * @param column Column clicked.
     */
    const onColumnClickJobUnitTable = useCallback((ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        if (supplierSummaries) {
            const columnsAndItems: ColumnsAndItems<SupplierSummary> = sortOnColumn(column, columnsJobUnitTable, supplierSummaries, undefined);
            setSupplierSummaries(columnsAndItems.items);
            setColumnsJobUnitTable(columnsAndItems.columns);
        }
    }, [columnsJobUnitTable, supplierSummaries]);

    /**
     * Effect to update onColumnClick handler. Otherwise the component state referenced in onColumnClick
     * would be stale from the render pass it was created on.
     */
    useEffect(() => {
        assignOnColumnClick(columnsJobUnitTable, onColumnClickJobUnitTable);
    }, [columnsJobUnitTable, onColumnClickJobUnitTable]);

    /**
     * Supplier job id clicked event handler.
     * Searches for job units for selected job id.
     * @param supplierJobDetails Supplier job details.
     * @param autoScroll Auto scroll to units table if true.
     */
    const supplierJobIdClicked = useCallback(async (supplierJobDetails: SupplierJobDetails, autoScroll: boolean = true) => {
        autoScrollToUnits.current = autoScroll;

        if (!activeSearchCriteria) {
            return;
        }

        setActiveSupplierJob(supplierJobDetails);

        // If correlation id was supplied in the search criteria, then show the correlation id and type columns,
        // otherwise do not. Using !! to convert correlationId to true/false based on value being present in correlationId.
        setupColumnsJobUnitTable(!!activeSearchCriteria.correlationId);

        setSelectedPageJobUnitTable(1);

        dispatch(callApiLoadSupplierSummary(
            activeSearchCriteria.programType,
            activeSearchCriteria.supplierId,
            supplierJobDetails.supplierJobId,
            activeSearchCriteria.supplierUnitId,
            activeSearchCriteria.correlationId,
            activeSearchCriteria.supplierUnitType,
            activeSearchCriteria.startDate,
            activeSearchCriteria.endDate,
            activeSearchCriteria.unitDispositionType,
            activeSearchCriteria.unitStatus
        ));
    }, [activeSearchCriteria, dispatch, setupColumnsJobUnitTable]);

    /**
     * Effect to add onRender handler for certain columns in columnsJobTable.
     */
    useEffect(() => {
        columnsJobTable.forEach((column: IColumn) => {
            if (column.fieldName === 'supplierJobId') {
                column.onRender = (item: SupplierJobDetails) => {
                    return (
                        <ActionButton className={pageStyles.actionButtonLink} onClick={() => supplierJobIdClicked(item)}>
                            {item.supplierJobId}
                        </ActionButton>
                    );
                }
            }
        });
    }, [columnsJobTable, supplierJobIdClicked]);

    /**
     * Effect for when Supplier Summary data is loaded.
     */
    useEffect(() => {
        if (apiLoadSupplierSummary.callApiState === CallApiState.DataAvailable) {
            setSupplierSummaries(apiLoadSupplierSummary.supplierSummaries);
            setColumnsJobUnitTable(resetColumnSorting(columnsJobUnitTable));
        }
    }, [columnsJobUnitTable, apiLoadSupplierSummary.callApiState, apiLoadSupplierSummary.supplierSummaries]);

    /**
     * Effect for when finished searching for job units.
     * Scroll the div below the job units table into view.
     */
    useEffect(() => {
        if (apiLoadSupplierSummary.callApiState === CallApiState.DataAvailable && autoScrollToUnits.current) {
            setTimeout(() => {
                if (divBelowJobUnitsScrollRef.current) {
                    divBelowJobUnitsScrollRef.current.scrollIntoView({ behavior: 'smooth' });
                }
            });
        }
    }, [apiLoadSupplierSummary.callApiState]);

    /**
     * Effect for when Job Unit Details data is loaded.
     */
    useEffect(() => {
        if (apiLoadJobUnitDetails.callApiState === CallApiState.DataAvailable) {
            setActiveRecyclingDocument(apiLoadJobUnitDetails.recyclingDocument);
            setColumnsJobUnitTable(resetColumnSorting(columnsJobUnitTable));

            if (!apiLoadJobUnitDetails.recyclingDocument) {
                setShowDetails(false);
                handleError(appConstants.noDataFound);
            }
        }
    }, [columnsJobUnitTable, handleError, apiLoadJobUnitDetails.callApiState, apiLoadJobUnitDetails.recyclingDocument]);

    /**
     * Search clicked event handler.
     * @param searchCriteria Search criteria.
     */
    const searchClicked = (searchCriteria: ISearchCriteria) => {
        setJobSearchSummary(undefined);
        setSupplierSummaries(undefined);
        setActiveSupplierJob(undefined);
        setActiveSupplierSummary(undefined);
        setActiveRecyclingDocument(undefined);
        setActiveSearchCriteria(searchCriteria);

        setSelectedPageJobTable(1);
        setSelectedPageInvoiceTable(1);
        setSelectedPagePoTable(1);

        dispatch(callApiJobSearch(
            searchCriteria.programType,
            searchCriteria.supplierId,
            searchCriteria.supplierJobId,
            searchCriteria.supplierInvoiceNumber,
            searchCriteria.supplierPoNumber,
            searchCriteria.correlationId,
            searchCriteria.startDate,
            searchCriteria.endDate
        ));
    };

    /**
     * Effect for when Job Search data is loaded.
     */
    useEffect(() => {
        if (apiJobSearch.callApiState === CallApiState.DataAvailable) {
            const searchResults: JobSearchSummary | null | undefined = apiJobSearch.jobSearchSummary;
            setJobSearchSummary(searchResults);

            // Put following state updates in a setTimeout, otherwise this useEffect will fire repeatedly.
            // This is because these columns are in the dependency array of this useEffect. This entire block
            // of code will only run during CallApiState.DataAvailable however, which is only for one render
            // pass, as it gets set to CallApiState.Completed on next render pass.
            setTimeout(() => {
                setColumnsJobTable(resetColumnSorting(columnsJobTable));
                setColumnsInvoiceTable(resetColumnSorting(columnsInvoiceTable));
                setColumnsPoTable(resetColumnSorting(columnsPoTable));
            });

            let sumTotalUnitFees: number = 0;
            let sumTotalUnitFeesUSD: number = 0;
            let sumLogisticsFee: number = 0;
            let sumLogisticsFeeUSD: number = 0;
            let sumMinimumServiceFee: number = 0;
            let sumMinimumServiceFeeUSD: number = 0;
            let sumInvoiceAmount: number = 0;
            let sumInvoiceAmountUSD: number = 0;
            if (searchResults && searchResults.supplierInvoiceSummary) {
                searchResults.supplierInvoiceSummary.forEach((value: SupplierInvoiceInfo, index: number) => {
                    sumTotalUnitFees += (value.totalUnitFees || 0);
                    sumTotalUnitFeesUSD += (value.totalUnitFeesUSD || 0);
                    sumLogisticsFee += (value.logisticsFee || 0);
                    sumLogisticsFeeUSD += (value.logisticsFeeUSD || 0);
                    sumMinimumServiceFee += (value.minimumServiceFee || 0);
                    sumMinimumServiceFeeUSD += (value.minimumServiceFeeUSD || 0);
                    sumInvoiceAmount += (value.invoiceAmount || 0);
                    sumInvoiceAmountUSD += (value.invoiceAmountUSD || 0);
                    value.clientRowKey = index.toString();
                });
            }

            let sumTotalSupplierCommission: number = 0;
            let sumTotalSupplierCommissionUSD: number = 0;
            let sumTotalMsRevenueShare: number = 0;
            let sumTotalMsRevenueShareUSD: number = 0;
            if (searchResults && searchResults.supplierPurchaseOrderSummary) {
                searchResults.supplierPurchaseOrderSummary.forEach((value: SupplierPurchaseOrderInfo, index: number) => {
                    sumTotalSupplierCommission += (value.totalSupplierCommission || 0);
                    sumTotalSupplierCommissionUSD += (value.totalSupplierCommissionUSD || 0);
                    sumTotalMsRevenueShare += (value.totalMsRevenueShare || 0);
                    sumTotalMsRevenueShareUSD += (value.totalMsRevenueShareUSD || 0);
                    value.clientRowKey = index.toString();
                });
            }

            grandTotals.current = { sumTotalUnitFees, sumTotalUnitFeesUSD, sumLogisticsFee, sumLogisticsFeeUSD, sumMinimumServiceFee, sumMinimumServiceFeeUSD, sumInvoiceAmount, sumInvoiceAmountUSD, sumTotalSupplierCommission, sumTotalSupplierCommissionUSD, sumTotalMsRevenueShare, sumTotalMsRevenueShareUSD };

            // If there is only one job in the search results, then auto click on the job to get and show the job units.
            if (searchResults && searchResults.supplierJobDetails && searchResults.supplierJobDetails.length === 1) {
                setTimeout(() => {
                    supplierJobIdClicked(searchResults!.supplierJobDetails![0], false);
                });
            }
        }
    }, [columnsInvoiceTable, columnsJobTable, columnsPoTable, apiJobSearch.callApiState, apiJobSearch.jobSearchSummary, supplierJobIdClicked]);

    /**
     * Search filter load error handler.
     * @param err Error
     */
    const searchFilterLoadError = (err: any) => {
        handleError(prepErrorMsg(appConstants.dataLoadFailed, err));
    };

    /**
     * Renders details footer row for Invoice or PO summary.
     * @param detailsFooterProps Details footer props.
     */
    const onRenderDetailsFooterForInvoiceOrPoSummary = (detailsFooterProps: IDetailsFooterProps | undefined): JSX.Element => {
        if (!detailsFooterProps) {
            return <></>;
        }

        return (
            <DetailsRow
                {...detailsFooterProps}
                columns={detailsFooterProps.columns}
                item={{ isDetailRow: true }}
                itemIndex={-1}
                groupNestingDepth={detailsFooterProps.groupNestingDepth}
                selectionMode={SelectionMode.none}
                selection={detailsFooterProps.selection}
                onRenderCheck={() => <></>}
            />
        );
    };

    return (
        <>
            <PageWrapper {...props}>
                <ErrorBar errors={errors} onDismiss={(index: number) => {
                    setErrors(clearErrorByIndex(errors, index));
                }} />

                <div className='ms-Grid' dir="ltr">
                    <div className='ms-Grid-row'>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <div className={commonStyles.pageHeader}>
                                <Text variant="xLarge" role="heading" aria-level={1}>Connected Data</Text>
                            </div>
                        </div>
                    </div>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <SearchFilter
                                programs={props.programs || []}
                                searchFilterOptions={{
                                    showFiscalRange: true,
                                    fiscalRangeFieldsRequired: false,
                                    showCalendarYearMonth: false,
                                    showProgram: true,
                                    showSupplierName: true,
                                    showComboInputGroup: ComboInputOption.SupplierJobId_SupplierInvoiceNumber_SupplierPoNumber,
                                    showSupplierJobId: false,
                                    showSupplierPoNumber: false,
                                    showSupplierInvoiceNumber: false,
                                    showCorrelationId: true,
                                    showMsInvoiceNumber: false,
                                    showSupplierUnitType: true,
                                    showJobUnit: true,
                                    showCountry: false,
                                    showComboAssetTagAndSerialNumber: false,
                                    showUnitDispositionType: true,
                                    showUnitStatus: true
                                }}
                                onSearchClicked={searchClicked}
                                onSearchFilterLoadError={searchFilterLoadError} />
                        </div>
                    </div>
                    <div className="ms-Grid-row" style={{ marginTop: '24px' }}>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            {
                                (apiJobSearch.callApiState !== CallApiState.Initial) && (
                                    <>
                                        <Stack style={{ marginRight: '1px' }}>
                                            <Stack.Item>
                                                {apiJobSearch.callApiState === CallApiState.Running && (
                                                    <Spinner size={SpinnerSize.medium} className={commonStyles.spinner} />
                                                )}
                                                {apiJobSearch.callApiState === CallApiState.Completed && (
                                                    <>
                                                        <div className={commonStyles.headingTextContainer}>
                                                            <Text variant="mediumPlus" className={commonStyles.headingText} role="heading" aria-level={2}>Jobs</Text>
                                                            <Text variant="smallPlus" style={{ fontStyle: 'italic', marginLeft: '18px' }}>Click on a Supplier Job Id to view Job Units.</Text>
                                                        </div>
                                                        <CustomDetailsList
                                                            showExcelExport={true}
                                                            exportExcelSheetName="Jobs"
                                                            ariaLabelForGrid="Jobs"
                                                            displayTotalItems={true}
                                                            showPaginator={true}
                                                            showPageSize={true}
                                                            selectedPage={selectedPageJobTable}
                                                            onSelectedPageChange={(page) => {
                                                                setSelectedPageJobTable(page);
                                                            }}
                                                            showNoDataFoundMsg={!jobSearchSummary || !jobSearchSummary.supplierJobDetails || jobSearchSummary.supplierJobDetails.length === 0}
                                                            items={jobSearchSummary?.supplierJobDetails || []}
                                                            compact={false}
                                                            columns={columnsJobTable}
                                                            selectionMode={SelectionMode.none}
                                                            getKey={(item: SupplierJobDetails) => item.clientRowKey}
                                                            setKey="none"
                                                            layoutMode={DetailsListLayoutMode.fixedColumns}
                                                            isHeaderVisible={true}
                                                            constrainMode={ConstrainMode.horizontalConstrained} />

                                                        <Separator></Separator>
                                                        <div className={commonStyles.headingTextContainer}>
                                                            <Text variant="mediumPlus" className={commonStyles.headingText} role="heading" aria-level={2}>Invoice Summary</Text>
                                                        </div>
                                                        <CustomDetailsList
                                                            showExcelExport={true}
                                                            exportExcelSheetName="Invoice Summary"
                                                            ariaLabelForGrid="Invoice Summary"
                                                            displayTotalItems={true}
                                                            showPaginator={false}
                                                            showPageSize={false}
                                                            selectedPage={selectedPageInvoiceTable}
                                                            onSelectedPageChange={(page) => {
                                                                setSelectedPageInvoiceTable(page);
                                                            }}
                                                            showNoDataFoundMsg={!jobSearchSummary || !jobSearchSummary.supplierInvoiceSummary || jobSearchSummary.supplierInvoiceSummary.length === 0}
                                                            items={jobSearchSummary?.supplierInvoiceSummary || []}
                                                            compact={false}
                                                            columns={columnsInvoiceTable}
                                                            selectionMode={SelectionMode.none}
                                                            getKey={(item: SupplierInvoiceInfo) => item.clientRowKey}
                                                            setKey="none"
                                                            layoutMode={DetailsListLayoutMode.fixedColumns}
                                                            isHeaderVisible={true}
                                                            constrainMode={ConstrainMode.horizontalConstrained}
                                                            onRenderDetailsFooter={onRenderDetailsFooterForInvoiceOrPoSummary} />

                                                        <Separator></Separator>
                                                        <div className={commonStyles.headingTextContainer}>
                                                            <Text variant="mediumPlus" className={commonStyles.headingText} role="heading" aria-level={2}>Purchase Order Summary</Text>
                                                        </div>
                                                        <CustomDetailsList
                                                            showExcelExport={true}
                                                            exportExcelSheetName="Purchase Order Summary"
                                                            ariaLabelForGrid="Purchase Order Summary"
                                                            displayTotalItems={true}
                                                            showPaginator={false}
                                                            showPageSize={false}
                                                            selectedPage={selectedPagePoTable}
                                                            onSelectedPageChange={(page) => {
                                                                setSelectedPagePoTable(page);
                                                            }}
                                                            showNoDataFoundMsg={!jobSearchSummary || !jobSearchSummary.supplierPurchaseOrderSummary || jobSearchSummary.supplierPurchaseOrderSummary.length === 0}
                                                            items={jobSearchSummary?.supplierPurchaseOrderSummary || []}
                                                            compact={false}
                                                            columns={columnsPoTable}
                                                            selectionMode={SelectionMode.none}
                                                            getKey={(item: SupplierPurchaseOrderInfo) => item.clientRowKey}
                                                            setKey="none"
                                                            layoutMode={DetailsListLayoutMode.fixedColumns}
                                                            isHeaderVisible={true}
                                                            constrainMode={ConstrainMode.horizontalConstrained}
                                                            onRenderDetailsFooter={onRenderDetailsFooterForInvoiceOrPoSummary} />
                                                    </>
                                                )}

                                                {apiLoadSupplierSummary.callApiState === CallApiState.Running && (
                                                    <Spinner size={SpinnerSize.medium} className={commonStyles.spinner} />
                                                )}
                                                {apiLoadSupplierSummary.callApiState === CallApiState.Completed && activeSupplierJob && (
                                                    <>
                                                        <Separator></Separator>
                                                        <div className={commonStyles.headingTextContainer}>
                                                            <Text variant="mediumPlus" className={commonStyles.headingText} role="heading" aria-level={2}>Job units for job id {activeSupplierJob.supplierJobId}</Text>
                                                            <Text variant="smallPlus" style={{ fontStyle: 'italic', marginLeft: '18px' }}>Click on a Unit Id to view details.</Text>
                                                        </div>
                                                        <CustomDetailsList
                                                            showExcelExport={true}
                                                            exportExcelSheetName={'Job units for job id ' + activeSupplierJob.supplierJobId }
                                                            ariaLabelForGrid={'Job units for job id ' + activeSupplierJob.supplierJobId }
                                                            displayTotalItems={true}
                                                            showPaginator={true}
                                                            showPageSize={true}
                                                            selectedPage={selectedPageJobUnitTable}
                                                            onSelectedPageChange={(page) => {
                                                                setSelectedPageJobUnitTable(page);
                                                            }}
                                                            showNoDataFoundMsg={!supplierSummaries || supplierSummaries.length === 0}
                                                            items={supplierSummaries || []}
                                                            compact={false}
                                                            columns={columnsJobUnitTable}
                                                            selectionMode={SelectionMode.none}
                                                            getKey={(item: SupplierSummary) => item.clientRowKey}
                                                            setKey="none"
                                                            layoutMode={DetailsListLayoutMode.fixedColumns}
                                                            isHeaderVisible={true}
                                                            constrainMode={ConstrainMode.horizontalConstrained} />
                                                        <div ref={divBelowJobUnitsScrollRef} />
                                                    </>
                                                )}
                                            </Stack.Item>
                                        </Stack>
                                    </>
                                )
                            }
                        </div>
                    </div>
                </div>
            </PageWrapper>
            <DetailsModal
                isDetailsLoading={apiLoadJobUnitDetails.callApiState === CallApiState.Running}
                showDetails={showDetails}
                activeSupplierJob={activeSupplierJob}
                activeSupplierSummary={activeSupplierSummary}
                activeRecyclingDocument={activeRecyclingDocument}
                onClosed={() => setShowDetails(false)} />
        </>
    );
};
