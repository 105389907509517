import React, { useState } from 'react';
import {
    Text,
    Stack,
    Toggle
} from '@fluentui/react';
import { commonStyles, stackTokens } from '../../common/common.styles';
import { ICommonPageProps } from '../../common/common.types';
import { useMountEffect } from '../../common/common.func';
import { appConfig } from '../../shell/appConfig';
import PrettyJSON from 'react-prettify-json';
import { getMsalInstance, getAccessTokenForGraph, getAccessTokenForRecycling } from '../../services/auth/msalHelper';
import { pageStyles } from './DiagnosticsPage.styles';
import { AccountInfo } from '@azure/msal-browser';
import { PageWrapper } from '../../components/PageWrapper/PageWrapper';

export const DiagnosticsPage: React.FunctionComponent<ICommonPageProps> = (props: ICommonPageProps): JSX.Element => {
    const [accountInfo, setAccountInfo] = useState<AccountInfo | null>(null);
    const [graphAccessToken, setGraphAccessToken] = useState<string>();
    const [recyclingAccessToken, setRecyclingAccessToken] = useState<string>();
    const [useLocalMockData, setUseLocalMockData] = useState<boolean>(appConfig.current.service.useLocalMockData);

    /**
     * This effect is run once during page load.
     */
    useMountEffect(() => {
        (async () => {
            setAccountInfo(getMsalInstance().getActiveAccount());
            setGraphAccessToken(await getAccessTokenForGraph());
            setRecyclingAccessToken(await getAccessTokenForRecycling());
        })();
    });

    /**
     * Returns a JSX.Element that uses PrettyJSON to display any JSON object.
     * @param jsonObj Any JSON object.
     */
    const jsonBlock: (jsonObj: any) => JSX.Element = (jsonObj) => {
        return (
            <div className={pageStyles.jsonBlock}>
                <PrettyJSON jsonObject={jsonObj} colors={{ punctuation: 'gray', key: 'teal', value: 'blue', string: 'blue' }}/>
            </div>
        );
    }

    /**
     * Returns a JSX.Element that contains a link to jwt.ms for the token.
     * @param jwt JWT token.
     */
    const jwtLink: (jwt: string | undefined) => JSX.Element = (jwt) => {
        if (!jwt) {
            return <></>;
        }
        return (
            <div style={{ marginTop: '10px' }}>
                <a rel="noreferrer noopener" href={'https://jwt.ms/#access_token=' + jwt} target="_blank">Examine token on jwt.ms</a>
            </div>
        );
    }

    /**
     * Mock data toggle change event handler.
     * @param ev Mouse event.
     * @param checked Checked or unchecked boolean.
     */
    const mockDataToggleOnChange = (ev: React.MouseEvent<HTMLElement>, checked: boolean | undefined) => {
        setUseLocalMockData(!!checked); // !! is to convert falsey to false or truthy to true given checked can be undefined.
        appConfig.current.service.useLocalMockData = !!checked;
        window.localStorage.setItem('useLocalMockData', checked ? 'true' : 'false');
    }

    return (
        <PageWrapper {...props}>
            <div className='ms-Grid' dir="ltr">
                <div className='ms-Grid-row'>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <div className={commonStyles.pageHeader}>
                            <Text variant="xLarge" role="heading" aria-level={1}>Diagnostics</Text>
                        </div>
                    </div>
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                            <table className={pageStyles.diagnosticDisplay} role="presentation">
                                <tbody>
                                    <tr>
                                        <td>Options</td>
                                        <td tabIndex={0}>
                                            <Stack tokens={stackTokens} horizontal style={{ paddingLeft: 20, paddingRight: 20 }}>
                                                <Toggle label="Mock data" checked={useLocalMockData} onText="On" offText="Off" onChange={mockDataToggleOnChange} />
                                            </Stack>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>NodeJS process environment variables during build</td>
                                        <td tabIndex={0}>
                                            {jsonBlock(process.env)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>App config</td>
                                        <td tabIndex={0}>
                                            {jsonBlock(appConfig.current)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Account info</td>
                                        <td tabIndex={0}>
                                            {jsonBlock(accountInfo)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Access token for Graph</td>
                                        <td tabIndex={0}>
                                            {graphAccessToken}
                                            {jwtLink(graphAccessToken)}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Access token for Recycling API</td>
                                        <td tabIndex={0}>
                                            {recyclingAccessToken}
                                            {jwtLink(recyclingAccessToken)}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </PageWrapper>
    );
}
