import { Payment } from '../../../models/supplier/payment';
import { serviceApiClient } from '../../../services/api/serviceApiClient';
import { AppDispatch, reduxStore } from '../../reduxStore';
import * as actionTypes from '../actionTypes';
import { callApi, CallApiState, ICallApiBase } from '../generic.action';

/**
 * Load payment notifications action payload.
 */
export interface IApiLoadPaymentNotifications extends ICallApiBase {
    payments?: Payment[] | null;
}

/**
 * Load payment notifications.
 * @param programType AAD object id.
 * @param supplierId Supplier id.
 * @param year Year.
 * @param month Month.
 */
export const callApiLoadPaymentNotifications = (programType: string, supplierId: string, year: number, month: number): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<Payment[]>(
        actionTypes.API_LOAD_PAYMENT_NOTIFICATIONS,
        async () => {
            return await serviceApiClient.paymentNotifications(programType, supplierId, year, month) || [];
        },
        (payload: IApiLoadPaymentNotifications, data) => {
            if (data) {
                // Set the clientRowKey in each object so it can be bound into the DetailsList.
                data.forEach((value: Payment, index: number) => {
                    value.clientRowKey = index.toString()
                });
            }

            payload.payments = data;
        }
    );
};

/**
 * Reset the page action call state for all api calls.
 * This clear all data from the Redux store for these calls, including any error state for failed calls.
 * This makes it so if there was a failed call and an error was shown on the page, if the user navigates
 * to another page and back, the prior error would not be shown.
 */
export const resetApiCallState = () => {
    const apiLoadPaymentNotifications: IApiLoadPaymentNotifications = {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        payments: undefined
    };
    reduxStore.dispatch({
        type: actionTypes.API_LOAD_PAYMENT_NOTIFICATIONS,
        payload: apiLoadPaymentNotifications
    });
};
