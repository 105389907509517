import { IStyle, mergeStyleSets } from '@fluentui/react';

/**
 * Styles used on this component.
 */
export const componentStyles = mergeStyleSets({
    userImage: {
        borderRadius: '50%'
    } as IStyle,
    userName: {
        fontWeight: 'bold'
    } as IStyle,
    buildNumber: {
        fontSize: '12px',
        color: '#727272',
        textAlign: 'right',
        width: '100%'
    } as IStyle
});
