export interface IInvoiceSummary {
    id: string;
    partitionKey: string;
    xcv: string;
    programType: string;
    year: number;
    month: number;
    supplierId: string;
    supplierName: string;
    supplierReportingDate: Date;
    msCompanyCode: string;
    msPoNumber: string;
    msPoCurrency: string;
    supplierJobId: string;
    supplierJobType: string;
    collectionCountry: string;
    supplierProcessingCountry: string;
    billingCountry: string;
    supplierInvoiceNumber: string;
    invoiceAmount: number;
    logisticsFee: number;
    minimumServiceFee: number;
    invoiceAmountUSD: number;
    logisticsFeeUSD: number;
    minimumServiceFeeUSD: number;
    supplierBillingEntity: string;
    modifiedDate: Date;
}

export class InvoiceSummary implements IInvoiceSummary {
    public id: string;
    public partitionKey: string;
    public xcv: string;
    public programType: string;
    public year: number;
    public month: number;
    public supplierId: string;
    public supplierName: string;
    public supplierReportingDate: Date;
    public msCompanyCode: string;
    public msPoNumber: string;
    public msPoCurrency: string;
    public supplierJobId: string;
    public supplierJobType: string;
    public collectionCountry: string;
    public supplierProcessingCountry: string;
    public billingCountry: string;
    public supplierInvoiceNumber: string;
    public invoiceAmount: number;
    public logisticsFee: number;
    public minimumServiceFee: number;
    public invoiceAmountUSD: number;
    public logisticsFeeUSD: number;
    public minimumServiceFeeUSD: number;
    public supplierBillingEntity: string;
    public modifiedDate: Date;

    // Below are properties NOT returned from the server but are used for client side purposes.
    public clientRowKey: string = '';

    constructor(jsonData: IInvoiceSummary) {
        this.id = jsonData.id;
        this.partitionKey = jsonData.partitionKey;
        this.xcv = jsonData.xcv;
        this.programType = jsonData.programType;
        this.year = jsonData.year;
        this.month = jsonData.month;
        this.supplierId = jsonData.supplierId;
        this.supplierName = jsonData.supplierName;
        this.supplierReportingDate = new Date(jsonData.supplierReportingDate);
        this.msCompanyCode = jsonData.msCompanyCode;
        this.msPoNumber = jsonData.msPoNumber;
        this.msPoCurrency = jsonData.msPoCurrency;
        this.supplierJobId = jsonData.supplierJobId;
        this.supplierJobType = jsonData.supplierJobType;
        this.collectionCountry = jsonData.collectionCountry;
        this.supplierProcessingCountry = jsonData.supplierProcessingCountry;
        this.billingCountry = jsonData.billingCountry;
        this.supplierInvoiceNumber = jsonData.supplierInvoiceNumber;
        this.invoiceAmount = jsonData.invoiceAmount;
        this.logisticsFee = jsonData.logisticsFee;
        this.minimumServiceFee = jsonData.minimumServiceFee;
        this.invoiceAmountUSD = jsonData.invoiceAmountUSD;
        this.logisticsFeeUSD = jsonData.logisticsFeeUSD;
        this.minimumServiceFeeUSD = jsonData.minimumServiceFeeUSD;
        this.supplierBillingEntity = jsonData.supplierBillingEntity;
        this.modifiedDate = new Date(jsonData.modifiedDate);
    }
}
