import React, { useCallback, useEffect, useState } from 'react';
import {
    FontIcon,
    Link,
    Spinner,
    SpinnerSize,
    Stack,
    Text
} from '@fluentui/react';
import { commonStyles } from '../../common/common.styles';
import { ICommonPageProps } from '../../common/common.types';
import { useMountEffect } from '../../common/common.func';
import { clearErrorByIndex, ErrorBar } from '../../components/ErrorBar/ErrorBar';
import { pageStyles } from './ReportsPage.styles';
import { IReportsPageReducerState } from '../../store/reducers/pageReducers/reportsPage.reducer';
import { IApiLoadReports, loadReports, resetApiCallState } from '../../store/actions/pageActions/reportsPage.action';
import { CallApiState, StateUpdateOption } from '../../store/actions/generic.action';
import { useAppSelector, useAppDispatch } from '../../store/hooks';
import { PageWrapper } from '../../components/PageWrapper/PageWrapper';

interface IPageProps extends ICommonPageProps {
}

export const ReportsPage: React.FunctionComponent<IPageProps> = (props: IPageProps): JSX.Element => {
    const [errors, setErrors] = useState<string[]>([]);
    const reducerState = useAppSelector<IReportsPageReducerState>(state => state.reportsPageReducer);

    // Redux store selectors to get state from the store when it changes.
    const apiLoadReports: IApiLoadReports =
        useAppSelector<IApiLoadReports>((state) => state.reportsPageReducer.apiLoadReports);

    // Redux store dispatch to send actions to the store.
    const dispatch = useAppDispatch();

    /**
     * This effect does nothing on mount, but will return a cleanup function that runs when the component unmounts.
     */
    useEffect(() => {
        return function cleanup() {
            resetApiCallState(StateUpdateOption.ErrorOnly);
        }
    }, []);

    /**
     * Handle error.
     * @param errMsg Error message.
     */
    const handleError = useCallback((errMsg: string) => {
        setErrors((prevErrors) => {
            // This will prevent the same error from being displayed if already displayed.
            // ex: Multiple page data load failures might occur if the api is not working,
            // and this page makes multiple load calls for various data.
            if (!prevErrors.includes(errMsg)) {
                return [...prevErrors, errMsg];
            }
            return prevErrors;
        });
    }, []);

    /**
     * Effect for when errors occur in any api call.
     */
    useEffect(() => {
        if (apiLoadReports.errMsg) {
            handleError(apiLoadReports.errMsg);
        }
    }, [handleError, apiLoadReports.errMsg]);

    /**
     * This effect is run once during page load.
     */
    useMountEffect(() => {
        dispatch(loadReports(reducerState));
    });

    /**
     * Return the request access link. This is a link to idwebelements that will automatically request an add to the security group.
     * ex: https://idwebelements/GroupManagement.aspx?Group=corusage&Operation=join
     * @param sgAlias Security group alias.
     */
    const requestAccessLink = (sgAlias: string): string => {
        return `https://idwebelements/GroupManagement.aspx?Group=${sgAlias}&Operation=join`;
    }

    return (
        <PageWrapper {...props}>
            <ErrorBar errors={errors} onDismiss={(index: number) => {
                setErrors(clearErrorByIndex(errors, index));
            }} />

            <div className="ms-Grid" dir="ltr">
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <div className={commonStyles.pageHeader}>
                            <Text variant="xLarge" role="heading" aria-level={1}>Reports</Text>
                        </div>
                    </div>
                </div>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        { apiLoadReports.callApiState === CallApiState.Running && (
                            <Spinner size={SpinnerSize.medium} className={commonStyles.spinner} />
                        )}

                        { apiLoadReports.callApiState === CallApiState.Completed && apiLoadReports.reportPrograms && apiLoadReports.reportPrograms.length > 0 && (
                            <Stack tokens={{ childrenGap: 24 }}>
                                {
                                    apiLoadReports.reportPrograms?.map((reportProgram, index1) => {
                                        return (
                                            <Stack.Item key={index1}>
                                                <Text variant="large" role="heading" aria-level={2}>{reportProgram.programName}</Text>

                                                <Stack tokens={{ childrenGap: 14 }} className={pageStyles.reportChildren}>
                                                    <div className={pageStyles.accessContainer}>
                                                        {
                                                            reportProgram.isMember ? (
                                                                <div>
                                                                    <FontIcon iconName="CheckMark" className={pageStyles.hasAccessIcon} />
                                                                    <Text className={pageStyles.accessText}>You have access to these reports.</Text>
                                                                </div>
                                                            ) : (
                                                                <>
                                                                    <div>
                                                                        <FontIcon iconName="StatusCircleErrorX" className={pageStyles.hasNoAccessIcon} />
                                                                        <Text className={pageStyles.accessText}>You do not have access to these reports.</Text>
                                                                    </div>
                                                                    <div>
                                                                        <Link href={requestAccessLink(reportProgram.sgAlias)} target="_blank" aria-label={'Click here to request access to reports for ' + reportProgram.programName}>Click here to request access.</Link>
                                                                    </div>
                                                                </>
                                                            )
                                                        }
                                                    </div>

                                                    {
                                                        reportProgram.reports.map((reportInfo, index2) => {
                                                            return (
                                                                <Stack.Item key={index2}>
                                                                    { reportProgram.isMember && (
                                                                        <Link href={reportInfo.reportLink} target="_blank">
                                                                            <Text variant="mediumPlus">{reportInfo.displayName}</Text>
                                                                        </Link>
                                                                    )}
                                                                    { !reportProgram.isMember && (
                                                                        // If not a member, then don't use a disabled link, just display the text.
                                                                        <Text variant="mediumPlus">{reportInfo.displayName}</Text>
                                                                    )}
                                                                    <Text block={true}>{reportInfo.description}</Text>
                                                                </Stack.Item>
                                                            )
                                                        })
                                                    }
                                                </Stack>
                                            </Stack.Item>
                                        );
                                    })
                                }
                            </Stack>
                        )}
                    </div>
                </div>
            </div>
        </PageWrapper>
    );
};
