import { CreditSummary } from '../../../models/supplier/creditSummary';
import { serviceApiClient } from '../../../services/api/serviceApiClient';
import { AppDispatch, reduxStore } from '../../reduxStore';
import * as actionTypes from '../actionTypes';
import { callApi, CallApiState, ICallApiBase } from '../generic.action';

/**
 * Load credit summary action payload.
 */
export interface IApiLoadCreditSummary extends ICallApiBase {
    creditSummaries?: CreditSummary[] | null;
}

/**
 * Load credit summary.
 * @param programType Program type.
 * @param supplierId Supplier id.
 * @param year Year.
 * @param month Month.
 * @param supplierPoNumber supplier PO number.
 * @returns Redux dispatch function.
 */
export const callApiLoadCreditSummary = (programType: string, supplierId: string, year: number, month: number, supplierPoNumber?: string): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<CreditSummary[]>(
        actionTypes.API_LOAD_CREDIT_SUMMARY,
        async () => {
            return await serviceApiClient.creditSummary(programType, supplierId, year, month, supplierPoNumber) || [];
        },
        (payload: IApiLoadCreditSummary, data) => {
            if (data) {
                // Set the clientRowKey in each object so it can be bound into the DetailsList.
                data.forEach((value: CreditSummary, index: number) => {
                    value.clientRowKey = index.toString()
                });
            }

            payload.creditSummaries = data;
        }
    );
};

/**
 * Reset the page action call state for all api calls.
 * This clear all data from the Redux store for these calls, including any error state for failed calls.
 * This makes it so if there was a failed call and an error was shown on the page, if the user navigates
 * to another page and back, the prior error would not be shown.
 */
export const resetApiCallState = () => {
    const apiLoadCreditSummary: IApiLoadCreditSummary = {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        creditSummaries: undefined
    };
    reduxStore.dispatch({
        type: actionTypes.API_LOAD_CREDIT_SUMMARY,
        payload: apiLoadCreditSummary
    });
};
