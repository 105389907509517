export interface ICredit {
    correlationId: string;
    supplierReportingDate?: Date;
    supplierPoNumber: string;
    dateSold?: Date;
    salePrice?: number;
    salePriceUSD?: number;
    supplierCommission?: number;
    supplierCommissionUSD?: number;
    msRevenueShare?: number;
    msRevenueShareUSD?: number;
}

export class Credit implements ICredit {
    public correlationId: string;
    public supplierReportingDate?: Date;
    public supplierPoNumber: string;
    public dateSold?: Date;
    public salePrice?: number;
    public salePriceUSD?: number;
    public supplierCommission?: number;
    public supplierCommissionUSD?: number;
    public msRevenueShare?: number;
    public msRevenueShareUSD?: number;

    constructor(jsonData: ICredit) {
        this.correlationId = jsonData.correlationId;
        this.supplierReportingDate = jsonData.supplierReportingDate ? new Date(jsonData.supplierReportingDate) : undefined;
        this.supplierPoNumber = jsonData.supplierPoNumber;
        this.dateSold = jsonData.dateSold ? new Date(jsonData.dateSold) : undefined;
        this.salePrice = jsonData.salePrice;
        this.salePriceUSD = jsonData.salePriceUSD;
        this.supplierCommission = jsonData.supplierCommission;
        this.supplierCommissionUSD = jsonData.supplierCommissionUSD;
        this.msRevenueShare = jsonData.msRevenueShare;
        this.msRevenueShareUSD = jsonData.msRevenueShareUSD;
    }
}
