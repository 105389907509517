export interface IRecyclingCertificate {
    correlationId: string;
    supplierReportingDate: Date;
    certificateFileName: string;
    certificateBlobUrl: string;
}

export class RecyclingCertificate implements IRecyclingCertificate {
    public correlationId: string;
    public supplierReportingDate: Date;
    public certificateFileName: string;
    public certificateBlobUrl: string;

    constructor(jsonData: IRecyclingCertificate) {
        this.correlationId = jsonData.correlationId;
        this.supplierReportingDate = new Date(jsonData.supplierReportingDate);
        this.certificateFileName = jsonData.certificateFileName;
        this.certificateBlobUrl = jsonData.certificateBlobUrl;
    }
}
