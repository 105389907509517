import React, { useState, useRef, useCallback, useEffect } from 'react';
import { useId } from '@fluentui/react-hooks';
import {
    IColumn,
    PrimaryButton,
    IconButton,
    DefaultButton,
    TooltipHost,
    Dialog,
    DialogFooter,
    SelectionMode,
    DetailsListLayoutMode,
    ConstrainMode,
    Stack,
    Text,
    Spinner,
    SpinnerSize
} from '@fluentui/react';
import {
    commonStyles,
    stackTokens
} from '../../common/common.styles';
import { ICommonPageProps } from '../../common/common.types';
import { assignOnColumnClick, ColumnsAndItems, commonColumnProps, isNullOrUndefinedOrEmptyString, resetColumnSorting, sortOnColumn } from '../../common/common.func';
import { clearErrorByIndex, ErrorBar, prepErrorMsg } from '../../components/ErrorBar/ErrorBar';
import { appConstants, DocumentType } from '../../common/appConstants';
import { CertificateSummary } from '../../models/certificates/certificateSummary';
import { SupplierDocumentDetails } from '../../models/certificates/supplierDocumentDetails';
import { CustomDetailsList } from '../../components/CustomDetailsList/CustomDetailsList';
import { ComboInputOption, ISearchCriteria, SearchFilter } from '../../components/SearchFilter/SearchFilter';
import mime from 'mime-types';
import { documentDialogModelProps, documentDialogContentProps, documentTooltipHostStyles, pageStyles, viewIcon } from './CertificatesPage.styles';
import {
    callApiDownloadBlobStream,
    IApiDownloadBlobStream,
    IApiLoadDataErasureCertificates,
    IApiLoadInvoiceDocuments,
    IApiLoadPoDocuments,
    IApiLoadRecyclingCertificates,
    callApiLoadDataErasureCertificates,
    callApiLoadInvoiceDocuments,
    callApiLoadPoDocuments,
    callApiLoadRecyclingCertificates,
    resetApiCallState
} from '../../store/actions/pageActions/certificatesPage.action';
import { CallApiState } from '../../store/actions/generic.action';
import { pdfjs, Document, Page } from 'react-pdf';
import { useAppSelector, useAppDispatch } from '../../store/hooks';
import { PageWrapper } from '../../components/PageWrapper/PageWrapper';

// Set the react-pdf / pdfjs worker.
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

interface IPageProps extends ICommonPageProps {
}

export const CertificatesPage: React.FunctionComponent<IPageProps> = (props: IPageProps): JSX.Element => {
    const [errors, setErrors] = useState<string[]>([]);

    const [selectedPageA, setSelectedPageA] = useState<number>(1);
    const [selectedPageB, setSelectedPageB] = useState<number>(1);
    const [selectedPageC, setSelectedPageC] = useState<number>(1);
    const [selectedPageD, setSelectedPageD] = useState<number>(1);

    const [showDocDialog, setShowDocDialog] = useState<boolean>(false);
    const [docType, setDocType] = useState<DocumentType | undefined>();
    const [docItem, setDocItem] = useState<CertificateSummary | SupplierDocumentDetails>();
    const [docData, setDocData] = useState<string | undefined>();
    const [docExt, setDocExt] = useState<'pdf' | 'image' | undefined>();
    const [docIsLoading, setDocIsLoading] = useState<boolean>(false);

    // Flags used to show certain sections. Depending on search done, only certain sections may show.
    const [showJobDetailsResults, setShowJobDetailsResults] = useState<boolean>(false);
    const [showPoDocumentsResults, setShowPoDocumentsResults] = useState<boolean>(false);
    const [showInvoiceDocumentsResults, setShowInvoiceDocumentsResults] = useState<boolean>(false);
    const [showUnitDetailsResults, setShowUnitDetailsResults] = useState<boolean>(false);

    const blobObjUrl = useRef<string>();

    const [certificateSummariesForJob, setCertificateSummariesForJob] = useState<CertificateSummary[] | null>();
    const [supplierDocumentDetailsForPoDocs, setSupplierDocumentDetailsForPoDocs] = useState<SupplierDocumentDetails[] | null>();
    const [supplierDocumentDetailsForInvoiceDocs, setSupplierDocumentDetailsForInvoiceDocs] = useState<SupplierDocumentDetails[] | null>();
    const [certificateSummariesForUnit, setCertificateSummariesForUnit] = useState<CertificateSummary[] | null>();

    const [docLoadErrorMsg, setDocLoadErrorMsg] = useState<string | undefined>('');
    const [numPages, setNumPages] = useState<number>(0);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const documentIconTooltip = useId('documentIconTooltip');

    // Redux store selectors to get state from the store when it changes.
    const apiLoadRecyclingCertificates: IApiLoadRecyclingCertificates =
        useAppSelector<IApiLoadRecyclingCertificates>((state) => state.certificatesPageReducer.apiLoadRecyclingCertificates);
    const apiLoadPoDocuments: IApiLoadPoDocuments =
        useAppSelector<IApiLoadPoDocuments>((state) => state.certificatesPageReducer.apiLoadPoDocuments);
    const apiLoadInvoiceDocuments: IApiLoadInvoiceDocuments =
        useAppSelector<IApiLoadInvoiceDocuments>((state) => state.certificatesPageReducer.apiLoadInvoiceDocuments);
    const apiLoadDataErasureCertificates: IApiLoadDataErasureCertificates =
        useAppSelector<IApiLoadDataErasureCertificates>((state) => state.certificatesPageReducer.apiLoadDataErasureCertificates);
    const apiDownloadBlobStream: IApiDownloadBlobStream =
        useAppSelector<IApiDownloadBlobStream>((state) => state.certificatesPageReducer.apiDownloadBlobStream);

    // Redux store dispatch to send actions to the store.
    const dispatch = useAppDispatch();

    /**
     * This effect does nothing on mount, but will return a cleanup function that runs when the component unmounts.
     */
    useEffect(() => {
        return function cleanup() {
            resetApiCallState();
        }
    }, []);

    /**
     * Handle error.
     * @param errMsg Error message.
     */
    const handleError = useCallback((errMsg: string) => {
        setErrors((prevErrors) => {
            // This will prevent the same error from being displayed if already displayed.
            // ex: Multiple page data load failures might occur if the api is not working,
            // and this page makes multiple load calls for various data.
            if (!prevErrors.includes(errMsg)) {
                return [...prevErrors, errMsg];
            }
            return prevErrors;
        });
    }, []);

    /**
     * Effect for when errors occur in any api call.
     */
    useEffect(() => {
        if (apiLoadRecyclingCertificates.errMsg) {
            handleError(apiLoadRecyclingCertificates.errMsg);
        }
        if (apiLoadPoDocuments.errMsg) {
            handleError(apiLoadPoDocuments.errMsg);
        }
        if (apiLoadInvoiceDocuments.errMsg) {
            handleError(apiLoadInvoiceDocuments.errMsg);
        }
        if (apiLoadDataErasureCertificates.errMsg) {
            handleError(apiLoadDataErasureCertificates.errMsg);
        }
    }, [handleError, apiLoadDataErasureCertificates.errMsg, apiLoadInvoiceDocuments.errMsg, apiLoadPoDocuments.errMsg, apiLoadRecyclingCertificates.errMsg]);

    /**
     * Search clicked event handler.
     * @param searchCriteria Search criteria.
     */
    const searchClicked = (searchCriteria: ISearchCriteria) => {
        setShowJobDetailsResults(false);
        setShowPoDocumentsResults(false);
        setShowInvoiceDocumentsResults(false);
        setShowUnitDetailsResults(false);

        setCertificateSummariesForJob(undefined);
        setSupplierDocumentDetailsForPoDocs(undefined);
        setSupplierDocumentDetailsForInvoiceDocs(undefined);
        setCertificateSummariesForUnit(undefined);

        setSelectedPageA(1);
        setSelectedPageB(1);
        setSelectedPageC(1);
        setSelectedPageD(1);

        // If user queried for an asset tag or serial number, then ONLY make the call to dataErasureCertificates
        // and not the other apis.
        if (searchCriteria.assetTag || searchCriteria.serialNumber) {
            dispatch(callApiLoadDataErasureCertificates(
                searchCriteria.programType, searchCriteria.supplierId,
                searchCriteria.supplierJobId, searchCriteria.supplierUnitId, searchCriteria.supplierUnitType,
                searchCriteria.assetTag, searchCriteria.serialNumber
            ));
        } else {
            // Only call loadRecyclingCertificates is searchCriteria.supplierJobId was supplied. The SearchFilter is
            // configured to also allow Supplier PO Number or Invoice Number in the combo input group. So if the user had
            // supplied one of those but not Supplier Job Id then do not call this api.
            if (searchCriteria.supplierJobId) {
                dispatch(callApiLoadRecyclingCertificates(searchCriteria.programType, searchCriteria.supplierId, searchCriteria.supplierJobId));
            }

            dispatch(callApiLoadPoDocuments(searchCriteria.programType, searchCriteria.supplierId, searchCriteria.supplierJobId, searchCriteria.supplierPoNumber));
            dispatch(callApiLoadInvoiceDocuments(searchCriteria.programType, searchCriteria.supplierId, searchCriteria.supplierJobId, searchCriteria.supplierInvoiceNumber));

            dispatch(callApiLoadDataErasureCertificates(
                searchCriteria.programType, searchCriteria.supplierId,
                searchCriteria.supplierJobId, searchCriteria.supplierUnitId, searchCriteria.supplierUnitType,
                searchCriteria.assetTag, searchCriteria.serialNumber
            ));
        }
    };

    /**
     * Search filter load error handler.
     * @param err Error
     */
    const searchFilterLoadError = (err: any) => {
        handleError(prepErrorMsg(appConstants.dataLoadFailed, err));
    }

    /**
     * Effect for when blob stream is downloaded.
     */
    useEffect(() => {
        if (apiDownloadBlobStream.callApiState === CallApiState.DataAvailable) {
            const blobFileBase64: string | undefined | null = apiDownloadBlobStream.blobStream;
            const item: CertificateSummary | SupplierDocumentDetails | undefined = apiDownloadBlobStream.item;
            const documentType: DocumentType | undefined = apiDownloadBlobStream.documentType;

            if (blobFileBase64 && item && item.fileBlobUrl) {
                // Convert the base 64 string to a byte array.
                const byteCharacters: string = atob(blobFileBase64);
                const byteNumbers: any[] = new Array(byteCharacters.length);
                for (let i: number = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
    
                // Get the file extension.
                const parts: string[] = item.fileBlobUrl.split('.');
                const ext: string = parts.length > 0 ? parts.pop()!.trim().toLowerCase() : '';
    
                // Get the mime type for the file based on the extension.
                const fileMimeType: string = mime.lookup(ext);
                
                // Convert the byte array to a blob.
                const blob: Blob = new Blob([byteArray], { type: fileMimeType });
    
                // Create a "object url" to be used with the download button.
                blobObjUrl.current = URL.createObjectURL(blob);

                // Create a "data url" for the blob and set it into the docData.
                const displayData = async () => {
                    await new Promise<void>((resolve) => {
                        const reader = new FileReader();
                        reader.onloadend = () => {
                            setDocItem(item);
                            setDocType(documentType);
                            setDocData(reader.result as string);
                            if (ext === 'pdf') {
                                setDocExt('pdf');
                            } else if (ext === 'png' || ext === 'jpg' || ext === 'jpeg' || ext === 'bmp') {
                                setDocExt('image');
                            } else {
                                setDocLoadErrorMsg('Unsupported document type');
                            }
                            setDocIsLoading(false);
                            resolve();
                        }
                        reader.onerror = () => {
                            setDocLoadErrorMsg('Failed to load document');
                            setDocIsLoading(false);
                            resolve();
                        }
                        reader.readAsDataURL(blob);
                    });
                }
                displayData(); // Execute the inline async function.
            }
        }
    }, [apiDownloadBlobStream.blobStream, apiDownloadBlobStream.callApiState, apiDownloadBlobStream.documentType, apiDownloadBlobStream.item])

    /**
     * Setup columns to display for job details.
     */
    const [columnsJobDetails, setColumnsJobDetails] = useState<IColumn[]>(
        [
            {
                ...commonColumnProps,
                key: 'column1',
                name: 'Job ID',
                fieldName: 'supplierJobId',
                minWidth: 75
            },
            {
                ...commonColumnProps,
                key: 'column2',
                name: 'Job Type',
                fieldName: 'supplierJobType',
                minWidth: 75
            },
            {
                ...commonColumnProps,
                key: 'column3',
                name: 'MS PO Number',
                fieldName: 'msPoNumber',
                minWidth: 75
            },
            {
                ...commonColumnProps,
                key: 'column4',
                name: 'Company Code',
                fieldName: 'msCompanyCode',
                minWidth: 60
            },
            {
                ...commonColumnProps,
                key: 'column5',
                name: 'Certificate', // Could be a "Recycling" or "Destruction" certificate.
                fieldName: '', // Leave blank as the view icon will render here.
                minWidth: 110,
                data: {
                    // This column will get a onRender handler added to it in a useEffect (see elsewhere in this file).
                    addOnDocumentColumnRender: true,
                    docType: DocumentType.RecyclingCertificate
                }
            }
        ]
    );

    /**
     * Setup columns to display for PO documents.
     */
    const [columnsPoDocuments, setColumnsPoDocuments] = useState<IColumn[]>(
        [
            {
                ...commonColumnProps,
                key: 'column1',
                name: 'Supplier PO number',
                fieldName: 'supplierPoNumber',
                minWidth: 160
            },
            {
                ...commonColumnProps,
                key: 'column2',
                name: 'PO Document',
                fieldName: '', // Leave blank as the view icon will render here.
                minWidth: 110,
                data: {
                    // This column will get a onRender handler added to it in a useEffect (see elsewhere in this file).
                    addOnDocumentColumnRender: true,
                    docType: DocumentType.PoDocument
                }
            }
        ]
    );

    /**
     * Setup columns to display for invoice documents.
     */
    const [columnsInvoiceDocuments, setColumnsInvoiceDocuments] = useState<IColumn[]>(
        [
            {
                ...commonColumnProps,
                key: 'column1',
                name: 'Supplier invoice number',
                fieldName: 'supplierInvoiceNumber',
                minWidth: 160
            },
            {
                ...commonColumnProps,
                key: 'column2',
                name: 'Invoice Document',
                fieldName: '', // Leave blank as the view icon will render here.
                minWidth: 110,
                data: {
                    // This column will get a onRender handler added to it in a useEffect (see elsewhere in this file).
                    addOnDocumentColumnRender: true,
                    docType: DocumentType.InvoiceDocument
                }
            }
        ]
    );

    /**
     * Setup columns to display for unit details.
     */
    const [columnsUnitDetails, setColumnsUnitDetails] = useState<IColumn[]>(
        [
            {
                ...commonColumnProps,
                key: 'column1',
                name: 'Unit ID',
                fieldName: 'supplierUnitId',
                minWidth: 85
            },
            {
                ...commonColumnProps,
                key: 'column2',
                name: 'Unit Type',
                fieldName: 'supplierUnitType',
                minWidth: 85
            },
            {
                ...commonColumnProps,
                key: 'column3',
                name: 'Quantity',
                fieldName: 'quantity',
                minWidth: 40
            },
            {
                ...commonColumnProps,
                key: 'column4',
                name: 'Asset Tag',
                fieldName: 'assetTagNumber',
                minWidth: 75
            },
            {
                ...commonColumnProps,
                key: 'column5',
                name: 'Serial Number',
                fieldName: 'serialNumber',
                minWidth: 75
            },
            {
                ...commonColumnProps,
                key: 'column6',
                name: 'Manufacturer',
                fieldName: 'manufacturer',
                minWidth: 75
            },
            {
                ...commonColumnProps,
                key: 'column7',
                name: 'Model',
                fieldName: 'model',
                minWidth: 75
            },
            {
                ...commonColumnProps,
                key: 'column8',
                name: 'Part Number',
                fieldName: 'partNumber',
                minWidth: 75
            },
            {
                ...commonColumnProps,
                key: 'column9',
                name: 'Wipe Certificate Number',
                fieldName: 'wipeCertificateNumber',
                minWidth: 110
            },
            {
                ...commonColumnProps,
                key: 'column10',
                name: 'Data Erasure Certificate',
                fieldName: '', // Leave blank as the view icon will render here.
                minWidth: 110,
                data: {
                    // This column will get a onRender handler added to it in a useEffect (see elsewhere in this file).
                    addOnDocumentColumnRender: true,
                    docType: DocumentType.DataErasureCertificate
                }
            },
            {
                ...commonColumnProps,
                key: 'column11',
                name: 'Job ID',
                fieldName: 'supplierJobId',
                minWidth: 75
            },
            {
                ...commonColumnProps,
                key: 'column12',
                name: 'Job Type',
                fieldName: 'supplierJobType',
                minWidth: 75
            },
            {
                ...commonColumnProps,
                key: 'column13',
                name: 'Supplier Name',
                fieldName: 'supplierName',
                minWidth: 110
            }
        ]
    );

    /**
     * On column click event handler for Job details grid.
     * @param ev The mouse event.
     * @param column The column header clicked on.
     */
     const onColumnClickJobDetails = useCallback((ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        if (certificateSummariesForJob) {
            const columnsAndItems: ColumnsAndItems<CertificateSummary> = sortOnColumn<CertificateSummary>(column, columnsJobDetails, certificateSummariesForJob, undefined);
            setCertificateSummariesForJob(columnsAndItems.items);
            setColumnsJobDetails(columnsAndItems.columns);
        }
    }, [certificateSummariesForJob, columnsJobDetails])

    /**
     * Effect to update onColumnClick handler. Otherwise the component state referenced in onColumnClick
     * would be stale from the render pass it was created on.
     */
    useEffect(() => {
        assignOnColumnClick(columnsJobDetails, onColumnClickJobDetails);
    }, [columnsJobDetails, onColumnClickJobDetails]);

    /**
     * On column click event handler for PO documents grid.
     * @param ev The mouse event.
     * @param column The column header clicked on.
     */
    const onColumnClickPoDocuments = useCallback((ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        if (supplierDocumentDetailsForPoDocs) {
            const columnsAndItems: ColumnsAndItems<SupplierDocumentDetails> = sortOnColumn<SupplierDocumentDetails>(column, columnsPoDocuments, supplierDocumentDetailsForPoDocs, undefined);
            setSupplierDocumentDetailsForPoDocs(columnsAndItems.items);
            setColumnsPoDocuments(columnsAndItems.columns);
        }
    }, [columnsPoDocuments, supplierDocumentDetailsForPoDocs])

    /**
     * Effect to update onColumnClick handler. Otherwise the component state referenced in onColumnClick
     * would be stale from the render pass it was created on.
     */
    useEffect(() => {
        assignOnColumnClick(columnsPoDocuments, onColumnClickPoDocuments);
    }, [columnsPoDocuments, onColumnClickPoDocuments]);

    /**
     * On column click event handler for invoice documents grid.
     * @param ev The mouse event.
     * @param column The column header clicked on.
     */
    const onColumnClickInvoiceDocuments = useCallback((ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        if (supplierDocumentDetailsForInvoiceDocs) {
            const columnsAndItems: ColumnsAndItems<SupplierDocumentDetails> = sortOnColumn<SupplierDocumentDetails>(column, columnsInvoiceDocuments, supplierDocumentDetailsForInvoiceDocs, undefined);
            setSupplierDocumentDetailsForInvoiceDocs(columnsAndItems.items);
            setColumnsInvoiceDocuments(columnsAndItems.columns);
        }
    }, [columnsInvoiceDocuments, supplierDocumentDetailsForInvoiceDocs])

    /**
     * Effect to update onColumnClick handler. Otherwise the component state referenced in onColumnClick
     * would be stale from the render pass it was created on.
     */
    useEffect(() => {
        assignOnColumnClick(columnsInvoiceDocuments, onColumnClickInvoiceDocuments);
    }, [columnsInvoiceDocuments, onColumnClickInvoiceDocuments]);

    /**
     * On column click event handler for unit details grid.
     * @param ev The mouse event.
     * @param column The column header clicked on.
     */
    const onColumnClickUnitDetails = useCallback((ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        if (certificateSummariesForUnit) {
            const columnsAndItems: ColumnsAndItems<CertificateSummary> = sortOnColumn<CertificateSummary>(column, columnsUnitDetails, certificateSummariesForUnit, undefined);
            setCertificateSummariesForUnit(columnsAndItems.items);
            setColumnsUnitDetails(columnsAndItems.columns);
        }
    }, [certificateSummariesForUnit, columnsUnitDetails])

    /**
     * Effect to update onColumnClick handler. Otherwise the component state referenced in onColumnClick
     * would be stale from the render pass it was created on.
     */
    useEffect(() => {
        assignOnColumnClick(columnsUnitDetails, onColumnClickUnitDetails);
    }, [columnsUnitDetails, onColumnClickUnitDetails]);

    /**
     * Show document dialog.
     * @param item The item whose document is being displayed.
     * @param documentType The type of document being displayed.
     */
    const showDocumentDialog = useCallback((item: CertificateSummary | SupplierDocumentDetails, documentType: DocumentType): void => {
        if (!item || !item.fileBlobUrl) {
            return;
        }

        setDocLoadErrorMsg(undefined);
        setDocType(undefined);
        setDocItem(undefined);
        setDocData(undefined);
        setDocExt(undefined);
        setDocIsLoading(true);
        setShowDocDialog(true);

        // Download the blob file for the certificate. The file is returned from the API as a base 64 encoded string.
        dispatch(callApiDownloadBlobStream(item, documentType));
    }, [dispatch]);

    /**
     * Dismiss document dialog.
     */
    const dismissDocumentDialog = (): void => {
        setShowDocDialog(false);

        // We are closing the dialog so we need to revoke the blob object URL to manage memory.
        if (blobObjUrl.current) {
            URL.revokeObjectURL(blobObjUrl.current);
        }
    }

    /**
     * Document on column render event handler.
     * @param item The item (row) being rendered in the details list. 
     * @param index The index of the row.
     * @param column The column being rendered.
     */
    const onDocumentColumnRender = useCallback((item: CertificateSummary | SupplierDocumentDetails, index: number | undefined, column: IColumn | undefined): JSX.Element => {
        const documentName: string | undefined = column?.name;
        const documentType: DocumentType = column?.data.docType;
        let hasDocumentLink: boolean = false;
        let docLinkFor: string = '';

        switch (documentType) {
            case DocumentType.RecyclingCertificate:
                hasDocumentLink = !isNullOrUndefinedOrEmptyString((item as CertificateSummary).fileBlobUrl);
                docLinkFor = ' for job id ' + (item as CertificateSummary).supplierJobId || '';
                break;
            case DocumentType.DataErasureCertificate:
                hasDocumentLink = !isNullOrUndefinedOrEmptyString((item as CertificateSummary).fileBlobUrl);
                docLinkFor = ' for job id and unit id ' + ((item as CertificateSummary).supplierJobId || '') + ' ' + ((item as CertificateSummary).supplierUnitId || '');
                break;
            case DocumentType.PoDocument:
                hasDocumentLink = !isNullOrUndefinedOrEmptyString((item as SupplierDocumentDetails).fileBlobUrl);
                docLinkFor = ' for purchase order number ' + (item as SupplierDocumentDetails).supplierPoNumber || '';
                break;
            case DocumentType.InvoiceDocument:
                hasDocumentLink = !isNullOrUndefinedOrEmptyString((item as SupplierDocumentDetails).fileBlobUrl);
                docLinkFor = ' for invoice number ' + (item as SupplierDocumentDetails).supplierInvoiceNumber || '';
                break;
            default:
                break;
        }

        return (
            <div style={{ textAlign: 'center' }}>
                {hasDocumentLink ?
                    <TooltipHost
                        content={`View/Download ${documentName}`}
                        // This id is used on the tooltip itself, not the host
                        // (so an element with this id only exists when the tooltip is shown).
                        id={documentIconTooltip}
                        styles={documentTooltipHostStyles}>
                        <IconButton
                            iconProps={viewIcon}
                            ariaLabel={`View/Download ${documentName}` + docLinkFor}
                            onClick={event => showDocumentDialog(item, documentType)} />
                    </TooltipHost>
                :
                    <></>
                }
            </div>
        );
    }, [documentIconTooltip, showDocumentDialog]);

    /**
     * Effect to update onRender handler. Otherwise the component state referenced in onRender
     * would be stale from the render pass it was created on.
     */
    useEffect(() => {
        columnsJobDetails.filter(x => x.data?.addOnDocumentColumnRender).forEach(c => c.onRender = onDocumentColumnRender);
    }, [columnsJobDetails, onDocumentColumnRender]);

    /**
     * Effect to update onRender handler. Otherwise the component state referenced in onRender
     * would be stale from the render pass it was created on.
     */
    useEffect(() => {
        columnsPoDocuments.filter(x => x.data?.addOnDocumentColumnRender).forEach(c => c.onRender = onDocumentColumnRender);
    }, [columnsPoDocuments, onDocumentColumnRender]);

    /**
     * Effect to update onRender handler. Otherwise the component state referenced in onRender
     * would be stale from the render pass it was created on.
     */
    useEffect(() => {
        columnsInvoiceDocuments.filter(x => x.data?.addOnDocumentColumnRender).forEach(c => c.onRender = onDocumentColumnRender);
    }, [columnsInvoiceDocuments, onDocumentColumnRender]);

    /**
     * Effect to update onRender handler. Otherwise the component state referenced in onRender
     * would be stale from the render pass it was created on.
     */
    useEffect(() => {
        columnsUnitDetails.filter(x => x.data?.addOnDocumentColumnRender).forEach(c => c.onRender = onDocumentColumnRender);
    }, [columnsUnitDetails, onDocumentColumnRender]);

    /**
     * Effect for when recycling certificates data is loaded.
     */
    useEffect(() => {
        if (apiLoadRecyclingCertificates.callApiState === CallApiState.DataAvailable) {
            setCertificateSummariesForJob(apiLoadRecyclingCertificates.recyclingCertificates);
            setColumnsJobDetails(resetColumnSorting(columnsJobDetails));
            setShowJobDetailsResults(true);
        }
    }, [columnsJobDetails, apiLoadRecyclingCertificates.callApiState, apiLoadRecyclingCertificates.recyclingCertificates]);

    /**
     * Effect for when PO documents data is loaded.
     */
    useEffect(() => {
        if (apiLoadPoDocuments.callApiState === CallApiState.DataAvailable) {
            setSupplierDocumentDetailsForPoDocs(apiLoadPoDocuments.poDocuments);
            setColumnsPoDocuments(resetColumnSorting(columnsPoDocuments));
            setShowPoDocumentsResults(true);
        }
    }, [columnsPoDocuments, apiLoadPoDocuments.callApiState, apiLoadPoDocuments.poDocuments]);

    /**
     * Effect for when invoice documents data is loaded.
     */
    useEffect(() => {
        if (apiLoadInvoiceDocuments.callApiState === CallApiState.DataAvailable) {
            setSupplierDocumentDetailsForInvoiceDocs(apiLoadInvoiceDocuments.invoiceDocuments);
            setColumnsInvoiceDocuments(resetColumnSorting(columnsInvoiceDocuments));
            setShowInvoiceDocumentsResults(true);
        }
    }, [columnsInvoiceDocuments, apiLoadInvoiceDocuments.callApiState, apiLoadInvoiceDocuments.invoiceDocuments]);

    /**
     * Effect for when data erasure certificates data is loaded.
     */
    useEffect(() => {
        if (apiLoadDataErasureCertificates.callApiState === CallApiState.DataAvailable) {
            setCertificateSummariesForUnit(apiLoadDataErasureCertificates.dataErasureCertificates);
            setColumnsUnitDetails(resetColumnSorting(columnsUnitDetails));
            setShowUnitDetailsResults(true);
        }
    }, [columnsUnitDetails, apiLoadDataErasureCertificates.callApiState, apiLoadDataErasureCertificates.dataErasureCertificates]);

    /**
     * PDF document load success handler.
     * @param pdfDocument PDF document.
     */
    const onDocumentLoadSuccess = (pdfDocument: any) => {
        setNumPages(pdfDocument.numPages);
        setDocLoadErrorMsg('');
    };

    /**
     * PDF document load error handler.
     * @param error Error.
     */
    const onDocumentLoadError = (error: Error) => {
        setNumPages(0);
        setDocLoadErrorMsg(error.message);
    }

    /**
     * Render the document dialog JSX element.
     */
    const renderDocumentDialogElement = (): JSX.Element => {
        const documentDownloadFileName: string = docItem?.fileName || '';
        let dialogTitle: string = '';

        switch (docType) {
            case DocumentType.RecyclingCertificate:
                dialogTitle = 'Certificate'; // Could be a "Recycling" or "Destruction" certificate.
                break;
            case DocumentType.DataErasureCertificate:
                dialogTitle = 'Data Erasure Certificate';
                break;
            case DocumentType.PoDocument:
                dialogTitle = 'PO Document';
                break;
            case DocumentType.InvoiceDocument:
                dialogTitle = 'Invoice Document';
                break;
            default:
                break;
        }

        return (
            <Dialog
                hidden={!showDocDialog}
                onDismiss={() => dismissDocumentDialog()}
                dialogContentProps={documentDialogContentProps(dialogTitle)}
                modalProps={documentDialogModelProps}
            >
                <Stack tokens={stackTokens} className={pageStyles.stackContainer}>
                    <Stack.Item className={pageStyles.stackItemContainer}>
                        <>
                            { docIsLoading &&
                                <Spinner size={SpinnerSize.medium} className={commonStyles.spinner} />
                            }
                            { !docIsLoading && docLoadErrorMsg &&
                                <div className={pageStyles.errorMsg}>{docLoadErrorMsg}</div>
                            }
                            { !docIsLoading && !docLoadErrorMsg && docExt === 'pdf' && docData &&
                                <div className={pageStyles.pdfDocContainer} tabIndex={0}>
                                    <Document file={docData} onLoadSuccess={onDocumentLoadSuccess} onLoadError={onDocumentLoadError}>
                                        <Page pageNumber={pageNumber} />
                                    </Document>
                                </div>
                            }
                            { !docIsLoading && !docLoadErrorMsg && docExt === 'image' && docData &&
                                <div className={pageStyles.imageDocContainer}>
                                    <img height="100%" src={docData} alt={dialogTitle} />
                                </div>
                            }
                        </>
                    </Stack.Item>
                </Stack>
                <DialogFooter>
                    { docExt === 'pdf' && 
                        <>
                            <div className={pageStyles.pageCount}>
                                Page {pageNumber} / {numPages}
                            </div>
                            <PrimaryButton text="Previous page" onClick={() => setPageNumber(pageNumber - 1)} disabled={pageNumber <= 1} style={{ marginRight: '8px' }}/>
                            <PrimaryButton text="Next page"onClick={() => setPageNumber(pageNumber + 1)} disabled={pageNumber >= (numPages || 0)} />
                        </>
                    }
                    <PrimaryButton text="Download" href={blobObjUrl.current} download={documentDownloadFileName} target="_blank"/>
                    <DefaultButton text="Close" onClick={() => dismissDocumentDialog()} />
                </DialogFooter>
            </Dialog>
        );
    }

    return (
        <PageWrapper {...props}>
            <ErrorBar errors={errors} onDismiss={(index: number) => {
                setErrors(clearErrorByIndex(errors, index));
            }} />

            <div className="ms-Grid" dir="ltr">
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <div className={commonStyles.pageHeader}>
                            <Text variant="xLarge" role="heading" aria-level={1}>Certificates &amp; Documents</Text>
                        </div>
                    </div>
                </div>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <SearchFilter
                            programs={props.programs || []}
                            searchFilterOptions={{
                                showFiscalRange: true,
                                fiscalRangeFieldsRequired: false,
                                showCalendarYearMonth: false,
                                showProgram: true,
                                showSupplierName: true,
                                showComboInputGroup: ComboInputOption.SupplierJobId_SupplierInvoiceNumber_SupplierPoNumber,
                                showSupplierJobId: false,
                                showSupplierPoNumber: false,
                                showSupplierInvoiceNumber: false,
                                showCorrelationId: false,
                                showMsInvoiceNumber: false,
                                showSupplierUnitType: true,
                                showJobUnit: true,
                                showCountry: false,
                                showComboAssetTagAndSerialNumber: true,
                                showUnitDispositionType: false,
                                showUnitStatus: false,
                                instructionText: 'Required search inputs may or may not be required based upon other inputs. If Asset Tag or Serial Number are supplied, then input is not required for Supplier Name or any of Job Id, Supplier Invoice Number or PO Number. Otherwise, a Supplier Name and at least one of Job Id, Supplier Invoice Number or PO Number is required.'
                            }}
                            onSearchClicked={searchClicked}
                            onSearchFilterLoadError={searchFilterLoadError} />
                    </div>
                </div>
                <div className="ms-Grid-row" style={{ marginTop: '24px' }}>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        {(showJobDetailsResults || showPoDocumentsResults || showInvoiceDocumentsResults || showUnitDetailsResults) &&
                            <>
                                <Stack style={{ marginRight: '1px' }}>
                                    {(
                                        apiLoadRecyclingCertificates.callApiState === CallApiState.Running ||
                                        apiLoadPoDocuments.callApiState === CallApiState.Running ||
                                        apiLoadInvoiceDocuments.callApiState === CallApiState.Running ||
                                        apiLoadDataErasureCertificates.callApiState === CallApiState.Running) &&
                                        <Spinner size={SpinnerSize.medium} className={commonStyles.spinner} />
                                    }
                                    <Stack tokens={{ childrenGap: 36 }}>
                                        { /* Note that certain sections may or may not show based on query run. */ }
                                        { showJobDetailsResults && (
                                            <Stack.Item>
                                                <Stack horizontal wrap tokens={stackTokens}>
                                                    <Stack.Item>
                                                        <div className={commonStyles.headingTextContainer}>
                                                            <Text variant="mediumPlus" className={commonStyles.headingText} role="heading" aria-level={2}>Job Details</Text>
                                                        </div>
                                                        <CustomDetailsList
                                                            showExcelExport={true}
                                                            exportExcelSheetName="Job Details"
                                                            ariaLabelForGrid="Job Details"
                                                            displayTotalItems={true}
                                                            showPaginator={false}
                                                            showPageSize={false}
                                                            selectedPage={selectedPageA}
                                                            onSelectedPageChange={(page) => {
                                                                setSelectedPageA(page);
                                                            }}
                                                            showNoDataFoundMsg={!certificateSummariesForJob || certificateSummariesForJob.length === 0}
                                                            items={certificateSummariesForJob ? certificateSummariesForJob : []}
                                                            compact={false}
                                                            columns={columnsJobDetails}
                                                            selectionMode={SelectionMode.none}
                                                            getKey={(item: CertificateSummary) => item.clientRowKey}
                                                            setKey="none"
                                                            layoutMode={DetailsListLayoutMode.fixedColumns}
                                                            isHeaderVisible={true}
                                                            constrainMode={ConstrainMode.horizontalConstrained} />
                                                    </Stack.Item>
                                                </Stack>
                                            </Stack.Item>
                                        )}

                                        { (showPoDocumentsResults || showInvoiceDocumentsResults) && (
                                            <Stack.Item>
                                                <Stack horizontal wrap tokens={stackTokens}>
                                                    { showPoDocumentsResults && (
                                                        <Stack.Item>
                                                            <div className={commonStyles.headingTextContainer}>
                                                                <Text variant="mediumPlus" className={commonStyles.headingText} role="heading" aria-level={2}>PO Documents</Text>
                                                            </div>
                                                            <CustomDetailsList
                                                                showExcelExport={true}
                                                                exportExcelSheetName="PO Documents"
                                                                ariaLabelForGrid="PO Documents"
                                                                displayTotalItems={true}
                                                                showPaginator={false}
                                                                showPageSize={false}
                                                                selectedPage={selectedPageB}
                                                                onSelectedPageChange={(page) => {
                                                                    setSelectedPageB(page);
                                                                }}
                                                                showNoDataFoundMsg={!supplierDocumentDetailsForPoDocs || supplierDocumentDetailsForPoDocs.length === 0}
                                                                items={supplierDocumentDetailsForPoDocs ? supplierDocumentDetailsForPoDocs : []}
                                                                compact={false}
                                                                columns={columnsPoDocuments}
                                                                selectionMode={SelectionMode.none}
                                                                getKey={(item: CertificateSummary) => item.clientRowKey}
                                                                setKey="none"
                                                                layoutMode={DetailsListLayoutMode.fixedColumns}
                                                                isHeaderVisible={true}
                                                                constrainMode={ConstrainMode.horizontalConstrained} />
                                                        </Stack.Item>
                                                    )}

                                                    { showInvoiceDocumentsResults && (
                                                        <Stack.Item>
                                                            <div className={commonStyles.headingTextContainer}>
                                                                <Text variant="mediumPlus" className={commonStyles.headingText} role="heading" aria-level={2}>Invoice Documents</Text>
                                                            </div>
                                                            <CustomDetailsList
                                                                showExcelExport={true}
                                                                exportExcelSheetName="Invoice Documents"
                                                                ariaLabelForGrid="Invoice Documents"
                                                                displayTotalItems={true}
                                                                showPaginator={false}
                                                                showPageSize={false}
                                                                selectedPage={selectedPageC}
                                                                onSelectedPageChange={(page) => {
                                                                    setSelectedPageC(page);
                                                                }}
                                                                showNoDataFoundMsg={!supplierDocumentDetailsForInvoiceDocs || supplierDocumentDetailsForInvoiceDocs.length === 0}
                                                                items={supplierDocumentDetailsForInvoiceDocs ? supplierDocumentDetailsForInvoiceDocs : []}
                                                                compact={false}
                                                                columns={columnsInvoiceDocuments}
                                                                selectionMode={SelectionMode.none}
                                                                getKey={(item: CertificateSummary) => item.clientRowKey}
                                                                setKey="none"
                                                                layoutMode={DetailsListLayoutMode.fixedColumns}
                                                                isHeaderVisible={true}
                                                                constrainMode={ConstrainMode.horizontalConstrained} />
                                                        </Stack.Item>
                                                    )}
                                                </Stack>
                                            </Stack.Item>
                                        )}

                                        { showUnitDetailsResults && (
                                            <Stack.Item>
                                                <Stack horizontal wrap tokens={stackTokens}>
                                                    <Stack.Item>
                                                        <div className={commonStyles.headingTextContainer}>
                                                            <Text variant="mediumPlus" className={commonStyles.headingText} role="heading" aria-level={2}>Unit Details</Text>
                                                        </div>
                                                        <CustomDetailsList
                                                            showExcelExport={true}
                                                            exportExcelSheetName="Unit Details"
                                                            ariaLabelForGrid="Unit Details"
                                                            displayTotalItems={true}
                                                            showPaginator={true}
                                                            showPageSize={true}
                                                            selectedPage={selectedPageD}
                                                            onSelectedPageChange={(page) => {
                                                                setSelectedPageD(page);
                                                            }}
                                                            showNoDataFoundMsg={!certificateSummariesForUnit || certificateSummariesForUnit.length === 0}
                                                            items={certificateSummariesForUnit ? certificateSummariesForUnit : []}
                                                            compact={false}
                                                            columns={columnsUnitDetails}
                                                            selectionMode={SelectionMode.none}
                                                            getKey={(item: CertificateSummary) => item.clientRowKey}
                                                            setKey="none"
                                                            layoutMode={DetailsListLayoutMode.fixedColumns}
                                                            isHeaderVisible={true}
                                                            constrainMode={ConstrainMode.horizontalConstrained} />
                                                    </Stack.Item>
                                                </Stack>
                                            </Stack.Item>
                                        )}

                                    </Stack>
                                </Stack>
                            </>
                        }
                        {
                            renderDocumentDialogElement()
                        }
                    </div>
                </div>
            </div>
        </PageWrapper>
    );
};
