export interface IUnitStatus {
    partitionKey: string;
    rowKey: string;
    unitStatus: string;
}

export class UnitStatus implements IUnitStatus {
    public partitionKey: string;
    public rowKey: string;
    public unitStatus: string;

    // Below are properties NOT returned from the server but are used for client side purposes.
    public clientRowKey: string = '';
    public isNew?: boolean;

    constructor(jsonData: IUnitStatus) {
        this.partitionKey = jsonData.partitionKey;
        this.rowKey = jsonData.rowKey;
        this.unitStatus = jsonData.unitStatus;
    }
}
