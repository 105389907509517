import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { reduxStore } from './store/reduxStore';
import { MsalProvider, AuthenticatedTemplate, MsalAuthenticationTemplate, MsalAuthenticationResult } from '@azure/msal-react';
import { InteractionType, EventMessage, EventType, AuthenticationResult, PublicClientApplication, RedirectRequest } from '@azure/msal-browser';
import reportWebVitals from './reportWebVitals';
import AppErrorBoundry from './shell/AppErrorBoundry';
import { appConfig } from './shell/appConfig';
import { initMsalInstance } from './services/auth/msalHelper';
import { telemetryService } from './services/TelemetryService/TelemetryService';
import { commonStyles } from './common/common.styles';
import './index.styles';
import { AppSecurityCheck } from './AppSecurityCheck';
import { initializeIcons } from '@fluentui/react';

initializeIcons();

const appRootElemId: string = 'app';
const rootElem: HTMLElement = document.getElementById(appRootElemId) as HTMLElement;
const root: ReactDOM.Root = ReactDOM.createRoot(rootElem);

/**
 * Error component used with MSAL.
 * @param msalAuthResult MSAL authentication result. 
 * @returns JSX component.
 */
const ErrorComponent: React.FunctionComponent<MsalAuthenticationResult> = (msalAuthResult: MsalAuthenticationResult) => {
    return (
        <div className={`${commonStyles.errorText} ${commonStyles.absoluteCenter}`}>
            An authentication error occurred: {msalAuthResult.error ? msalAuthResult.error.errorCode : 'unknown error'}
        </div>
    );
}

/**
 * Loading component used with MSAL.
 * @returns JSX component.
 */
const LoadingComponent: React.FunctionComponent = () => {
    return (
        <div className={commonStyles.absoluteCenter}>
            Authenticating, please wait...
        </div>
    );
}

/**
 * Load config.
 * @returns True if config loaded, otherwise false.
 */
const loadConfig = async (): Promise<boolean> => {
    try {
        await appConfig.loadConfig();
        return true;
    } catch {
        root.render(<div className={`${commonStyles.errorText} ${commonStyles.absoluteCenter}`}>Failed to load config.</div>);
        return false;
    }
}

/**
 * Initialize telemetry.
 */
const initTelemetry = (): void => {
    telemetryService.initialize(appConfig.current.instrumentation.appInsightsInstrumentationKey);
}

/**
 * Returned from initMsal function.
 */
 interface IMsalInitResult {
    msalInstance: PublicClientApplication;
    loginRedirectRequest: RedirectRequest;
}

/**
 * Initialize MSAL.
 * @returns Init result object.
 */
const initMsal = async (): Promise<IMsalInitResult | undefined> => {
    let msalInstance: PublicClientApplication | undefined = undefined;
    try {
        msalInstance = await initMsalInstance();

        await msalInstance.handleRedirectPromise();

        const accounts = msalInstance.getAllAccounts();
        if (accounts.length > 0) {
            // User is already signed in.
            msalInstance.setActiveAccount(accounts[0]);
        } else {
            // User is not yet signed in.
            msalInstance!.addEventCallback((event: EventMessage) => {
                if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
                    const payload = event.payload as AuthenticationResult;
                    const account = payload.account;
                    msalInstance!.setActiveAccount(account);
                }
            });
        }
    } catch {
        root.render(<div className={`${commonStyles.errorText} ${commonStyles.absoluteCenter}`}>Failed to initialize MSAL.</div>);
        return undefined;
    }

    const loginRedirectRequest: RedirectRequest = {
        scopes: [appConfig.current.msal.graphOpenIdScope, appConfig.current.msal.graphUserReadScope]
    };

    if (msalInstance) {
        return {
            msalInstance,
            loginRedirectRequest
        };
    }

    return undefined;
};

(async () => {
    if (await loadConfig()) {
        initTelemetry();
        const initMsalResult: IMsalInitResult | undefined = await initMsal();
        if (initMsalResult) {    
            root.render(
                // Disabling strict mode check. The Fluent UI controls in @m365-admin have tons of deprecated things
                // like componentWillReceiveProps lifecycle method. Strict mode check will flag all these things and
                // make the console log noisy. Enable only when we want to find issues in our own components.
                // This React.StrictMode could be used individually in our own component code on a case by case basis.
                // See: https://reactjs.org/docs/strict-mode.html
                // <React.StrictMode>
                <Provider store={reduxStore}>
                    <AppErrorBoundry>
                        <MsalProvider instance={initMsalResult.msalInstance}>
                            <MsalAuthenticationTemplate
                                interactionType={InteractionType.Redirect}
                                authenticationRequest={initMsalResult.loginRedirectRequest}
                                errorComponent={ErrorComponent}
                                loadingComponent={LoadingComponent}>
                            </MsalAuthenticationTemplate>
                            <AuthenticatedTemplate>
                                <AppSecurityCheck/>
                            </AuthenticatedTemplate>
                        </MsalProvider>
                    </AppErrorBoundry>
                </Provider>
                // </React.StrictMode>
            );
        }
    }
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
