/**
 * Contains generic factory functions that instantiate (new'ing up) and object or object array from json.
 * This converts raw JSON (such as returned from a service call) to a new object (new operator used) by new'ing
 * up the type and then copy the raw JSON to that new object. The point of this is so the new object is an
 * actual object of the correct type with the proper prototype chain. This allows for the "instanceof"
 * operator to function correctly, as "instanceof" can verify the data type using the object prototype chain.
 * Uses TypeScript Generics, see: https://www.typescriptlang.org/docs/handbook/generics.html 
 */
export class JsonObjectFactory {
    /**
     * Generic factory method to create a new object array of type T from JSON data array.
     * @param jsonDataArray Array of JSON data.
     * @param t Type to create array of.
     */
    public static instantiateFromJsonArray<T>(jsonDataArray: T[] | null | undefined, t: new(jsonData: any) => T): T[] | null {
        if (!jsonDataArray) {
            // If jsonData is empty, null, or undefined, return null.
            return null;
        }

        if (!JsonObjectFactory.isValidJsonString(jsonDataArray)) {
            throw new Error('Invalid JSON data.');
        }

        // Initialize the object array.
        const instantiatedArray: T[] = [];

        for (let i: number = 0; i < jsonDataArray.length; i++) {
            const instantiated = JsonObjectFactory.instantiateFromJson(jsonDataArray[i], t) as T;
            if (instantiated) {
                instantiatedArray.push(instantiated);
            }
        }

        // Return the instantiated object array.
        return instantiatedArray;
    }

    /**
     * Generic factory method to create a new object array of type T from JSON data.
     * @param jsonData Json data.
     * @param t Type to be instantiated.
     */
    public static instantiateFromJson<T>(jsonData: T | null | undefined, t: new (jsonData: any) => T): T | null {
        if (!jsonData) {
            // If jsonData is empty, null, or undefined, return null.
            return null;
        }
    
        if (!JsonObjectFactory.isValidJsonString(jsonData)) {
            throw new Error('Invalid JSON data.');
        }

        // New up the object by passing the jsonData into the constructor of the object.
        const instantiated: T = new t(jsonData);

        // Return the instantiated object.
        return instantiated;
    }

    /**
     * Check if string is valid json.
     * @param str String to check.
     */
    public static isValidJsonString(str: any) {
        // If str is not a string (ex: it is an object or array), return true.
        if (typeof str !== 'string') {
            return true;
        }

        try {
            JSON.parse(str);
        } catch (ex) {
            return false;
        }
        return true;
    }
}
