export interface IRecyclingIdentity {
    programType: string;
    supplierId: string;
    supplierName: string;
    msCompanyCode: string;
    supplierJobId: string;
    supplierJobType: string;
    supplierUnitId: string;
    supplierUnitType: string;
    msPoNumber: string;
    msPoCurrency: string;
    supplierPoNumber: string;
    supplierPoCurrency: string;
    supplierPoAmount?: number;
    supplierInvoiceNumber: string;
    invoiceAmount?: number;
    invoiceAmountUSD?: number;
    logisticsFee?: number;
    logisticsFeeUSD?: number;
    processingCountry: string;
    billingCountry: string;
    supplierBillingEntity: string;
    parentUnitId: string;
}

export abstract class RecyclingIdentity implements IRecyclingIdentity {
    public programType: string;
    public supplierId: string;
    public supplierName: string;
    public msCompanyCode: string;
    public supplierJobId: string;
    public supplierJobType: string;
    public supplierUnitId: string;
    public supplierUnitType: string;
    public msPoNumber: string;
    public msPoCurrency: string;
    public supplierPoNumber: string;
    public supplierPoCurrency: string;
    public supplierPoAmount?: number;
    public supplierInvoiceNumber: string;
    public invoiceAmount?: number;
    public invoiceAmountUSD?: number;
    public logisticsFee?: number;
    public logisticsFeeUSD?: number;
    public processingCountry: string;
    public billingCountry: string;
    public supplierBillingEntity: string;
    public parentUnitId: string;

    constructor(jsonData: IRecyclingIdentity) {
        this.programType = jsonData.programType;
        this.supplierId = jsonData.supplierId;
        this.supplierName = jsonData.supplierName;
        this.msCompanyCode = jsonData.msCompanyCode;
        this.supplierJobId = jsonData.supplierJobId;
        this.supplierJobType = jsonData.supplierJobType;
        this.supplierUnitId = jsonData.supplierUnitId;
        this.supplierUnitType = jsonData.supplierUnitType;
        this.msPoNumber = jsonData.msPoNumber;
        this.msPoCurrency = jsonData.msPoCurrency;
        this.supplierPoNumber = jsonData.supplierPoNumber;
        this.supplierPoCurrency = jsonData.supplierPoCurrency;
        this.supplierPoAmount = jsonData.supplierPoAmount;
        this.supplierInvoiceNumber = jsonData.supplierInvoiceNumber;
        this.invoiceAmount = jsonData.invoiceAmount;
        this.invoiceAmountUSD = jsonData.invoiceAmountUSD;
        this.logisticsFee = jsonData.logisticsFee;
        this.logisticsFeeUSD = jsonData.logisticsFeeUSD;
        this.processingCountry = jsonData.processingCountry;
        this.billingCountry = jsonData.billingCountry;
        this.supplierBillingEntity = jsonData.supplierBillingEntity;
        this.parentUnitId = jsonData.parentUnitId;
    }
}
