import {
    AccountInfo,
    AuthenticationResult,
    Configuration,
    PublicClientApplication,
    SilentRequest
} from '@azure/msal-browser';
import { appConfig } from '../../shell/appConfig';
import { initMsalConfig } from './msalConfig';

let msalInstance: PublicClientApplication;

/**
 * Initialize the MSAL instance.
 * Should only be called after the app config has loaded, because the msalConfig uses app config settings.
 * @returns The new MSAL instance.
 */
export const initMsalInstance = async (): Promise<PublicClientApplication> => {
    const msalConfig: Configuration = initMsalConfig();
    msalInstance = new PublicClientApplication(msalConfig);
    await msalInstance.initialize();
    return msalInstance;
};

/**
 * Get the MSAL instance that was initialized in initMsalInstance.
 * @returns MSAL instance.
 */
export const getMsalInstance = (): PublicClientApplication => {
    if (!msalInstance) {
        throw new Error('MSAL instance has not been initialized.');
    }
    return msalInstance;
};

/**
 * Get the active MSAL account.
 * @returns Active MSAL account.
 */
export const getActiveAccount = (): AccountInfo => {
    const account: AccountInfo | null = msalInstance.getActiveAccount();
    if (!account) {
        throw new Error('No active account! Verify a user has been signed in and setActiveAccount has been called.');
    }
    return account;
};

/**
 * Get users AAD object id.
 * @returns Users AAD object id.
 */
export const getUserAadOid = (): string => {
    return getActiveAccount().localAccountId;
};

/**
 * Get users email.
 * @returns Users email.
 */
export const getUserEmail = (): string => {
    return getActiveAccount().username;
};

/**
 * Get users email alias.
 * @returns Users email alias.
 */
export const getUserAlias = (): string => {
    return getUserEmail().split('@')[0] || ''
};

/**
 * Get user full name.
 * @returns Users full name.
 */
export const getUserFullName = (): string => {
    return getActiveAccount().name || '';
};

/**
 * Get access token for the scope.
 */
export const getAccessTokenForScope = async (scope: string): Promise<string> => {
    const silentRequest: SilentRequest = {
        scopes: [scope]
    }
    
    const account = msalInstance.getActiveAccount();
    if (!account) {
        throw new Error('No active account! Verify a user has been signed in and setActiveAccount has been called.');
    }

    const authenticationResult: AuthenticationResult = await msalInstance.acquireTokenSilent(silentRequest);
    return authenticationResult.accessToken;
};

/**
 * Get access token for Graph API calls.
 */
export const getAccessTokenForGraph = async (): Promise<string> => {
    return getAccessTokenForScope(appConfig.current.msal.graphUserReadScope);
};

/**
 * Get access token for Recycling API calls.
 */
export const getAccessTokenForRecycling = async (): Promise<string> => {
    return getAccessTokenForScope(appConfig.current.msal.recyclingApiScope);
};
