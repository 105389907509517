import { PackagingDetails } from '../../../models/supplier/packagingDetails';
import { SupplierTransportation } from '../../../models/supplier/supplierTransportation';
import { serviceApiClient } from '../../../services/api/serviceApiClient';
import { AppDispatch, reduxStore } from '../../reduxStore';
import * as actionTypes from '../actionTypes';
import { callApi, CallApiState, ICallApiBase } from '../generic.action';

/**
 * Load Transport And Packaging action payload.
 */
export interface IApiLoadTransportAndPackaging extends ICallApiBase {
    transportAndPackaging?: SupplierTransportation | null;
}

/**
 * Load Transport And Packaging.
 * @param programType Program type.
 * @param supplierId Supplier id.
 * @param supplierJobId Supplier job id.
 * @param correlationId Correlation id.
 * @returns Redux dispatch function.
 */
export const loadTransportAndPackaging = (programType: string, supplierId: string, supplierJobId?: string, correlationId?: string): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<SupplierTransportation | null>(
        actionTypes.API_LOAD_TRANSPORT_AND_PACKAGING,
        async () => {
            return await serviceApiClient.transportAndPackaging(programType, supplierId, supplierJobId, correlationId);
        },
        (payload: IApiLoadTransportAndPackaging, data) => {
            if (data && data.packagingDetails) {
                // Set the clientRowKey in each object so it can be bound into the DetailsList.
                data.packagingDetails.forEach((value: PackagingDetails, index: number) => {
                    value.clientRowKey = index.toString()
                });
            }

            payload.transportAndPackaging = data;
        }
    );
};

/**
 * Reset the page action call state for all api calls.
 * This clear all data from the Redux store for these calls, including any error state for failed calls.
 * This makes it so if there was a failed call and an error was shown on the page, if the user navigates
 * to another page and back, the prior error would not be shown.
 */
export const resetApiCallState = () => {
    const apiLoadTransportAndPackaging: IApiLoadTransportAndPackaging = {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        transportAndPackaging: undefined
    };
    reduxStore.dispatch({
        type: actionTypes.API_LOAD_TRANSPORT_AND_PACKAGING,
        payload: apiLoadTransportAndPackaging
    });
};
