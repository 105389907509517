// Public url can be set in .env files like this: PUBLIC_URL=/SPA-next
let publicUrl = process.env['PUBLIC_URL']?.trim() || '';
if (publicUrl.endsWith('/')) {
    // Trim trailing / if present.
    publicUrl = publicUrl.substring(0, publicUrl.length - 1);
}

/**
 * Constants used app wide.
 */
export const appConstants = {
    publicUrl: publicUrl,
    env: process.env['REACT_APP_UI_ENV']?.trim() || '',
    noDataFound: 'No data found.',
    dataLoadFailed: 'Failed to load data.',
    badRequest: 'Bad request.',
    nothingSelected: 'Nothing selected'
};

/**
 * Local storage key names.
 */
export const localStorageKey = {
    useLocalMockData: 'useLocalMockData',
    fiscalYear: 'Fiscal Year'
};

/**
 * Document types.
 */
export enum DocumentType {
    RecyclingCertificate,
    DestructionCertificate,
    DataErasureCertificate,
    PoDocument,
    InvoiceDocument
}

/**
 * Options used with toLocaleString to get 2 digit fraction output. Ex: 123.40 instead of 123.4
 */
export const toLocaleStringTwoDigitFraction: any = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2 
};
