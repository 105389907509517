import { AnyAction, Reducer } from 'redux';
import * as actionTypes from '../../actions/actionTypes';
import { IApiLoadSupplierUnitTypes, IApiUpdateSupplierUnitType, IApiAddSupplierUnitType, IApiDeleteSupplierUnitType } from '../../actions/adminTabActions/adminTabSupplierUnitTypes.action';
import { CallApiState, StateUpdateOption } from '../../actions/generic.action';

export interface IAdminTabSupplierUnitTypesReducerState {
    apiLoadSupplierUnitTypes: IApiLoadSupplierUnitTypes;
    apiUpdateSupplierUnitType: IApiUpdateSupplierUnitType;
    apiAddSupplierUnitType: IApiAddSupplierUnitType;
    apiDeleteSupplierUnitType: IApiDeleteSupplierUnitType;
}

const initialAdminTabSupplierReducerState: IAdminTabSupplierUnitTypesReducerState = {
    apiLoadSupplierUnitTypes: {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        supplierUnitTypes: undefined
    },
    apiUpdateSupplierUnitType: {
        callApiState: CallApiState.Initial,
        errMsg: undefined
    },
    apiAddSupplierUnitType: {
        callApiState: CallApiState.Initial,
        errMsg: undefined
    },
    apiDeleteSupplierUnitType: {
        callApiState: CallApiState.Initial,
        errMsg: undefined
    }
};

export const adminTabSupplierUnitTypesReducer: Reducer<IAdminTabSupplierUnitTypesReducerState, AnyAction> = (
    state: IAdminTabSupplierUnitTypesReducerState = initialAdminTabSupplierReducerState, action: AnyAction
): IAdminTabSupplierUnitTypesReducerState => {
    switch (action.type) {
        case actionTypes.API_LOAD_SUPPLIER_UNIT_TYPES: {
            const payload: IApiLoadSupplierUnitTypes = action.payload as IApiLoadSupplierUnitTypes;
            switch (payload.stateUpdateOption) {
                case StateUpdateOption.ErrorOnly:
                    return {
                        ...state,
                        // Keep the prior payload except for the errMsg which will be set to undefined.
                        apiLoadSupplierUnitTypes: {
                            ...state.apiLoadSupplierUnitTypes,
                            errMsg: undefined
                        }
                    }
                default:
                    return {
                        ...state,
                        apiLoadSupplierUnitTypes: { ...payload }
                    }
            }
        }
        case actionTypes.API_UPDATE_SUPPLIER_UNIT_TYPE: {
            const payload: IApiUpdateSupplierUnitType = action.payload as IApiUpdateSupplierUnitType;
            switch (payload.stateUpdateOption) {
                case StateUpdateOption.ErrorOnly:
                    return {
                        ...state,
                        // Keep the prior payload except for the errMsg which will be set to undefined.
                        apiUpdateSupplierUnitType: {
                            ...state.apiUpdateSupplierUnitType,
                            errMsg: undefined
                        }
                    }
                default:
                    return {
                        ...state,
                        apiUpdateSupplierUnitType: { ...payload }
                    }
            }
        }
        case actionTypes.API_ADD_SUPPLIER_UNIT_TYPE: {
            const payload: IApiAddSupplierUnitType = action.payload as IApiAddSupplierUnitType;
            switch (payload.stateUpdateOption) {
                case StateUpdateOption.ErrorOnly:
                    return {
                        ...state,
                        // Keep the prior payload except for the errMsg which will be set to undefined.
                        apiAddSupplierUnitType: {
                            ...state.apiAddSupplierUnitType,
                            errMsg: undefined
                        }
                    }
                default:
                    return {
                        ...state,
                        apiAddSupplierUnitType: { ...payload }
                    }
            }
        }
        case actionTypes.API_DELETE_SUPPLIER_UNIT_TYPE: {
            const payload: IApiDeleteSupplierUnitType = action.payload as IApiDeleteSupplierUnitType;
            switch (payload.stateUpdateOption) {
                case StateUpdateOption.ErrorOnly:
                    return {
                        ...state,
                        // Keep the prior payload except for the errMsg which will be set to undefined.
                        apiDeleteSupplierUnitType: {
                            ...state.apiDeleteSupplierUnitType,
                            errMsg: undefined
                        }
                    }
                default:
                    return {
                        ...state,
                        apiDeleteSupplierUnitType: { ...payload }
                    }
            }
        }
        default:
    }

    return state;
};
