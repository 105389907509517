import React, { useState, useCallback, useEffect, useMemo } from 'react';
import {
    Stack,
    Text,
    Spinner,
    SpinnerSize,
    DefaultButton,
    IComboBox,
    IComboBoxOption,
    TextField,
    TooltipHost,
    FontIcon,
    VirtualizedComboBox
} from '@fluentui/react';
import { commonStyles, stackTokens, comboBoxStyles } from '../../common/common.styles';
import { appConstants, localStorageKey } from '../../common/appConstants';
import { determineStartAndEndDateFromFiscalRange, getCalendarYears, getFiscalYears, isValidAlphaNumericWithDashOrUnderscoreOrSpace, isValidGuid, StartEndDate } from '../../common/common.func';
import { serviceApiClient } from '../../services/api/serviceApiClient';
import { SupplierJob } from '../../models/supplier/supplierJob';
import { JobUnit } from '../../models/supplier/jobUnit';
import { Supplier } from '../../models/supplier/supplier';
import { Program } from '../../models/domainData/program';
import { SupplierUnitType } from '../../models/domainData/supplierUnitType';
import { Country } from '../../models/domainData/country';
import { month, fiscalQuarter, MonthMap, quarterMonths } from '../../models/appModels/fiscalMonths';
import { controlStyles, comboInputTextFieldStyles, standaloneInputTextFieldStyles, tooltipHostStyles } from './SearchFilter.styles';
import { UnitStatus } from '../../models/domainData/unitStatus';
import { UnitDispositionType } from '../../models/domainData/unitDispositionType';

/*
The "SupplierWebMicroFrontend" project has a variation of this control. If changes are made here, consider if
equivalent changes are needed in that project as well. There are some differences between the projects, such as
localization and SupplierWeb integration in that project, so be careful when copying/integrating changes.
*/

/**
 * Potentially required fields or field group combinations. See usage in determinePotentiallyRequiredInput().
 */
enum PotentiallyRequiredField {
    /**
     * Supplier Name may be required.
     */
    SupplierName,

    /**
     * At least one of these may be required: Asset Tag, Serial Number
     * Usable when SearchFilterOptions.showComboAssetTagAndSerialNumber is true.
     */
    Combo_AssetTag_SerialNumber,

    /**
     * At least one of these may be required: Supplier Job Id, Supplier Invoice Number, Supplier PO Number
     * Usable when SearchFilterOptions.showComboInputGroup is SupplierJobId_SupplierInvoiceNumber_SupplierPoNumber.
     */
    Combo_SupplierJobId_SupplierInvoiceNumber_SupplierPoNumber
}

/**
 * Combo input option. Used to show grouping of fields, of which one may be required.
 * This is expressed as an enum as these are mutually exclusive. One, not both, must be specified in SearchFilterOptions.showComboInputGroup.
 */
export enum ComboInputOption {
    None,
    SupplierJobId_SupplierInvoiceNumber_SupplierPoNumber // Includes the and/or inputs for: Supplier Job Id, Supplier Invoice Number, Supplier PO Number
}

/**
 * Search filter options.
 * Flags indicate what input fields or combinations of fields to show.
 * Certain fields are required automatically if the field is enabled, or are pre-populated with the first item
 * automatically selected. Other fields may be optionally required (see determinePotentiallyRequiredInput()). Other fields
 * have required overrides such as fiscalRangeFieldsRequired.
 */
export interface ISearchFilterOptions {
    // Displays fiscal year, quarter, and month pickers.
    // Changes in the fiscal pickers do cause other controls to load.
    showFiscalRange: boolean; // Includes inputs for: Fiscal Year, Fiscal Quarter, Fiscal Month
    fiscalRangeFieldsRequired: boolean; // Indicates if the FY selectors (year, quarter, month) are required or not.
    
    // Displays a calendar picker. Should use only this or fiscal, not both.
    // Changing the calendar year or month does not cause any other controls to load.
    showCalendarYearMonth: boolean;

    showProgram: boolean;
    showSupplierName: boolean;
    showComboInputGroup: ComboInputOption;
    showSupplierJobId: boolean; // Show Supplier Job Id as a standalone field. Also available as part of a ComboInputOption.
    showSupplierPoNumber: boolean; // Show Supplier PO Number as a standalone field. Also available as part of a ComboInputOption.
    showSupplierInvoiceNumber: boolean; // Show Supplier Invoice Number as a standalone field. Also available as part of a ComboInputOption.
    showCorrelationId: boolean;
    showMsInvoiceNumber: boolean;
    showSupplierUnitType: boolean;
    showJobUnit: boolean;
    showCountry: boolean;
    showComboAssetTagAndSerialNumber: boolean;
    showUnitDispositionType: boolean;
    showUnitStatus: boolean;
    instructionText?: string;
}

export interface ISearchCriteria {
    startDate: Date;
    endDate: Date;
    programType: string | undefined;
    supplierId: string | undefined;
    supplierJobId: string | undefined;
    correlationId: string | undefined;
    supplierInvoiceNumber: string | undefined;
    supplierPoNumber: string | undefined;
    msInvoiceNumber: string | undefined;
    supplierUnitType: string | undefined;
    supplierUnitId: string | undefined;
    countryCode: string | undefined;
    assetTag: string | undefined;
    serialNumber: string | undefined;
    unitDispositionType: string | undefined;
    unitStatus: string | undefined;
}

export interface ISearchFilterProps {
    programs: Program[]; // Exclusive to this version of this control, not in SupplierWeb version.
    searchFilterOptions: ISearchFilterOptions;
    onSearchClicked: (searchCriteria: ISearchCriteria) => void;
    onSearchFilterLoadError: (err: any) => void;
    disabled?: boolean;
}

enum ControlDataLoadStage {
    Initial,
    Loading,
    Loaded,
    Failed
}

const supplierNameTooltip: string = 'Values populated here are driven from the selected program.';
const supplierJobIdTooltip: string = 'Values populated here are driven from selections you make in the fiscal date selectors and the selected supplier. You may also manually type any job id here (must be alphanumeric, dash and underscore allowed).';
const correlationIdTooltip: string = 'You must supply a valid GUID.';
const supplierInvoiceNumberTooltip: string = 'Enter a valid supplier invoice number.';
const supplierPoNumberTooltip: string = 'Enter a valid supplier PO number.';
const msInvoiceNumberTooltip: string = 'Enter a valid MS invoice number.';
const drivenBySelectedProgramTypeTooltip: string = 'Values populated here are driven from the selected program.';
const jobUnitTooltip: string = 'Values populated here are driven from the selected supplier and supplier job id.';

/*
This search filter control is similar to but not completely the same as the one in the SupplierWeb version.
*/

export const SearchFilter: React.FunctionComponent<ISearchFilterProps> = (props: ISearchFilterProps): JSX.Element => {
    const [controlDataLoadStage, setControlDataLoadStage] = useState<ControlDataLoadStage>(ControlDataLoadStage.Initial);
    const [isSupplierJobsLoading, setIsSupplierJobsLoading] = useState<boolean>(false);
    const [isJobUnitsLoading, setIsJobUnitsLoading] = useState<boolean>(false);
    const [isSuppliersLoading, setIsSuppliersLoading] = useState<boolean>(false);
    const [isSupplierUnitTypesLoading, setIsSupplierUnitTypesLoading] = useState<boolean>(false);
    const [isUnitDispositionTypesLoading, setIsUnitDispositionTypesLoading] = useState<boolean>(false);
    const [isUnitStatusesLoading, setIsUnitStatusesLoading] = useState<boolean>(false);

    const nothingSelectedOption: IComboBoxOption = useMemo<IComboBoxOption>(() => {
        return {
            key: 0,
            text: appConstants.nothingSelected
        } as IComboBoxOption
    }, []);

    const [selectedProgramType, setSelectedProgramType] = useState<string | number | undefined>(0);
    const [selectedSupplier, setSelectedSupplier] = useState<string | number | undefined>(0);
    const [selectedSupplierJobId, setSelectedSupplierJobId] = useState<string | number | undefined>(0);
    const [selectedSupplierUnitType, setSelectedSupplierUnitType] = useState<string | number | undefined>(0);
    const [selectedSupplierUnitId, setSelectedSupplierUnitId] = useState<string | number | undefined>(0);
    const [selectedCountryCode, setSelectedCountryCode] = useState<string | number | undefined>(0);
    const [selectedUnitDispositionType, setSelectedUnitDispositionType] = useState<string | number | undefined>(0);
    const [selectedUnitStatus, setSelectedUnitStatus] = useState<string[]>([]);
    const [selectedFiscalYear, setSelectedFiscalYear] = useState<number | undefined>(0);
    const [selectedFiscalQuarter, setSelectedFiscalQuarter] = useState<string | number | undefined>(0);
    const [selectedFiscalMonth, setSelectedFiscalMonth] = useState<number | undefined>(0);
    const [selectedCalendarYear, setSelectedCalendarYear] = useState<number | undefined>(0);
    const [selectedCalendarMonth, setSelectedCalendarMonth] = useState<number | undefined>(0);

    const [programsOptions, setProgramsOptions] = useState<IComboBoxOption[]>([/* Nothing selected will not be an option here. */]);
    const [suppliersOptions, setSuppliersOptions] = useState<IComboBoxOption[]>([nothingSelectedOption]);
    const [supplierJobOptions, setSupplierJobOptions] = useState<IComboBoxOption[]>([nothingSelectedOption]);
    const [correlationId, setCorrelationId] = useState<string>('');
    const [supplierInvoiceNumber, setSupplierInvoiceNumber] = useState<string>('');
    const [supplierPoNumber, setSupplierPoNumber] = useState<string>('');
    const [msInvoiceNumber, setMsInvoiceNumber] = useState<string>('');
    const [supplierUnitTypesOptions, setSupplierUnitTypesOptions] = useState<IComboBoxOption[]>([nothingSelectedOption]);
    const [jobUnitsOptions, setJobUnitsOptions] = useState<IComboBoxOption[]>([nothingSelectedOption]);
    const [countriesOptions, setCountriesOptions] = useState<IComboBoxOption[]>([nothingSelectedOption]);
    const [unitDispositionTypeOptions, setUnitDispositionTypeOptions] = useState<IComboBoxOption[]>([nothingSelectedOption]);
    const [unitStatusOptions, setUnitStatusOptions] = useState<IComboBoxOption[]>([nothingSelectedOption]);
    const [fiscalYearOptions, setFiscalYearOptions] = useState<IComboBoxOption[]>([nothingSelectedOption]);
    const [fiscalQuarterOptions, setFiscalQuarterOptions] = useState<IComboBoxOption[]>([nothingSelectedOption]);
    const [fiscalMonthOptions, setFiscalMonthOptions] = useState<IComboBoxOption[]>([nothingSelectedOption]);
    const [calendarYearOptions, setCalendarYearOptions] = useState<IComboBoxOption[]>([nothingSelectedOption]);
    const [calendarMonthOptions, setCalendarMonthOptions] = useState<IComboBoxOption[]>([nothingSelectedOption]);
    const [assetTag, setAssetTag] = useState<string>('');
    const [serialNumber, setSerialNumber] = useState<string>('');

    const [supplierNameErrorMessage, setSupplierNameErrorMessage] = useState<string>('');
    const [comboAssetTagSerialNumberErrorMessage, setComboAssetTagSerialNumberErrorMessage] = useState<string>('');
    const [comboInputsErrorMessage, setComboInputsErrorMessage] = useState<string>('');
    const [correlationIdErrorMessage, setCorrectionIdErrorMessage] = useState<string>('');
    const [fiscalQuarterErrorMessage, setFiscalQuarterErrorMessage] = useState<string>('');
    const [fiscalMonthErrorMessage, setFiscalMonthErrorMessage] = useState<string>('');

    /**
     * Determines if a field has required input or not based on certain conditions for potentially required fields (PotentiallyRequiredField).
     * 
     * This function works with passed in params for all checked fields rather than using state values (from useState)
     * for those fields as they are not set until the render pass completes.
     * Prefixing params with 'input' to differentiate from state variables.
     * @param potentiallyRequiredField Potentially required field or combo input field group.
     * @param inputSupplierId Supplier id.
     * @param inputSupplierJobId Supplier job id.
     * @param inputSupplierInvoiceNumber Supplier Invoice Number.
     * @param inputSupplierPoNumber Supplier PO Number.
     * @param inputAssetTag Asset Tag.
     * @param inputSerialNumber Serial Number.
     * @returns True if required input.
     */
     const determinePotentiallyRequiredInput = useCallback((potentiallyRequiredField: PotentiallyRequiredField, inputSupplierId: string | number | undefined, inputSupplierJobId: string | number | undefined, inputSupplierInvoiceNumber: string, inputSupplierPoNumber: string, inputAssetTag: string, inputSerialNumber: string): boolean => {
        if (typeof inputSupplierId === 'string') {
            inputSupplierId = inputSupplierId.trim();
        }
        if (typeof inputSupplierJobId === 'string') {
            inputSupplierJobId = inputSupplierJobId.trim();
        }
        inputSupplierInvoiceNumber = inputSupplierInvoiceNumber.trim();
        inputSupplierPoNumber = inputSupplierPoNumber.trim();
        inputAssetTag = inputAssetTag.trim();
        inputSerialNumber = inputSerialNumber.trim();

        let result: boolean = true; // Default to true, may be toggled off below.

        switch (potentiallyRequiredField) {
            case PotentiallyRequiredField.Combo_AssetTag_SerialNumber:
                if (!props.searchFilterOptions.showComboAssetTagAndSerialNumber) {
                    result = false;
                } else {
                    // If Serial Number is supplied, then Asset Tag is not required.
                    if (inputSerialNumber) {
                        result = false;
                    } else if (props.searchFilterOptions.showComboInputGroup === ComboInputOption.SupplierJobId_SupplierInvoiceNumber_SupplierPoNumber) {
                        // If showing this combo input group and one of them is supplied, then Asset Tag is not required.
                        if (inputSupplierJobId || inputSupplierInvoiceNumber || inputSupplierPoNumber) {
                            result = false;
                        }
                    }

                    // If Asset Tag is supplied, then Serial Number is not required.
                    if (inputAssetTag) {
                        result = false;
                    } else if (props.searchFilterOptions.showComboInputGroup === ComboInputOption.SupplierJobId_SupplierInvoiceNumber_SupplierPoNumber) {
                        // If showing this combo input group and one of them is supplied, then Serial Number is not required.
                        if (inputSupplierJobId || inputSupplierInvoiceNumber || inputSupplierPoNumber) {
                            result = false;
                        }
                    }
                }
                break;
            case PotentiallyRequiredField.Combo_SupplierJobId_SupplierInvoiceNumber_SupplierPoNumber:
                if (props.searchFilterOptions.showComboInputGroup !== ComboInputOption.SupplierJobId_SupplierInvoiceNumber_SupplierPoNumber) {
                    result = false;
                } else {
                    // If Asset Tag or Serial Number were supplied, then Supplier Name is not required.
                    if (props.searchFilterOptions.showComboAssetTagAndSerialNumber && (inputAssetTag || inputSerialNumber)) {
                        result = false;
                    }
                }
                break;
            case PotentiallyRequiredField.SupplierName:
                if (!props.searchFilterOptions.showSupplierName) {
                    result = false;
                } else {
                    // If Asset Tag or Serial Number were supplied, then Supplier Name is not required.
                    if (props.searchFilterOptions.showComboAssetTagAndSerialNumber && (inputAssetTag || inputSerialNumber)) {
                        result = false;
                    }
                }
                break;
            default:
                break;
        }

        return result;
    }, [props.searchFilterOptions.showComboAssetTagAndSerialNumber, props.searchFilterOptions.showComboInputGroup, props.searchFilterOptions.showSupplierName]);

    /**
     * Set required input error messages.
     * 
     * This function works with passed in params for all checked fields rather than using state values (from useState)
     * for those fields as they are not set until the render pass completes.
     * Prefixing params with 'input' to differentiate from state variables.
     * @param inputSupplierId Supplier id.
     * @param inputSupplierJobId Supplier job id.
     * @param inputCorrelationId Correlation id.
     * @param inputSupplierInvoiceNumber Supplier Invoice Number.
     * @param inputSupplierPoNumber Supplier PO Number.
     * @param inputAssetTag Asset Tag.
     * @param inputSerialNumber Serial Number.
     * @param inputFiscalQuarter Fiscal quarter.
     * @param inputFiscalMonth Fiscal month.
     */
    const setRequiredInputErrorMessages = useCallback((inputSupplierId: string | number | undefined, inputSupplierJobId: string | number | undefined, inputCorrelationId: string, inputSupplierInvoiceNumber: string, inputSupplierPoNumber: string, inputAssetTag: string, inputSerialNumber: string, inputFiscalQuarter: string | number | undefined, inputFiscalMonth: number | undefined) => {
        if (typeof inputSupplierId === 'string') {
            inputSupplierId = inputSupplierId.trim();
        }
        if (typeof inputSupplierJobId === 'string') {
            inputSupplierJobId = inputSupplierJobId.trim();
        }
        inputCorrelationId = inputCorrelationId.trim();
        inputSupplierInvoiceNumber = inputSupplierInvoiceNumber.trim();
        inputSupplierPoNumber = inputSupplierPoNumber.trim();
        inputAssetTag = inputAssetTag.trim();
        inputSerialNumber = inputSerialNumber.trim();

        const inputRequiredMsg: string = '* Input required';
        const invalidSupplierJobIdMsg: string = '* Invalid Job Id';
        const atLeastOneRequiredMsg: string = '* At least one of these fields must be supplied';
        const jobIdRequiredWithCorrelationIdMsg: string = '* Job Id also required';
        const correlationIdInvalidMsg: string = '* Invalid Correlation Id';

        if (determinePotentiallyRequiredInput(PotentiallyRequiredField.SupplierName, inputSupplierId, inputSupplierJobId, inputSupplierInvoiceNumber, inputSupplierPoNumber, inputAssetTag, inputSerialNumber) &&
            props.searchFilterOptions.showSupplierName && inputSupplierId === nothingSelectedOption.key) {
            // If Supplier Name is required and shown and no supplier is selected.
            setSupplierNameErrorMessage(inputRequiredMsg);
        } else {
            // Clear out the error message.
            setSupplierNameErrorMessage('');
        }

        if (determinePotentiallyRequiredInput(PotentiallyRequiredField.Combo_AssetTag_SerialNumber, inputSupplierId, inputSupplierJobId, inputSupplierInvoiceNumber, inputSupplierPoNumber, inputAssetTag, inputSerialNumber) &&
            props.searchFilterOptions.showComboAssetTagAndSerialNumber && !(inputAssetTag || inputSerialNumber)) {
            // If Asset Tag or Serial Number is required and shown and no Asset Tag or Serial Number is input.
            setComboAssetTagSerialNumberErrorMessage(atLeastOneRequiredMsg);
        } else {
            // Clear out the error message.
            setComboAssetTagSerialNumberErrorMessage('');
        }

        if (determinePotentiallyRequiredInput(PotentiallyRequiredField.Combo_SupplierJobId_SupplierInvoiceNumber_SupplierPoNumber, inputSupplierId, inputSupplierJobId, inputSupplierInvoiceNumber, inputSupplierPoNumber, inputAssetTag, inputSerialNumber)) {
            if ((inputSupplierJobId === nothingSelectedOption.key && inputSupplierInvoiceNumber.length === 0 && inputSupplierPoNumber.length === 0)) {
                // If Supplier Job Id is not selected and Invoice Number is blank and PO number is blank.
                setComboInputsErrorMessage(atLeastOneRequiredMsg);
            } else if (typeof inputSupplierJobId === 'string' && !isValidAlphaNumericWithDashOrUnderscoreOrSpace(inputSupplierJobId)) {
                // If Supplier Job Id is supplied and it is not valid.
                setComboInputsErrorMessage(invalidSupplierJobIdMsg);
            } else {
                // Clear out the error message.
                setComboInputsErrorMessage('');
            }
        } else {
            // Clear out the error message.
            setComboInputsErrorMessage('');
        }

        // If showing the correlation id and the job id (standalone or as part of combo input).
        // Then require that job id must be supplied if correlation id is supplied.
        if (props.searchFilterOptions.showCorrelationId &&
            (props.searchFilterOptions.showSupplierJobId || props.searchFilterOptions.showComboInputGroup === ComboInputOption.SupplierJobId_SupplierInvoiceNumber_SupplierPoNumber)) {
            if (inputCorrelationId.length > 0) {
                if (inputSupplierJobId === nothingSelectedOption.key || (typeof inputSupplierJobId === 'string' && inputSupplierJobId.trim().length === 0)) {
                    setCorrectionIdErrorMessage(jobIdRequiredWithCorrelationIdMsg);
                } else if (!isValidGuid(inputCorrelationId)) {
                    setCorrectionIdErrorMessage(correlationIdInvalidMsg);
                } else {
                    setCorrectionIdErrorMessage('');
                }
            } else {
                setCorrectionIdErrorMessage('');
            }
        }

        // If fiscal range fields are required, then update error messages for those if needed.
        if (props.searchFilterOptions.showFiscalRange && props.searchFilterOptions.fiscalRangeFieldsRequired) {
            if (!inputFiscalQuarter) {
                setFiscalQuarterErrorMessage(inputRequiredMsg);
            } else {
                setFiscalQuarterErrorMessage('');
            }

            if (!inputFiscalMonth) {
                setFiscalMonthErrorMessage(inputRequiredMsg);
            } else {
                setFiscalMonthErrorMessage('');
            }
        }
    }, [determinePotentiallyRequiredInput, nothingSelectedOption.key, props.searchFilterOptions.fiscalRangeFieldsRequired, props.searchFilterOptions.showComboAssetTagAndSerialNumber, props.searchFilterOptions.showComboInputGroup, props.searchFilterOptions.showCorrelationId, props.searchFilterOptions.showFiscalRange, props.searchFilterOptions.showSupplierJobId, props.searchFilterOptions.showSupplierName]);

    /**
     * Check if any required inputs are invalid.
     * @returns True if any inputs are invalid.
     */
    const checkRequiredInputsInvalid = (): boolean => {
        // If any error messages are not empty, then there is an error.
        if (supplierNameErrorMessage || comboAssetTagSerialNumberErrorMessage || comboInputsErrorMessage || correlationIdErrorMessage || fiscalQuarterErrorMessage || fiscalMonthErrorMessage) {
            return true;
        }

        return false;
    }

    /**
     * Load supplier jobs based on inputs.
     * @param fy Fiscal year.
     * @param fq Fiscal quarter.
     * @param fm Fiscal month.
     * @param supplierId Supplier id.
     * @param programType Program type.
     */
    const loadSupplierJobs = useCallback(async (fy: number | undefined, fq: string | number | undefined, fm: number | undefined, supplierId: string | number | undefined, programType: string | number | undefined) => {
        if (props.searchFilterOptions.showSupplierJobId !== true &&
            props.searchFilterOptions.showComboInputGroup !== ComboInputOption.SupplierJobId_SupplierInvoiceNumber_SupplierPoNumber) {
            return;
        }

        try {
            setIsSupplierJobsLoading(true);
            setSelectedSupplierJobId(nothingSelectedOption.key);

            let supplierJobs: SupplierJob[] = [];

            if (typeof supplierId === 'string' && typeof programType === 'string') {
                // Load supplier jobs based on selected supplier and date range. This will populate the Supplier Job Id combobox.
                const { startDate, endDate } = determineStartAndEndDateFromFiscalRange(fy!, fq, fm);
                supplierJobs = await serviceApiClient.supplierJobs(startDate, endDate, supplierId, programType) || [];
            }

            setSupplierJobOptions([
                nothingSelectedOption,
                ...supplierJobs
                    // Not sorting supplier jobs.
                    .map<IComboBoxOption>((supplierJob: SupplierJob) => {
                    return {
                        key: supplierJob.supplierJobId,
                        text: `${supplierJob.supplierJobId} (${supplierJob.supplierJobType})`
                    };
                })
            ]);
            const supplierJobId: string | number = nothingSelectedOption.key;
            setSelectedSupplierJobId(supplierJobId);
            setRequiredInputErrorMessages(supplierId, supplierJobId, correlationId, supplierInvoiceNumber, supplierPoNumber, assetTag, serialNumber, fq, fm);
        } catch (err: any) {
            setControlDataLoadStage(ControlDataLoadStage.Failed);
            props.onSearchFilterLoadError(err);
        } finally {
            setIsSupplierJobsLoading(false);
        }
    }, [assetTag, correlationId, nothingSelectedOption, props, serialNumber, setRequiredInputErrorMessages, supplierInvoiceNumber, supplierPoNumber]);

    /**
     * Load job units.
     * @param supplierId Supplier id.
     * @param supplierJobId Supplier job id.
     */
    const loadJobUnits = useCallback(async (supplierId: string | number | undefined, supplierJobId: string | number | undefined) => {
        if (!props.searchFilterOptions.showJobUnit) {
            return;
        }

        try {
            setIsJobUnitsLoading(true);
            setSelectedSupplierUnitId(nothingSelectedOption.key);

            let jobUnits: JobUnit[] = [];

            if (typeof supplierId === 'string' && typeof supplierJobId === 'string') {
                // Load job units based on selected supplier and job id. This will populate the Job Units combobox.
                jobUnits = await serviceApiClient.jobUnits(supplierId, supplierJobId) || [];
            }

            setJobUnitsOptions([
                nothingSelectedOption,
                ...jobUnits
                    // Not sorting job units.
                    .map<IComboBoxOption>((jobUnit: JobUnit) => {
                    return {
                        key: jobUnit.supplierUnitId,
                        text: `${jobUnit.supplierUnitId} (${jobUnit.supplierUnitType})`
                    };
                })
            ]);
            setSelectedSupplierUnitId(nothingSelectedOption.key);
        } catch (err: any) {
            setControlDataLoadStage(ControlDataLoadStage.Failed);
            props.onSearchFilterLoadError(err);
        } finally {
            setIsJobUnitsLoading(false);
        }
    }, [props, nothingSelectedOption]);

    /**
     * Handle supplier change.
     */
    const handleSupplierChange = useCallback((supplierId: string | number) => {
        setSelectedSupplier(supplierId);

        // Supplier jobs is driven from selected supplier, program type, and selected date range driven from fiscal date selectors.
        // Note: Cannot just pass selectedSupplier as react state has not updated it yet.
        loadSupplierJobs(selectedFiscalYear, selectedFiscalQuarter, selectedFiscalMonth, supplierId, selectedProgramType);

        // Job units is driven from selected supplier and job id.
        loadJobUnits(supplierId, selectedSupplierJobId);

        setRequiredInputErrorMessages(supplierId, selectedSupplierJobId, correlationId, supplierInvoiceNumber, supplierPoNumber, assetTag, serialNumber, selectedFiscalQuarter, selectedFiscalMonth);
    }, [assetTag, correlationId, loadJobUnits, loadSupplierJobs, selectedFiscalMonth, selectedFiscalQuarter, selectedFiscalYear, selectedProgramType, selectedSupplierJobId, serialNumber, setRequiredInputErrorMessages, supplierInvoiceNumber, supplierPoNumber]);

    /**
     * Load suppliers based on program type selected.
     * @param programType Program type.
     */
    const loadSuppliers = useCallback(async (programType: string | number | undefined) => {
        if (!props.searchFilterOptions.showSupplierName) {
            return;
        }

        try {
            setIsSuppliersLoading(true);
            setSelectedSupplier(nothingSelectedOption.key);

            if (typeof programType !== 'string') {
                return;
            }

            // Load supplier jobs based on selected program type. This will populate the Supplier Name combobox.
            const suppliers: Supplier[] = await serviceApiClient.suppliers(programType) || [];

            setSuppliersOptions([
                nothingSelectedOption,
                ...suppliers
                    .sort((a, b) => a.supplierName > b.supplierName ? 1 : -1)
                    .map<IComboBoxOption>((supplier: Supplier) => {
                    return {
                        key: supplier.supplierId,
                        text: supplier.supplierName
                    };
                })
            ]);

            handleSupplierChange(nothingSelectedOption.key);
        } catch (err: any) {
            setControlDataLoadStage(ControlDataLoadStage.Failed);
            props.onSearchFilterLoadError(err);
        } finally {
            setIsSuppliersLoading(false);
        }
    }, [props, nothingSelectedOption, handleSupplierChange]);

    /**
     * Handle supplier unit type change.
     */
    const handleSupplierUnitTypeChange = useCallback((supplierUnitType: string | number) => {
        setSelectedSupplierUnitType(supplierUnitType);
    }, []);

    /**
     * Load supplier unit types based on program type selected.
     * @param programType Program type.
     */
    const loadSupplierUnitTypes = useCallback(async (programType: string | number | undefined) => {
        if (!props.searchFilterOptions.showSupplierUnitType) {
            return;
        }

        try {
            setIsSupplierUnitTypesLoading(true);
            setSelectedSupplierUnitType(nothingSelectedOption.key);

            if (typeof programType !== 'string') {
                return;
            }

            // Load Unit Types based on selected program type.
            const supplierUnitTypes: SupplierUnitType[] = await serviceApiClient.supplierUnitTypes(programType) || [];

            setSupplierUnitTypesOptions([
                nothingSelectedOption,
                ...supplierUnitTypes
                    .sort((a, b) => a.supplierUnitType > b.supplierUnitType ? 1 : -1)
                    .map<IComboBoxOption>((supplierUnitType: SupplierUnitType) => {
                    return {
                        key: supplierUnitType.supplierUnitType,
                        text: supplierUnitType.supplierUnitType
                    };
                })
            ]);

            handleSupplierUnitTypeChange(nothingSelectedOption.key);
        } catch (err: any) {
            setControlDataLoadStage(ControlDataLoadStage.Failed);
            props.onSearchFilterLoadError(err);
        } finally {
            setIsSupplierUnitTypesLoading(false);
        }
    }, [props, nothingSelectedOption, handleSupplierUnitTypeChange]);

    /**
     * Load disposition types based on program type selected.
     * @param programType Program type.
     */
     const loadUnitDispositionTypes = useCallback(async (programType: string | number | undefined) => {
        if (!props.searchFilterOptions.showUnitDispositionType) {
            return;
        }

        try {
            setIsUnitDispositionTypesLoading(true);
            setSelectedUnitDispositionType(nothingSelectedOption.key);

            if (typeof programType !== 'string') {
                return;
            }

            // Load Unit Disposition Types on selected program type.
            const unitDispositionTypes: UnitDispositionType[] = await serviceApiClient.unitDispositionTypes(programType) || [];

            setUnitDispositionTypeOptions([
                nothingSelectedOption,
                ...unitDispositionTypes
                    .sort((a, b) => a.dispositionType > b.dispositionType ? 1 : -1)
                    .map<IComboBoxOption>((unitDispositionType: UnitDispositionType) => {
                    return {
                        key: unitDispositionType.dispositionType,
                        text: unitDispositionType.dispositionType
                    };
                })
            ]);
        } catch (err: any) {
            setControlDataLoadStage(ControlDataLoadStage.Failed);
            props.onSearchFilterLoadError(err);
        } finally {
            setIsUnitDispositionTypesLoading(false);
        }
    }, [props, nothingSelectedOption]);

    /**
     * Load unit statuses based on program type selected.
     * @param programType Program type.
     */
     const loadUnitStatuses = useCallback(async (programType: string | number | undefined) => {
        if (!props.searchFilterOptions.showUnitStatus) {
            return;
        }

        try {
            setIsUnitStatusesLoading(true);
            setSelectedUnitStatus([]);

            if (typeof programType !== 'string') {
                return;
            }

            // Load Unit Statuses on selected program type.
            const unitStatuses: UnitStatus[] = await serviceApiClient.unitStatuses(programType) || [];

            setUnitStatusOptions([
                ...unitStatuses
                    .sort((a, b) => a.unitStatus > b.unitStatus ? 1 : -1)
                    .map<IComboBoxOption>((unitStatus: UnitStatus) => {
                    return {
                        key: unitStatus.unitStatus,
                        text: unitStatus.unitStatus
                    };
                })
            ]);
        } catch (err: any) {
            setControlDataLoadStage(ControlDataLoadStage.Failed);
            props.onSearchFilterLoadError(err);
        } finally {
            setIsUnitStatusesLoading(false);
        }
    }, [props]);

    /**
     * Handle program change.
     */
    const handleProgramChange = useCallback((programType: string | number) => {
        setSelectedProgramType(programType);

        if (props.searchFilterOptions.showSupplierName) {
            // If Suppliers dropdown is shown (which is the case in the Recycling UI but not SupplierWeb version)
            // then changing Program Type will cause Suppliers to load.
            loadSuppliers(programType);
        } else if (!props.searchFilterOptions.showSupplierName &&
                  (props.searchFilterOptions.showSupplierJobId ||
                   props.searchFilterOptions.showComboInputGroup === ComboInputOption.SupplierJobId_SupplierInvoiceNumber_SupplierPoNumber)) {
            // If Suppliers dropdown is not shown (which is the case in the SupplierWeb version but not the Recycling UI)
            // and showing the Supplier Jobs (individually or as part of a combo input group), then changing the Program Type
            // will cause the Supplier Jobs to load.
            // In the Recycling UI version of this control, changing the Program Type causes the Suppliers to
            // load, and then changing a Supplier causes the Supplier Jobs to load.
            loadSupplierJobs(selectedFiscalYear, selectedFiscalQuarter, selectedFiscalMonth, selectedSupplier, programType);

            // Job units is driven from selected Supplier and Supplir Job.
            // Do not pass selectedSupplierJobId below (2nd param) as the above call to loadSupplierJobs will load the supplier jobs.
            loadJobUnits(selectedSupplier, nothingSelectedOption.key);
        }

        // Unit Types is driven from selected program type.
        loadSupplierUnitTypes(programType);

        // Disposition Types is driven from selected program type.
        loadUnitDispositionTypes(programType);

        // Unit Status is driven from selected program type.
        loadUnitStatuses(programType);

        setRequiredInputErrorMessages(selectedSupplier, selectedSupplierJobId, correlationId, supplierInvoiceNumber, supplierPoNumber, assetTag, serialNumber, selectedFiscalQuarter, selectedFiscalMonth);
    }, [assetTag, correlationId, loadJobUnits, loadSupplierJobs, loadSupplierUnitTypes, loadSuppliers, loadUnitDispositionTypes, loadUnitStatuses, nothingSelectedOption.key, props.searchFilterOptions.showComboInputGroup, props.searchFilterOptions.showSupplierJobId, props.searchFilterOptions.showSupplierName, selectedFiscalMonth, selectedFiscalQuarter, selectedFiscalYear, selectedSupplier, selectedSupplierJobId, serialNumber, setRequiredInputErrorMessages, supplierInvoiceNumber, supplierPoNumber]);

    /**
     * Handle fiscal year change.
     */
    const handleFiscalYearChange = useCallback((fy: number, programType?: string | number) => {
        setSelectedFiscalYear(fy);

        // If the programType was passed in, use that, otherwise use the selectedProgramType state value.
        const pt: string | number | undefined = programType !== undefined ? programType : selectedProgramType;

        // Supplier jobs is driven from selected supplier, program type, and selected date range driven from fiscal date selectors.
        // Note: Cannot just pass selectedFiscalYear as react state has not updated it yet.
        loadSupplierJobs(fy, selectedFiscalQuarter, selectedFiscalMonth, selectedSupplier, pt);
    }, [loadSupplierJobs, selectedFiscalQuarter, selectedFiscalMonth, selectedSupplier, selectedProgramType])

    /**
     * Load control data.
     */
    const loadControlData = useCallback(async () => {
        setControlDataLoadStage(ControlDataLoadStage.Loading);

        try {
            // Programs come in from props (they are loaded during app start based on user SG membership.)
            const programs: Program[] = props.programs || [];
            const ptOps: IComboBoxOption[] = programs
                .sort((a, b) => a.programName > b.programName ? 1 : -1)
                .map<IComboBoxOption>((program: Program) => {
                return {
                    key: program.programType,
                    text: program.programName
                };
            });
            setProgramsOptions(ptOps);
            const ptKey: string | number = ptOps.length === 0 ? nothingSelectedOption.key : ptOps[0].key;
            handleProgramChange(ptKey);

            // Load countries.
            const countries: Country[] = await serviceApiClient.countries() || [];
            setCountriesOptions([
                nothingSelectedOption,
                ...countries
                    .sort((a, b) => a.countryName > b.countryName ? 1 : -1)
                    .map<IComboBoxOption>((country: Country) => {
                    return {
                        key: country.countryCode,
                        text: country.countryName
                    }
                })
            ]);

            // Populate fiscal years.
            const fiscalYears: number[] = getFiscalYears();
            const fyOptions: IComboBoxOption[] = fiscalYears.map<IComboBoxOption>((fiscalYear: number) => {
                return {
                    key: fiscalYear,
                    text: `FY${fiscalYear}`
                };
            })
            setFiscalYearOptions(fyOptions);

            // Load the fiscal year from the local storage cache, if possible. Otherwise, autoselect the first option.
            const cachedFiscalYearSelection: string | null = localStorage.getItem(`${localStorageKey.fiscalYear}`);

            // Need to pass in the selected program type (set above) and not rely on selectedProgramType state as it isn't set until the render pass.
            handleFiscalYearChange(cachedFiscalYearSelection ? Number(cachedFiscalYearSelection) : fyOptions[0].key as number, ptKey);

            // Populate fiscal quarters.
            setFiscalQuarterOptions([
                nothingSelectedOption,
                ...[fiscalQuarter.q1, fiscalQuarter.q2, fiscalQuarter.q3, fiscalQuarter.q4].map<IComboBoxOption>((fq: string) => {
                    return {
                        key: fq,
                        text: fq
                    };
                })
            ]);
    
            // Populate fiscal months.
            // Note that these values for fiscal months will change as the quarter gets selected.
            // Initially this is set to all months (as if no quarter is selected).
            setFiscalMonthOptions([
                nothingSelectedOption,
                ...Object.values(month).map<IComboBoxOption>((m: string, index: number) => {
                    return {
                        key: index + 1,
                        text: m
                    };
                })
            ]);

            // Populate calendar years.
            const calendarYears: number[] = getCalendarYears();
            const cyOptions: IComboBoxOption[] = calendarYears.map<IComboBoxOption>((calendarYear: number) => {
                return {
                    key: calendarYear,
                    text: `${calendarYear}`
                };
            })
            setCalendarYearOptions(cyOptions);
            setSelectedCalendarYear(calendarYears[0]);

            // Populate calendar months.
            setCalendarMonthOptions([
                ...Object.values(month).map<IComboBoxOption>((m: string, index: number) => {
                    return {
                        key: index + 1,
                        text: m
                    };
                })
            ]);
            setSelectedCalendarMonth(1);

            // Set the required input error messages with nothing selected or input (selectedSupplier and
            // selectedSupplierJobId will be 0, and correlationId, supplierInvoiceNumber, supplierPoNumber
            // will be blank at this point).
            setRequiredInputErrorMessages(selectedSupplier, selectedSupplierJobId, correlationId, supplierInvoiceNumber, supplierPoNumber, assetTag, serialNumber, selectedFiscalQuarter, selectedFiscalMonth);

            setControlDataLoadStage(ControlDataLoadStage.Loaded);
        } catch (err: any) {
            setControlDataLoadStage(ControlDataLoadStage.Failed);
            props.onSearchFilterLoadError(err);
        }
    }, [assetTag, correlationId, handleFiscalYearChange, handleProgramChange, nothingSelectedOption, props, selectedFiscalMonth, selectedFiscalQuarter, selectedSupplier, selectedSupplierJobId, serialNumber, setRequiredInputErrorMessages, supplierInvoiceNumber, supplierPoNumber]);

    /**
     * This useEffect will trigger loading of control data.
     */
    useEffect(() => {
        // If control data load failed, don't try to load again. Likely failures due to api failure.
        // User can try to refresh the page. This prevents this loading infinately failing each time.
        if (controlDataLoadStage === ControlDataLoadStage.Failed) {
            return;
        }

        // If not yet loaded, load control data.
        if (controlDataLoadStage === ControlDataLoadStage.Initial) {
            loadControlData();
        }
    }, [controlDataLoadStage, loadControlData]);

    /**
     * Check if controls should be disabled given state of data.
     * @returns True if controls should be disabled.
     */
    const checkDisableControls: () => boolean = () => {
        // If the entire control is disabled.
        if (props.disabled) {
            return true;
        }

        // If control data load stage is not Loaded or LoadedAndSupplierSet.
        if (controlDataLoadStage !== ControlDataLoadStage.Loaded && controlDataLoadStage) {
            return true;
        }
        return false;
    }

    /**
     * Program change event handler.
     * @param event Form event.
     * @param option ComboBox option.
     * @param index The index of the selected combo box option. Undefined if the option is manually submitted.
     * @param value The value of a manually submitted option. Undefined if the option already exists in the combo box.
     */
    const onProgramChange = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string): void => {
        if (!option) {
            return;
        }

        const programType: string | number = option.key;
        handleProgramChange(programType);
    };

    /**
     * Supplier change event handler.
     * @param event Form event.
     * @param option ComboBox option.
     * @param index The index of the selected combo box option. Undefined if the option is manually submitted.
     * @param value The value of a manually submitted option. Undefined if the option already exists in the combo box.
     */
    const onSupplierChange = async (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        if (!option) {
            return;
        }

        const supplierId: string | number = option.key;
        handleSupplierChange(supplierId);
    };
    
    /**
     * Supplier job id change event handler.
     * @param event Form event.
     * @param option ComboBox option.
     * @param index The index of the selected combo box option. Undefined if the option is manually submitted.
     * @param value The value of a manually submitted option. Undefined if the option already exists in the combo box.
     */
    const onSupplierJobIdChange = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string): void => {
        let supplierJobId: string | number;

        if (option) {
            supplierJobId = option.key;
        } else if (value) {
            supplierJobId = value;
            // Add a new option for what the user entered. This supplier job id combobox will allow for items shown in the combobox
            // in addition to user entered values.
            setSupplierJobOptions([
                ...supplierJobOptions, {
                    key: supplierJobId,
                    text: supplierJobId
                } as IComboBoxOption
            ]);
        } else {
            return;
        }

        setSelectedSupplierJobId(supplierJobId);

        // Job units is driven from selected supplier and job id.
        loadJobUnits(selectedSupplier, supplierJobId);

        setRequiredInputErrorMessages(selectedSupplier, supplierJobId, correlationId, supplierInvoiceNumber, supplierPoNumber, assetTag, serialNumber, selectedFiscalQuarter, selectedFiscalMonth);
    };

    /**
     * Correlation Id change event handler.
     * @param event The text field input event.
     * @param newValue The new value entered into the text field.
     */
    const onCorrelationIdTextFieldChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (typeof newValue === 'string') {
            newValue = newValue.trim();
        }

        newValue = newValue || '';
        setCorrelationId(newValue);

        setRequiredInputErrorMessages(selectedSupplier, selectedSupplierJobId, newValue, supplierInvoiceNumber, supplierPoNumber, assetTag, serialNumber, selectedFiscalQuarter, selectedFiscalMonth);
    }

    /**
     * Supplier Invoice Number change event handler.
     * @param event The text field input event.
     * @param newValue The new value entered into the text field.
     */
    const onSupplierInvoiceNumberTextFieldChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (typeof newValue === 'string') {
            newValue = newValue.trim();
        }

        newValue = newValue || '';
        setSupplierInvoiceNumber(newValue);

        setRequiredInputErrorMessages(selectedSupplier, selectedSupplierJobId, correlationId, newValue, supplierPoNumber, assetTag, serialNumber, selectedFiscalQuarter, selectedFiscalMonth);
    }

    /**
     * Supplier PO Number change event handler.
     * @param event The text field input event.
     * @param newValue The new value entered into the text field.
     */
    const onSupplierPoNumberTextFieldChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (typeof newValue === 'string') {
            newValue = newValue.trim();
        }

        newValue = newValue || '';
        setSupplierPoNumber(newValue);

        setRequiredInputErrorMessages(selectedSupplier, selectedSupplierJobId, correlationId, supplierInvoiceNumber, newValue, assetTag, serialNumber, selectedFiscalQuarter, selectedFiscalMonth);
    }

    /**
     * MS Invoice Number change event handler.
     * @param event The text field input event.
     * @param newValue The new value entered into the text field.
     */
    const onMsInvoiceNumberTextFieldChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (typeof newValue === 'string') {
            newValue = newValue.trim();
        }

        newValue = newValue || '';
        setMsInvoiceNumber(newValue);

        // Not passing the MS Invoice Number newValue to the setRequiredInputErrorMessages as it isn't a required field and doesn't infuence other required fields.
        setRequiredInputErrorMessages(selectedSupplier, selectedSupplierJobId, correlationId, supplierInvoiceNumber, supplierPoNumber, assetTag, serialNumber, selectedFiscalQuarter, selectedFiscalMonth);
    }

    /**
     * Asset Tag change event handler.
     * @param event The text field input event.
     * @param newValue The new value entered into the text field.
     */
    const onAssetTagTextFieldChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (typeof newValue === 'string') {
            newValue = newValue.trim();
        }

        newValue = newValue || '';
        setAssetTag(newValue);

        setRequiredInputErrorMessages(selectedSupplier, selectedSupplierJobId, correlationId, supplierInvoiceNumber, supplierPoNumber, newValue, serialNumber, selectedFiscalQuarter, selectedFiscalMonth);
    }

    /**
     * Serial Number change event handler.
     * @param event The text field input event.
     * @param newValue The new value entered into the text field.
     */
    const onSerialNumberTextFieldChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (typeof newValue === 'string') {
            newValue = newValue.trim();
        }

        newValue = newValue || '';
        setSerialNumber(newValue);

        setRequiredInputErrorMessages(selectedSupplier, selectedSupplierJobId, correlationId, supplierInvoiceNumber, supplierPoNumber, assetTag, newValue, selectedFiscalQuarter, selectedFiscalMonth);
    }

    /**
     * Supplier unit type change event handler.
     * @param event Form event.
     * @param option ComboBox option.
     * @param index The index of the selected combo box option. Undefined if the option is manually submitted.
     * @param value The value of a manually submitted option. Undefined if the option already exists in the combo box.
     */
    const onSupplierUnitTypeChange = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string): void => {
        if (!option) {
            return;
        }

        const supplierUnitType: string | number = option.key;
        handleSupplierUnitTypeChange(supplierUnitType);
    };

    /**
     * Job Unit change event handler.
     * @param event Form event.
     * @param option ComboBox option.
     * @param index The index of the selected combo box option. Undefined if the option is manually submitted.
     * @param value The value of a manually submitted option. Undefined if the option already exists in the combo box.
     */
    const onJobUnitChange = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string): void => {
        let supplierUnitId: string | number;

        if (option) {
            supplierUnitId = option.key;  
        } else if (value) {
            supplierUnitId = value;
            // Add a new option for what the user entered. This job unit combobox will allow for items shown in the combobox
            // in addition to user entered values.
            setJobUnitsOptions([
                ...jobUnitsOptions, {
                    key: supplierUnitId,
                    text: supplierUnitId
                } as IComboBoxOption
            ]);
        } else {
            return;
        }

        setSelectedSupplierUnitId(supplierUnitId);
    };

    /**
     * Country change event handler.
     * @param event Form event.
     * @param option ComboBox option.
     * @param index The index of the selected combo box option. Undefined if the option is manually submitted.
     * @param value The value of a manually submitted option. Undefined if the option already exists in the combo box.
     */
    const onCountryChange = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string): void => {
        if (!option) {
            return;
        }

        const countryCode: string | number = option.key;
        setSelectedCountryCode(countryCode);
    };

    /**
     * Disposition Type change event handler.
     * @param event Form event.
     * @param option ComboBox option.
     * @param index The index of the selected combo box option. Undefined if the option is manually submitted.
     * @param value The value of a manually submitted option. Undefined if the option already exists in the combo box.
     */
      const onUnitDispositionTypeChange = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string): void => {
        if (!option) {
            return;
        }

        const unitDispositionType: string | number = option.key;
        setSelectedUnitDispositionType(unitDispositionType);
    };

    /**
     * Unit Status change event handler.
     * @param event Form event.
     * @param option ComboBox option.
     * @param index The index of the selected combo box option. Undefined if the option is manually submitted.
     * @param value The value of a manually submitted option. Undefined if the option already exists in the combo box.
     */
      const onUnitStatusChange = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string): void => {
        if (!option) {
            return;
        }

        // Unit status is multi-select. This will add or remove the selected values in the selectedUnitStatus state.
        const unitStatuses: string[] = [...selectedUnitStatus];
        const i: number = selectedUnitStatus.indexOf(option.key as string);
        if (!option.selected && i > -1) {
            unitStatuses.splice(i, 1);
        } else if (option.selected && i < 0) {
            unitStatuses.push(option.key as string);
        }

        setSelectedUnitStatus(unitStatuses);
    };

    /**
     * Fiscal year change event handler.
     * @param event Form event.
     * @param option ComboBox option.
     * @param index The index of the selected combo box option. Undefined if the option is manually submitted.
     * @param value The value of a manually submitted option. Undefined if the option already exists in the combo box.
     */
    const onFiscalYearChange = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string): void => {
        if (!option) {
            return;
        }

        const fy: number = option.key as number;
        handleFiscalYearChange(fy);

        // Save the selected fiscal year into local storage cache.
        localStorage.setItem(`${localStorageKey.fiscalYear}`, `${fy}`);
    };

    /**
     * Fiscal quarter change event handler.
     * @param event Form event.
     * @param option ComboBox option.
     * @param index The index of the selected combo box option. Undefined if the option is manually submitted.
     * @param value The value of a manually submitted option. Undefined if the option already exists in the combo box.
     */
    const onFiscalQuarterChange = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string): void => {
        if (!option) {
            return;
        }

        const fq: string | number = option.key as string | number; // String or number because when nothing selected it is a number (0).
        setSelectedFiscalQuarter(fq);

        let months: MonthMap[];
        switch (fq) {
            case fiscalQuarter.q1:
                months = quarterMonths.q1Months;
                break;
            case fiscalQuarter.q2:
                months = quarterMonths.q2Months;
                break;
            case fiscalQuarter.q3:
                months = quarterMonths.q3Months;
                break;
            case fiscalQuarter.q4:
                months = quarterMonths.q4Months;
                break;
            default:
                // If no quarter is selected then show all 12 months.
                months = [...quarterMonths.q1Months, ...quarterMonths.q2Months, ...quarterMonths.q3Months, ...quarterMonths.q4Months];
                break;
        }

        setFiscalMonthOptions([
            nothingSelectedOption,
            ...months.map<IComboBoxOption>((fm: MonthMap) => {
                return {
                    key: fm.index,
                    text: fm.month
                };
            })
        ]);

        setSelectedFiscalMonth(0);

        // Supplier jobs is driven from selected supplier, program type, and selected date range driven from fiscal date selectors.
        // Note: Cannot just pass selectedFiscalQuarter (or others) as react state has not updated it yet.
        loadSupplierJobs(selectedFiscalYear, fq, selectedFiscalMonth, selectedSupplier, selectedProgramType);

        setRequiredInputErrorMessages(selectedSupplier, selectedSupplierJobId, correlationId, supplierInvoiceNumber, supplierPoNumber, assetTag, serialNumber, fq, selectedFiscalMonth);
    };

    /**
     * Fiscal month change event handler.
     * @param event Form event.
     * @param option ComboBox option.
     * @param index The index of the selected combo box option. Undefined if the option is manually submitted.
     * @param value The value of a manually submitted option. Undefined if the option already exists in the combo box.
     */
    const onFiscalMonthChange = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string): void => {
        if (!option) {
            return;
        }

        const fm: number = option.key as number;
        setSelectedFiscalMonth(fm);

        // Supplier jobs is driven from selected supplier, program type, and selected date range driven from fiscal date selectors.
        // Note: Cannot just pass selectedFiscalMonth as react state has not updated it yet.
        loadSupplierJobs(selectedFiscalYear, selectedFiscalQuarter, fm, selectedSupplier, selectedProgramType);

        setRequiredInputErrorMessages(selectedSupplier, selectedSupplierJobId, correlationId, supplierInvoiceNumber, supplierPoNumber, assetTag, serialNumber, selectedFiscalQuarter, fm);
    };

    /**
     * Calendar year change event handler.
     * @param event Form event.
     * @param option ComboBox option.
     * @param index The index of the selected combo box option. Undefined if the option is manually submitted.
     * @param value The value of a manually submitted option. Undefined if the option already exists in the combo box.
     */
    const onCalendarYearChange = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string): void => {
        if (!option) {
            return;
        }

        const cy: number = option.key as number;
        setSelectedCalendarYear(cy);

        // Changing the calendar year or month does not cause any other controls to load.
        // The fiscal selectors do cause other controls to load.
    };

    /**
     * Calendar year change event handler.
     * @param event Form event.
     * @param option ComboBox option.
     * @param index The index of the selected combo box option. Undefined if the option is manually submitted.
     * @param value The value of a manually submitted option. Undefined if the option already exists in the combo box.
     */
    const onCalendarMonthChange = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string): void => {
        if (!option) {
            return;
        }

        const cm: number = option.key as number;
        setSelectedCalendarMonth(cm);

        // Changing the calendar year or month does not cause any other controls to load.
        // The fiscal selectors do cause other controls to load.
    };

    /**
     * Search clicked event handler.
     */
    const searchClicked = () => {
        let startEndDate: StartEndDate = {
            startDate: new Date(),
            endDate: new Date()
        }

        // One of showFiscalRange or showCalendarYearMonth should be used, never both.
        if (props.searchFilterOptions.showFiscalRange) {
            // UI does not allow selectedFiscalYear to be unselected.
            startEndDate = determineStartAndEndDateFromFiscalRange(selectedFiscalYear!, selectedFiscalQuarter, selectedFiscalMonth); 
        } else if (props.searchFilterOptions.showCalendarYearMonth && selectedCalendarYear && selectedCalendarMonth) {
            startEndDate.startDate = new Date(selectedCalendarYear, selectedCalendarMonth - 1 /* month is 0 based */, 1 /* First day of month */);
            startEndDate.endDate = new Date(selectedCalendarYear, selectedCalendarMonth /* month is 0 based, so keeping selectedCalendarMonth as is, which is the next month when 0 based */, 0)
        }
 
        // If the value is 0 or empty string for any combobox or input, then that is falsey
        // and will result in the variable being set to undefined below.
        const programType: string | undefined = selectedProgramType ? selectedProgramType as string : undefined;
        const supplierId: string | undefined = selectedSupplier ? selectedSupplier as string : undefined;
        const supplierJobId: string | undefined = selectedSupplierJobId ? selectedSupplierJobId as string : undefined;
        const supplierUnitType: string | undefined = selectedSupplierUnitType ? selectedSupplierUnitType as string : undefined;
        const supplierUnitId: string | undefined = selectedSupplierUnitId ? selectedSupplierUnitId as string : undefined;
        const countryCode: string | undefined = selectedCountryCode ? selectedCountryCode as string : undefined;
        const unitDispositionType: string | undefined = selectedUnitDispositionType ? selectedUnitDispositionType as string : undefined;
        const unitStatuses: string[] | undefined = selectedUnitStatus ? selectedUnitStatus as string[] : undefined;

        const searchCriteria: ISearchCriteria = {
            startDate: startEndDate.startDate,
            endDate: startEndDate.endDate,
            programType: programType,
            supplierId: supplierId,
            supplierJobId: supplierJobId,
            correlationId: correlationId,
            supplierInvoiceNumber: supplierInvoiceNumber,
            supplierPoNumber: supplierPoNumber,
            msInvoiceNumber: msInvoiceNumber,
            supplierUnitType: supplierUnitType,
            supplierUnitId: supplierUnitId,
            countryCode: countryCode,
            assetTag: assetTag,
            serialNumber: serialNumber,
            unitDispositionType: unitDispositionType,
            unitStatus: unitStatuses?.join(',')
        }
        props.onSearchClicked(searchCriteria);
    }

    return (
        <Stack tokens={stackTokens}>
            <Stack horizontal wrap tokens={stackTokens} className={controlStyles.inputFilterContainer}>
                { props.searchFilterOptions.showFiscalRange && (
                    <>
                        <VirtualizedComboBox
                            label="Fiscal Year"
                            options={fiscalYearOptions}
                            className={controlStyles.comboBox}
                            styles={comboBoxStyles}
                            disabled={checkDisableControls()}
                            onChange={onFiscalYearChange}
                            selectedKey={selectedFiscalYear}
                            allowFreeform
                            autoComplete="on"
                            useComboBoxAsMenuWidth={true} />
                            {/* No need to mark required on Fiscal Year as it will always have a value selected. */}
                        <VirtualizedComboBox
                            label="Fiscal Quarter"
                            options={fiscalQuarterOptions}
                            className={controlStyles.comboBox}
                            styles={comboBoxStyles}
                            disabled={checkDisableControls()}
                            onChange={onFiscalQuarterChange}
                            selectedKey={selectedFiscalQuarter}
                            allowFreeform
                            autoComplete="on"
                            useComboBoxAsMenuWidth={true}
                            required={props.searchFilterOptions.fiscalRangeFieldsRequired}
                            errorMessage={fiscalQuarterErrorMessage} />
                        <VirtualizedComboBox
                            label="Fiscal Month"
                            options={fiscalMonthOptions}
                            className={controlStyles.comboBox}
                            styles={comboBoxStyles}
                            disabled={checkDisableControls() || !selectedFiscalQuarter}
                            onChange={onFiscalMonthChange}
                            selectedKey={selectedFiscalMonth}
                            allowFreeform
                            autoComplete="on"
                            useComboBoxAsMenuWidth={true}
                            required={props.searchFilterOptions.fiscalRangeFieldsRequired}
                            errorMessage={fiscalMonthErrorMessage} />
                    </>
                )}

                { props.searchFilterOptions.showCalendarYearMonth && (
                    <>
                        <VirtualizedComboBox
                            label="Calendar Year"
                            options={calendarYearOptions}
                            className={controlStyles.comboBox}
                            styles={comboBoxStyles}
                            disabled={checkDisableControls()}
                            onChange={onCalendarYearChange}
                            selectedKey={selectedCalendarYear}
                            allowFreeform
                            autoComplete="on"
                            useComboBoxAsMenuWidth={true} />
                            {/* No need to mark required on Calendar Year as it will always have a value selected. */}
                        <VirtualizedComboBox
                            label="Calendar Month"
                            options={calendarMonthOptions}
                            className={controlStyles.comboBox}
                            styles={comboBoxStyles}
                            disabled={checkDisableControls()}
                            onChange={onCalendarMonthChange}
                            selectedKey={selectedCalendarMonth}
                            allowFreeform
                            autoComplete="on"
                            useComboBoxAsMenuWidth={true} />
                            {/* No need to mark required on Calendar Month as it will always have a value selected. */}
                    </>
                )}

                { props.searchFilterOptions.showProgram && (
                    <VirtualizedComboBox
                        label="Program"
                        options={programsOptions}
                        className={controlStyles.comboBox}
                        styles={comboBoxStyles}
                        disabled={checkDisableControls()}
                        onChange={onProgramChange}
                        selectedKey={selectedProgramType}
                        allowFreeform
                        autoComplete="on"
                        useComboBoxAsMenuWidth={true} />
                )}

                { props.searchFilterOptions.showSupplierName && (
                    <div style={{ position: 'relative' }}>
                        <VirtualizedComboBox
                            label="Supplier Name"
                            options={suppliersOptions}
                            className={controlStyles.comboBox}
                            styles={comboBoxStyles}
                            disabled={checkDisableControls() || isSuppliersLoading}
                            onChange={onSupplierChange}
                            selectedKey={selectedSupplier}
                            allowFreeform
                            autoComplete="on"
                            useComboBoxAsMenuWidth={true}
                            required={determinePotentiallyRequiredInput(PotentiallyRequiredField.SupplierName, selectedSupplier, selectedSupplierJobId, supplierInvoiceNumber, supplierPoNumber, assetTag, serialNumber)}
                            errorMessage={supplierNameErrorMessage} />
                        {isSuppliersLoading &&
                            <div style={{ position: 'absolute', top: '34px', right: '28px' }}>
                                <Spinner size={SpinnerSize.medium} className={commonStyles.spinner} />
                            </div>
                        }
                        <TooltipHost content={supplierNameTooltip}
                            id='supplierNameTooltip' calloutProps={{ gapSpace: 0 }} styles={tooltipHostStyles}>
                            <div tabIndex={0} className={commonStyles.tooltipFontIconContainer}>
                                <FontIcon iconName="Info" className={controlStyles.inputTooltipIcon} aria-describedby='supplierNameTooltip' />
                            </div>
                        </TooltipHost>
                    </div>
                )}

                { (props.searchFilterOptions.showComboInputGroup === ComboInputOption.SupplierJobId_SupplierInvoiceNumber_SupplierPoNumber) && (
                    <div className={controlStyles.comboAndOrInputContainer}>
                        <div className={controlStyles.comboInputFieldContainer}>
                            <VirtualizedComboBox
                                label="Job Id"
                                options={supplierJobOptions}
                                className={controlStyles.comboBox}
                                styles={comboBoxStyles}
                                disabled={checkDisableControls() || isSupplierJobsLoading}
                                onChange={onSupplierJobIdChange}
                                selectedKey={selectedSupplierJobId}
                                allowFreeform={true}
                                autoComplete="on"
                                useComboBoxAsMenuWidth={true} />
                            {isSupplierJobsLoading &&
                                <div style={{ position: 'absolute', top: '34px', right: '28px' }}>
                                    <Spinner size={SpinnerSize.medium} className={commonStyles.spinner} />
                                </div>
                            }
                            <TooltipHost content={supplierJobIdTooltip}
                                id='supplierJobIdTooltip' calloutProps={{ gapSpace: 0 }} styles={tooltipHostStyles}>
                                <div tabIndex={0} className={commonStyles.tooltipFontIconContainer}>
                                    <FontIcon iconName="Info" className={controlStyles.inputTooltipIcon} aria-describedby='supplierJobIdTooltip' />
                                </div>
                            </TooltipHost>
                        </div>
                        <div className={controlStyles.comboInputFieldContainer}>
                            <TextField
                                label="Supplier Invoice Number"
                                placeholder=""
                                className={controlStyles.textField}
                                styles={comboInputTextFieldStyles}
                                defaultValue={supplierInvoiceNumber}
                                onChange={onSupplierInvoiceNumberTextFieldChange}>
                            </TextField>
                            <TooltipHost content={supplierInvoiceNumberTooltip}
                                id='supplierInvoiceNumberTooltip' calloutProps={{ gapSpace: 0 }} styles={tooltipHostStyles}>
                                <div tabIndex={0} className={commonStyles.tooltipFontIconContainer}>
                                    <FontIcon iconName="Info" className={controlStyles.inputTooltipIcon} aria-describedby='supplierInvoiceNumberTooltip' />
                                </div>
                            </TooltipHost>
                        </div>
                        <div className={controlStyles.comboInputFieldContainer}>
                            <TextField
                                label="Supplier PO Number"
                                placeholder=""
                                className={controlStyles.textField}
                                styles={comboInputTextFieldStyles}
                                defaultValue={supplierPoNumber}
                                onChange={onSupplierPoNumberTextFieldChange}>
                            </TextField>
                            <TooltipHost content={supplierPoNumberTooltip}
                                id='supplierPoNumberTooltip' calloutProps={{ gapSpace: 0 }} styles={tooltipHostStyles}>
                                <div tabIndex={0} className={commonStyles.tooltipFontIconContainer}>
                                    <FontIcon iconName="Info" className={controlStyles.inputTooltipIcon} aria-describedby='supplierPoNumberTooltip' />
                                </div>
                            </TooltipHost>
                        </div>
                        {
                            /* Required star. Placing it custom here rather than using required={true} on above inputs - since only one is required. */
                            (determinePotentiallyRequiredInput(PotentiallyRequiredField.Combo_SupplierJobId_SupplierInvoiceNumber_SupplierPoNumber, selectedSupplier, selectedSupplierJobId, supplierInvoiceNumber, supplierPoNumber, assetTag, serialNumber)) && (
                                <div className={controlStyles.comboInputRequiredStar}>
                                    *
                                </div>
                            )
                        }
                        <div>
                            {
                                comboInputsErrorMessage && (
                                    <Text variant="smallPlus" style={{ color: 'rgb(164, 38, 44)', marginLeft: '5px' }}>{comboInputsErrorMessage}</Text>
                                )
                            }
                        </div>
                    </div>
                )}

                { props.searchFilterOptions.showSupplierJobId && (
                    <div style={{ position: 'relative' }}>
                        <VirtualizedComboBox
                            label="Job Id"
                            options={supplierJobOptions}
                            className={controlStyles.comboBox}
                            styles={comboBoxStyles}
                            disabled={checkDisableControls() || isSupplierJobsLoading}
                            onChange={onSupplierJobIdChange}
                            selectedKey={selectedSupplierJobId}
                            allowFreeform={true}
                            autoComplete="on"
                            useComboBoxAsMenuWidth={true} />
                        {isSupplierJobsLoading &&
                            <div style={{ position: 'absolute', top: '34px', right: '28px' }}>
                                <Spinner size={SpinnerSize.medium} className={commonStyles.spinner} />
                            </div>
                        }
                        <TooltipHost content={supplierJobIdTooltip}
                            id='supplierJobIdTooltip' calloutProps={{ gapSpace: 0 }} styles={tooltipHostStyles}>
                            <div tabIndex={0} className={commonStyles.tooltipFontIconContainer}>
                                <FontIcon iconName="Info" className={controlStyles.inputTooltipIcon} aria-describedby='supplierJobIdTooltip' />
                            </div>
                        </TooltipHost>
                    </div>
                )}

                { props.searchFilterOptions.showCorrelationId && (
                    <div style={{ position: 'relative' }}>
                        <TextField
                            label="Correlation Id"
                            placeholder="00000000-0000-0000-0000-000000000000"
                            className={controlStyles.textField}
                            styles={standaloneInputTextFieldStyles}
                            defaultValue={correlationId}
                            onChange={onCorrelationIdTextFieldChange}
                            errorMessage={correlationIdErrorMessage}>
                        </TextField>
                        <TooltipHost content={correlationIdTooltip}
                            id='correlationIdTooltip' calloutProps={{ gapSpace: 0 }} styles={tooltipHostStyles}>
                            <div tabIndex={0} className={commonStyles.tooltipFontIconContainer}>
                                <FontIcon iconName="Info" className={controlStyles.inputTooltipIcon} aria-describedby='correlationIdTooltip' />
                            </div>
                        </TooltipHost>
                    </div>
                )}

                { props.searchFilterOptions.showSupplierPoNumber && (
                    <div style={{ position: 'relative' }}>
                        <TextField
                            label="Supplier PO Number"
                            placeholder=""
                            className={controlStyles.textField}
                            styles={standaloneInputTextFieldStyles}
                            defaultValue={supplierPoNumber}
                            onChange={onSupplierPoNumberTextFieldChange}>
                        </TextField>
                        <TooltipHost content={supplierPoNumberTooltip}
                            id='supplierPoNumberTooltip' calloutProps={{ gapSpace: 0 }} styles={tooltipHostStyles}>
                            <div tabIndex={0} className={commonStyles.tooltipFontIconContainer}>
                                <FontIcon iconName="Info" className={controlStyles.inputTooltipIcon} aria-describedby='supplierPoNumberTooltip' />
                            </div>
                        </TooltipHost>
                    </div>
                )}

                { props.searchFilterOptions.showSupplierInvoiceNumber && (
                    <div style={{ position: 'relative' }}>
                        <TextField
                            label="Supplier Invoice Number"
                            placeholder=""
                            className={controlStyles.textField}
                            styles={standaloneInputTextFieldStyles}
                            defaultValue={supplierInvoiceNumber}
                            onChange={onSupplierInvoiceNumberTextFieldChange}>
                        </TextField>
                        <TooltipHost content={supplierInvoiceNumberTooltip}
                            id='supplierInvoiceNumberTooltip' calloutProps={{ gapSpace: 0 }} styles={tooltipHostStyles}>
                            <div tabIndex={0} className={commonStyles.tooltipFontIconContainer}>
                                <FontIcon iconName="Info" className={controlStyles.inputTooltipIcon} aria-describedby='supplierInvoiceNumberTooltip' />
                            </div>
                        </TooltipHost>
                    </div>
                )}

                { props.searchFilterOptions.showMsInvoiceNumber && (
                    <div style={{ position: 'relative' }}>
                        <TextField
                            label="MS Invoice Number"
                            placeholder=""
                            className={controlStyles.textField}
                            styles={standaloneInputTextFieldStyles}
                            defaultValue={msInvoiceNumber}
                            onChange={onMsInvoiceNumberTextFieldChange}>
                        </TextField>
                        <TooltipHost content={msInvoiceNumberTooltip}
                            id='msInvoiceNumberTooltip' calloutProps={{ gapSpace: 0 }} styles={tooltipHostStyles}>
                            <div tabIndex={0} className={commonStyles.tooltipFontIconContainer}>
                                <FontIcon iconName="Info" className={controlStyles.inputTooltipIcon} aria-describedby='msInvoiceNumberTooltip' />
                            </div>
                        </TooltipHost>
                    </div>
                )}

                { props.searchFilterOptions.showSupplierUnitType && (
                    <div style={{ position: 'relative' }}>
                        <VirtualizedComboBox
                            label="Unit Type"
                            options={supplierUnitTypesOptions}
                            className={controlStyles.comboBox}
                            styles={comboBoxStyles}
                            disabled={checkDisableControls() || isSupplierUnitTypesLoading}
                            onChange={onSupplierUnitTypeChange}
                            selectedKey={selectedSupplierUnitType}
                            allowFreeform
                            autoComplete="on"
                            useComboBoxAsMenuWidth={true} />
                        {isSupplierUnitTypesLoading &&
                            <div style={{ position: 'absolute', top: '34px', right: '28px' }}>
                                <Spinner size={SpinnerSize.medium} className={commonStyles.spinner} />
                            </div>
                        }
                        <TooltipHost content={drivenBySelectedProgramTypeTooltip}
                            id='supplierUnitTypeTooltip' calloutProps={{ gapSpace: 0 }} styles={tooltipHostStyles}>
                            <div tabIndex={0} className={commonStyles.tooltipFontIconContainer}>
                                <FontIcon iconName="Info" className={controlStyles.inputTooltipIcon} aria-describedby='supplierUnitTypeTooltip' />
                            </div>
                        </TooltipHost>
                    </div>
                )}

                { props.searchFilterOptions.showJobUnit && (
                    <div style={{ position: 'relative' }}>
                        <VirtualizedComboBox
                            label="Job Unit"
                            options={jobUnitsOptions}
                            className={controlStyles.comboBox}
                            styles={comboBoxStyles}
                            disabled={checkDisableControls() || isJobUnitsLoading}
                            onChange={onJobUnitChange}
                            selectedKey={selectedSupplierUnitId}
                            allowFreeform
                            autoComplete="on"
                            useComboBoxAsMenuWidth={true} />
                        {isJobUnitsLoading &&
                            <div style={{ position: 'absolute', top: '34px', right: '28px' }}>
                                <Spinner size={SpinnerSize.medium} className={commonStyles.spinner} />
                            </div>
                        }
                        <TooltipHost content={jobUnitTooltip}
                            id='jobUnitTooltip' calloutProps={{ gapSpace: 0 }} styles={tooltipHostStyles}>
                            <div tabIndex={0} className={commonStyles.tooltipFontIconContainer}>
                                <FontIcon iconName="Info" className={controlStyles.inputTooltipIcon} aria-describedby='jobUnitTooltip' />
                            </div>
                        </TooltipHost>
                    </div>
                )}

                { props.searchFilterOptions.showCountry && (
                    <VirtualizedComboBox
                        label="Country"
                        options={countriesOptions}
                        className={controlStyles.comboBox}
                        styles={comboBoxStyles}
                        disabled={checkDisableControls()}
                        onChange={onCountryChange}
                        selectedKey={selectedCountryCode}
                        allowFreeform
                        autoComplete="on"
                        useComboBoxAsMenuWidth={true} />
                )}

                { (props.searchFilterOptions.showComboAssetTagAndSerialNumber) && (
                    <div className={controlStyles.comboAndOrInputContainer}>
                        <div className={controlStyles.comboInputFieldContainer}>
                            <TextField
                                label="Asset Tag"
                                className={controlStyles.textField}
                                styles={comboInputTextFieldStyles}
                                defaultValue={assetTag}
                                onChange={onAssetTagTextFieldChange}>
                            </TextField>
                        </div>
                        <div className={controlStyles.comboInputFieldContainer}>
                            <TextField
                                label="Serial Number"
                                className={controlStyles.textField}
                                styles={comboInputTextFieldStyles}
                                defaultValue={serialNumber}
                                onChange={onSerialNumberTextFieldChange}>
                            </TextField>
                        </div>
                        {
                            /* Required star. Placing it custom here rather than using required={true} on above inputs - since only one is required. */
                            determinePotentiallyRequiredInput(PotentiallyRequiredField.Combo_AssetTag_SerialNumber, selectedSupplier, selectedSupplierJobId, supplierInvoiceNumber, supplierPoNumber, assetTag, serialNumber) && (
                                <div className={controlStyles.comboInputRequiredStar}>
                                    *
                                </div>
                            )
                        }
                        <div>
                            {
                                comboAssetTagSerialNumberErrorMessage && (
                                    <Text variant="smallPlus" style={{ color: 'rgb(164, 38, 44)', marginLeft: '5px' }}>{comboAssetTagSerialNumberErrorMessage}</Text>
                                )
                            }
                        </div>
                    </div>
                )}

                { props.searchFilterOptions.showUnitDispositionType && (
                    <div style={{ position: 'relative' }}>
                        <VirtualizedComboBox
                            label="Disposition Type"
                            options={unitDispositionTypeOptions}
                            className={controlStyles.comboBox}
                            styles={comboBoxStyles}
                            disabled={checkDisableControls()}
                            onChange={onUnitDispositionTypeChange}
                            selectedKey={selectedUnitDispositionType}
                            allowFreeform
                            autoComplete="on"
                            useComboBoxAsMenuWidth={true} />
                        {isUnitDispositionTypesLoading &&
                            <div style={{ position: 'absolute', top: '34px', right: '28px' }}>
                                <Spinner size={SpinnerSize.medium} className={commonStyles.spinner} />
                            </div>
                        }
                        <TooltipHost content={drivenBySelectedProgramTypeTooltip}
                            id='unitDispositionTypeTooltip' calloutProps={{ gapSpace: 0 }} styles={tooltipHostStyles}>
                            <div tabIndex={0} className={commonStyles.tooltipFontIconContainer}>
                                <FontIcon iconName="Info" className={controlStyles.inputTooltipIcon} aria-describedby='unitDispositionTypeTooltip' />
                            </div>
                        </TooltipHost>
                    </div>
                )}

                { props.searchFilterOptions.showUnitStatus && (
                    <div style={{ position: 'relative' }}>
                        <VirtualizedComboBox
                            label="Unit Status"
                            options={unitStatusOptions}
                            className={controlStyles.comboBox}
                            styles={comboBoxStyles}
                            disabled={checkDisableControls()}
                            onChange={onUnitStatusChange}
                            selectedKey={selectedUnitStatus}
                            placeholder={appConstants.nothingSelected}
                            multiSelect={true}
                            useComboBoxAsMenuWidth={true} />
                        {isUnitStatusesLoading &&
                            <div style={{ position: 'absolute', top: '34px', right: '28px' }}>
                                <Spinner size={SpinnerSize.medium} className={commonStyles.spinner} />
                            </div>
                        }
                        <TooltipHost content={drivenBySelectedProgramTypeTooltip}
                            id='unitStatusTooltip' calloutProps={{ gapSpace: 0 }} styles={tooltipHostStyles}>
                            <div tabIndex={0} className={commonStyles.tooltipFontIconContainer}>
                                <FontIcon iconName="Info" className={controlStyles.inputTooltipIcon} aria-describedby='unitStatusTooltip' />
                            </div>
                        </TooltipHost>
                    </div>
                )}
            </Stack>
            {
                props.searchFilterOptions.instructionText && (
                    <div className={controlStyles.instructionText}>
                        <Text variant="medium">{props.searchFilterOptions.instructionText}</Text>
                    </div>
                )
            }
            <div>
                <DefaultButton
                    className={controlStyles.searchButton}
                    text="Search"
                    onClick={searchClicked}
                    disabled={checkDisableControls() || checkRequiredInputsInvalid()} />
            </div>
        </Stack>
    );
};
