import { InvoiceSummary } from '../../../models/supplier/invoiceSummary';
import { serviceApiClient } from '../../../services/api/serviceApiClient';
import { AppDispatch, reduxStore } from '../../reduxStore';
import * as actionTypes from '../actionTypes';
import { callApi, CallApiState, ICallApiBase } from '../generic.action';

/**
 * Load invoice summary action payload.
 */
export interface IApiLoadInvoiceSummary extends ICallApiBase {
    invoiceSummaries?: InvoiceSummary[] | null;
}

/**
 * Load invoice summary.
 * @param programType Program type.
 * @param supplierId Supplier id.
 * @param year Year.
 * @param month Month.
 * @param supplierJobId Supplier job id.
 * @param supplierInvoiceNumber Supplier invoice number.
 * @returns Redux dispatch function.
 */
export const callApiLoadInvoiceSummary = (programType: string, supplierId: string, year: number, month: number, supplierJobId?: string, supplierInvoiceNumber?: string): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<InvoiceSummary[]>(
        actionTypes.API_LOAD_INVOICE_SUMMARY,
        async () => {
            return await serviceApiClient.invoiceSummary(programType, supplierId, year, month, supplierJobId, supplierInvoiceNumber) || [];
        },
        (payload: IApiLoadInvoiceSummary, data) => {
            if (data) {
                // Set the clientRowKey in each object so it can be bound into the DetailsList.
                data.forEach((value: InvoiceSummary, index: number) => {
                    value.clientRowKey = index.toString()
                });
            }

            payload.invoiceSummaries = data;
        }
    );
};

/**
 * Reset the page action call state for all api calls.
 * This clear all data from the Redux store for these calls, including any error state for failed calls.
 * This makes it so if there was a failed call and an error was shown on the page, if the user navigates
 * to another page and back, the prior error would not be shown.
 */
export const resetApiCallState = () => {
    const apiLoadInvoiceSummary: IApiLoadInvoiceSummary = {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        invoiceSummaries: undefined
    };
    reduxStore.dispatch({
        type: actionTypes.API_LOAD_INVOICE_SUMMARY,
        payload: apiLoadInvoiceSummary
    });
};
