import { DbdDetails } from './dbdDetails';
import { DbdImageLink } from './dbdImageLink';

export interface IDbdReport {
    supplierId: string;
    supplierName: string;
    supplierJobId: string;
    supplierJobType: string;
    supplierReceivedDate?: Date;
    collectionDate?: Date;
    collectionSiteName: string;
    collectionSiteCode: string;
    collectionCountry: string;
    dbdDetails?: DbdDetails[];
    dbdImageLinks?: DbdImageLink[];
}

export class DbdReport implements IDbdReport {
    public supplierId: string;
    public supplierName: string;
    public supplierJobId: string;
    public supplierJobType: string;
    public supplierReceivedDate?: Date;
    public collectionDate?: Date;
    public collectionSiteName: string;
    public collectionSiteCode: string;
    public collectionCountry: string;
    public dbdDetails?: DbdDetails[] = [];
    public dbdImageLinks?: DbdImageLink[] = [];

    constructor(jsonData: IDbdReport) {
        this.supplierId = jsonData.supplierId;
        this.supplierName = jsonData.supplierName;
        this.supplierJobId = jsonData.supplierJobId;
        this.supplierJobType = jsonData.supplierJobType;
        this.supplierReceivedDate = jsonData.supplierReceivedDate ? new Date(jsonData.supplierReceivedDate) : undefined;
        this.collectionDate = jsonData.collectionDate ? new Date(jsonData.collectionDate) : undefined;
        this.collectionSiteName = jsonData.collectionSiteName;
        this.collectionSiteCode = jsonData.collectionSiteCode;
        this.collectionCountry = jsonData.collectionCountry;

        this.dbdDetails = jsonData.dbdDetails ?
            jsonData.dbdDetails.map((value: DbdDetails) => {
                return new DbdDetails(value)
            })
            : undefined;

        this.dbdImageLinks = jsonData.dbdImageLinks ?
        jsonData.dbdImageLinks.map((value: DbdImageLink) => {
            return new DbdImageLink(value)
        })
        : undefined;
    }
}
