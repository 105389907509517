import { IStyle, mergeStyleSets } from '@fluentui/react';

/**
 * Styles used on this component.
 */
export const componentStyles = mergeStyleSets({
    loadingContainer: {
        display: 'grid',
        height: '100vh'
    } as IStyle,
    copilotContainer: {
        border: '1px solid lightgray'
    } as IStyle
});
