import { IStyle, mergeStyleSets } from '@fluentui/react';

/**
 * Styles used on this page.
 */
export const pageStyles = mergeStyleSets({
    actionButtonLink: {
        height: '16px',
        fontSize: '12px',
        textDecoration: 'none',
        color: '#0070cc',
        paddingLeft: '0px',
        span: {
            marginLeft: '0px'
        }
    } as IStyle,
    grandTotalContainer: {
        margin: '12px 22px 12px 22px',
        textAlign: 'right'
    } as IStyle
});
