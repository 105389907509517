import { AnyAction } from 'redux';
import * as actionTypes from './actionTypes';

/**
 * Action creator: Set copilot panel is open.
 * @returns Redux action.
 */
export const setCopilotPanelIsOpen = (isOpen: boolean): AnyAction => {
    return {
        type: actionTypes.COPILOT_PANEL_IS_OPEN,
        payload: isOpen
    } as AnyAction;
};

/**
 * Action creator: Set nav is open.
 * @param isOpen Is open.
 * @returns Redux action.
 */
export const setNavIsOpen = (isOpen: boolean): AnyAction => {
    return {
        type: actionTypes.NAV_IS_OPEN,
        payload: isOpen
    } as AnyAction;
};
