import { AnyAction, Reducer } from 'redux';
import * as actionTypes from '../../actions/actionTypes';
import { CallApiState } from '../../actions/generic.action';
import { IApiLoadPaymentNotifications } from '../../actions/pageActions/paymentNotificationPage.action';

export interface IPaymentNotificationPageReducerState {
    apiLoadPaymentNotifications: IApiLoadPaymentNotifications;
}

const initialPaymentNotificationPageReducerState: IPaymentNotificationPageReducerState = {
    apiLoadPaymentNotifications: {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        payments: undefined
    }
}

export const paymentNotificationPageReducer: Reducer<IPaymentNotificationPageReducerState, AnyAction> = (
    state: IPaymentNotificationPageReducerState = initialPaymentNotificationPageReducerState, action: AnyAction
): IPaymentNotificationPageReducerState => {
    switch (action.type) {
        case actionTypes.API_LOAD_PAYMENT_NOTIFICATIONS: {
            const payload: IApiLoadPaymentNotifications = action.payload as IApiLoadPaymentNotifications;
            return {
                ...state,
                apiLoadPaymentNotifications: { ...payload }
            }
        }
        default:
    }

    return state;
}
