import React, { useState } from 'react';
import { App } from './App';
import { useMountEffect } from './common/common.func';
import { commonStyles } from './common/common.styles';
import { SecurityGroupMembership } from './models/appModels/securityGroupMembership';
import { Program } from './models/domainData/program';
import { serviceApiClient } from './services/api/serviceApiClient';
import { getUserAadOid, getUserEmail } from './services/auth/msalHelper';

interface AppSecurityCheckProps {
}

export const AppSecurityCheck: React.FunctionComponent<AppSecurityCheckProps> = (props: AppSecurityCheckProps): JSX.Element => {
    const [programs, setPrograms] = useState<Program[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [securityGroups, setSecurityGroups] = useState<SecurityGroupMembership[]>([
        {
            sgName: 'MSRecycling Team',
            isMember: false
        }
    ]);

    useMountEffect(() => {
        const getProgramTypes = async () => {
            setPrograms(await serviceApiClient.programs() || []);
            setIsLoading(false);
        };

        const checkSecurityGroupMemberships = async () => {
            // Check security group memberships.
            const promises: any = [];
            securityGroups.forEach(async (sg: SecurityGroupMembership) => {
                const p: Promise<boolean> = serviceApiClient.sgCheck(sg.sgName);
                promises.push(p);
                const isMember: boolean = await p;
                setSecurityGroups(prevArray => prevArray.map(group => group.sgName === sg.sgName ? { ...group, isMember: isMember } : group))                
            });
            await Promise.all(promises);
        };

        getProgramTypes();
        checkSecurityGroupMemberships();
    });
    
    return (
        <>
            {isLoading ?
                <div className={commonStyles.absoluteCenter}>Loading...</div>
            :
                <App
                    aadOid={getUserAadOid()}
                    userEmail={getUserEmail()}
                    programs={programs}
                    securityGroupMemberships={securityGroups}
                />
            }
        </>
    );
}
