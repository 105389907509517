export interface ICarbonFootprint {
    category: string;
    attribute: string;
    type: string;
    factor: string;
    amountInLB: number;
    amountInKG: number;
}

export class CarbonFootprint implements ICarbonFootprint {
    public category: string;
    public type: string;
    public factor: string;
    public attribute: string;
    public amountInKG: number;
    public amountInLB: number;

    // Below are properties NOT returned from the server but are used for client side purposes.
    public clientRowKey: string = '';

    constructor(jsonData: ICarbonFootprint) {
        this.category = jsonData.category;
        this.type = jsonData.type;
        this.factor = jsonData.factor;
        this.attribute = jsonData.attribute;
        this.amountInKG = jsonData.amountInKG;
        this.amountInLB = jsonData.amountInLB;
    }
}