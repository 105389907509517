import { ResultType } from './resultType';

export interface ISlaDetail {
    supplierName: string;
    dateUtc: Date;
    type: ResultType;
}

export class SlaDetail implements ISlaDetail {
    public supplierName: string;
    public dateUtc: Date;
    public type: ResultType;

    constructor(jsonData: ISlaDetail) {
        this.supplierName = jsonData.supplierName;
        this.dateUtc = new Date(jsonData.dateUtc);
        this.type = new ResultType(jsonData.type);
    }
}
