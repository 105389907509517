export interface IUnitDispositionType {
    partitionKey: string;
    rowKey: string;
    dispositionType: string;
}

export class UnitDispositionType implements IUnitDispositionType {
    public partitionKey: string;
    public rowKey: string;
    public dispositionType: string;

    // Below are properties NOT returned from the server but are used for client side purposes.
    public clientRowKey: string = '';
    public isNew?: boolean;

    constructor(jsonData: IUnitDispositionType) {
        this.partitionKey = jsonData.partitionKey;
        this.rowKey = jsonData.rowKey;
        this.dispositionType = jsonData.dispositionType;
    }
}
