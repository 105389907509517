import { IStyle, ITextFieldStyles, ITooltipHostStyles, mergeStyleSets } from '@fluentui/react';
import { textFieldStyles } from '../../common/common.styles';

/**
 * Styles used on this control.
 */
export const controlStyles = mergeStyleSets({
    inputFilterContainer: {
        marginRight: '7.5px',
        marginBottom: '4px'
    } as IStyle,
    searchButton: {
        marginTop: '0px'
    } as IStyle,
    comboAndOrInputContainer: {
        display: 'inline-block',
        outline: '1px solid black',
        padding: '0px 0px 4px 0px',
        outlineStyle: 'dashed',
        position: 'relative',
        paddingRight: '12px' // This is to leave a bit of room for the required star, if needed.
    } as IStyle,
    comboInputFieldContainer: {
        position: 'relative',
        display: 'inline-block',
        margin: '0px 6px 0px 6px'
    } as IStyle,
    comboInputRequiredStar: {
        color: 'rgb(164, 38, 44)',
        fontSize: '16px',
        display: 'inline-block',
        verticalAlign: 'top',
        margin: '0 4px 0 0',
        position: 'absolute',
        top: 0,
        right: 0
    } as IStyle,
    instructionText: {
        marginTop: '4px',
        fontStyle: 'italic'
    } as IStyle,
    textField: {
        width: '200px'
    } as IStyle,
    comboBox: {
        width: '200px'
    } as IStyle,
    inputTooltipIcon: {
        fontSize: 16
    } as IStyle
});

/**
 * Combo input text field styles. A modification of textFieldStyles.
 */
export const comboInputTextFieldStyles: Partial<ITextFieldStyles> = {
    ...textFieldStyles,
    root: {
        ...(textFieldStyles as any).root,
        padding: 0
    } as IStyle
};

/**
 * Standalone input text field styles. A modification of textFieldStyles.
 */
export const standaloneInputTextFieldStyles: Partial<ITextFieldStyles> = {
    ...textFieldStyles,
    root: {
        ...(textFieldStyles as any).root,
        padding: 0,
        margin: 0
    } as IStyle
};

/**
 * Tooltip host styles.
 */
export const tooltipHostStyles: Partial<ITooltipHostStyles> = {
    root: {
        display: 'inline-block',
        position: 'absolute',
        right: '-5px',
        top: '5px'
    } as IStyle
}
