import { Program } from '../../../models/domainData/program';
import { serviceApiClient } from '../../../services/api/serviceApiClient';
import { AppDispatch, reduxStore } from '../../reduxStore';
import * as actionTypes from '../actionTypes';
import { callApi, CallApiState, ICallApiBase } from '../generic.action';

/**
 * Load programs action payload.
 */
export interface IApiLoadPrograms extends ICallApiBase {
    programs?: Program[] | null;
}

/**
 * Load programs.
 * @returns Redux dispatch function.
 */
export const callApiLoadPrograms = (): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<Program[]>(
        actionTypes.API_LOAD_PROGRAMS,
        async () => {
            return await serviceApiClient.programs() || [];
        },
        (payload: IApiLoadPrograms, data) => {
            if (data) {
                // Set the clientRowKey in each object so it can be bound into the DetailsList.
                data.forEach((value: Program, index: number) => {
                    value.clientRowKey = index.toString()
                });
            }

            payload.programs = data;
        }
    );
};

/**
 * Update program action payload.
 */
export interface IApiUpdateProgram extends ICallApiBase {
    // No data returned from this api.
}

/**
 * Update program.
 * @param program Program to update.
 * @returns Redux dispatch function.
 */
export const callApiUpdateProgram = (program: Program): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<null>(
        actionTypes.API_UPDATE_PROGRAM,
        async () => {
            return await serviceApiClient.updateProgram(program);
        },
        (payload: IApiUpdateProgram, data) => {
            // No data returned from this api.
        }
    );
};

/**
 * Add program action payload.
 */
export interface IApiAddProgram extends ICallApiBase {
    // No data returned from this api.
}

/**
 * Add program.
 * @param program Program to add.
 * @returns Redux dispatch function.
 */
export const callApiAddProgram = (program: Program): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<null>(
        actionTypes.API_ADD_PROGRAM,
        async () => {
            return await serviceApiClient.addProgram(program);
        },
        (payload: IApiAddProgram, data) => {
            // No data returned from this api.
        }
    );
};

/**
 * Check is msrecycling team member action payload.
 */
export interface IApiCheckIsMsRecyclingTeamMember extends ICallApiBase {
    isMsRecyclingTeamMember?: boolean | null;
}

/**
 * Check if user is an MS Recycling Team member.
 * @returns Redux dispatch function.
 */
export const callApiCheckIsMsRecyclingTeamMember = (): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<boolean>(
        actionTypes.API_CHECK_IS_MSRECYCLING_TEAM_MEMBER,
        async () => {
            return await serviceApiClient.sgCheck('MSRecycling Team');
        },
        (payload: IApiCheckIsMsRecyclingTeamMember, data) => {
            payload.isMsRecyclingTeamMember = data;
        }
    );
};

/**
 * Reset the page action call state for all api calls.
 * This clear all data from the Redux store for these calls, including any error state for failed calls.
 * This makes it so if there was a failed call and an error was shown on the page, if the user navigates
 * to another page and back, the prior error would not be shown.
 */
export const resetApiCallState = () => {
    const apiLoadAdminProgramTypes: IApiLoadPrograms = {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        programs: undefined
    };
    reduxStore.dispatch({
        type: actionTypes.API_LOAD_PROGRAMS,
        payload: apiLoadAdminProgramTypes
    });

    const apiUpdateProgram: IApiUpdateProgram = {
        callApiState: CallApiState.Initial,
        errMsg: undefined
    };
    reduxStore.dispatch({
        type: actionTypes.API_UPDATE_PROGRAM,
        payload: apiUpdateProgram
    });

    const apiAddProgram: IApiAddProgram = {
        callApiState: CallApiState.Initial,
        errMsg: undefined
    };
    reduxStore.dispatch({
        type: actionTypes.API_ADD_PROGRAM,
        payload: apiAddProgram
    });

    const apiCheckIsMsRecyclingTeamMember: IApiCheckIsMsRecyclingTeamMember = {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        isMsRecyclingTeamMember: undefined
    };
    reduxStore.dispatch({
        type: actionTypes.API_CHECK_IS_MSRECYCLING_TEAM_MEMBER,
        payload: apiCheckIsMsRecyclingTeamMember
    });
};
