import { PackagingMaterialType } from '../../../models/domainData/packagingMaterialType';
import { serviceApiClient } from '../../../services/api/serviceApiClient';
import { AppDispatch, reduxStore } from '../../reduxStore';
import * as actionTypes from '../actionTypes';
import { callApi, CallApiState, ICallApiBase } from '../generic.action';

/**
 * Load packaging material types action payload.
 */
export interface IApiLoadPackagingMaterialTypes extends ICallApiBase {
    packagingMaterialTypes?: PackagingMaterialType[] | null;
}

/**
 * Load packaging material types.
 * @returns Redux dispatch function.
 */
export const callApiLoadPackagingMaterialTypes = (programType: string): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<PackagingMaterialType[]>(
        actionTypes.API_LOAD_PACKAGING_MATERIAL_TYPES,
        async () => {
            return await serviceApiClient.packagingMaterialTypes(programType) || [];
        },
        (payload: IApiLoadPackagingMaterialTypes, data) => {
            if (data) {
                // Set the clientRowKey in each object so it can be bound into the DetailsList.
                data.forEach((value: PackagingMaterialType, index: number) => {
                    value.clientRowKey = index.toString()
                });
            }

            payload.packagingMaterialTypes = data;
        }
    );
};

/**
 * Update packaging material type action payload.
 */
export interface IApiUpdatePackagingMaterialType extends ICallApiBase {
    // No data returned from this api.
}

/**
 * Update packaging material type.
 * @param packagingMaterialType Packaging material type to update.
 * @returns Redux dispatch function.
 */
export const callApiUpdatePackagingMaterialType = (packagingMaterialType: PackagingMaterialType): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<null>(
        actionTypes.API_UPDATE_PACKAGING_MATERIAL_TYPE,
        async () => {
            return await serviceApiClient.updatePackagingMaterialType(packagingMaterialType);
        },
        (payload: IApiUpdatePackagingMaterialType, data) => {
            // No data returned from this api.
        }
    );
};

/**
 * Add packaging material type action payload.
 */
export interface IApiAddPackagingMaterialType extends ICallApiBase {
    // No data returned from this api.
}

/**
 * Add packaging material type.
 * @param packagingMaterialType Packaging material type to add.
 * @param programType Program type.
 * @returns Redux dispatch function.
 */
export const callApiAddPackagingMaterialType = (packagingMaterialType: PackagingMaterialType, programType: string): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<null>(
        actionTypes.API_ADD_PACKAGING_MATERIAL_TYPE,
        async () => {
            return await serviceApiClient.addPackagingMaterialType(packagingMaterialType, programType);
        },
        (payload: IApiAddPackagingMaterialType, data) => {
            // No data returned from this api.
        }
    );
};

/**
 * Delete packaging material type action payload.
 */
export interface IApiDeletePackagingMaterialType extends ICallApiBase {
    // No data returned from this api.
}

/**
 * Delete packaging material type.
 * @param packagingMaterialType Packaging material type to delete.
 * @param programType Program type.
 * @returns Redux dispatch function.
 */
export const callApiDeletePackagingMaterialType = (packagingMaterialType: PackagingMaterialType): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<null>(
        actionTypes.API_DELETE_PACKAGING_MATERIAL_TYPE,
        async () => {
            return await serviceApiClient.deletePackagingMaterialType(packagingMaterialType);
        },
        (payload: IApiDeletePackagingMaterialType, data) => {
            // No data returned from this api.
        }
    );
};

/**
 * Reset the page action call state for all api calls.
 * This clear all data from the Redux store for these calls, including any error state for failed calls.
 * This makes it so if there was a failed call and an error was shown on the page, if the user navigates
 * to another page and back, the prior error would not be shown.
 */
export const resetApiCallState = () => {
    const apiLoadPackagingMaterialTypes: IApiLoadPackagingMaterialTypes = {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        packagingMaterialTypes: undefined
    };
    reduxStore.dispatch({
        type: actionTypes.API_LOAD_PACKAGING_MATERIAL_TYPES,
        payload: apiLoadPackagingMaterialTypes
    });

    const apiUpdatePackagingMaterialTypes: IApiUpdatePackagingMaterialType = {
        callApiState: CallApiState.Initial,
        errMsg: undefined
    };
    reduxStore.dispatch({
        type: actionTypes.API_UPDATE_PACKAGING_MATERIAL_TYPE,
        payload: apiUpdatePackagingMaterialTypes
    });

    const apiAddPackagingMaterialTypes: IApiAddPackagingMaterialType = {
        callApiState: CallApiState.Initial,
        errMsg: undefined
    };
    reduxStore.dispatch({
        type: actionTypes.API_ADD_PACKAGING_MATERIAL_TYPE,
        payload: apiAddPackagingMaterialTypes
    });

    const apiDeletePackagingMaterialTypes: IApiDeletePackagingMaterialType = {
        callApiState: CallApiState.Initial,
        errMsg: undefined
    };
    reduxStore.dispatch({
        type: actionTypes.API_DELETE_PACKAGING_MATERIAL_TYPE,
        payload: apiDeletePackagingMaterialTypes
    });
};
