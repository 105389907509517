export interface ISupplierUnitType {
    partitionKey: string;
    rowKey: string;
    businessComments: string;
    supplierUnitType: string;
    microsoftUnitType: string;
    microsoftUnitTypeMapping: string;
}

export class SupplierUnitType implements ISupplierUnitType {
    public partitionKey: string;
    public rowKey: string;
    public businessComments: string;
    public supplierUnitType: string;
    public microsoftUnitType: string;
    public microsoftUnitTypeMapping: string;

    // Below are properties NOT returned from the server but are used for client side purposes.
    public clientRowKey: string = '';
    public isNew?: boolean;

    constructor(jsonData: ISupplierUnitType) {
        this.partitionKey = jsonData.partitionKey;
        this.rowKey = jsonData.rowKey;
        this.businessComments = jsonData.businessComments;
        this.supplierUnitType = jsonData.supplierUnitType;
        this.microsoftUnitType = jsonData.microsoftUnitType;
        this.microsoftUnitTypeMapping = jsonData.microsoftUnitTypeMapping;
    }
}
