import { AnyAction, Reducer } from 'redux';
import * as actionTypes from '../../actions/actionTypes';
import { CallApiState, StateUpdateOption } from '../../actions/generic.action';
import { IApiLoadCreditSummary } from '../../actions/pageActions/creditSummaryPage.action';

export interface ICreditSummaryPageReducerState {
    apiLoadCreditSummary: IApiLoadCreditSummary;
}

const initialCreditSummaryPageReducerState: ICreditSummaryPageReducerState = {
    apiLoadCreditSummary: {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        creditSummaries: undefined
    }
};

export const creditSummaryPageReducer: Reducer<ICreditSummaryPageReducerState, AnyAction> = (
    state: ICreditSummaryPageReducerState = initialCreditSummaryPageReducerState, action: AnyAction
): ICreditSummaryPageReducerState => {
    switch (action.type) {
        case actionTypes.API_LOAD_CREDIT_SUMMARY: {
            const payload: IApiLoadCreditSummary = action.payload as IApiLoadCreditSummary;
            switch (payload.stateUpdateOption) {
                case StateUpdateOption.ErrorOnly:
                    return {
                        ...state,
                        // Keep the prior payload except for the errMsg which will be set to undefined.
                        apiLoadCreditSummary: {
                            ...state.apiLoadCreditSummary,
                            errMsg: undefined
                        }
                    }
                default:
                    return {
                        ...state,
                        apiLoadCreditSummary: { ...payload }
                    }
            }
        }
        default:
    }

    return state;
};
