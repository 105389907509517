import React, { useState, useCallback, useEffect, useRef } from 'react';
import {
    Text,
    Spinner,
    SpinnerSize,
    SelectionMode,
    DetailsListLayoutMode,
    ConstrainMode,
    IColumn,
    IDetailsFooterProps,
    DetailsRow,
    Stack
} from '@fluentui/react';
import { commonStyles } from '../../common/common.styles';
import { ICommonPageProps } from '../../common/common.types';
import { assignOnColumnClick, ColumnsAndItems, commonColumnProps, resetColumnSorting, sortOnColumn } from '../../common/common.func';
import { clearErrorByIndex, ErrorBar, prepErrorMsg } from '../../components/ErrorBar/ErrorBar';
import { CustomDetailsList } from '../../components/CustomDetailsList/CustomDetailsList';
import { ComboInputOption, ISearchCriteria, SearchFilter } from '../../components/SearchFilter/SearchFilter';
import { appConstants, toLocaleStringTwoDigitFraction } from '../../common/appConstants';
import { resetApiCallState, callApiLoadInvoiceSummary, IApiLoadInvoiceSummary } from '../../store/actions/pageActions/invoiceSummaryPage.action';
import { CallApiState } from '../../store/actions/generic.action';
import { InvoiceSummary } from '../../models/supplier/invoiceSummary';
import { useAppSelector, useAppDispatch } from '../../store/hooks';
import { PageWrapper } from '../../components/PageWrapper/PageWrapper';

/*
The "SupplierWebMicroFrontend" project has a variation of this page. If changes are made here, consider if
equivalent changes are needed in that project as well. There are some differences between the projects, such as
localization and SupplierWeb integration in that project, so be careful when copying/integrating changes.
*/

interface IPageProps extends ICommonPageProps {
}

export const InvoiceSummaryPage: React.FunctionComponent<IPageProps> = (props: IPageProps): JSX.Element => {
    const [errors, setErrors] = useState<string[]>([]);
    const [selectedPageInvoiceSummaryTable, setSelectedPageInvoiceSummaryTable] = useState<number>(1);
    const [invoiceSummary, setInvoiceSummary] = useState<InvoiceSummary[] | null>();

    const grandTotals = useRef<{
        sumInvoiceAmount?: number;
        sumInvoiceAmountUSD?: number;
        sumLogisticsFee?: number;
        sumLogisticsFeeUSD?: number;
        sumMinimumServiceFee?: number;
        sumMinimumServiceFeeUSD?: number;
    }>({});

    // Redux store selectors to get state from the store when it changes.
    const apiLoadInvoiceSummary: IApiLoadInvoiceSummary =
        useAppSelector<IApiLoadInvoiceSummary>((state) => state.invoiceSummaryPageReducer.apiLoadInvoiceSummary);

    // Redux store dispatch to send actions to the store.
    const dispatch = useAppDispatch();

    /**
     * This effect does nothing on mount, but will return a cleanup function that runs when the component unmounts.
     */
    useEffect(() => {
        return function cleanup() {
            resetApiCallState();
        }
    }, []);

    /**
     * Handle error.
     * @param errMsg Error message.
     */
    const handleError = useCallback((errMsg: string) => {
        setErrors((prevErrors) => {
            // This will prevent the same error from being displayed if already displayed.
            // ex: Multiple page data load failures might occur if the api is not working,
            // and this page makes multiple load calls for various data.
            if (!prevErrors.includes(errMsg)) {
                return [...prevErrors, errMsg];
            }
            return prevErrors;
        });
    }, []);

    /**
     * Effect for when errors occur in any api call.
     */
    useEffect(() => {
        if (apiLoadInvoiceSummary.errMsg) {
            handleError(apiLoadInvoiceSummary.errMsg);
        }
    }, [handleError, apiLoadInvoiceSummary.errMsg]);

    /**
     * Search clicked event handler.
     * @param searchCriteria Search criteria.
     */
    const searchClicked = (searchCriteria: ISearchCriteria) => {
        setInvoiceSummary(undefined);

        setSelectedPageInvoiceSummaryTable(1);

        // The search filter is set to use the calendar (not fiscal) year and month picker, see showCalendarYearMonth on searchFilterOptions.
        // The search criteria will contain a startDate and endDate based on the selection, for the start day of the month
        // to the end day of the month. But we just need the year and month here so we just pick that off the startDate.
        const year: number = searchCriteria.startDate.getFullYear();
        const month: number = searchCriteria.startDate.getMonth() + 1; // getMonth returns zero based month, but api expects 1 based.

        if (searchCriteria.programType && searchCriteria.supplierId) {
            dispatch(callApiLoadInvoiceSummary(
                searchCriteria.programType,
                searchCriteria.supplierId,
                year,
                month,
                searchCriteria.supplierJobId,
                searchCriteria.supplierInvoiceNumber
            ));
        }
    };

    /**
     * Search filter load error handler.
     * @param err Error
     */
    const searchFilterLoadError = (err: any) => {
        handleError(prepErrorMsg(appConstants.dataLoadFailed, err));
    };

    /**
     * Render right aligned currency column text. Currency values will use 2 digit precision.
     * @param item Item data.
     * @param index Index of item.
     * @param column Column being rendered.
     */
    const renderRightAlignedCurrencyColumnText = (item?: any, index?: number, column?: IColumn) => {
        if (index === undefined || index === null) {
            return;
        }

        if (!item.isDetailRow) {
            // If not a detail row then it is rendering a normal row cell.
            return (
                <div style={{ textAlign: 'right' }}>{item[column!.fieldName as string]?.toLocaleString(undefined, toLocaleStringTwoDigitFraction)}</div>
            );
        } else {
            // Render a detail row with totals.
            return (
                <div style={{ textAlign: 'right', fontWeight: 'bold' }}>
                    {column?.fieldName === 'invoiceAmount' && <>{grandTotals.current.sumInvoiceAmount?.toLocaleString(undefined, toLocaleStringTwoDigitFraction)}</>}
                    {column?.fieldName === 'invoiceAmountUSD' && <>{grandTotals.current.sumInvoiceAmountUSD?.toLocaleString(undefined, toLocaleStringTwoDigitFraction)}</>}
                    {column?.fieldName === 'logisticsFee' && <>{grandTotals.current.sumLogisticsFee?.toLocaleString(undefined, toLocaleStringTwoDigitFraction)}</>}
                    {column?.fieldName === 'logisticsFeeUSD' && <>{grandTotals.current.sumLogisticsFeeUSD?.toLocaleString(undefined, toLocaleStringTwoDigitFraction)}</>}
                    {column?.fieldName === 'minimumServiceFee' && <>{grandTotals.current.sumMinimumServiceFee?.toLocaleString(undefined, toLocaleStringTwoDigitFraction)}</>}
                    {column?.fieldName === 'minimumServiceFeeUSD' && <>{grandTotals.current.sumMinimumServiceFeeUSD?.toLocaleString(undefined, toLocaleStringTwoDigitFraction)}</>}
                </div>
            );
        }
    };

    /**
     * Columns for the Invoice Summary table.
     */
    const [columnsInvoiceSummaryTable, setColumnsInvoiceSummaryTable] = useState<IColumn[]>(
        [
            {
                ...commonColumnProps,
                key: 'column1',
                name: 'Job ID',
                fieldName: 'supplierJobId',
                minWidth: 75
            },
            {
                ...commonColumnProps,
                key: 'column2',
                name: 'Job Type',
                fieldName: 'supplierJobType',
                minWidth: 75
            },
            {
                ...commonColumnProps,
                key: 'column3',
                name: 'Supplier Reporting Date',
                fieldName: 'supplierReportingDate',
                minWidth: 150,
                onRender: (item: InvoiceSummary) => {
                    return <span>{item.supplierReportingDate?.toLocaleDateString()}</span>;
                }
            },
            {
                ...commonColumnProps,
                key: 'column4',
                name: 'Collection Country',
                fieldName: 'collectionCountry',
                minWidth: 100
            },
            {
                ...commonColumnProps,
                key: 'column5',
                name: 'Billing Country',
                fieldName: 'billingCountry',
                minWidth: 80
            },
            {
                ...commonColumnProps,
                key: 'column6',
                name: 'MS Company Code',
                fieldName: 'msCompanyCode',
                minWidth: 100
            },
            {
                ...commonColumnProps,
                key: 'column7',
                name: 'MS PO Number',
                fieldName: 'msPoNumber',
                minWidth: 80
            },
            {
                ...commonColumnProps,
                key: 'column8',
                name: 'MS PO Currency',
                fieldName: 'msPoCurrency',
                minWidth: 80
            },
            {
                ...commonColumnProps,
                key: 'column9',
                name: 'Supplier Billing Entity',
                fieldName: 'supplierBillingEntity',
                minWidth: 120
            },
            {
                ...commonColumnProps,
                key: 'column10',
                name: 'Supplier Invoice Number',
                fieldName: 'supplierInvoiceNumber',
                minWidth: 140
            },
            {
                ...commonColumnProps,
                key: 'column11',
                name: 'Invoice Amount',
                fieldName: 'invoiceAmount',
                minWidth: 80,
                onRender: renderRightAlignedCurrencyColumnText
            },
            {
                ...commonColumnProps,
                key: 'column12',
                name: 'Invoice Amount USD',
                fieldName: 'invoiceAmountUSD',
                minWidth: 116,
                onRender: renderRightAlignedCurrencyColumnText
            },
            {
                ...commonColumnProps,
                key: 'column13',
                name: 'Logistics Fee',
                fieldName: 'logisticsFee',
                minWidth: 70,
                onRender: renderRightAlignedCurrencyColumnText
            },
            {
                ...commonColumnProps,
                key: 'column14',
                name: 'Logistics Fee USD',
                fieldName: 'logisticsFeeUSD',
                minWidth: 106,
                onRender: renderRightAlignedCurrencyColumnText
            },
            {
                ...commonColumnProps,
                key: 'column15',
                name: 'Minimum Service Fee',
                fieldName: 'minimumServiceFee',
                minWidth: 120,
                onRender: renderRightAlignedCurrencyColumnText
            },
            {
                ...commonColumnProps,
                key: 'column16',
                name: 'Minimum Service Fee USD',
                fieldName: 'minimumServiceFeeUSD',
                minWidth: 156,
                onRender: renderRightAlignedCurrencyColumnText
            }
        ]
    );

    /**
     * Effect for when Invoice Summary data is loaded.
     */
    useEffect(() => {
        if (apiLoadInvoiceSummary.callApiState === CallApiState.DataAvailable) {
            const searchResults: InvoiceSummary[] | null | undefined = apiLoadInvoiceSummary.invoiceSummaries;
            setInvoiceSummary(searchResults);
            setColumnsInvoiceSummaryTable(resetColumnSorting(columnsInvoiceSummaryTable));

            let sumInvoiceAmount: number = 0;
            let sumInvoiceAmountUSD: number = 0;
            let sumLogisticsFee: number = 0;
            let sumLogisticsFeeUSD: number = 0;
            let sumMinimumServiceFee: number = 0;
            let sumMinimumServiceFeeUSD: number = 0;
            if (searchResults) {
                searchResults.forEach((value: InvoiceSummary, index: number) => {
                    sumInvoiceAmount += (value.invoiceAmount || 0);
                    sumInvoiceAmountUSD += (value.invoiceAmountUSD || 0);
                    sumLogisticsFee += (value.logisticsFee || 0);
                    sumLogisticsFeeUSD += (value.logisticsFeeUSD || 0);
                    sumMinimumServiceFee += (value.minimumServiceFee || 0);
                    sumMinimumServiceFeeUSD += (value.minimumServiceFeeUSD || 0);
                    value.clientRowKey = index.toString();
                });
            }

            grandTotals.current = { sumInvoiceAmount, sumInvoiceAmountUSD, sumLogisticsFee, sumLogisticsFeeUSD, sumMinimumServiceFee, sumMinimumServiceFeeUSD };
        }
    }, [columnsInvoiceSummaryTable, apiLoadInvoiceSummary.callApiState, apiLoadInvoiceSummary.invoiceSummaries]);

    /**
     * On column click event handler for Invoice Summary table.
     * @param ev Mouse event.
     * @param column Column clicked.
     */
    const onColumnClickInvoiceSummaryTable = useCallback((ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        if (invoiceSummary) {
            const columnsAndItems: ColumnsAndItems<InvoiceSummary> = sortOnColumn<InvoiceSummary>(column, columnsInvoiceSummaryTable, invoiceSummary, undefined);
            setInvoiceSummary(columnsAndItems.items);
            setColumnsInvoiceSummaryTable(columnsAndItems.columns);
        }
    }, [columnsInvoiceSummaryTable, invoiceSummary]);

    /**
     * Effect to update onColumnClick handler. Otherwise the component state referenced in onColumnClick
     * would be stale from the render pass it was created on.
     */
    useEffect(() => {
        assignOnColumnClick(columnsInvoiceSummaryTable, onColumnClickInvoiceSummaryTable);
    }, [columnsInvoiceSummaryTable, onColumnClickInvoiceSummaryTable]);

    /**
     * Renders details footer row.
     * @param detailsFooterProps Details footer props.
     */
    const onRenderDetailsFooter = (detailsFooterProps: IDetailsFooterProps | undefined): JSX.Element => {
        if (!detailsFooterProps) {
            return <></>;
        }

        return (
            <DetailsRow
                {...detailsFooterProps}
                columns={detailsFooterProps.columns}
                item={{ isDetailRow: true }}
                itemIndex={-1}
                groupNestingDepth={detailsFooterProps.groupNestingDepth}
                selectionMode={SelectionMode.none}
                selection={detailsFooterProps.selection}
                onRenderCheck={() => <></>}
            />
        );
    };

    return (
        <PageWrapper {...props}>
            <ErrorBar errors={errors} onDismiss={(index: number) => {
                setErrors(clearErrorByIndex(errors, index));
            }} />

            <div className='ms-Grid' dir="ltr">
                <div className='ms-Grid-row'>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <div className={commonStyles.pageHeader}>
                            <Text variant="xLarge" role="heading" aria-level={1}>Invoice Summary</Text>
                        </div>
                    </div>
                </div>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <SearchFilter
                            programs={props.programs || []}
                            searchFilterOptions={{
                                showFiscalRange: false,
                                fiscalRangeFieldsRequired: false,
                                showCalendarYearMonth: true,
                                showProgram: true,
                                showSupplierName: true,
                                showComboInputGroup: ComboInputOption.None,
                                showSupplierJobId: true,
                                showSupplierPoNumber: false,
                                showSupplierInvoiceNumber: true,
                                showCorrelationId: false,
                                showMsInvoiceNumber: false,
                                showSupplierUnitType: false,
                                showJobUnit: false,
                                showCountry: false,
                                showComboAssetTagAndSerialNumber: false,
                                showUnitDispositionType: false,
                                showUnitStatus: false
                            }}
                            onSearchClicked={searchClicked}
                            onSearchFilterLoadError={searchFilterLoadError} />
                    </div>
                </div>
                <div className="ms-Grid-row" style={{ marginTop: '24px' }}>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        { apiLoadInvoiceSummary.callApiState !== CallApiState.Initial && (
                            <>
                                <Stack style={{ marginRight: '1px' }}>
                                    { apiLoadInvoiceSummary.callApiState === CallApiState.Running && (
                                        <Spinner size={SpinnerSize.medium} className={commonStyles.spinner} />
                                    )}

                                    { apiLoadInvoiceSummary.callApiState === CallApiState.Completed && (
                                        <CustomDetailsList
                                            showExcelExport={true}
                                            exportExcelSheetName="Invoice Summary"
                                            ariaLabelForGrid="Invoice Summary"
                                            displayTotalItems={true}
                                            showPaginator={true}
                                            showPageSize={true}
                                            selectedPage={selectedPageInvoiceSummaryTable}
                                            onSelectedPageChange={(page) => {
                                                setSelectedPageInvoiceSummaryTable(page);
                                            }}
                                            showNoDataFoundMsg={!invoiceSummary || invoiceSummary.length === 0}
                                            items={invoiceSummary || []}
                                            compact={false}
                                            columns={columnsInvoiceSummaryTable}
                                            selectionMode={SelectionMode.none}
                                            getKey={(item: InvoiceSummary) => item.clientRowKey}
                                            setKey="none"
                                            layoutMode={DetailsListLayoutMode.fixedColumns}
                                            isHeaderVisible={true}
                                            constrainMode={ConstrainMode.horizontalConstrained}
                                            onRenderDetailsFooter={onRenderDetailsFooter} />
                                    )}
                                </Stack>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </PageWrapper>
    );
};
