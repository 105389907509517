import { SupplierInvoiceInfo } from './supplierInvoiceInfo';
import { SupplierJobDetails } from './supplierJobDetails';
import { SupplierPurchaseOrderInfo } from './supplierPurchaseOrderInfo';

export interface IJobSearchSummary {
    supplierJobDetails?: SupplierJobDetails[];
    supplierInvoiceSummary?: SupplierInvoiceInfo[];
    supplierPurchaseOrderSummary?: SupplierPurchaseOrderInfo[];
}

export class JobSearchSummary implements IJobSearchSummary {
    public supplierJobDetails?: SupplierJobDetails[];
    public supplierInvoiceSummary?: SupplierInvoiceInfo[];
    public supplierPurchaseOrderSummary?: SupplierPurchaseOrderInfo[];

    // Below are properties NOT returned from the server but are used for client side purposes.
    public clientRowKey: string = '';

    constructor(jsonData: IJobSearchSummary) {
        this.supplierJobDetails = jsonData.supplierJobDetails ?
            jsonData.supplierJobDetails.map((value: SupplierJobDetails) => {
                return new SupplierJobDetails(value)
            })
            : undefined;

        this.supplierInvoiceSummary = jsonData.supplierInvoiceSummary ?
            jsonData.supplierInvoiceSummary.map((value: SupplierInvoiceInfo) => {
                return new SupplierInvoiceInfo(value)
            })
            : undefined;

        this.supplierPurchaseOrderSummary = jsonData.supplierPurchaseOrderSummary ?
            jsonData.supplierPurchaseOrderSummary.map((value: SupplierPurchaseOrderInfo) => {
                return new SupplierPurchaseOrderInfo(value)
            })
            : undefined;
    }
}
