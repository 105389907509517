export interface IDisposition {
    correlationId: string;
    supplierReportingDate?: Date;
    unitStatus: string;
    dispositionDate?: Date;
    dispositionType: string;
    returnType: string;
    wipeDate?: Date;
    wipeCertificateNumber: string;
    recycleCertificateNumber: string;
    triageCompleteDate?: Date;
    triageFailureReasons: string;
    quantity: number;
    weightInKilos?: number;
    assetAdditionalDetails: string;
    salePrice?: number;
    totalUnitFees?: number;
    salePriceUSD?: number;
    totalUnitFeesUSD?: number;
    lastModifiedDate: Date;
}

export class Disposition implements IDisposition {
    public correlationId: string;
    public supplierReportingDate?: Date;
    public unitStatus: string;
    public dispositionDate?: Date;
    public dispositionType: string;
    public returnType: string;
    public wipeDate?: Date;
    public wipeCertificateNumber: string;
    public recycleCertificateNumber: string;
    public triageCompleteDate?: Date;
    public triageFailureReasons: string;
    public quantity: number;
    public weightInKilos?: number;
    public assetAdditionalDetails: string;
    public salePrice?: number;
    public totalUnitFees?: number;
    public salePriceUSD?: number;
    public totalUnitFeesUSD?: number;
    public lastModifiedDate: Date;

    constructor(jsonData: IDisposition) {
        this.correlationId = jsonData.correlationId;
        this.supplierReportingDate = jsonData.supplierReportingDate ? new Date(jsonData.supplierReportingDate) : undefined;
        this.unitStatus = jsonData.unitStatus;
        this.dispositionDate = jsonData.dispositionDate ? new Date(jsonData.dispositionDate) : undefined;
        this.dispositionType = jsonData.dispositionType;
        this.returnType = jsonData.returnType;
        this.wipeDate = jsonData.wipeDate ? new Date(jsonData.wipeDate) : undefined;
        this.wipeCertificateNumber = jsonData.wipeCertificateNumber;
        this.recycleCertificateNumber = jsonData.recycleCertificateNumber;
        this.triageCompleteDate = jsonData.triageCompleteDate ? new Date(jsonData.triageCompleteDate) : undefined;
        this.triageFailureReasons = jsonData.triageFailureReasons;
        this.quantity = jsonData.quantity;
        this.weightInKilos = jsonData.weightInKilos;
        this.assetAdditionalDetails = jsonData.assetAdditionalDetails;
        this.salePrice = jsonData.salePrice;
        this.totalUnitFees = jsonData.totalUnitFees;
        this.salePriceUSD = jsonData.salePriceUSD;
        this.totalUnitFeesUSD = jsonData.totalUnitFeesUSD;
        this.lastModifiedDate = jsonData.lastModifiedDate;
    }
}
