import { FontWeights, IButtonStyles, IStyle, mergeStyleSets } from '@fluentui/react';

/**
 * Styles used on this control.
 */
export const controlStyles = mergeStyleSets({
    detailTableContainer: {
        overflow: 'auto',
        height: '380px',
        margin: '10px 0 0 0'
    } as IStyle,
    detailTable: {
        textAlign: 'left',
        borderCollapse: 'collapse',
        width: '100%',
        td: {
            textAlign: 'left'
        },
        'td:first-child': {
            width: 'auto',
            minWidth: '180px',
            whiteSpace: 'nowrap',
            padding: '2px 14px 2px 4px'
        },
        'td:last-child': {
            width: '100%',
            padding: '2px 8px 2px 4px'
        }
    } as IStyle,
    slaNotMet: {
        color: '#AD0000'
    } as IStyle,
    slaMet: {
        color: '#2B5F2C'
    } as IStyle,
    detailsModalScrollable: {
        overflow: 'hidden',
        maxHeight: 'initial'
    } as IStyle,
    detailsModalContainer: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
        width: '800px',
        minWidth: '800px',
        maxWidth: '800px',
        height: '500px',
        minHeight: '500px',
        maxHeight: '500px'
    } as IStyle,
    detailsModalHeader: {
        flex: '1 1 auto',
        display: 'flex',
        alignItems: 'center',
        fontWeight: FontWeights.semibold,
        padding: '0px 12px 0px 14px',
        height: '40px'
    } as IStyle,
    detailsModalBody: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px'
    } as IStyle
});

/**
 * Details modal icon button styles.
 */
export const detailsModalIconButtonStyles: IButtonStyles = {
    root: {
        color: 'gray',
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px'
    } as IStyle,
    rootHovered: {
        color: 'darkgray'
    } as IStyle
};
