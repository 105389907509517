import React, { useEffect } from 'react';
import {
    Pivot,
    PivotItem,
    Stack,
    Text
} from '@fluentui/react';
import { useLocation, useNavigate } from 'react-router';
import { commonStyles, stackTokens } from '../../common/common.styles';
import { ICommonPageProps } from '../../common/common.types';
import { resetApiCallState } from '../../store/actions/pageActions/adminPage.action';
import { AdminTabPrograms } from './AdminTabPrograms';
import { AdminTabSuppliers } from './AdminTabSuppliers';
import { AdminTabSupplierUnitTypes } from './AdminTabSupplierUnitTypes';
import { AdminTabSupplierJobTypes } from './AdminTabSupplierJobTypes';
import { AdminTabPackagingMaterialTypes } from './AdminTabPackagingMaterialTypes';
import { AdminTabDestructionTypes } from './AdminTabDestructionTypes';
import { AdminTabUnitStatuses } from './AdminTabUnitStatuses';
import { AdminTabUnitDispositionTypes } from './AdminTabUnitDispositionTypes';
import { PageWrapper } from '../../components/PageWrapper/PageWrapper';

interface IPageProps extends ICommonPageProps {
}

export const AdminPage: React.FunctionComponent<IPageProps> = (props: IPageProps): JSX.Element => {
    const location = useLocation();
    const navigate = useNavigate();

    /**
     * This effect does nothing on mount, but will return a cleanup function that runs when the component unmounts.
     */
    useEffect(() => {
        return function cleanup() {
            resetApiCallState();
        }
    }, []);

    return (
        <PageWrapper {...props}>           
            <div className="ms-Grid" dir="ltr">
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <div className={commonStyles.pageHeader}>
                            <Text variant="xLarge" role="heading" aria-level={1}>Administration</Text>
                        </div>
                    </div>
                </div>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <Stack tokens={stackTokens}>
                            <Stack.Item>
                                <Pivot
                                    overflowBehavior='menu'
                                    defaultSelectedKey={location.hash.substring(1)/* The substring is to get past the hash # */}
                                    onLinkClick={(item?: PivotItem, ev?: React.MouseEvent<HTMLElement>) => {
                                        // Navigates to this same page but with the itemKey as the hash.
                                        // This is to make it so page refreshes will remain on the same pivot tab.
                                        // Or it can be bookmarked to that tab.
                                        navigate(`${location.pathname}#${item?.props.itemKey}`);
                                    }}>
                                    <PivotItem headerText="Programs" itemKey="Programs">
                                        <AdminTabPrograms
                                            programs={props.programs || []}
                                        />
                                    </PivotItem>
                                    <PivotItem headerText="Suppliers" itemKey="Suppliers">
                                        <AdminTabSuppliers
                                            programs={props.programs || []}
                                        />
                                    </PivotItem>
                                    <PivotItem headerText="Supplier Unit Types" itemKey="SupplierUnitTypes">
                                        <AdminTabSupplierUnitTypes
                                            programs={props.programs || []}
                                        />
                                    </PivotItem>
                                    <PivotItem headerText="Supplier Job Types" itemKey="SupplierJobTypes">
                                        <AdminTabSupplierJobTypes
                                            programs={props.programs || []}
                                        />
                                    </PivotItem>
                                    <PivotItem headerText="Packaging Material Types" itemKey="PackagingMaterialTypes">
                                        <AdminTabPackagingMaterialTypes
                                            programs={props.programs || []}
                                        />
                                    </PivotItem>
                                    <PivotItem headerText="Destruction Types" itemKey="DestructionTypes">
                                        <AdminTabDestructionTypes
                                            programs={props.programs || []}
                                        />
                                    </PivotItem>
                                    <PivotItem headerText="Unit Statuses" itemKey="UnitStatuses">
                                        <AdminTabUnitStatuses
                                            programs={props.programs || []}
                                        />
                                    </PivotItem>
                                    <PivotItem headerText="Unit Disposition Types" itemKey="UnitDispositionTypes">
                                        <AdminTabUnitDispositionTypes
                                            programs={props.programs || []}
                                        />
                                    </PivotItem>
                                    <PivotItem headerText="Active / Close Status" itemKey="ActiveCloseStatus">
                                        coming soon
                                    </PivotItem>
                                </Pivot>
                            </Stack.Item>
                        </Stack>
                    </div>
                </div>
            </div>
        </PageWrapper>
    );
}
