import { RecycledPeripheral } from './recycledPeripheral';
import { OfficeLocation } from './officeLocation';

export interface IUserPeripheralsSummary {
    alias: string;
    officeLocation: OfficeLocation;
    donatedPeripherals: RecycledPeripheral[];
    takenPeripherals: RecycledPeripheral[];
}

export class UserPeripheralsSummary implements IUserPeripheralsSummary {
    public alias: string;
    public officeLocation: OfficeLocation;
    public donatedPeripherals: RecycledPeripheral[] = [];
    public takenPeripherals: RecycledPeripheral[] = [];

    // Below are properties NOT returned from the server but are used for client side purposes.
    public clientRowKey: string = '';

    constructor(jsonData: IUserPeripheralsSummary) {
        this.alias = jsonData.alias;
        this.officeLocation = new OfficeLocation(jsonData.officeLocation);

        for (let i: number = 0; i < jsonData.donatedPeripherals.length; i++) {
            this.donatedPeripherals.push(new RecycledPeripheral(jsonData.donatedPeripherals[i]));
        }

        for (let i: number = 0; i < jsonData.takenPeripherals.length; i++) {
            this.takenPeripherals.push(new RecycledPeripheral(jsonData.takenPeripherals[i]));
        }
    }
}
