export interface ICarbonFootprintCategory {
    category: string;
}

export class CarbonFootprintCategory implements ICarbonFootprintCategory {
    public category: string;

    constructor(jsonData: ICarbonFootprintCategory) {
        this.category = jsonData.category;
    }
}
