export const API_LOAD_TOTAL_JOBS_REPORTED = 'API_LOAD_TOTAL_JOBS_REPORTED';
export const API_LOAD_SUPPLIER_API_INTERACTIONS = 'API_LOAD_SUPPLIER_API_INTERACTIONS';
export const API_LOAD_SUPPLIER_TRENDS = 'API_LOAD_SUPPLIER_TRENDS';
export const API_LOAD_LATEST_SUPPLIER_ACTIVITY_BY_PROGRAM_TYPE = 'API_LOAD_LATEST_SUPPLIER_ACTIVITY_BY_PROGRAM_TYPE';
export const API_LOAD_REPORTS = 'API_LOAD_REPORTS';
export const API_LOAD_CARBON_FOOTPRINT_CATEGORIES = 'API_LOAD_CARBON_FOOTPRINT_CATEGORIES';
export const API_LOAD_CARBON_FOOTPRINT = 'API_LOAD_CARBON_FOOTPRINT';
export const API_LOAD_RECYCLING_CERTIFICATES = 'API_LOAD_RECYCLING_CERTIFICATES';
export const API_LOAD_PO_DOCUMENTS = 'API_LOAD_PO_DOCUMENTS';
export const API_LOAD_INVOICE_DOCUMENTS = 'API_LOAD_INVOICE_DOCUMENTS';
export const API_LOAD_DATA_ERASURE_CERTIFICATES = 'API_LOAD_DATA_ERASURE_CERTIFICATES';
export const API_DOWNLOAD_BLOB_STREAM = 'API_DOWNLOAD_BLOB_STREAM';
export const API_LOAD_SUPPLIER_SUMMARY = 'API_LOAD_SUPPLIER_SUMMARY';
export const API_LOAD_JOB_UNIT_DETAILS = 'API_LOAD_JOB_UNIT_DETAILS';
export const API_JOB_SEARCH = 'API_JOB_SEARCH';
export const API_LOAD_PAYMENT_NOTIFICATIONS = 'API_LOAD_PAYMENT_NOTIFICATIONS';
export const API_LOAD_DBD_REPORT = 'API_LOAD_DBD_REPORT';
export const API_LOAD_INVOICE_SUMMARY = 'API_LOAD_INVOICE_SUMMARY';
export const API_LOAD_CREDIT_SUMMARY = 'API_LOAD_CREDIT_SUMMARY';
export const API_LOAD_TRANSPORT_AND_PACKAGING = 'API_LOAD_TRANSPORT_AND_PACKAGING';
export const API_CHECK_IS_MSRECYCLING_TEAM_MEMBER = 'API_CHECK_IS_MSRECYCLING_TEAM_MEMBER';

export const API_LOAD_PROGRAMS = 'API_LOAD_PROGRAMS';
export const API_UPDATE_PROGRAM = 'API_UPDATE_PROGRAM';
export const API_ADD_PROGRAM = 'API_ADD_PROGRAM';

export const API_LOAD_SUPPLIERS = 'API_LOAD_SUPPLIERS';
export const API_UPDATE_SUPPLIER = 'API_UPDATE_SUPPLIER';
export const API_ADD_SUPPLIER = 'API_ADD_SUPPLIER';

export const API_LOAD_SUPPLIER_UNIT_TYPES = 'API_LOAD_SUPPLIER_UNIT_TYPES';
export const API_UPDATE_SUPPLIER_UNIT_TYPE = 'API_UPDATE_SUPPLIER_UNIT_TYPE';
export const API_ADD_SUPPLIER_UNIT_TYPE = 'API_ADD_SUPPLIER_UNIT_TYPE';
export const API_DELETE_SUPPLIER_UNIT_TYPE = 'API_DELETE_SUPPLIER_UNIT_TYPE';

export const API_LOAD_SUPPLIER_JOB_TYPES = 'API_LOAD_SUPPLIER_JOB_TYPES';
export const API_UPDATE_SUPPLIER_JOB_TYPE = 'API_UPDATE_SUPPLIER_JOB_TYPE';
export const API_ADD_SUPPLIER_JOB_TYPE = 'API_ADD_SUPPLIER_JOB_TYPE';
export const API_DELETE_SUPPLIER_JOB_TYPE = 'API_DELETE_SUPPLIER_JOB_TYPE';

export const API_LOAD_PACKAGING_MATERIAL_TYPES = 'API_LOAD_PACKAGING_MATERIAL_TYPES';
export const API_UPDATE_PACKAGING_MATERIAL_TYPE = 'UAPI_PDATE_PACKAGING_MATERIAL_TYPE';
export const API_ADD_PACKAGING_MATERIAL_TYPE = 'API_ADD_PACKAGING_MATERIAL_TYPE';
export const API_DELETE_PACKAGING_MATERIAL_TYPE = 'API_DELETE_PACKAGING_MATERIAL_TYPE';

export const API_LOAD_DESTRUCTION_TYPES = 'API_LOAD_DESTRUCTION_TYPES';
export const API_UPDATE_DESTRUCTION_TYPE = 'API_UPDATE_DESTRUCTION_TYPE';
export const API_ADD_DESTRUCTION_TYPE = 'API_ADD_DESTRUCTION_TYPE';
export const API_DELETE_DESTRUCTION_TYPE = 'API_DELETE_DESTRUCTION_TYPE';

export const API_LOAD_UNIT_STATUSES = 'API_LOAD_UNIT_STATUSES';
export const API_UPDATE_UNIT_STATUS = 'API_UPDATE_UNIT_STATUS';
export const API_ADD_UNIT_STATUS = 'API_ADD_UNIT_STATUS';
export const API_DELETE_UNIT_STATUS = 'API_DELETE_UNIT_STATUS';

export const API_LOAD_UNIT_DISPOSITION_TYPES = 'API_LOAD_UNIT_DISPOSITION_TYPES';
export const API_UPDATE_UNIT_DISPOSITION_TYPE = 'API_UPDATE_UNIT_DISPOSITION_TYPE';
export const API_ADD_UNIT_DISPOSITION_TYPE = 'API_ADD_UNIT_DISPOSITION_TYPE';
export const API_DELETE_UNIT_DISPOSITION_TYPE = 'API_DELETE_UNIT_DISPOSITION_TYPE';

export const API_LOAD_OPEN_AI_SAMPLE_QUESTIONS = 'API_LOAD_OPEN_AI_SAMPLE_QUESTIONS';
export const API_ASK_OPEN_AI = 'API_ASK_OPEN_AI';

export const COPILOT_PANEL_IS_OPEN = 'COPILOT_PANEL_IS_OPEN';
export const NAV_IS_OPEN = 'NAV_IS_OPEN';
