import { CarbonFootprint } from '../../../models/carbonFootprint/carbonFootprint';
import { CarbonFootprintCategory } from '../../../models/carbonFootprint/carbonFootprintCategory';
import { serviceApiClient } from '../../../services/api/serviceApiClient';
import { AppDispatch, reduxStore } from '../../reduxStore';
import * as actionTypes from '../actionTypes';
import { callApi, CallApiState, ICallApiBase } from '../generic.action';

/**
 * Load carbon footprint categories action payload.
 */
export interface IApiLoadCarbonFootprintCategories extends ICallApiBase {
    carbonFootprintCategories?: CarbonFootprintCategory[] | null;
}

/**
 * 
 * @returns Load carbon footprint categories.
 */
export const loadCarbonFootprintCategories = () => {
    return callApi<CarbonFootprintCategory[]>(
        actionTypes.API_LOAD_CARBON_FOOTPRINT_CATEGORIES,
        async () => {
            return await serviceApiClient.carbonFootprintCategories() || [];
        },
        (payload: IApiLoadCarbonFootprintCategories, data) => {
            payload.carbonFootprintCategories = data;
        }
    );
};

/**
 * Load carbon footprint action payload.
 */
export interface IApiLoadCarbonFootprint extends ICallApiBase {
    carbonFootprint?: CarbonFootprint[] | null;
}

/**
 * Load carbon footprint.
 * @param unitCategory Unit category.
 * @param unitCount Unit count.
 * @returns Redux dispatch function.
 */
export const loadCarbonFootprint = (unitCategory: string | undefined, unitCount: number | undefined): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<CarbonFootprint[]>(
        actionTypes.API_LOAD_CARBON_FOOTPRINT,
        async () => {
            return await serviceApiClient.carbonFootprint(unitCategory, unitCount) || [];
        },
        (payload: IApiLoadCarbonFootprint, data) => {
            if (data) {
                // Set the clientRowKey in each object so it can be bound into the DetailsList.
                data.forEach((value: CarbonFootprint, index: number) => {
                    value.clientRowKey = index.toString()
                });
            }

            payload.carbonFootprint = data;
        }
    );
};

/**
 * Reset the page action call state for all api calls.
 * This clear all data from the Redux store for these calls, including any error state for failed calls.
 * This makes it so if there was a failed call and an error was shown on the page, if the user navigates
 * to another page and back, the prior error would not be shown.
 */
export const resetApiCallState = () => {
    const apiLoadCarbonFootprintCategories: IApiLoadCarbonFootprintCategories = {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        carbonFootprintCategories: undefined
    };
    reduxStore.dispatch({
        type: actionTypes.API_LOAD_CARBON_FOOTPRINT_CATEGORIES,
        payload: apiLoadCarbonFootprintCategories
    });

    const apiLoadCarbonFootprint: IApiLoadCarbonFootprint = {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        carbonFootprint: undefined
    };
    reduxStore.dispatch({
        type: actionTypes.API_LOAD_CARBON_FOOTPRINT,
        payload: apiLoadCarbonFootprint
    });
};
