import { appConfig } from '../../shell/appConfig';
import { ApiClientBase, ApiService } from './apiClientBase';
import { RecyclingDocument } from '../../models/recyclingDetail/recyclingDocument';
import { SupplierJob } from '../../models/supplier/supplierJob';
import { Supplier } from '../../models/supplier/supplier';
import { Country } from '../../models/domainData/country';
import { CompanyCode } from '../../models/domainData/companyCode';
import { SupplierSummary } from '../../models/supplier/supplierSummary';
import { CarbonFootprint } from '../../models/carbonFootprint/carbonFootprint';
import { SupplierTrend } from '../../models/supplier/supplierTrend';
import { SupplierApiInteraction } from '../../models/supplier/supplierApiInteraction';
import { SupplierActivity } from '../../models/supplier/supplierActivity';
import { SlaDetail } from '../../models/sla/slaDetail';
import { CarbonFootprintCategory } from '../../models/carbonFootprint/carbonFootprintCategory';
import { CertificateSummary } from '../../models/certificates/certificateSummary';
import { UserPeripheralsSummary } from '../../models/peripherals/userPeripheralsSummary';
import { OfficeLocation } from '../../models/peripherals/officeLocation';
import { RecycledPeripheral } from '../../models/peripherals/recycledPeripheral';
import { Program } from '../../models/domainData/program';
import { SupplierJobType } from '../../models/domainData/supplierJobType';
import { SupplierUnitType } from '../../models/domainData/supplierUnitType';
import { JobUnit } from '../../models/supplier/jobUnit';
import { ReportProgram } from '../../models/reports/reportProgram';
import { SupplierDocumentDetails } from '../../models/certificates/supplierDocumentDetails';
import { JobSearchSummary } from '../../models/supplier/jobSearchSummary';
import { Payment } from '../../models/supplier/payment';
import { CountryStates } from '../../models/domainData/countryStates';
import { UnitDispositionType } from '../../models/domainData/unitDispositionType';
import { UnitStatus } from '../../models/domainData/unitStatus';
import { DbdReport } from '../../models/supplier/dbdReport';
import { CreditSummary } from '../../models/supplier/creditSummary';
import { InvoiceSummary } from '../../models/supplier/invoiceSummary';
import { SupplierTransportation } from '../../models/supplier/supplierTransportation';
import { PackagingMaterialType } from '../../models/domainData/packagingMaterialType';
import { DestructionType } from '../../models/domainData/destructionType';
import { getUserAadOid } from '../auth/msalHelper';

/**
 * Service api client.
 */
class ServiceApiClient extends ApiClientBase {
    // API endpoint route path categories
    private carbonFootprintEndpoint: string = 'sustainability';
    private domainDataEndpoint: string = 'domain';
    private reportingEndpoint: string = 'reporting';
    private aadHelperEndpoint: string = 'aadhelper';

    /**
     * Constructor for the receipting service api client.
     */
    constructor() {
        super(ApiService.Recycling)
    }

    /**
     * Get total jobs reported.
     * @param startDateUtc Start date.
     * @param endDateUtc End date.
     * @param programType Program type. Nullable, not used as of now. To be used later.
     * @param supplierId Supplier id. Nullable, not used as of now. To be used later.
     */
    public async totalJobsReported(startDateUtc: Date, endDateUtc: Date, programType?: string, supplierId?: string): Promise<number> {
        const isoStartDateUtc = startDateUtc.toISOString();
        const isoEndDateUtc = endDateUtc.toISOString();

        const apiUrl: string = appConfig.current.service.useLocalMockData ?
            `${this.mockDataFolder}/totalJobsReported.json` : `${appConfig.current.service.baseUrl}${this.reportingEndpoint}/totalJobsReported`;
        return await this.getValue<number>(apiUrl, { startDateUtc: isoStartDateUtc, endDateUtc: isoEndDateUtc, programType: programType, supplierId: supplierId });
    }

    /**
     * Gets supplier trends.
     * @param startDateUtc Start date.
     * @param endDateUtc End date.
     * @param programType Program type. Nullable, not used as of now. To be used later.
     * @param supplierId Supplier id. Nullable, not used as of now. To be used later.
     */
    public async supplierTrends(startDateUtc: Date, endDateUtc: Date, programType?: string, supplierId?: string): Promise<SupplierTrend[] | null> {
        const isoStartDateUtc = startDateUtc.toISOString();
        const isoEndDateUtc = endDateUtc.toISOString();
        const aadOid = getUserAadOid();
        
        const apiUrl: string = appConfig.current.service.useLocalMockData ?
            `${this.mockDataFolder}/supplierTrends.json` : `${appConfig.current.service.baseUrl}${this.reportingEndpoint}/supplierTrends`;
        return await this.getObjectArray<SupplierTrend>(SupplierTrend, apiUrl, { aadOid: aadOid, startDateUtc: isoStartDateUtc, endDateUtc: isoEndDateUtc, programType: programType, supplierId: supplierId });
    }

    /**
     * Gets supplier interactions.
     * @param startDateUtc Start date.
     * @param endDateUtc End date.
     * @param programType Program type. Nullable, not used as of now. To be used later.
     * @param supplierId Supplier id. Nullable, not used as of now. To be used later.
     */
    public async supplierApiInteractions(startDateUtc: Date, endDateUtc: Date, programType?: string, supplierId?: string): Promise<SupplierApiInteraction[] | null> {
        const isoStartDateUtc = startDateUtc.toISOString();
        const isoEndDateUtc = endDateUtc.toISOString();

        const apiUrl: string = appConfig.current.service.useLocalMockData ?
            `${this.mockDataFolder}/supplierApiInteractions.json` : `${appConfig.current.service.baseUrl}${this.reportingEndpoint}/supplierApiInteractions`;
        return await this.getObjectArray<SupplierApiInteraction>(SupplierApiInteraction, apiUrl, { startDateUtc: isoStartDateUtc, endDateUtc: isoEndDateUtc, programType: programType, supplierId: supplierId });
    }

    /**
     * Gets latest supplier activity by program type.
     * The time span passed in is the prior 3 months from today.
     * @param startDateUtc Start date.
     * @param endDateUtc End date.
     * @param programType Program type. Nullable, not used as of now. To be used later.
     * @param supplierId Supplier id. Nullable, not used as of now. To be used later.
     */
    public async latestSupplierActivityByProgramType(startDateUtc: Date, endDateUtc: Date, programType?: string, supplierId?: string): Promise<SupplierActivity[] | null> {
        const isoStartDateUtc = startDateUtc.toISOString();
        const isoEndDateUtc = endDateUtc.toISOString();

        const apiUrl: string = appConfig.current.service.useLocalMockData ?
            `${this.mockDataFolder}/latestSupplierActivityByProgramType.json` : `${appConfig.current.service.baseUrl}${this.reportingEndpoint}/latestSupplierActivityByProgramType`;
        return await this.getObjectArray<SupplierActivity>(SupplierActivity, apiUrl, { startDateUtc: isoStartDateUtc, endDateUtc: isoEndDateUtc, programType: programType, supplierId: supplierId });
    }

    /**
     * Get job unit details.
     * @param supplierId Supplier id.
     * @param supplierJobId Supplier job id.
     * @param supplierUnitId Supplier unit id.
     */
    public async jobUnitDetails(supplierId: string, supplierJobId: string, supplierUnitId: string): Promise<RecyclingDocument | null> {
        const apiUrl: string = appConfig.current.service.useLocalMockData ?
            `${this.mockDataFolder}/jobUnitDetails.json` : `${appConfig.current.service.baseUrl}${this.reportingEndpoint}/jobunitdetails`;
        return await this.getObject<RecyclingDocument>(RecyclingDocument, apiUrl, { supplierId: supplierId, supplierJobId: supplierJobId, supplierUnitId: supplierUnitId });
    }

    /**
     * Get programs.
     */
    public async programs(): Promise<Program[] | null> {
        const aadOid = getUserAadOid();
        const apiUrl: string = appConfig.current.service.useLocalMockData ?
            `${this.mockDataFolder}/programs.json` : `${appConfig.current.service.baseUrl}${this.domainDataEndpoint}/programs`;
        return this.getObjectArray<Program>(Program, apiUrl, { aadOid });
    }

    /**
     * Update a program.
     * @param program Program to update.
     */
     public async updateProgram(program: Program): Promise<null> {
        if (appConfig.current.service.useLocalMockData) {
            await this.simulatedDelay(1000);
            return null;
        }

        const apiUrl: string = `${appConfig.current.service.baseUrl}${this.domainDataEndpoint}/program`;
        return await this.postObject<Program, null>(null, apiUrl, program);
    }

    /**
     * Add a program.
     * @param program Program to add.
     * @param programType Program type.
     */
    public async addProgram(program: Program): Promise<null> {
        if (appConfig.current.service.useLocalMockData) {
            await this.simulatedDelay(1000);
            return null;
        }

        const apiUrl: string = `${appConfig.current.service.baseUrl}${this.domainDataEndpoint}/program`;
        return await this.putObject<Program, null>(null, apiUrl, program);
    }

    /**
     * Get suppliers.
     */
    public async suppliers(programType: string): Promise<Supplier[] | null> {
        const apiUrl: string = appConfig.current.service.useLocalMockData ?
            `${this.mockDataFolder}/suppliers.json` : `${appConfig.current.service.baseUrl}${this.domainDataEndpoint}/suppliers`;
        return await this.getObjectArray<Supplier>(Supplier, apiUrl, { programType });
    }

    /**
     * Update a supplier.
     * @param supplier Supplier to update.
     */
    public async updateSupplier(supplier: Supplier): Promise<null> {
        if (appConfig.current.service.useLocalMockData) {
            await this.simulatedDelay(1000);
            return null;
        }

        const apiUrl: string = `${appConfig.current.service.baseUrl}${this.domainDataEndpoint}/supplier`;
        return await this.postObject<Supplier, null>(null, apiUrl, supplier);
    }

    /**
     * Add a supplier.
     * @param supplier Supplier to add.
     * @param programType Program type.
     */
    public async addSupplier(supplier: Supplier, programType: string): Promise<null> {
        if (appConfig.current.service.useLocalMockData) {
            await this.simulatedDelay(1000);
            return null;
        }

        const apiUrl: string = `${appConfig.current.service.baseUrl}${this.domainDataEndpoint}/supplier`;
        return await this.putObject<Supplier, null>(null, apiUrl, supplier, { programType });
    }

    /**
     * Get supplier job types.
     * @param programType Program type.
     */
    public async supplierJobTypes(programType: string): Promise<SupplierJobType[] | null> {
        const apiUrl: string = appConfig.current.service.useLocalMockData ?
            `${this.mockDataFolder}/supplierJobTypes.json` : `${appConfig.current.service.baseUrl}${this.domainDataEndpoint}/supplierJobTypes`;
        return await this.getObjectArray<SupplierJobType>(SupplierJobType, apiUrl, { programType });
    }

    /**
     * Update a supplier job type.
     * @param supplierJobType Supplier job type to update.
     */
    public async updateSupplierJobType(supplierJobType: SupplierJobType): Promise<null> {
        if (appConfig.current.service.useLocalMockData) {
            await this.simulatedDelay(1000);
            return null;
        }

        const apiUrl: string = `${appConfig.current.service.baseUrl}${this.domainDataEndpoint}/supplierJobType`;
        return await this.postObject<SupplierJobType, null>(null, apiUrl, supplierJobType);
    }

    /**
     * Add a supplier job type.
     * @param supplierJobType Supplier job type to add.
     * @param programType Program type.
     */
    public async addSupplierJobType(supplierJobType: SupplierJobType, programType: string): Promise<null> {
        if (appConfig.current.service.useLocalMockData) {
            await this.simulatedDelay(1000);
            return null;
        }

        const apiUrl: string = `${appConfig.current.service.baseUrl}${this.domainDataEndpoint}/supplierJobType`;
        return await this.putObject<SupplierJobType, null>(null, apiUrl, supplierJobType, { programType });
    }

    /**
     * Delete a supplier job type.
     * @param supplierJobType Supplier job type to delete.
     */
    public async deleteSupplierJobType(supplierJobType: SupplierJobType): Promise<null> {
        if (appConfig.current.service.useLocalMockData) {
            await this.simulatedDelay(1000);
            return null;
        }

        const apiUrl: string = `${appConfig.current.service.baseUrl}${this.domainDataEndpoint}/supplierJobType`;
        return await this.delete(apiUrl, { partitionKey: supplierJobType.partitionKey, rowKey: supplierJobType.rowKey });
    }

    /**
     * Get supplier unit types.
     * @param programType Program type.
     */
    public async supplierUnitTypes(programType: string): Promise<SupplierUnitType[] | null> {
        const apiUrl: string = appConfig.current.service.useLocalMockData ?
            `${this.mockDataFolder}/supplierUnitTypes.json` : `${appConfig.current.service.baseUrl}${this.domainDataEndpoint}/supplierUnitTypes`;
        return this.getObjectArray<SupplierUnitType>(SupplierUnitType, apiUrl, { programType });
    }

    /**
     * Update a supplier unit type.
     * @param supplierUnitType Supplier unit type to update.
     */
    public async updateSupplierUnitType(supplierUnitType: SupplierUnitType): Promise<null> {
        if (appConfig.current.service.useLocalMockData) {
            await this.simulatedDelay(1000);
            return null;
        }

        const apiUrl: string = `${appConfig.current.service.baseUrl}${this.domainDataEndpoint}/supplierUnitType`;
        return await this.postObject<SupplierUnitType, null>(null, apiUrl, supplierUnitType);
    }

    /**
     * Add a supplier unit type.
     * @param supplierUnitType Supplier unit type to add.
     * @param programType Program type.
     */
    public async addSupplierUnitType(supplierUnitType: SupplierUnitType, programType: string): Promise<null> {
        if (appConfig.current.service.useLocalMockData) {
            await this.simulatedDelay(1000);
            return null;
        }

        const apiUrl: string = `${appConfig.current.service.baseUrl}${this.domainDataEndpoint}/supplierUnitType`;
        return await this.putObject<SupplierUnitType, null>(null, apiUrl, supplierUnitType, { programType });
    }

    /**
     * Delete a supplier unit type.
     * @param supplierUnitType Supplier unit type to delete.
     */
    public async deleteSupplierUnitType(supplierUnitType: SupplierUnitType): Promise<null> {
        if (appConfig.current.service.useLocalMockData) {
            await this.simulatedDelay(1000);
            return null;
        }

        const apiUrl: string = `${appConfig.current.service.baseUrl}${this.domainDataEndpoint}/supplierUnitType`;
        return await this.delete(apiUrl, { partitionKey: supplierUnitType.partitionKey, rowKey: supplierUnitType.rowKey });
    }

    /**
     * Get supplier jobs.
     * @param startDateUtc Start date.
     * @param endDateUtc End date.
     * @param supplierId Supplier id.
     * @param programType Program type.
     */
    public async supplierJobs(startDateUtc: Date, endDateUtc: Date, supplierId: string, programType: string): Promise<SupplierJob[] | null> {
        const isoStartDateUtc = startDateUtc.toISOString();
        const isoEndDateUtc = endDateUtc.toISOString();

        const apiUrl: string = appConfig.current.service.useLocalMockData ?
            `${this.mockDataFolder}/supplierJobs.json` : `${appConfig.current.service.baseUrl}${this.reportingEndpoint}/supplierJobs`;
        return await this.getObjectArray<SupplierJob>(SupplierJob, apiUrl, { startDateUtc: isoStartDateUtc, endDateUtc: isoEndDateUtc, supplierId, programType });
    }

    /**
     * Get job units.
     * @param supplierId Supplier id.
     * @param supplierJobId Supplier job id.
     */
    public async jobUnits(supplierId: string, supplierJobId: string): Promise<JobUnit[] | null> {
        const apiUrl: string = appConfig.current.service.useLocalMockData ?
            `${this.mockDataFolder}/jobUnits.json` : `${appConfig.current.service.baseUrl}${this.reportingEndpoint}/jobunits`;
        return await this.getObjectArray<JobUnit>(JobUnit, apiUrl, { supplierId, supplierJobId });
    }

    /**
     * Get countries.
     */
    public async countries(): Promise<Country[] | null> {
        const apiUrl: string = appConfig.current.service.useLocalMockData ?
            `${this.mockDataFolder}/countries.json` : `${appConfig.current.service.baseUrl}${this.domainDataEndpoint}/countries`;
        return await this.getObjectArray<Country>(Country, apiUrl);
    }

    /**
     * Get the states for a specific country or for all countries.
     * @param countryCode A country code to filter the states.
     */
    public async countryStates(countryCode?: string): Promise<CountryStates[] | null> {
        const apiUrl: string = appConfig.current.service.useLocalMockData ?
            `${this.mockDataFolder}/countryStates.json` : `${appConfig.current.service.baseUrl}${this.domainDataEndpoint}/states`;
        return await this.getObjectArray<CountryStates>(CountryStates, apiUrl, { countryCode });
    }

    /**
     * Get company codes.
     */
    public async companyCodes(): Promise<CompanyCode[] | null> {
        const apiUrl: string = appConfig.current.service.useLocalMockData || this.useMockDataOverride ?
            `${this.mockDataFolder}/companyCodes.json` : `${appConfig.current.service.baseUrl}${this.domainDataEndpoint}/companyCodes`;
        return await this.getObjectArray<CompanyCode>(CompanyCode, apiUrl);
    }

    /**
     * Get unit disposition types.
     * @param programType Program type.
     */
    public async unitDispositionTypes(programType: string): Promise<UnitDispositionType[] | null> {
        const apiUrl: string = appConfig.current.service.useLocalMockData ?
            `${this.mockDataFolder}/unitDispositionTypes.json` : `${appConfig.current.service.baseUrl}${this.domainDataEndpoint}/unitDispositionTypes`;
        return await this.getObjectArray<UnitDispositionType>(UnitDispositionType, apiUrl, { programType });
    }
    
    /**
     * Update a unit disposition type.
     * @param dispositionType Disposition type to update.
     */
    public async updateUnitDispositionType(unitDispositionType: UnitDispositionType): Promise<null> {
        if (appConfig.current.service.useLocalMockData) {
            await this.simulatedDelay(1000);
            return null;
        }

        const apiUrl: string = `${appConfig.current.service.baseUrl}${this.domainDataEndpoint}/unitDispositionType`;
        return await this.postObject<UnitDispositionType, null>(null, apiUrl, unitDispositionType);
    }

    /**
     * Add a unit disposition type.
     * @param dispositionType Disposition type to add.
     * @param programType Program type.
     */
    public async addUnitDispositionType(unitDispositionType: UnitDispositionType, programType: string): Promise<null> {
        if (appConfig.current.service.useLocalMockData) {
            await this.simulatedDelay(1000);
            return null;
        }

        const apiUrl: string = `${appConfig.current.service.baseUrl}${this.domainDataEndpoint}/unitDispositionType`;
        return await this.putObject<UnitDispositionType, null>(null, apiUrl, unitDispositionType, { programType });
    }

    /**
     * Delete a unit disposition type.
     * @param dispositionType Disposition type to delete.
     */
    public async deleteUnitDispositionType(unitDispositionType: UnitDispositionType): Promise<null> {
        if (appConfig.current.service.useLocalMockData) {
            await this.simulatedDelay(1000);
            return null;
        }

        const apiUrl: string = `${appConfig.current.service.baseUrl}${this.domainDataEndpoint}/unitDispositionType`;
        return await this.delete(apiUrl, { partitionKey: unitDispositionType.partitionKey, rowKey: unitDispositionType.rowKey });
    }

    /**
     * Add a unit disposition type.
     * @param dispositionType Disposition type to add.
     * @param programType Program type.
     */
    public async unitStatuses(programType: string): Promise<UnitStatus[] | null> {
        const apiUrl: string = appConfig.current.service.useLocalMockData ?
            `${this.mockDataFolder}/unitStatuses.json` : `${appConfig.current.service.baseUrl}${this.domainDataEndpoint}/unitStatuses`;
        return await this.getObjectArray<UnitStatus>(UnitStatus, apiUrl, { programType });
    }
    
    /**
     * Update a unit status.
     * @param unitStatus Unit status to update.
     */
    public async updateUnitStatus(unitStatus: UnitStatus): Promise<null> {
        if (appConfig.current.service.useLocalMockData) {
            await this.simulatedDelay(1000);
            return null;
        }

        const apiUrl: string = `${appConfig.current.service.baseUrl}${this.domainDataEndpoint}/unitStatus`;
        return await this.postObject<UnitStatus, null>(null, apiUrl, unitStatus);
    }

    /**
     * Add a unit status.
     * @param unitStatus Unit status to add.
     * @param programType Program type.
     */
    public async addUnitStatus(unitStatus: UnitStatus, programType: string): Promise<null> {
        if (appConfig.current.service.useLocalMockData) {
            await this.simulatedDelay(1000);
            return null;
        }

        const apiUrl: string = `${appConfig.current.service.baseUrl}${this.domainDataEndpoint}/unitStatus`;
        return await this.putObject<UnitStatus, null>(null, apiUrl, unitStatus, { programType });
    }

    /**
     * Delete a unit status.
     * @param unitStatus Unit status to delete.
     */
    public async deleteUnitStatus(unitStatus: UnitStatus): Promise<null> {
        if (appConfig.current.service.useLocalMockData) {
            await this.simulatedDelay(1000);
            return null;
        }

        const apiUrl: string = `${appConfig.current.service.baseUrl}${this.domainDataEndpoint}/unitStatus`;
        return await this.delete(apiUrl, { partitionKey: unitStatus.partitionKey, rowKey: unitStatus.rowKey });
    }

    /**
     * Job search.
     * @param programType Program type.
     * @param supplierId Supplier id.
     * @param supplierJobId Supplier job id.
     * @param supplierInvoiceNumber Supplier invoice number.
     * @param supplierPoNumber supplier PO number.
     * @param correlationId Correlation id.
     * @param startDateUtc Start date.
     * @param endDateUtc End date.
     */
    public async jobSearch(programType?: string, supplierId?: string, supplierJobId?: string, supplierInvoiceNumber?: string, supplierPoNumber?: string, correlationId?: string, startDateUtc?: Date, endDateUtc?: Date): Promise<JobSearchSummary | null> {
        const apiUrl: string = appConfig.current.service.useLocalMockData ?
            `${this.mockDataFolder}/jobSearch.json` : `${appConfig.current.service.baseUrl}${this.reportingEndpoint}/jobSearch`;
        return await this.getObject<JobSearchSummary>(JobSearchSummary, apiUrl, { programType, supplierId, supplierJobId, supplierInvoiceNumber, supplierPoNumber, correlationId, startDateUtc, endDateUtc });
    }

    /**
     * Supplier summary.
     * @param programType Program type.
     * @param supplierId Supplier id.
     * @param supplierJobType Supplier job type.
     * @param supplierJobId Supplier job id.
     * @param supplierUnitId Supplier unit id.
     * @param correlationId Correlation id.
     * @param supplierUnitType Supplier unit type.
     * @param countryCode Country code.
     * @param startDateUtc Start date.
     * @param endDateUtc End date.
     * @param dispositionType Disposition type.
     * @param unitStatus Unit status.
     */
    public async supplierSummary(programType?: string, supplierId?: string, supplierJobType?: string, supplierJobId?: string, supplierUnitId?: string, correlationId?: string, supplierUnitType?: string, countryCode?: string, startDateUtc?: Date, endDateUtc?: Date, dispositionType?: string, unitStatus?: string): Promise<SupplierSummary[] | null> {
        const apiUrl: string = appConfig.current.service.useLocalMockData ?
            `${this.mockDataFolder}/supplierSummary.json` : `${appConfig.current.service.baseUrl}${this.reportingEndpoint}/supplierSummary`;
        return await this.getObjectArray<SupplierSummary>(SupplierSummary, apiUrl, { programType, supplierId, supplierJobType, supplierJobId, supplierUnitId, correlationId, supplierUnitType, countryCode, startDateUtc, endDateUtc, dispositionType, unitStatus });
    }

    /**
     * Get the carbon footprint.
     * @param category The category type for recycled devices.
     * @param unitCount The number of units recycled.
     */
    public async carbonFootprint(category?: string, unitCount?: number): Promise<CarbonFootprint[] | null> {
        const apiUrl: string = appConfig.current.service.useLocalMockData ?
            `${this.mockDataFolder}/carbonFootprint.json` : `${appConfig.current.service.baseUrl}${this.carbonFootprintEndpoint}/carbonFootprint`;
        return this.getObjectArray<CarbonFootprint>(CarbonFootprint, apiUrl, { category, unitCount });
    }

    /**
     * Get carbon footprint categories.
     */
    public async carbonFootprintCategories(): Promise<CarbonFootprintCategory[] | null> {
        const apiUrl: string = appConfig.current.service.useLocalMockData ?
            `${this.mockDataFolder}/carbonFootprintCategories.json` : `${appConfig.current.service.baseUrl}${this.carbonFootprintEndpoint}/carbonFootprintCategories`;
        return this.getObjectArray<CarbonFootprintCategory>(CarbonFootprintCategory, apiUrl);
    }

    /**
     * Get PO documents.
     * @param programType Program type.
     * @param supplierId Supplier id.
     * @param supplierJobId Supplier job id.
     * @param supplierPoNumber Supplier PO number.
     */
    public async poDocuments(programType?: string, supplierId?: string, supplierJobId?: string, supplierPoNumber?: string): Promise<SupplierDocumentDetails[] | null> {
        const apiUrl: string = appConfig.current.service.useLocalMockData ?
            `${this.mockDataFolder}/poDocuments.json` : `${appConfig.current.service.baseUrl}${this.reportingEndpoint}/poDocuments`;
        return await this.getObjectArray<SupplierDocumentDetails>(SupplierDocumentDetails, apiUrl, { programType, supplierId, supplierJobId, supplierPoNumber });
    }

    /**
     * Get invoice documents.
     * @param programType Program type.
     * @param supplierId Supplier id.
     * @param supplierJobId Supplier job id.
     * @param supplierInvoiceNumber Supplier invoice number.
     */
    public async invoiceDocuments(programType?: string, supplierId?: string, supplierJobId?: string, supplierInvoiceNumber?: string): Promise<SupplierDocumentDetails[] | null> {
        const apiUrl: string = appConfig.current.service.useLocalMockData ?
            `${this.mockDataFolder}/invoiceDocuments.json` : `${appConfig.current.service.baseUrl}${this.reportingEndpoint}/invoiceDocuments`;
        return await this.getObjectArray<SupplierDocumentDetails>(SupplierDocumentDetails, apiUrl, { programType, supplierId, supplierJobId, supplierInvoiceNumber });
    }

    /**
     * Get recycling certificates.
     * @param programType Program type.
     * @param supplierId Supplier id.
     * @param supplierJobId Supplier job id.
     */
    public async recyclingCertificates(programType?: string, supplierId?: string, supplierJobId?: string): Promise<CertificateSummary[] | null> {
        const apiUrl: string = appConfig.current.service.useLocalMockData ?
            `${this.mockDataFolder}/recyclingCertificates.json` : `${appConfig.current.service.baseUrl}${this.reportingEndpoint}/recyclingCertificates`;
        return await this.getObjectArray<CertificateSummary>(CertificateSummary, apiUrl, { programType, supplierId, supplierJobId });
    }

    /**
     * Get data erasure certificates.
     * @param programType Program type.
     * @param supplierId Supplier id.
     * @param supplierJobId Supplier job id.
     * @param supplierUnitId Supplier unit id.
     * @param supplierUnitType Supplier unit type.
     * @param assetTagNumber Asset tag number.
     * @param serialNumber Serial number.
     */
    public async dataErasureCertificates(programType?: string, supplierId?: string, supplierJobId?: string, supplierUnitId?: string, supplierUnitType?: string, assetTagNumber?: string, serialNumber?: string): Promise<CertificateSummary[] | null> {
        const apiUrl: string = appConfig.current.service.useLocalMockData ?
            `${this.mockDataFolder}/dataErasureCertificates.json` : `${appConfig.current.service.baseUrl}${this.reportingEndpoint}/dataErasureCertificates`;
        return await this.getObjectArray<CertificateSummary>(CertificateSummary, apiUrl, { programType, supplierId, supplierJobId, supplierUnitId, supplierUnitType, assetTagNumber, serialNumber });
    }

    /**
     * Download a blob file as a base64 encoded string.
     * @param blobUrl The URL of the blob to download.
     */
    public async downloadBlobStream(blobUrl: string): Promise<string | null> {
        const apiUrl: string = appConfig.current.service.useLocalMockData ?
            `${this.mockDataFolder}/downloadBlobStream.json` : `${appConfig.current.service.baseUrl}${this.reportingEndpoint}/downloadBlobStream`;
        return await this.getValue<string>(apiUrl, { blobUrl: blobUrl });
    }

    /**
     * Download a blob file directly.
     * This api will cause the file to be downloaded in the browser. See api code for "application/force-download".
     * @param blobUrl The URL of the blob to download.
     */
    public async downloadBlobFile(blobUrl: string): Promise<string> {
        if (appConfig.current.service.useLocalMockData) {
            return ''; // Do nothing if mock data is on.
        }

        const apiUrl = `${appConfig.current.service.baseUrl}${this.reportingEndpoint}/downloadBlobFile`;
        return await this.downloadBlobFileHelper(apiUrl, { blobUrl: blobUrl });
    }

    /**
     * Download data erasure certificate file.
     * This api will cause the file to be downloaded in the browser. See api code for "application/force-download".
     * @param supplierId Supplier id.
     * @param supplierJobId Supplier job id.
     * @param supplierUnitId Supplier unit id.
     * @returns Name of file downloaded.
     */
    public async downloadDataErasureCertificate(supplierId: string, supplierJobId: string, supplierUnitId: string): Promise<string> {
        if (appConfig.current.service.useLocalMockData) {
            return ''; // Do nothing if mock data is on.
        }

        const apiUrl = `${appConfig.current.service.baseUrl}${this.reportingEndpoint}/downloadDataErasureCertificate`;
        return await this.downloadBlobFileHelper(apiUrl, { supplierId, supplierJobId, supplierUnitId });
    }

    /**
     * Download recycling certificate file.
     * This api will cause the file to be downloaded in the browser. See api code for "application/force-download".
     * @param programType Program type.
     * @param supplierId Supplier id.
     * @param supplierJobId Supplier job id.
     * @returns Name of file downloaded.
     */
    public async downloadRecyclingCertificate(programType: string, supplierId: string, supplierJobId: string): Promise<string> {
        if (appConfig.current.service.useLocalMockData) {
            return ''; // Do nothing if mock data is on.
        }

        const apiUrl = `${appConfig.current.service.baseUrl}${this.reportingEndpoint}/downloadRecyclingCertificate`;
        return await this.downloadBlobFileHelper(apiUrl, { programType, supplierId, supplierJobId });
    }

    /**
     * Download destruction certificate file.
     * This api will cause the file to be downloaded in the browser. See api code for "application/force-download".
     * @param programType Program type.
     * @param supplierId Supplier id.
     * @param supplierJobId Supplier job id.
     * @returns Name of file downloaded.
     */
    public async downloadDestructionCertificate(programType: string, supplierId: string, supplierJobId: string): Promise<string> {
        if (appConfig.current.service.useLocalMockData) {
            return ''; // Do nothing if mock data is on.
        }

        const apiUrl = `${appConfig.current.service.baseUrl}${this.reportingEndpoint}/downloadDestructionCertificate`;
        return await this.downloadBlobFileHelper(apiUrl, { programType, supplierId, supplierJobId });
    }

    /**
     * Download PO document file.
     * This api will cause the file to be downloaded in the browser. See api code for "application/force-download".
     * @param programType Program type.
     * @param supplierId Supplier id.
     * @param supplierPoNumber Supplier PO number.
     * @returns Name of file downloaded.
     */
    public async downloadPoDocument(programType: string, supplierId: string, supplierPoNumber: string): Promise<string> {
        if (appConfig.current.service.useLocalMockData) {
            return ''; // Do nothing if mock data is on.
        }

        const apiUrl = `${appConfig.current.service.baseUrl}${this.reportingEndpoint}/downloadPoDocument`;
        return await this.downloadBlobFileHelper(apiUrl, { programType, supplierId, supplierPoNumber });
    }

    /**
     * Download invoice document file.
     * This api will cause the file to be downloaded in the browser. See api code for "application/force-download".
     * @param programType Program type.
     * @param supplierId Supplier id.
     * @param supplierInvoiceNumber Supplier invoice number.
     * @returns Name of file downloaded.
     */
    public async downloadInvoiceDocument(programType: string, supplierId: string, supplierInvoiceNumber: string): Promise<string> {
        if (appConfig.current.service.useLocalMockData) {
            return ''; // Do nothing if mock data is on.
        }

        const apiUrl = `${appConfig.current.service.baseUrl}${this.reportingEndpoint}/downloadInvoiceDocument`;
        return await this.downloadBlobFileHelper(apiUrl, { programType, supplierId, supplierInvoiceNumber });
    }

    /**
     * Get service level agreement (SLA) detals.
     */
    public async slaDetail(programType?: string, supplierId?: string, companyCode?: string, countryCode?: string, startDateUtc?: Date, endDateUtc?: Date): Promise<SlaDetail[] | null> {
        const apiUrl: string = appConfig.current.service.useLocalMockData || this.useMockDataOverride ?
            `${this.mockDataFolder}/slaDetail.json` : `${appConfig.current.service.baseUrl}${this.reportingEndpoint}/slaDetail`;
        return await this.getObjectArray<SlaDetail>(SlaDetail, apiUrl, { programType, supplierId, companyCode, countryCode, startDateUtc, endDateUtc });
    }

    /**
     * Get user peripherals summary.
     * @param alias The current user's alias for whom to get the peripherals summary.
     */
    public async userPeripheralsSummary(alias: string): Promise<UserPeripheralsSummary | null> {
        const apiUrl: string = appConfig.current.service.useLocalMockData || this.useMockDataOverride ?
            `${this.mockDataFolder}/peripherals/userPeripheralsSummary.json` : `${appConfig.current.service.baseUrl}${this.reportingEndpoint}/userPeripheralsSummary`;
        return await this.getObject<UserPeripheralsSummary>(UserPeripheralsSummary, apiUrl, { alias });
    }

    /**
     * Get the recycled peripherals summary.
     * @param assetTag Asset tag search filter.
     * @param serialNumber Serial number search filter.
     * @param peripheralType Peripheral type search filter.
     * @param isAvailable Availability flag search filter.
     * @param pickupLocation Pickup location search filter.
     * @param peripheralCondition Peripheral condition search filter.
     */
    public async recycledPeripheralsSummary(assetTag?: string, serialNumber?: string, peripheralType?: string, isAvailable?: boolean, pickupLocation?: OfficeLocation, peripheralCondition?: string): Promise<RecycledPeripheral[] | null> {
        const apiUrl: string = appConfig.current.service.useLocalMockData || this.useMockDataOverride ?
            `${this.mockDataFolder}/peripherals/recycledPeripheralsSummary.json` : `${appConfig.current.service.baseUrl}${this.reportingEndpoint}/recycledPeripheralsSummary`;
        return await this.getObjectArray<RecycledPeripheral>(RecycledPeripheral, apiUrl, { assetTag, serialNumber, peripheralType, isAvailable, pickupLocation, peripheralCondition });
    }

    /**
     * Get peripheral types.
     */
    public async peripheralTypes(): Promise<string[]> {
        const apiUrl: string = appConfig.current.service.useLocalMockData || this.useMockDataOverride ?
            `${this.mockDataFolder}/peripherals/peripheralTypes.json` : `${appConfig.current.service.baseUrl}${this.reportingEndpoint}/peripheralTypes`;
        return await this.getStringArray(apiUrl);
    }

    /**
     * Get peripheral conditions.
     */
    public async peripheralConditions(): Promise<string[]> {
        const apiUrl: string = appConfig.current.service.useLocalMockData || this.useMockDataOverride ?
            `${this.mockDataFolder}/peripherals/peripheralConditions.json` : `${appConfig.current.service.baseUrl}${this.reportingEndpoint}/peripheralConditions`;
        return await this.getStringArray(apiUrl);
    }

    /**
     * Get a list of all microsoft office locations.
     */
    public async officeLocations(): Promise<OfficeLocation[] | null> {
        const apiUrl: string = appConfig.current.service.useLocalMockData || this.useMockDataOverride ?
            `${this.mockDataFolder}/peripherals/officeLocations.json` : `${appConfig.current.service.baseUrl}${this.reportingEndpoint}/officeLocations`;
        return await this.getObjectArray<OfficeLocation>(OfficeLocation, apiUrl);
    }

    /**
     * Checks if a user is in a security group.
     * @param sgName Security group name. Note this is the display name, not the email alias.
     */
    public async sgCheck(sgName: string): Promise<boolean> {
        if (appConfig.current.service.useLocalMockData) {
            return true;
        }
        const aadOid = getUserAadOid();
        const apiUrl: string = `${appConfig.current.service.baseUrl}${this.aadHelperEndpoint}/${aadOid}/sgcheck/${sgName}`;
        return await this.getValue<boolean>(apiUrl);
    }

    /**
     * Get available reports.
     */
    public async reports(): Promise<ReportProgram[] | null> {
        const apiUrl: string = appConfig.current.service.useLocalMockData ?
            `${this.mockDataFolder}/reports.json` : `${appConfig.current.service.baseUrl}${this.reportingEndpoint}/reports`;
        return await this.getObjectArray<ReportProgram>(ReportProgram, apiUrl);
    }

    /**
     * Get payment notifications.
     * @param programType Program type.
     * @param supplierId Supplier id.
     * @param year Year.
     * @param month Month.
     * @returns Array of payment notifications.
     */
    public async paymentNotifications(programType: string, supplierId: string, year: number, month: number): Promise<Payment[] | null> {
        const apiUrl: string = appConfig.current.service.useLocalMockData ?
            `${this.mockDataFolder}/paymentNotifications.json` : `${appConfig.current.service.baseUrl}${this.reportingEndpoint}/paymentNotifications`;
        // This api takes a 'week' parameter also, but not using it from the client at this time.
        return await this.getObjectArray<Payment>(Payment, apiUrl, { programType, supplierId, year, month }); 
    }

    /**
     * Data bearing device (DBD).
     * @param programType Program type.
     * @param supplierId Supplier id.
     * @param supplierJobId Supplier job id.
     * @param correlationId Correlation id.
     * @param supplierUnitId Supplier unit id.
     * @param supplierUnitType Supplier unit type.
     */
    public async dbd(programType: string, supplierId: string, supplierJobId?: string, correlationId?: string, supplierUnitId?: string, supplierUnitType?: string): Promise<DbdReport | null> {
        const apiUrl: string = appConfig.current.service.useLocalMockData ?
            `${this.mockDataFolder}/dbd.json` : `${appConfig.current.service.baseUrl}${this.reportingEndpoint}/dbd`;
        return await this.getObject<DbdReport>(DbdReport, apiUrl, { programType, supplierId, supplierJobId, correlationId, supplierUnitId, supplierUnitType });
    }

    /**
     * Credit summary.
     * @param programType Program type.
     * @param supplierId Supplier id.
     * @param year Year.
     * @param month Month.
     * @param supplierPoNumber supplier PO number.
     */
    public async creditSummary(programType: string, supplierId: string, year: number, month: number, supplierPoNumber?: string): Promise<CreditSummary[] | null> {
        const apiUrl: string = appConfig.current.service.useLocalMockData ?
            `${this.mockDataFolder}/creditSummary.json` : `${appConfig.current.service.baseUrl}${this.reportingEndpoint}/creditsummary`;
        return await this.getObjectArray<CreditSummary>(CreditSummary, apiUrl, { programType, supplierId, year, month, supplierPoNumber });
    }

    /**
     * Invoice summary.
     * @param programType Program type.
     * @param supplierId Supplier id.
     * @param year Year.
     * @param month Month.
     * @param supplierJobId Supplier job id.
     * @param supplierInvoiceNumber Supplier invoice number.
     */
    public async invoiceSummary(programType: string, supplierId: string, year: number, month: number, supplierJobId?: string, supplierInvoiceNumber?: string): Promise<InvoiceSummary[] | null> {
        const apiUrl: string = appConfig.current.service.useLocalMockData ?
            `${this.mockDataFolder}/invoiceSummary.json` : `${appConfig.current.service.baseUrl}${this.reportingEndpoint}/invoicesummary`;
        return await this.getObjectArray<InvoiceSummary>(InvoiceSummary, apiUrl, { programType, supplierId, year, month, supplierJobId, supplierInvoiceNumber });
    }

    /**
     * Transport And Packaging.
     * @param programType Program type.
     * @param supplierId Supplier id.
     * @param supplierJobId Supplier job id.
     * @param correlationId Correlation id.
     */
    public async transportAndPackaging(programType: string, supplierId: string, supplierJobId?: string, correlationId?: string): Promise<SupplierTransportation | null> {
        const apiUrl: string = appConfig.current.service.useLocalMockData ?
            `${this.mockDataFolder}/transportAndPackaging.json` : `${appConfig.current.service.baseUrl}${this.reportingEndpoint}/transportandpackaging`;
        return await this.getObject<SupplierTransportation>(SupplierTransportation, apiUrl, { programType, supplierId, supplierJobId, correlationId });
    }

    /**
     * Get packaging material types.
     * @param programType Program type.
     */
    public async packagingMaterialTypes(programType: string): Promise<PackagingMaterialType[] | null> {
        const apiUrl: string = appConfig.current.service.useLocalMockData ?
            `${this.mockDataFolder}/packagingMaterialTypes.json` : `${appConfig.current.service.baseUrl}${this.domainDataEndpoint}/packagingMaterialTypes`;
        return await this.getObjectArray<PackagingMaterialType>(PackagingMaterialType, apiUrl, { programType });
    }

    /**
     * Update a packaging material type.
     * @param packagingMaterialType Packaging material type to update.
     */
    public async updatePackagingMaterialType(packagingMaterialType: PackagingMaterialType): Promise<null> {
        if (appConfig.current.service.useLocalMockData) {
            await this.simulatedDelay(1000);
            return null;
        }

        const apiUrl: string = `${appConfig.current.service.baseUrl}${this.domainDataEndpoint}/packagingMaterialType`;
        return await this.postObject<PackagingMaterialType, null>(null, apiUrl, packagingMaterialType);
    }

    /**
     * Add a packaging material type.
     * @param packagingMaterialType Packaging material type to add.
     * @param programType Program type.
     */
    public async addPackagingMaterialType(packagingMaterialType: PackagingMaterialType, programType: string): Promise<null> {
        if (appConfig.current.service.useLocalMockData) {
            await this.simulatedDelay(1000);
            return null;
        }

        const apiUrl: string = `${appConfig.current.service.baseUrl}${this.domainDataEndpoint}/packagingMaterialType`;
        return await this.putObject<PackagingMaterialType, null>(null, apiUrl, packagingMaterialType, { programType });
    }

    /**
     * Delete a packaging material type.
     * @param packagingMaterialType Packaging matreial type to delete.
     */
    public async deletePackagingMaterialType(packagingMaterialType: PackagingMaterialType): Promise<null> {
        if (appConfig.current.service.useLocalMockData) {
            await this.simulatedDelay(1000);
            return null;
        }

        const apiUrl: string = `${appConfig.current.service.baseUrl}${this.domainDataEndpoint}/packagingMaterialType`;
        return await this.delete(apiUrl, { partitionKey: packagingMaterialType.partitionKey, rowKey: packagingMaterialType.rowKey });
    }

    /**
     * Get destruction types.
     * @param programType Program type.
     */
     public async destructionTypes(programType: string): Promise<DestructionType[] | null> {
        const apiUrl: string = appConfig.current.service.useLocalMockData ?
            `${this.mockDataFolder}/destructionTypes.json` : `${appConfig.current.service.baseUrl}${this.domainDataEndpoint}/destructionTypes`;
        return await this.getObjectArray<DestructionType>(DestructionType, apiUrl, { programType });
    }

    /**
     * Update a destruction type.
     * @param destructionType Destruction type to update.
     */
    public async updateDestructionType(destructionType: DestructionType): Promise<null> {
        if (appConfig.current.service.useLocalMockData) {
            await this.simulatedDelay(1000);
            return null;
        }

        const apiUrl: string = `${appConfig.current.service.baseUrl}${this.domainDataEndpoint}/destructionType`;
        return await this.postObject<DestructionType, null>(null, apiUrl, destructionType);
    }

    /**
     * Add a destruction type.
     * @param destructionType Destruction type to add.
     * @param programType Program type.
     */
    public async addDestructionType(destructionType: DestructionType, programType: string): Promise<null> {
        if (appConfig.current.service.useLocalMockData) {
            await this.simulatedDelay(1000);
            return null;
        }

        const apiUrl: string = `${appConfig.current.service.baseUrl}${this.domainDataEndpoint}/destructionType`;
        return await this.putObject<DestructionType, null>(null, apiUrl, destructionType, { programType });
    }

    /**
     * Delete a destruction type.
     * @param destructionType Destruction type to delete.
     */
    public async deleteDestructionType(destructionType: DestructionType): Promise<null> {
        if (appConfig.current.service.useLocalMockData) {
            await this.simulatedDelay(1000);
            return null;
        }

        const apiUrl: string = `${appConfig.current.service.baseUrl}${this.domainDataEndpoint}/destructionType`;
        return await this.delete(apiUrl, { partitionKey: destructionType.partitionKey, rowKey: destructionType.rowKey });
    }
}

export const serviceApiClient = new ServiceApiClient();
