import { SlaJobUnits } from './slaJobUnits';

export interface IResultType {
    collection: SlaJobUnits;
    disposition: SlaJobUnits;
    settlement: SlaJobUnits;
}

export class ResultType implements IResultType {
    public collection: SlaJobUnits;
    public disposition: SlaJobUnits;
    public settlement: SlaJobUnits;

    constructor(jsonData: IResultType) {
        this.collection = new SlaJobUnits(jsonData.collection);
        this.disposition = new SlaJobUnits(jsonData.disposition);
        this.settlement = new SlaJobUnits(jsonData.settlement);
    }
}
