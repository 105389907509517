import {
    IStyle,
    mergeStyleSets,
    IStackTokens,
    IComboBoxStyles,
    ITextFieldStyles
} from '@fluentui/react';

/**
 * Common styles used across all pages.
 */
export const commonStyles = mergeStyleSets({
    rootDiv: {
        paddingRight: '30px',
        paddingLeft: '10px'
    } as IStyle,
    dividerLine: {
        width: '100%',
        height: '1px',
        backgroundColor: 'black',
        marginBottom: '20px'
    } as IStyle,
    rowGap: {
        height: '30px'
    } as IStyle,
    spinner: {
        alignItems: 'unset'
    } as IStyle,
    errorText: {
        color: 'red'
    } as IStyle,
    pageHeader: {
        marginBottom: '12px'
    } as IStyle,
    headingTextContainer: {
        marginLeft: '12px',
        width: '100%'
    } as IStyle,
    headingText: {
        fontWeight: 'bold'
    } as IStyle,
    wordWrapColumnHeader: {
        overflowWrap: 'break-word',
        whiteSpace: 'break-spaces',
        lineHeight: 'normal'
    } as IStyle,
    // Use on a div containing a FontIcon when used in a TooltipHost.
    tooltipFontIconContainer: {
        padding: '2px 2px 2px 2px',
        height: '16px'
    } as IStyle,
    absoluteCenter: {
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        position: 'absolute'
    } as IStyle,
    panel: {
        marginTop: '48px',
        position: 'absolute'
    } as IStyle,
    panelOverlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.4)'
    } as IStyle
});

export const stackTokens: IStackTokens = {
    childrenGap: 15
};

/**
 * Styles used for all comboboxes.
 */
export const comboBoxStyles: Partial<IComboBoxStyles> = {
    root: {
        // Nothing at this time.
    } as IStyle
};

/**
 * Styles used for all textfields.
 */
export const textFieldStyles: Partial<ITextFieldStyles> = {
    root: {
        marginTop: 2,
        marginLeft: 1,
        padding: 5
    } as IStyle
};
