import { ProgramConfiguration } from './programConfiguration';

export interface IProgram {
    programType: string;
    programName: string;
    securityGroups: string;
    adminSecurityGroup?: string;
    businessTeamMailingGroup?: string;
    emailNotification?: boolean;
    configuration: string;
    programConfiguration?: ProgramConfiguration;
}

export class Program implements IProgram {
    public programType: string;
    public programName: string;
    public securityGroups: string;
    public adminSecurityGroup?: string;
    public businessTeamMailingGroup?: string;
    public emailNotification?: boolean;
    public configuration: string;
    public programConfiguration?: ProgramConfiguration;

    // Below are properties NOT returned from the server but are used for client side purposes.
    public clientRowKey: string = '';
    public isNew?: boolean;

    // Following properties are used to get to and set nested data.
    // When binding to the details list grid, it is useful to be able to access these fields by name
    // from this top level object. Ex: item[fieldName]

    public get msCollectionTicketNumber(): boolean | undefined {
        return this.programConfiguration?.validations?.msCollectionTicketNumber;
    }

    public set msCollectionTicketNumber(value: boolean | undefined) {
        this.programConfiguration = this.programConfiguration || {};
        this.programConfiguration.validations = this.programConfiguration.validations || {};
        this.programConfiguration.validations.msCollectionTicketNumber = value;
    }

    public get checkJobExistence(): boolean | undefined {
        return this.programConfiguration?.validations?.checkJobExistence;
    }

    public set checkJobExistence(value: boolean | undefined) {
        this.programConfiguration = this.programConfiguration || {};
        this.programConfiguration.validations = this.programConfiguration.validations || {};
        this.programConfiguration.validations.checkJobExistence = value;
    }

    public get checkJobUnitExistence(): boolean | undefined {
        return this.programConfiguration?.validations?.checkJobUnitExistence;
    }

    public set checkJobUnitExistence(value: boolean | undefined) {
        this.programConfiguration = this.programConfiguration || {};
        this.programConfiguration.validations = this.programConfiguration.validations || {};
        this.programConfiguration.validations.checkJobUnitExistence = value;
    }

    public get checkJobStatus(): boolean | undefined {
        return this.programConfiguration?.validations?.checkJobStatus;
    }

    public set checkJobStatus(value: boolean | undefined) {
        this.programConfiguration = this.programConfiguration || {};
        this.programConfiguration.validations = this.programConfiguration.validations || {};
        this.programConfiguration.validations.checkJobStatus = value;
    }

    public get jobExpiryDays(): number | undefined {
        return this.programConfiguration?.validations?.jobExpiryDays;
    }

    public set jobExpiryDays(value: number | undefined) {
        this.programConfiguration = this.programConfiguration || {};
        this.programConfiguration.validations = this.programConfiguration.validations || {};
        this.programConfiguration.validations.jobExpiryDays = value;
    }

    public get collectionSiteCode(): boolean | undefined {
        return this.programConfiguration?.validations?.collectionSiteCode;
    }

    public set collectionSiteCode(value: boolean | undefined) {
        this.programConfiguration = this.programConfiguration || {};
        this.programConfiguration.validations = this.programConfiguration.validations || {};
        this.programConfiguration.validations.collectionSiteCode = value;
    }

    public get msCompanyCodeValidation(): boolean | undefined {
        return this.programConfiguration?.validations?.msCompanyCodeValidation;
    }

    public set msCompanyCodeValidation(value: boolean | undefined) {
        this.programConfiguration = this.programConfiguration || {};
        this.programConfiguration.validations = this.programConfiguration.validations || {};
        this.programConfiguration.validations.msCompanyCodeValidation = value;
    }

    public get assetTagNumberRegex(): string | undefined {
        return this.programConfiguration?.validations?.assetTagNumberRegex;
    }

    public set assetTagNumberRegex(value: string | undefined) {
        this.programConfiguration = this.programConfiguration || {};
        this.programConfiguration.validations = this.programConfiguration.validations || {};
        this.programConfiguration.validations.assetTagNumberRegex = value;
    }

    public get serialNumberRegex(): string | undefined {
        return this.programConfiguration?.validations?.serialNumberRegex;
    }

    public set serialNumberRegex(value: string | undefined) {
        this.programConfiguration = this.programConfiguration || {};
        this.programConfiguration.validations = this.programConfiguration.validations || {};
        this.programConfiguration.validations.serialNumberRegex = value;
    }

    public get apiMaxTotalUnits(): number | undefined {
        return this.programConfiguration?.validations?.apiMaxTotalUnits;
    }

    public set apiMaxTotalUnits(value: number | undefined) {
        this.programConfiguration = this.programConfiguration || {};
        this.programConfiguration.validations = this.programConfiguration.validations || {};
        this.programConfiguration.validations.apiMaxTotalUnits = value;
    }

    constructor(jsonData: IProgram) {
        this.programType = jsonData.programType;
        this.programName = jsonData.programName;
        this.securityGroups = jsonData.securityGroups;
        this.adminSecurityGroup = jsonData.adminSecurityGroup;
        this.businessTeamMailingGroup = jsonData.businessTeamMailingGroup;
        this.emailNotification = jsonData.emailNotification;
        this.configuration = jsonData.configuration;
        this.programConfiguration = jsonData.programConfiguration ? new ProgramConfiguration(jsonData.programConfiguration) : {};
    }
}
