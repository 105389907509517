import React, { useState, useCallback, useEffect, useRef } from 'react';
import {
    Text,
    Spinner,
    SpinnerSize,
    SelectionMode,
    DetailsListLayoutMode,
    ConstrainMode,
    IColumn,
    IDetailsFooterProps,
    DetailsRow,
    Stack
} from '@fluentui/react';
import { commonStyles } from '../../common/common.styles';
import { ICommonPageProps } from '../../common/common.types';
import { assignOnColumnClick, ColumnsAndItems, commonColumnProps, resetColumnSorting, sortOnColumn } from '../../common/common.func';
import { clearErrorByIndex, ErrorBar, prepErrorMsg } from '../../components/ErrorBar/ErrorBar';
import { CustomDetailsList } from '../../components/CustomDetailsList/CustomDetailsList';
import { ComboInputOption, ISearchCriteria, SearchFilter } from '../../components/SearchFilter/SearchFilter';
import { appConstants, toLocaleStringTwoDigitFraction } from '../../common/appConstants';
import { resetApiCallState, callApiLoadCreditSummary, IApiLoadCreditSummary } from '../../store/actions/pageActions/creditSummaryPage.action';
import { CallApiState } from '../../store/actions/generic.action';
import { CreditSummary } from '../../models/supplier/creditSummary';
import { useAppSelector, useAppDispatch } from '../../store/hooks';
import { PageWrapper } from '../../components/PageWrapper/PageWrapper';

/*
The "SupplierWebMicroFrontend" project has a variation of this page. If changes are made here, consider if
equivalent changes are needed in that project as well. There are some differences between the projects, such as
localization and SupplierWeb integration in that project, so be careful when copying/integrating changes.
*/

interface IPageProps extends ICommonPageProps {
}

export const CreditSummaryPage: React.FunctionComponent<IPageProps> = (props: IPageProps): JSX.Element => {
    const [errors, setErrors] = useState<string[]>([]);
    const [selectedPageCreditSummaryTable, setSelectedPageCreditSummaryTable] = useState<number>(1);
    const [creditSummary, setCreditSummary] = useState<CreditSummary[] | null>();

    const grandTotals = useRef<{
        sumSupplierPoAmount?: number;
        sumSupplierPoAmountUSD?: number;
    }>({});

    // Redux store selectors to get state from the store when it changes.
    const apiLoadCreditSummary: IApiLoadCreditSummary =
        useAppSelector<IApiLoadCreditSummary>((state) => state.creditSummaryPageReducer.apiLoadCreditSummary);

    // Redux store dispatch to send actions to the store.
    const dispatch = useAppDispatch();

    /**
     * This effect does nothing on mount, but will return a cleanup function that runs when the component unmounts.
     */
    useEffect(() => {
        return function cleanup() {
            resetApiCallState();
        }
    }, []);

    /**
     * Handle error.
     * @param errMsg Error message.
     */
    const handleError = useCallback((errMsg: string) => {
        setErrors((prevErrors) => {
            // This will prevent the same error from being displayed if already displayed.
            // ex: Multiple page data load failures might occur if the api is not working,
            // and this page makes multiple load calls for various data.
            if (!prevErrors.includes(errMsg)) {
                return [...prevErrors, errMsg];
            }
            return prevErrors;
        });
    }, []);

    /**
     * Effect for when errors occur in any api call.
     */
    useEffect(() => {
        if (apiLoadCreditSummary.errMsg) {
            handleError(apiLoadCreditSummary.errMsg);
        }
    }, [handleError, apiLoadCreditSummary.errMsg]);

    /**
     * Search clicked event handler.
     * @param searchCriteria Search criteria.
     */
    const searchClicked = (searchCriteria: ISearchCriteria) => {
        setCreditSummary(undefined);

        setSelectedPageCreditSummaryTable(1);

        // The search filter is set to use the calendar (not fiscal) year and month picker, see showCalendarYearMonth on searchFilterOptions.
        // The search criteria will contain a startDate and endDate based on the selection, for the start day of the month
        // to the end day of the month. But we just need the year and month here so we just pick that off the startDate.
        const year: number = searchCriteria.startDate.getFullYear();
        const month: number = searchCriteria.startDate.getMonth() + 1; // getMonth returns zero based month, but api expects 1 based.

        if (searchCriteria.programType && searchCriteria.supplierId) {
            dispatch(callApiLoadCreditSummary(
                searchCriteria.programType,
                searchCriteria.supplierId,
                year,
                month,
                searchCriteria.supplierPoNumber
            ));
        }
    };

    /**
     * Search filter load error handler.
     * @param err Error
     */
    const searchFilterLoadError = (err: any) => {
        handleError(prepErrorMsg(appConstants.dataLoadFailed, err));
    };

    /**
     * Render right aligned currency column text. Currency values will use 2 digit precision.
     * @param item Item data.
     * @param index Index of item.
     * @param column Column being rendered.
     */
    const renderRightAlignedCurrencyColumnText = (item?: any, index?: number, column?: IColumn) => {
        if (index === undefined || index === null) {
            return;
        }

        if (!item.isDetailRow) {
            // If not a detail row then it is rendering a normal row cell.
            return (
                <div style={{ textAlign: 'right' }}>{item[column!.fieldName as string]?.toLocaleString(undefined, toLocaleStringTwoDigitFraction)}</div>
            );
        } else {
            // Render a detail row with totals.
            return (
                <div style={{ textAlign: 'right', fontWeight: 'bold' }}>
                    {column?.fieldName === 'supplierPoAmount' && <>{grandTotals.current.sumSupplierPoAmount?.toLocaleString(undefined, toLocaleStringTwoDigitFraction)}</>}
                    {column?.fieldName === 'supplierPoAmountUSD' && <>{grandTotals.current.sumSupplierPoAmountUSD?.toLocaleString(undefined, toLocaleStringTwoDigitFraction)}</>}
                </div>
            );
        }
    };

    /**
     * Columns for the Credit Summary table.
     */
    const [columnsCreditSummaryTable, setColumnsCreditSummaryTable] = useState<IColumn[]>(
        [
            {
                ...commonColumnProps,
                key: 'column1',
                name: 'Supplier Reporting Date',
                fieldName: 'supplierReportingDate',
                minWidth: 150,
                onRender: (item: CreditSummary) => {
                    return <span>{item.supplierReportingDate?.toLocaleDateString()}</span>;
                }
            },
            {
                ...commonColumnProps,
                key: 'column2',
                name: 'Supplier PO Number',
                fieldName: 'supplierPoNumber',
                minWidth: 125
            },
            {
                ...commonColumnProps,
                key: 'column3',
                name: 'Supplier PO Currency',
                fieldName: 'supplierPoCurrency',
                minWidth: 120
            },
            {
                ...commonColumnProps,
                key: 'column4',
                name: 'Supplier PO Amount',
                fieldName: 'supplierPoAmount',
                minWidth: 125,
                onRender: renderRightAlignedCurrencyColumnText
            },
            {
                ...commonColumnProps,
                key: 'column5',
                name: 'Supplier PO Amount USD',
                fieldName: 'supplierPoAmountUSD',
                minWidth: 140,
                onRender: renderRightAlignedCurrencyColumnText
            },
            {
                ...commonColumnProps,
                key: 'column6',
                name: 'MS Customer Number',
                fieldName: 'msCustomerNumber',
                minWidth: 120
            },
            {
                ...commonColumnProps,
                key: 'column7',
                name: 'PO Type',
                fieldName: 'poType',
                minWidth: 120
            }
        ]
    );

    /**
     * Effect for when Credit Summary data is loaded.
     */
    useEffect(() => {
        if (apiLoadCreditSummary.callApiState === CallApiState.DataAvailable) {
            const searchResults: CreditSummary[] | null | undefined = apiLoadCreditSummary.creditSummaries;
            setCreditSummary(searchResults);
            setColumnsCreditSummaryTable(resetColumnSorting(columnsCreditSummaryTable));

            let sumSupplierPoAmount: number = 0;
            let sumSupplierPoAmountUSD: number = 0;
            if (searchResults) {
                searchResults.forEach((value: CreditSummary, index: number) => {
                    sumSupplierPoAmount += (value.supplierPoAmount || 0);
                    sumSupplierPoAmountUSD += (value.supplierPoAmountUSD || 0);
                    value.clientRowKey = index.toString();
                });
            }

            grandTotals.current = { sumSupplierPoAmount, sumSupplierPoAmountUSD };
        }
    }, [columnsCreditSummaryTable, apiLoadCreditSummary.callApiState, apiLoadCreditSummary.creditSummaries]);

    /**
     * On column click event handler for Credit Summary table.
     * @param ev Mouse event.
     * @param column Column clicked.
     */
    const onColumnClickCreditSummaryTable = useCallback((ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        if (creditSummary) {
            const columnsAndItems: ColumnsAndItems<CreditSummary> = sortOnColumn<CreditSummary>(column, columnsCreditSummaryTable, creditSummary, undefined);
            setCreditSummary(columnsAndItems.items);
            setColumnsCreditSummaryTable(columnsAndItems.columns);
        }
    }, [columnsCreditSummaryTable, creditSummary]);

    /**
     * Effect to update onColumnClick handler. Otherwise the component state referenced in onColumnClick
     * would be stale from the render pass it was created on.
     */
    useEffect(() => {
        assignOnColumnClick(columnsCreditSummaryTable, onColumnClickCreditSummaryTable);
    }, [columnsCreditSummaryTable, onColumnClickCreditSummaryTable]);

    /**
     * Renders details footer row.
     * @param detailsFooterProps Details footer props.
     */
    const onRenderDetailsFooter = (detailsFooterProps: IDetailsFooterProps | undefined): JSX.Element => {
        if (!detailsFooterProps) {
            return <></>;
        }

        return (
            <DetailsRow
                {...detailsFooterProps}
                columns={detailsFooterProps.columns}
                item={{ isDetailRow: true }}
                itemIndex={-1}
                groupNestingDepth={detailsFooterProps.groupNestingDepth}
                selectionMode={SelectionMode.none}
                selection={detailsFooterProps.selection}
                onRenderCheck={() => <></>}
            />
        );
    };

    return (
        <PageWrapper {...props}>
            <ErrorBar errors={errors} onDismiss={(index: number) => {
                setErrors(clearErrorByIndex(errors, index));
            }} />

            <div className='ms-Grid' dir="ltr">
                <div className='ms-Grid-row'>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <div className={commonStyles.pageHeader}>
                            <Text variant="xLarge" role="heading" aria-level={1}>Credit Summary</Text>
                        </div>
                    </div>
                </div>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <SearchFilter
                            programs={props.programs || []}
                            searchFilterOptions={{
                                showFiscalRange: false,
                                fiscalRangeFieldsRequired: false,
                                showCalendarYearMonth: true,
                                showProgram: true,
                                showSupplierName: true,
                                showComboInputGroup: ComboInputOption.None,
                                showSupplierJobId: false,
                                showSupplierPoNumber: true,
                                showSupplierInvoiceNumber: false,
                                showCorrelationId: false,
                                showMsInvoiceNumber: false,
                                showSupplierUnitType: false,
                                showJobUnit: false,
                                showCountry: false,
                                showComboAssetTagAndSerialNumber: false,
                                showUnitDispositionType: false,
                                showUnitStatus: false
                            }}
                            onSearchClicked={searchClicked}
                            onSearchFilterLoadError={searchFilterLoadError} />
                    </div>
                </div>
                <div className="ms-Grid-row" style={{ marginTop: '24px' }}>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        { apiLoadCreditSummary.callApiState !== CallApiState.Initial && (
                            <>
                                <Stack style={{ marginRight: '1px' }}>
                                    <Stack.Item>
                                        { apiLoadCreditSummary.callApiState === CallApiState.Running && (
                                            <Spinner size={SpinnerSize.medium} className={commonStyles.spinner} />
                                        )}

                                        { apiLoadCreditSummary.callApiState === CallApiState.Completed && (
                                            <CustomDetailsList
                                                showExcelExport={true}
                                                exportExcelSheetName="Credit Summary"
                                                ariaLabelForGrid="Credit Summary"
                                                displayTotalItems={true}
                                                showPaginator={true}
                                                showPageSize={true}
                                                selectedPage={selectedPageCreditSummaryTable}
                                                onSelectedPageChange={(page) => {
                                                    setSelectedPageCreditSummaryTable(page);
                                                }}
                                                showNoDataFoundMsg={!creditSummary || creditSummary.length === 0}
                                                items={creditSummary || []}
                                                compact={false}
                                                columns={columnsCreditSummaryTable}
                                                selectionMode={SelectionMode.none}
                                                getKey={(item: CreditSummary) => item.clientRowKey}
                                                setKey="none"
                                                layoutMode={DetailsListLayoutMode.fixedColumns}
                                                isHeaderVisible={true}
                                                constrainMode={ConstrainMode.horizontalConstrained}
                                                onRenderDetailsFooter={onRenderDetailsFooter} />
                                        )}
                                    </Stack.Item>
                                </Stack>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </PageWrapper>
    );
};
