import React, { useState } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { CoherenceTheme } from '@coherence-design-system/styles';
import { loadTheme, Stack, ThemeProvider } from '@fluentui/react';
import AppHeader from './shell/AppHeader/AppHeader';
import { AppNav } from './shell/AppNav/AppNav';
import { UnauthorizedPage } from './pages/UnauthorizedPage/UnauthorizedPage';
import { Program } from './models/domainData/program';
import { HomePage } from './pages/HomePage/HomePage';
import { ReportsPage } from './pages/ReportsPage/ReportsPage';
import { CarbonFootprintPage } from './pages/CarbonFootprintPage/CarbonFootprintPage';
import { CertificatesPage } from './pages/CertificatesPage/CertificatesPage';
import { ConnectedDataPage } from './pages/ConnectedDataPage/ConnectedDataPage';
import { DocLinkPage } from './pages/DocLinkPage/DocLinkPage';
import { DiagnosticsPage } from './pages/DiagnosticsPage/DiagnosticsPage';
import { PaymentNotificationPage } from './pages/PaymentNotificationPage/PaymentNotificationPage';
import { DbdPage } from './pages/DbdPage/DbdPage';
import { InvoiceSummaryPage } from './pages/InvoiceSummaryPage/InvoiceSummaryPage';
import { CreditSummaryPage } from './pages/CreditSummaryPage/CreditSummaryPage';
import { TransportAndPackagingPage } from './pages/TransportAndPackagingPage/TransportAndPackagingPage';
import { AdminPage } from './pages/AdminPage/AdminPage';
import { SecurityGroupMembership } from './models/appModels/securityGroupMembership';
import { appStyles } from './App.styles';
import { CopilotPanel } from './shell/CopilotPanel/CopilotPanel';
import { useAppDispatch, useAppSelector } from './store/hooks';
import { setCopilotPanelIsOpen } from './store/actions/app.action';

loadTheme(CoherenceTheme);

interface AppProps {
    aadOid?: string;
    userEmail?: string;
    programs?: Program[];
    securityGroupMemberships?: SecurityGroupMembership[];
}

export const App: React.FunctionComponent<AppProps> = (props: AppProps): JSX.Element => {
    const [isNavCollapsed, setIsNavCollapsed] = useState<boolean>(true);
    const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);

    // Redux store selectors to get state from the store when it changes.
    const copilotPanelIsOpen: boolean =
        useAppSelector<boolean>((state) => state.appReducer.copilotPanelIsOpen);

    // Redux store dispatch to send actions to the store.
    const dispatch = useAppDispatch();

    /**
     * On navigation collapsed event handler.
     * @param isCollapsed Whether the navigation menu is collapsed or not.
     */
    const onNavCollapsed = (isCollapsed: boolean) => {
        // This is to work around a bug in CoherenceNav where the initial value for isCollapsed is always false, even
        // through we set isNavCollapsed to true initially.
        if (isInitialLoad) {
            isCollapsed = true;
            setIsInitialLoad(false);
        }
        setIsNavCollapsed(isCollapsed);
    };

    /**
     * Checks whether or not the user is a member of the recycling team security group.
     * @returns True, if the user is a member of the recycling security group. False, otherwise.
     */
    const isUserMemberOfRecyclingTeam = (): boolean => {
        if (props.securityGroupMemberships === undefined || props.securityGroupMemberships.length === 0) {
            return false;
        }

        for (let i = 0; i < props.securityGroupMemberships.length; i++) {
            const sg: SecurityGroupMembership = props.securityGroupMemberships[i];
            if (sg.sgName === 'MSRecycling Team' && sg.isMember) {
                return true;
            }
        }

        return false;
    }

    /**
     * Checks whether or not the user authorized to view any program types.
     * @returns True, if the user is authorized for program types. False, otherwise.
     */
    const isAuthorizedForProgramTypes = (): boolean => {
        if (props.programs && props.programs.length > 0) {
            return true;
        }
        return false;
    }

    /**
     * Gets the page component passed as a parameter, if the user is authorized to view a program type.
     * @param restrictedPageComponent The page component for which we are checking authorization.
     * @param unauthorizedMessage An alternative unauthorized message to be used.
     * @returns The page component, if authorized. The unauthorized page component otherwise.
     */
    const getProgramTypeAuthorizedPage = (restrictedPageComponent: JSX.Element, unauthorizedMessage?: string): JSX.Element => {
        if (props.programs && props.programs.length > 0) {
            return restrictedPageComponent;
        }

        return (
            <UnauthorizedPage
                error={unauthorizedMessage}>
            </UnauthorizedPage>
        );
    }

    return (
        <ThemeProvider theme={CoherenceTheme}>
            <div className={appStyles.windowContainer}>
                <AppHeader />
                <BrowserRouter>
                    <div className={appStyles.pageContainer}>
                        <AppNav
                            isAuthorizedForProgramTypes={isAuthorizedForProgramTypes}
                            isUserMemberOfRecyclingTeam={isUserMemberOfRecyclingTeam}
                        />
                        <Stack horizontal wrap={false}>
                            <Stack.Item>
                                <main id="main" tabIndex={-1} className={appStyles.mainContainer}>
                                    <div className={appStyles.mainContent}>
                                        <Routes>
                                            <Route path="/Home" element={getProgramTypeAuthorizedPage(
                                                <HomePage
                                                    userEmail={props.userEmail || ''}
                                                    programs={props.programs || []}
                                                />
                                            )} />
                                            <Route path="/ConnectedData" element={getProgramTypeAuthorizedPage(
                                                <ConnectedDataPage
                                                    userEmail={props.userEmail || ''}
                                                    programs={props.programs || []}
                                                />
                                            )} />
                                            <Route path="/Certificates" element={getProgramTypeAuthorizedPage(
                                                <CertificatesPage
                                                    userEmail={props.userEmail || ''}
                                                    programs={props.programs || []}
                                                />
                                            )} />
                                            <Route path="/DBD" element={getProgramTypeAuthorizedPage(
                                                <DbdPage
                                                    userEmail={props.userEmail || ''}
                                                    programs={props.programs || []}
                                                />
                                            )} />
                                            <Route path="/InvoiceSummary" element={getProgramTypeAuthorizedPage(
                                                <InvoiceSummaryPage
                                                    userEmail={props.userEmail || ''}
                                                    programs={props.programs || []}
                                                />
                                            )} />
                                            <Route path="/CreditSummary" element={getProgramTypeAuthorizedPage(
                                                <CreditSummaryPage
                                                    userEmail={props.userEmail || ''}
                                                    programs={props.programs || []}
                                                />
                                            )} />
                                            <Route path="/PaymentNotifications" element={getProgramTypeAuthorizedPage(
                                                <PaymentNotificationPage
                                                    userEmail={props.userEmail || ''}
                                                    programs={props.programs || []}
                                                />
                                            )} />
                                            <Route path="/TransportAndPackaging" element={getProgramTypeAuthorizedPage(
                                                <TransportAndPackagingPage
                                                    userEmail={props.userEmail || ''}
                                                    programs={props.programs || []}
                                                />
                                            )} />
                                            <Route path="/Reports" element={getProgramTypeAuthorizedPage(
                                                <ReportsPage
                                                    userEmail={props.userEmail || ''}
                                                    programs={props.programs || []}
                                                />
                                            )} />
                                            <Route path="/CarbonFootprint" element={getProgramTypeAuthorizedPage(
                                                <CarbonFootprintPage
                                                    userEmail={props.userEmail || ''}
                                                    programs={props.programs || []}
                                                />
                                            )} />
                                            <Route path="/DocLink" element={getProgramTypeAuthorizedPage(
                                                <DocLinkPage
                                                    userEmail={props.userEmail || ''}
                                                    programs={props.programs || []}
                                                />
                                            )} />
                                            <Route path="/Diagnostics" element={ 
                                                <DiagnosticsPage
                                                    userEmail={props.userEmail || ''}
                                                    programs={props.programs || []}
                                                />
                                            } />
                                            <Route path="/Administration" element={getProgramTypeAuthorizedPage(
                                                <AdminPage
                                                    userEmail={props.userEmail || ''}
                                                    programs={props.programs || []}
                                                />
                                            )} />
                                            <Route path="*" element={<Navigate to="/Home" />} />
                                        </Routes>
                                    </div>
                                </main>
                            </Stack.Item>
                            <Stack.Item>
                                <div className={copilotPanelIsOpen ? appStyles.copilotContainer : appStyles.copilotContainerCollapsed}>
                                    <CopilotPanel
                                        isOpen={copilotPanelIsOpen}
                                        onDismissCopilotPanel={() => {
                                            dispatch(setCopilotPanelIsOpen(!copilotPanelIsOpen));
                                        }}
                                    />
                                </div>
                            </Stack.Item>
                        </Stack>
                    </div>
                </BrowserRouter>
            </div>
        </ThemeProvider>
    );
};
