export interface ISupplierSummary {
    correlationId: string;
    type: string;
    createdDate: Date;
    supplierReportingDateUtc: Date;
    programType: string;
    supplierId: string;
    supplierName: string;
    supplierJobId: string;
    supplierJobType: string;
    supplierUnitId: string;
    supplierUnitType: string;
    quantity?: number;
    weightInKilos?: number;
    unitStatus: string;
    dispositionDate?: Date;
    dispositionType: string;
    returnType: string;
}

export class SupplierSummary implements ISupplierSummary {
    public correlationId: string;
    public type: string;
    public createdDate: Date;
    public supplierReportingDateUtc: Date;
    public programType: string;
    public supplierId: string;
    public supplierName: string;
    public supplierJobId: string;
    public supplierJobType: string;
    public supplierUnitId: string;
    public supplierUnitType: string;
    public quantity?: number;
    public weightInKilos?: number;
    public unitStatus: string;
    public dispositionDate?: Date;
    public dispositionType: string;
    public returnType: string;

    // Below are properties NOT returned from the server but are used for client side purposes.
    public clientRowKey: string = '';

    constructor(jsonData: ISupplierSummary) {
        this.correlationId = jsonData.correlationId;
        this.type = jsonData.type;
        this.createdDate = jsonData.createdDate;
        this.supplierReportingDateUtc = new Date(jsonData.supplierReportingDateUtc);
        this.programType = jsonData.programType;
        this.supplierId = jsonData.supplierId;
        this.supplierName = jsonData.supplierName;
        this.supplierJobId = jsonData.supplierJobId;
        this.supplierJobType = jsonData.supplierJobType;
        this.supplierUnitId = jsonData.supplierUnitId;
        this.supplierUnitType = jsonData.supplierUnitType;
        this.quantity = jsonData.quantity;
        this.weightInKilos = jsonData.weightInKilos;
        this.unitStatus = jsonData.unitStatus;
        this.dispositionDate = jsonData.dispositionDate ? new Date(jsonData.dispositionDate) : undefined;
        this.dispositionType = jsonData.dispositionType;
        this.returnType = jsonData.returnType;
    }
}
