export interface ICreditSummary {
    id: string;
    partitionKey: string;
    xcv: string;
    programType: string;
    year: number;
    month: number;
    supplierId: string;
    supplierName: string;
    msCompanyCode: string;
    supplierReportingDate: Date;
    supplierPoNumber: string;
    supplierPoCurrency: string;
    supplierPoAmount: number;
    supplierPoAmountUSD: number;
    msCustomerNumber: string;
    poType: string;
    modifiedDate: Date;
}

export class CreditSummary implements ICreditSummary {
    public id: string;
    public partitionKey: string;
    public xcv: string;
    public programType: string;
    public year: number;
    public month: number;
    public supplierId: string;
    public supplierName: string;
    public msCompanyCode: string;
    public supplierReportingDate: Date;
    public supplierPoNumber: string;
    public supplierPoCurrency: string;
    public supplierPoAmount: number;
    public supplierPoAmountUSD: number;
    public msCustomerNumber: string;
    public poType: string;
    public modifiedDate: Date;

    // Below are properties NOT returned from the server but are used for client side purposes.
    public clientRowKey: string = '';

    constructor(jsonData: ICreditSummary) {
        this.id = jsonData.id;
        this.partitionKey = jsonData.partitionKey;
        this.xcv = jsonData.xcv;
        this.programType = jsonData.programType;
        this.year = jsonData.year;
        this.month = jsonData.month;
        this.supplierId = jsonData.supplierId;
        this.supplierName = jsonData.supplierName;
        this.msCompanyCode = jsonData.msCompanyCode;
        this.supplierReportingDate = new Date(jsonData.supplierReportingDate);
        this.supplierPoNumber = jsonData.supplierPoNumber;
        this.supplierPoCurrency = jsonData.supplierPoCurrency;
        this.supplierPoAmount = jsonData.supplierPoAmount;
        this.supplierPoAmountUSD = jsonData.supplierPoAmountUSD;
        this.msCustomerNumber = jsonData.msCustomerNumber;
        this.poType = jsonData.poType;
        this.modifiedDate = new Date(jsonData.modifiedDate);
    }
}
