import { serviceApiClient } from '../../../services/api/serviceApiClient';
import * as actionTypes from '../actionTypes';
import { callApi, CallApiState, ICallApiBase } from '../generic.action';
import { SupplierSummary } from '../../../models/supplier/supplierSummary';
import { RecyclingDocument } from '../../../models/recyclingDetail/recyclingDocument';
import { JobSearchSummary } from '../../../models/supplier/jobSearchSummary';
import { SupplierJobDetails } from '../../../models/supplier/supplierJobDetails';
import { SupplierInvoiceInfo } from '../../../models/supplier/supplierInvoiceInfo';
import { SupplierPurchaseOrderInfo } from '../../../models/supplier/supplierPurchaseOrderInfo';
import { AppDispatch, reduxStore } from '../../reduxStore';

/**
 * Load supplier summary action payload.
 */
export interface IApiLoadSupplierSummary extends ICallApiBase {
    supplierSummaries?: SupplierSummary[] | null;
}

/**
 * Load supplier summary.
 * @param programType Program type.
 * @param supplierId Supplier id.
 * @param supplierJobId Supplier job id.
 * @param supplierUnitId Supplier unit id.
 * @param correlationId Correlation id.
 * @param supplierUnitType Supplier unit type.
 * @param startDateUtc Start date utc.
 * @param endDateUtc End date utc.
 * @param dispositionType Disposition type.
 * @param unitStatus Unit status.
 * @returns Redux dispatch function.
 */
export const callApiLoadSupplierSummary = (programType?: string, supplierId?: string, supplierJobId?: string, supplierUnitId?: string, correlationId?: string, supplierUnitType?: string, startDateUtc?: Date, endDateUtc?: Date, dispositionType?: string, unitStatus?: string): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<SupplierSummary[]>(
        actionTypes.API_LOAD_SUPPLIER_SUMMARY,
        async () => {
            return await serviceApiClient.supplierSummary(
                programType,
                supplierId,
                undefined, // Not using supplierJobType.
                supplierJobId,
                supplierUnitId,
                correlationId,
                supplierUnitType,
                undefined, // Not using countryCode.
                startDateUtc,
                endDateUtc,
                dispositionType,
                unitStatus
            ) || [];
        },
        (payload: IApiLoadSupplierSummary, data) => {
            if (data) {
                // Set the clientRowKey in each object so it can be bound into the DetailsList.
                data.forEach((value: SupplierSummary, index: number) => {
                    value.clientRowKey = index.toString()
                });
            }

            payload.supplierSummaries = data;
        }
    );
};

/**
 * Load job unit details action payload.
 */
export interface IApiLoadJobUnitDetails extends ICallApiBase {
    recyclingDocument?: RecyclingDocument | null;
}

/**
 * Load job unit details.
 * @param supplierId Supplier id.
 * @param supplierJobId Supplier job id.
 * @param supplierUnitId Supplier unit id.
 * @returns Redux dispatch function.
 */
export const callApiLoadJobUnitDetails = (supplierId: string, supplierJobId: string, supplierUnitId: string): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<RecyclingDocument | null>(
        actionTypes.API_LOAD_JOB_UNIT_DETAILS,
        async () => {
            return await serviceApiClient.jobUnitDetails(supplierId, supplierJobId, supplierUnitId);
        },
        (payload: IApiLoadJobUnitDetails, data) => {
            payload.recyclingDocument = data;
        }
    );
};

/**
 * Job search action payload.
 */
export interface IApiJobSearch extends ICallApiBase {
    jobSearchSummary?: JobSearchSummary | null;
}

/**
 * Job search.
 * @param programType Program type.
 * @param supplierId Supplier id.
 * @param supplierJobId Supplier job id.
 * @param supplierInvoiceNumber Supplier invoice number.
 * @param supplierPoNumber Supplier PO number.
 * @param correlationId Correlation id.
 * @param startDateUtc Start date utc.
 * @param endDateUtc End date utc.
 * @returns Redux dispatch function.
 */
export const callApiJobSearch = (programType?: string, supplierId?: string, supplierJobId?: string, supplierInvoiceNumber?: string, supplierPoNumber?: string, correlationId?: string, startDateUtc?: Date, endDateUtc?: Date): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<JobSearchSummary | null>(
        actionTypes.API_JOB_SEARCH,
        async () => {
            return await serviceApiClient.jobSearch(programType, supplierId, supplierJobId, supplierInvoiceNumber, supplierPoNumber, correlationId, startDateUtc, endDateUtc);
        },
        (payload: IApiJobSearch, data) => {
            if (data && data.supplierJobDetails) {
                // Set the clientRowKey in each object so it can be bound into the DetailsList.
                data.supplierJobDetails.forEach((value: SupplierJobDetails, index: number) => {
                    value.clientRowKey = index.toString()
                });
            }
            if (data && data.supplierInvoiceSummary) {
                // Set the clientRowKey in each object so it can be bound into the DetailsList.
                data.supplierInvoiceSummary.forEach((value: SupplierInvoiceInfo, index: number) => {
                    value.clientRowKey = index.toString()
                });
            }
            if (data && data.supplierPurchaseOrderSummary) {
                // Set the clientRowKey in each object so it can be bound into the DetailsList.
                data.supplierPurchaseOrderSummary.forEach((value: SupplierPurchaseOrderInfo, index: number) => {
                    value.clientRowKey = index.toString()
                });
            }

            payload.jobSearchSummary = data;
        }
    );
};

/**
 * Reset the page action call state for all api calls.
 * This clear all data from the Redux store for these calls, including any error state for failed calls.
 * This makes it so if there was a failed call and an error was shown on the page, if the user navigates
 * to another page and back, the prior error would not be shown.
 */
export const resetApiCallState = () => {
    const apiLoadSupplierSummary: IApiLoadSupplierSummary = {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        supplierSummaries: undefined
    };
    reduxStore.dispatch({
        type: actionTypes.API_LOAD_SUPPLIER_SUMMARY,
        payload: apiLoadSupplierSummary
    });

    const apiLoadJobUnitDetails: IApiLoadJobUnitDetails = {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        recyclingDocument: undefined
    };
    reduxStore.dispatch({
        type: actionTypes.API_LOAD_JOB_UNIT_DETAILS,
        payload: apiLoadJobUnitDetails
    });

    const apiJobSearch: IApiJobSearch = {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        jobSearchSummary: undefined
    };
    reduxStore.dispatch({
        type: actionTypes.API_JOB_SEARCH,
        payload: apiJobSearch
    });
};
