import { IStyle, mergeStyles } from '@fluentui/react';

// Global styles used site wide.
mergeStyles({
    ':global(body), :global(html), :global(#app)': {
        margin: 0,
        padding: 0,
        height: '100vh'
    } as IStyle,
    ':global(.ms-Grid-row)': {
        margin: '0 0 0 -8px' // Normally: 0 -8px; Removed the -8px on the right as it was causing a horizontal page scroll bar.
    } as IStyle
});
