import { Supplier } from '../../../models/supplier/supplier';
import { serviceApiClient } from '../../../services/api/serviceApiClient';
import { AppDispatch, reduxStore } from '../../reduxStore';
import * as actionTypes from '../actionTypes';
import { callApi, CallApiState, ICallApiBase } from '../generic.action';

/**
 * Load suppliers action payload.
 */
export interface IApiLoadSuppliers extends ICallApiBase {
    suppliers?: Supplier[] | null;
}

/**
 * Load suppliers.
 * @returns Redux dispatch function.
 */
export const loadSuppliers = (programType: string): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<Supplier[]>(
        actionTypes.API_LOAD_SUPPLIERS,
        async () => {
            return await serviceApiClient.suppliers(programType) || [];
        },
        (payload: IApiLoadSuppliers, data) => {
            if (data) {
                // Set the clientRowKey in each object so it can be bound into the DetailsList.
                data.forEach((value: Supplier, index: number) => {
                    value.clientRowKey = index.toString()
                });
            }

            payload.suppliers = data;
        }
    );
};

/**
 * Update supplier action payload.
 */
export interface IApiUpdateSupplier extends ICallApiBase {
    // No data returned from this api.
}

/**
 * Update supplier.
 * @param supplier Supplier to update.
 * @returns Redux dispatch function.
 */
export const updateSupplier = (supplier: Supplier): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<null>(
        actionTypes.API_UPDATE_SUPPLIER,
        async () => {
            return await serviceApiClient.updateSupplier(supplier);
        },
        (payload: IApiUpdateSupplier, data) => {
            // No data returned from this api.
        }
    );
};

/**
 * Add supplier action payload.
 */
export interface IApiAddSupplier extends ICallApiBase {
    // No data returned from this api.
}

/**
 * Add supplier.
 * @param supplier Supplier to add.
 * @param programType Program type.
 * @returns Redux dispatch function.
 */
export const addSupplier = (supplier: Supplier, programType: string): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<null>(
        actionTypes.API_ADD_SUPPLIER,
        async () => {
            return await serviceApiClient.addSupplier(supplier, programType);
        },
        (payload: IApiAddSupplier, data) => {
            // No data returned from this api.
        }
    );
};

/**
 * Reset the page action call state for all api calls.
 * This clear all data from the Redux store for these calls, including any error state for failed calls.
 * This makes it so if there was a failed call and an error was shown on the page, if the user navigates
 * to another page and back, the prior error would not be shown.
 */
export const resetApiCallState = () => {
    const apiLoadSuppliers: IApiLoadSuppliers = {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        suppliers: undefined
    };
    reduxStore.dispatch({
        type: actionTypes.API_LOAD_SUPPLIERS,
        payload: apiLoadSuppliers
    });

    const apiUpdateSuppliers: IApiUpdateSupplier = {
        callApiState: CallApiState.Initial,
        errMsg: undefined
    };
    reduxStore.dispatch({
        type: actionTypes.API_UPDATE_SUPPLIER,
        payload: apiUpdateSuppliers
    });

    const apiAddSuppliers: IApiAddSupplier = {
        callApiState: CallApiState.Initial,
        errMsg: undefined
    };
    reduxStore.dispatch({
        type: actionTypes.API_ADD_SUPPLIER,
        payload: apiAddSuppliers
    });
};
