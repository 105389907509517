export interface ICertificateSummary {
    serialNumber?: string;
    assetTagNumber?: string;
    supplierUnitType?: string;
    quantity?: number;
    msCompanyCode?: string;
    supplierUnitId?: string;
    supplierJobId?: string;
    supplierJobType?: string;
    supplierName?: string;
    fileName?: string;
    fileBlobUrl?: string;
    manufacturer?: string;
    model?: string;
    partNumber?: string;
    wipeCertificateNumber?: string;
    msPoNumber?: string;
}

export class CertificateSummary implements ICertificateSummary {
    public serialNumber?: string;
    public assetTagNumber?: string;
    public supplierUnitType?: string;
    public quantity?: number;
    public msCompanyCode?: string;
    public supplierUnitId?: string;
    public supplierJobId?: string;
    public supplierJobType?: string;
    public supplierName?: string;
    public fileName?: string;
    public fileBlobUrl?: string;
    public manufacturer?: string;
    public model?: string;
    public partNumber?: string;
    public wipeCertificateNumber?: string;
    public msPoNumber?: string;

    // Below are properties NOT returned from the server but are used for client side purposes.
    public clientRowKey: string = '';

    constructor(jsonData: ICertificateSummary) {
        this.serialNumber = jsonData.serialNumber;
        this.assetTagNumber = jsonData.assetTagNumber;
        this.supplierUnitType = jsonData.supplierUnitType;
        this.quantity = jsonData.quantity;
        this.msCompanyCode = jsonData.msCompanyCode;
        this.supplierUnitId = jsonData.supplierUnitId;
        this.supplierJobId = jsonData.supplierJobId;
        this.supplierJobType = jsonData.supplierJobType;
        this.supplierName = jsonData.supplierName;
        this.fileName = jsonData.fileName;
        this.fileBlobUrl = jsonData.fileBlobUrl;
        this.manufacturer = jsonData.manufacturer;
        this.model = jsonData.model;
        this.partNumber = jsonData.partNumber;
        this.wipeCertificateNumber = jsonData.wipeCertificateNumber;
        this.msPoNumber = jsonData.msPoNumber;
    }
}
