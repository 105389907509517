import { Validations } from './validations';

export interface IProgramConfiguration {
    validations?: Validations;

    // There are other fields on the server side model but not needed client side.
}

export class ProgramConfiguration implements IProgramConfiguration {
    public validations?: Validations;

    constructor(jsonData: IProgramConfiguration) {
        this.validations = jsonData.validations ? new Validations(jsonData.validations) : {};
    }
}
