import { SupplierUnitType } from '../../../models/domainData/supplierUnitType';
import { serviceApiClient } from '../../../services/api/serviceApiClient';
import { AppDispatch, reduxStore } from '../../reduxStore';
import * as actionTypes from '../actionTypes';
import { callApi, CallApiState, ICallApiBase } from '../generic.action';

/**
 * Load supplier unit types action payload.
 */
export interface IApiLoadSupplierUnitTypes extends ICallApiBase {
    supplierUnitTypes?: SupplierUnitType[] | null;
}

/**
 * Load supplier unit types.
 * @returns Redux dispatch function.
 */
export const loadSupplierUnitTypes = (programType: string): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<SupplierUnitType[]>(
        actionTypes.API_LOAD_SUPPLIER_UNIT_TYPES,
        async () => {
            return await serviceApiClient.supplierUnitTypes(programType) || [];
        },
        (payload: IApiLoadSupplierUnitTypes, data) => {
            if (data) {
                // Set the clientRowKey in each object so it can be bound into the DetailsList.
                data.forEach((value: SupplierUnitType, index: number) => {
                    value.clientRowKey = index.toString()
                });
            }

            payload.supplierUnitTypes = data;
        }
    );
};

/**
 * Update supplier unit type action payload.
 */
export interface IApiUpdateSupplierUnitType extends ICallApiBase {
    // No data returned from this api.
}

/**
 * Update supplier unit type.
 * @param supplierUnitType Supplier unit type to update.
 * @returns Redux dispatch function.
 */
export const updateSupplierUnitType = (supplierUnitType: SupplierUnitType): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<null>(
        actionTypes.API_UPDATE_SUPPLIER_UNIT_TYPE,
        async () => {
            return await serviceApiClient.updateSupplierUnitType(supplierUnitType);
        },
        (payload: IApiUpdateSupplierUnitType, data) => {
            // No data returned from this api.
        }
    );
};

/**
 * Add supplier unit type action payload.
 */
export interface IApiAddSupplierUnitType extends ICallApiBase {
    // No data returned from this api.
}

/**
 * Add supplier unit type.
 * @param supplierUnitType Supplier unit type to add.
 * @param programType Program type.
 * @returns Redux dispatch function.
 */
export const addSupplierUnitType = (supplierUnitType: SupplierUnitType, programType: string): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<null>(
        actionTypes.API_ADD_SUPPLIER_UNIT_TYPE,
        async () => {
            return await serviceApiClient.addSupplierUnitType(supplierUnitType, programType);
        },
        (payload: IApiAddSupplierUnitType, data) => {
            // No data returned from this api.
        }
    );
};

/**
 * Delete supplier unit type action payload.
 */
export interface IApiDeleteSupplierUnitType extends ICallApiBase {
    // No data returned from this api.
}

/**
 * Delete supplier unit type.
 * @param supplierUnitType Supplier unit type to delete.
 * @param programType Program type.
 * @returns Redux dispatch function.
 */
export const deleteSupplierUnitType = (supplierUnitType: SupplierUnitType): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<null>(
        actionTypes.API_DELETE_SUPPLIER_UNIT_TYPE,
        async () => {
            return await serviceApiClient.deleteSupplierUnitType(supplierUnitType);
        },
        (payload: IApiDeleteSupplierUnitType, data) => {
            // No data returned from this api.
        }
    );
};

/**
 * Reset the page action call state for all api calls.
 * This clear all data from the Redux store for these calls, including any error state for failed calls.
 * This makes it so if there was a failed call and an error was shown on the page, if the user navigates
 * to another page and back, the prior error would not be shown.
 */
export const resetApiCallState = () => {
    const apiLoadSupplierUnitTypes: IApiLoadSupplierUnitTypes = {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        supplierUnitTypes: undefined
    };
    reduxStore.dispatch({
        type: actionTypes.API_LOAD_SUPPLIER_UNIT_TYPES,
        payload: apiLoadSupplierUnitTypes
    });

    const apiUpdateSupplierUnitTypes: IApiUpdateSupplierUnitType = {
        callApiState: CallApiState.Initial,
        errMsg: undefined
    };
    reduxStore.dispatch({
        type: actionTypes.API_UPDATE_SUPPLIER_UNIT_TYPE,
        payload: apiUpdateSupplierUnitTypes
    });

    const apiAddSupplierUnitTypes: IApiAddSupplierUnitType = {
        callApiState: CallApiState.Initial,
        errMsg: undefined
    };
    reduxStore.dispatch({
        type: actionTypes.API_ADD_SUPPLIER_UNIT_TYPE,
        payload: apiAddSupplierUnitTypes
    });

    const apiDeleteSupplierUnitTypes: IApiDeleteSupplierUnitType = {
        callApiState: CallApiState.Initial,
        errMsg: undefined
    };
    reduxStore.dispatch({
        type: actionTypes.API_DELETE_SUPPLIER_UNIT_TYPE,
        payload: apiDeleteSupplierUnitTypes
    });
};
