export interface IDestructionType {
    partitionKey: string;
    rowKey: string;
    destructionType: string;
}

export class DestructionType implements IDestructionType {
    public partitionKey: string;
    public rowKey: string;
    public destructionType: string;

    // Below are properties NOT returned from the server but are used for client side purposes.
    public clientRowKey: string = '';
    public isNew?: boolean;

    constructor(jsonData: IDestructionType) {
        this.partitionKey = jsonData.partitionKey;
        this.rowKey = jsonData.rowKey;
        this.destructionType = jsonData.destructionType;
    }
}
