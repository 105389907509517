import {
    ICoherencePageSizeStyleProps,
    ICoherencePageSizeStyles,
    ICoherencePageSizeSubComponentsStyles,
    ICoherencePaginationComponentsStyles,
    ICoherencePaginationStyleProps,
    ICoherencePaginationStyles
} from '@coherence-design-system/controls';
import {
    IStackStyles,
    IStyle,
    IStyleFunctionOrObject,
    mergeStyleSets
} from '@fluentui/react';

/**
 * Styles used on this control.
 */
export const controlStyles = mergeStyleSets({
    paginatorContainer: {
        marginTop: '15px',
        marginBottom: '15px'
    } as IStyle,
    footerTextContainer: {
        whiteSpace: 'nowrap',
        padding: '0 30px 0 0',
        lineHeight: '44px'
    } as IStyle,
    footerText: {
        verticalAlign: 'middle'
    } as IStyle,
    exportButton: {
        marginTop: '0px',
        minWidth: '30px',
        maxWidth: '30px',
        verticalAlign: 'middle'
    } as IStyle,
    excelExport: {
        padding: '0 30px 0 0',
        lineHeight: '48px'
    } as IStyle,
    excelIcon: {
        fontSize: 16
    } as IStyle
});

/**
 * Styles used with coherence pagination control.
 */
export const coherencePaginationStyles: IStyleFunctionOrObject<ICoherencePaginationStyleProps, ICoherencePaginationStyles> = {
    root: {
        gridTemplateColumns: 'auto' // Override default: '1fr minmax(375px, 1fr) 1fr' (which makes it appear centered)
    } as IStyle,
    subComponentStyles: {
        container: {
            root: {
                height: '100%'
            } as IStyle
        }
    } as ICoherencePaginationComponentsStyles
};

/**
 * Styles used with coherence page size control.
 */
export const coherencePageSizeStyles: IStyleFunctionOrObject<ICoherencePageSizeStyleProps, ICoherencePageSizeStyles> = {
    subComponentStyles: {
        container: {
            root: {
                height: '100%'
            } as IStyle
        } as IStyleFunctionOrObject<Record<string, unknown>, IStackStyles>
    } as ICoherencePageSizeSubComponentsStyles
} as IStyleFunctionOrObject<ICoherencePageSizeStyleProps, ICoherencePageSizeStyles>;
