import { AnyAction, Reducer } from 'redux';
import * as actionTypes from '../../actions/actionTypes';
import { IApiLoadPackagingMaterialTypes, IApiUpdatePackagingMaterialType, IApiAddPackagingMaterialType, IApiDeletePackagingMaterialType } from '../../actions/adminTabActions/adminTabPackagingMaterialTypes.action';
import { CallApiState, StateUpdateOption } from '../../actions/generic.action';

export interface IAdminTabPackagingMaterialTypesReducerState {
    apiLoadPackagingMaterialTypes: IApiLoadPackagingMaterialTypes;
    apiUpdatePackagingMaterialType: IApiUpdatePackagingMaterialType;
    apiAddPackagingMaterialType: IApiAddPackagingMaterialType;
    apiDeletePackagingMaterialType: IApiDeletePackagingMaterialType;
}

const initialAdminTabSupplierReducerState: IAdminTabPackagingMaterialTypesReducerState = {
    apiLoadPackagingMaterialTypes: {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        packagingMaterialTypes: undefined
    },
    apiUpdatePackagingMaterialType: {
        callApiState: CallApiState.Initial,
        errMsg: undefined
    },
    apiAddPackagingMaterialType: {
        callApiState: CallApiState.Initial,
        errMsg: undefined
    },
    apiDeletePackagingMaterialType: {
        callApiState: CallApiState.Initial,
        errMsg: undefined
    }
}

export const adminTabPackagingMaterialTypesReducer: Reducer<IAdminTabPackagingMaterialTypesReducerState, AnyAction> = (
    state: IAdminTabPackagingMaterialTypesReducerState = initialAdminTabSupplierReducerState, action: AnyAction
): IAdminTabPackagingMaterialTypesReducerState => {
    switch (action.type) {
        case actionTypes.API_LOAD_PACKAGING_MATERIAL_TYPES: {
            const payload: IApiLoadPackagingMaterialTypes = action.payload as IApiLoadPackagingMaterialTypes;
            switch (payload.stateUpdateOption) {
                case StateUpdateOption.ErrorOnly:
                    return {
                        ...state,
                        // Keep the prior payload except for the errMsg which will be set to undefined.
                        apiLoadPackagingMaterialTypes: {
                            ...state.apiLoadPackagingMaterialTypes,
                            errMsg: undefined
                        }
                    }
                default:
                    return {
                        ...state,
                        apiLoadPackagingMaterialTypes: { ...payload }
                    }
            }
        }
        case actionTypes.API_UPDATE_PACKAGING_MATERIAL_TYPE: {
            const payload: IApiUpdatePackagingMaterialType = action.payload as IApiUpdatePackagingMaterialType;
            switch (payload.stateUpdateOption) {
                case StateUpdateOption.ErrorOnly:
                    return {
                        ...state,
                        // Keep the prior payload except for the errMsg which will be set to undefined.
                        apiUpdatePackagingMaterialType: {
                            ...state.apiUpdatePackagingMaterialType,
                            errMsg: undefined
                        }
                    }
                default:
                    return {
                        ...state,
                        apiUpdatePackagingMaterialType: { ...payload }
                    }
            }
        }
        case actionTypes.API_ADD_PACKAGING_MATERIAL_TYPE: {
            const payload: IApiAddPackagingMaterialType = action.payload as IApiAddPackagingMaterialType;
            switch (payload.stateUpdateOption) {
                case StateUpdateOption.ErrorOnly:
                    return {
                        ...state,
                        // Keep the prior payload except for the errMsg which will be set to undefined.
                        apiAddPackagingMaterialType: {
                            ...state.apiAddPackagingMaterialType,
                            errMsg: undefined
                        }
                    }
                default:
                    return {
                        ...state,
                        apiAddPackagingMaterialType: { ...payload }
                    }
            }
        }
        case actionTypes.API_DELETE_PACKAGING_MATERIAL_TYPE: {
            const payload: IApiDeletePackagingMaterialType = action.payload as IApiDeletePackagingMaterialType;
            switch (payload.stateUpdateOption) {
                case StateUpdateOption.ErrorOnly:
                    return {
                        ...state,
                        // Keep the prior payload except for the errMsg which will be set to undefined.
                        apiDeletePackagingMaterialType: {
                            ...state.apiDeletePackagingMaterialType,
                            errMsg: undefined
                        }
                    }
                default:
                    return {
                        ...state,
                        apiDeletePackagingMaterialType: { ...payload }
                    }
            }
        }
        default:
    }

    return state;
}
