import { JobDocuments } from './jobDocuments';

export interface ISupplierJobDetails {
    id: string;
    partitionKey: string;
    programType: string;
    supplierId: string;
    supplierName: string;
    msCompanyCode: string;
    supplierJobId: string;
    supplierJobType: string;
    jobStatus: string;
    totalSupplierUnits?: number;
    supplierReceivedDate?: Date;
    collectionDate?: Date;
    collectionSiteName: string;
    collectionSiteCode: string;
    msCollectionTicketNumber: string;
    collectionAddress: string;
    collectionAddressNumber: string;
    collectionCity: string;
    collectionCountry: string;
    collectionZipCode: string;
    supplierProcessingSite: string;
    supplierProcessingCountry: string;
    msPoNumber: string;
    msPoCurrency: string;
    supplierPoNumbers: string[];
    supplierPoCurrency: string;
    totalMsRevenueShare?: number;
    totalMsRevenueShareUSD?: number;
    totalSupplierCommission?: number;
    totalSupplierCommissionUSD?: number;
    supplierInvoiceNumbers: string[];
    totalUnitFees?: number;
    totalUnitFeesUSD?: number;
    totalInvoiceAmount?: number;
    totalInvoiceAmountUSD?: number;
    logisticsFee?: number;
    logisticsFeeUSD?: number;
    minimumServiceFee?: number;
    minimumServiceFeeUSD?: number;
    totalQuantity?: number;
    totalWeightInKilos?: number;
    supplierBillingEntity: string;
    billingCountry: string;
    createdDate: Date;
    modifiedDate?: Date;
    supplierReportingDateCollection?: Date;
    supplierReportingDateDisposition?: Date;
    supplierReportingDateSettlement?: Date;
    supplierReportingDateInvoice?: Date;
    supplierReportingDateCredit?: Date;
    documents?: JobDocuments;
}

export class SupplierJobDetails implements ISupplierJobDetails {
    public id: string;
    public partitionKey: string;
    public programType: string;
    public supplierId: string;
    public supplierName: string;
    public msCompanyCode: string;
    public supplierJobId: string;
    public supplierJobType: string;
    public jobStatus: string;
    public totalSupplierUnits?: number;
    public supplierReceivedDate?: Date;
    public collectionDate?: Date;
    public collectionSiteName: string;
    public collectionSiteCode: string;
    public msCollectionTicketNumber: string;
    public collectionAddress: string;
    public collectionAddressNumber: string;
    public collectionCity: string;
    public collectionCountry: string;
    public collectionZipCode: string;
    public supplierProcessingSite: string;
    public supplierProcessingCountry: string;
    public msPoNumber: string;
    public msPoCurrency: string;
    public supplierPoNumbers: string[] = [];
    public supplierPoCurrency: string;
    public totalMsRevenueShare?: number;
    public totalMsRevenueShareUSD?: number;
    public totalSupplierCommission?: number;
    public totalSupplierCommissionUSD?: number;
    public supplierInvoiceNumbers: string[] = [];
    public totalUnitFees?: number;
    public totalUnitFeesUSD?: number;
    public totalInvoiceAmount?: number;
    public totalInvoiceAmountUSD?: number;
    public logisticsFee?: number;
    public logisticsFeeUSD?: number;
    public minimumServiceFee?: number;
    public minimumServiceFeeUSD?: number;
    public totalQuantity?: number;
    public totalWeightInKilos?: number;
    public supplierBillingEntity: string;
    public billingCountry: string;
    public createdDate: Date;
    public modifiedDate?: Date;
    public supplierReportingDateCollection?: Date;
    public supplierReportingDateDisposition?: Date;
    public supplierReportingDateSettlement?: Date;
    public supplierReportingDateInvoice?: Date;
    public supplierReportingDateCredit?: Date;
    public documents?: JobDocuments;

    // Below are properties NOT returned from the server but are used for client side purposes.
    public clientRowKey: string = '';

    constructor(jsonData: ISupplierJobDetails) {
        this.id = jsonData.id;
        this.partitionKey = jsonData.partitionKey;
        this.programType = jsonData.programType;
        this.supplierId = jsonData.supplierId;
        this.supplierName = jsonData.supplierName;
        this.msCompanyCode = jsonData.msCompanyCode;
        this.supplierJobId = jsonData.supplierJobId;
        this.supplierJobType = jsonData.supplierJobType;
        this.jobStatus = jsonData.jobStatus;
        this.totalSupplierUnits = jsonData.totalSupplierUnits;
        this.supplierReceivedDate = jsonData.supplierReceivedDate ? new Date(jsonData.supplierReceivedDate) : undefined;
        this.collectionDate = jsonData.collectionDate ? new Date(jsonData.collectionDate) : undefined;
        this.collectionSiteName = jsonData.collectionSiteName;
        this.collectionSiteCode = jsonData.collectionSiteCode;
        this.msCollectionTicketNumber = jsonData.msCollectionTicketNumber;
        this.collectionAddress = jsonData.collectionAddress;
        this.collectionAddressNumber = jsonData.collectionAddressNumber;
        this.collectionCity = jsonData.collectionCity;
        this.collectionCountry = jsonData.collectionCountry;
        this.collectionZipCode = jsonData.collectionZipCode;
        this.supplierProcessingSite = jsonData.supplierProcessingSite;
        this.supplierProcessingCountry = jsonData.supplierProcessingCountry;
        this.msPoNumber = jsonData.msPoNumber;
        this.msPoCurrency = jsonData.msPoCurrency;
        this.supplierPoNumbers = [...jsonData.supplierPoNumbers || []];
        this.supplierPoCurrency = jsonData.supplierPoCurrency;
        this.totalMsRevenueShare = jsonData.totalMsRevenueShare;
        this.totalMsRevenueShareUSD = jsonData.totalMsRevenueShareUSD;
        this.totalSupplierCommission = jsonData.totalSupplierCommission;
        this.totalSupplierCommissionUSD = jsonData.totalSupplierCommissionUSD;
        this.supplierInvoiceNumbers = [...jsonData.supplierInvoiceNumbers || []];
        this.totalUnitFees = jsonData.totalUnitFees;
        this.totalUnitFeesUSD = jsonData.totalUnitFeesUSD;
        this.totalInvoiceAmount = jsonData.totalInvoiceAmount;
        this.totalInvoiceAmountUSD = jsonData.totalInvoiceAmountUSD;
        this.logisticsFee = jsonData.logisticsFee;
        this.logisticsFeeUSD = jsonData.logisticsFeeUSD;
        this.minimumServiceFee = jsonData.minimumServiceFee;
        this.minimumServiceFeeUSD = jsonData.minimumServiceFeeUSD;
        this.totalQuantity = jsonData.totalQuantity;
        this.totalWeightInKilos = jsonData.totalWeightInKilos;
        this.supplierBillingEntity = jsonData.supplierBillingEntity;
        this.billingCountry = jsonData.billingCountry;
        this.createdDate = new Date(jsonData.createdDate);
        this.modifiedDate = jsonData.modifiedDate ? new Date(jsonData.modifiedDate) : undefined;
        this.supplierReportingDateCollection = jsonData.supplierReportingDateCollection ? new Date(jsonData.supplierReportingDateCollection) : undefined;
        this.supplierReportingDateDisposition = jsonData.supplierReportingDateDisposition ? new Date(jsonData.supplierReportingDateDisposition) : undefined;
        this.supplierReportingDateSettlement = jsonData.supplierReportingDateSettlement ? new Date(jsonData.supplierReportingDateSettlement) : undefined;
        this.supplierReportingDateInvoice = jsonData.supplierReportingDateInvoice ? new Date(jsonData.supplierReportingDateInvoice) : undefined;
        this.supplierReportingDateCredit = jsonData.supplierReportingDateCredit ? new Date(jsonData.supplierReportingDateCredit) : undefined;
        this.documents = jsonData.documents ? new JobDocuments(jsonData.documents) : undefined;
    }
}
