import React from 'react';
import {
    DefaultButton,
    Dialog,
    DialogType,
    PrimaryButton,
    Stack,
    Text
} from '@fluentui/react';
import { stackTokens } from '../../common/common.styles';

interface IConfirmDeleteDialogProps {
    confirmDelete: boolean;
    itemBeingDeleted: any;
    confirmDeleteButtonClicked: (item: any) => void;
    cancelDeleteButtonClicked: () => void;
}

export const ConfirmDeleteDialog: React.FunctionComponent<IConfirmDeleteDialogProps> = (props: IConfirmDeleteDialogProps): JSX.Element => {
    return (
        <Dialog
            hidden={!props.confirmDelete}
            dialogContentProps={{
                type: DialogType.normal,
                showCloseButton: false,
                title: 'Please confirm'
            }}
            modalProps={{
                isBlocking: true
            }}
            minWidth={390}
        >
            <Stack tokens={stackTokens}>
                <Text variant="large">Are you sure you want to delete this item?</Text>
                <Stack tokens={stackTokens} horizontal horizontalAlign='end' style={{ marginTop: '30px' }}>
                    <PrimaryButton onClick={event => props.confirmDeleteButtonClicked(props.itemBeingDeleted)} text='Yes' />
                    <DefaultButton onClick={event => props.cancelDeleteButtonClicked()} text='No' />
                </Stack>
            </Stack>
        </Dialog>
    );
}
