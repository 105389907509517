import {
    IStyle,
    mergeStyleSets
} from '@fluentui/react';

/**
 * Styles used on this page.
 */
export const pageStyles = mergeStyleSets({
    transportationAndPackagingSectionFieldsTable: {
        margin: '10px 20px 0 12px',
        borderCollapse: 'collapse',
        'td': {
            padding: '2px 4px 2px 4px',
            border: '.1px solid lightgray',
            minWidth: '100px'
        }
    } as IStyle,
    transportationAndPackagingSectionLabel: {
        fontWeight: '600'
    } as IStyle,
    packagingDetailsSection: {
        margin: '24px 0 0 0'
    } as IStyle
});
