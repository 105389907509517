export interface IReportInfo {
    displayName: string;
    description: string;
    reportLink: string;
}

export class ReportInfo implements IReportInfo {
    public displayName: string;
    public description: string;
    public reportLink: string;

    constructor(jsonData: IReportInfo) {
        this.displayName = jsonData.displayName;
        this.description = jsonData.description;
        this.reportLink = jsonData.reportLink;
    }
}
