import React, { useCallback, useEffect, useState } from 'react';
import {
    ConstrainMode,
    DetailsListLayoutMode,
    IColumn,
    SelectionMode,
    Spinner,
    SpinnerSize,
    Text
} from '@fluentui/react';
import { commonStyles } from '../../common/common.styles';
import { ICommonPageProps } from '../../common/common.types';
import { assignOnColumnClick, ColumnsAndItems, commonColumnProps, resetColumnSorting, sortOnColumn } from '../../common/common.func';
import { clearErrorByIndex, ErrorBar, prepErrorMsg } from '../../components/ErrorBar/ErrorBar';
import { IApiLoadPaymentNotifications, callApiLoadPaymentNotifications, resetApiCallState } from '../../store/actions/pageActions/paymentNotificationPage.action';
import { CallApiState } from '../../store/actions/generic.action';
import { ComboInputOption, ISearchCriteria, SearchFilter } from '../../components/SearchFilter/SearchFilter';
import { CustomDetailsList } from '../../components/CustomDetailsList/CustomDetailsList';
import { Payment } from '../../models/supplier/payment';
import { appConstants, toLocaleStringTwoDigitFraction } from '../../common/appConstants';
import { useAppSelector, useAppDispatch } from '../../store/hooks';
import { PageWrapper } from '../../components/PageWrapper/PageWrapper';

interface IPageProps extends ICommonPageProps {
}

export const PaymentNotificationPage: React.FunctionComponent<IPageProps> = (props: IPageProps): JSX.Element => {
    const [errors, setErrors] = useState<string[]>([]);
    const [selectedPaymentsPage, setSelectedPaymentsPage] = useState<number>(1);
    const [payments, setPayments] = useState<Payment[] | null>();

    // Redux store selectors to get state from the store when it changes.
    const apiLoadPaymentNotifications: IApiLoadPaymentNotifications =
    useAppSelector<IApiLoadPaymentNotifications>((state) => state.paymentNotificationPageReducer.apiLoadPaymentNotifications);

    // Redux store dispatch to send actions to the store.
    const dispatch = useAppDispatch();

    /**
     * This effect does nothing on mount, but will return a cleanup function that runs when the component unmounts.
     */
    useEffect(() => {
        return function cleanup() {
            resetApiCallState();
        }
    }, []);

    /**
     * Handle error.
     * @param errMsg Error message.
     */
    const handleError = useCallback((errMsg: string) => {
        setErrors((prevErrors) => {
            // This will prevent the same error from being displayed if already displayed.
            // ex: Multiple page data load failures might occur if the api is not working,
            // and this page makes multiple load calls for various data.
            if (!prevErrors.includes(errMsg)) {
                return [...prevErrors, errMsg];
            }
            return prevErrors;
        });
    }, []);

    /**
     * Effect for when errors occur in any api call.
     */
    useEffect(() => {
        if (apiLoadPaymentNotifications.errMsg) {
            handleError(apiLoadPaymentNotifications.errMsg);
        }
    }, [handleError, apiLoadPaymentNotifications.errMsg]);

    /**
     * Search clicked event handler.
     * @param searchCriteria Search criteria.
     */
    const searchClicked = (searchCriteria: ISearchCriteria) => {
        setPayments(undefined);

        setSelectedPaymentsPage(1);

        // The search filter is set to use the calendar (not fiscal) year and month picker, see showCalendarYearMonth on searchFilterOptions.
        // The search criteria will contain a startDate and endDate based on the selection, for the start day of the month
        // to the end day of the month. But we just need the year and month here so we just pick that off the startDate.
        const year: number = searchCriteria.startDate.getFullYear();
        const month: number = searchCriteria.startDate.getMonth() + 1; // getMonth returns zero based month, but api expects 1 based.

        if (searchCriteria.programType && searchCriteria.supplierId) {
            dispatch(callApiLoadPaymentNotifications(
                searchCriteria.programType,
                searchCriteria.supplierId,
                year,
                month
            ));
        }
    };

    /**
     * Search filter load error handler.
     * @param err Error
     */
    const searchFilterLoadError = (err: any) => {
        handleError(prepErrorMsg(appConstants.dataLoadFailed, err));
    };

    /**
     * Render right aligned currency column text. Currency values will use 2 digit precision.
     * @param item Item data.
     * @param index Index of item.
     * @param column Column being rendered.
     */
    const renderRightAlignedCurrencyColumnText = (item?: any, index?: number, column?: IColumn) => {
        if (index === undefined || index === null) {
            return;
        }

        return (
            <div style={{ textAlign: 'right' }}>{item[column!.fieldName as string]?.toLocaleString(undefined, toLocaleStringTwoDigitFraction)}</div>
        );
    };

    /**
     * Setup columns to display for payments.
     */
    const [columnsPayments, setColumnsPayments] = useState<IColumn[]>(
        [
            {
                ...commonColumnProps,
                key: 'column1',
                name: 'Supplier Reporting Date',
                fieldName: 'supplierReportingDate',
                minWidth: 150,
                onRender: (item: Payment) => {
                    return <span>{item.supplierReportingDate?.toLocaleDateString()}</span>;
                }
            },
            {
                ...commonColumnProps,
                key: 'column2',
                name: 'Supplier PO Number',
                fieldName: 'supplierPoNumber',
                minWidth: 125
            },
            {
                ...commonColumnProps,
                key: 'column3',
                name: 'Supplier PO Currency',
                fieldName: 'supplierPoCurrency',
                minWidth: 120
            },
            {
                ...commonColumnProps,
                key: 'column4',
                name: 'MS Invoice Number',
                fieldName: 'msInvoiceNumber',
                minWidth: 125
            },
            {
                ...commonColumnProps,
                key: 'column5',
                name: 'Payment Date',
                fieldName: 'paymentDate',
                minWidth: 70,
                onRender: (item: Payment) => {
                    return <span>{item.paymentDate?.toLocaleDateString()}</span>;
                }
            },
            {
                ...commonColumnProps,
                key: 'column6',
                name: 'Payment Amount',
                fieldName: 'paymentAmount',
                minWidth: 100,
                onRender: renderRightAlignedCurrencyColumnText
            },
            {
                ...commonColumnProps,
                key: 'column7',
                name: 'Payment Amount USD',
                fieldName: 'paymentAmountUSD',
                minWidth: 120,
                onRender: renderRightAlignedCurrencyColumnText
            }
        ]
    );

    /**
     * Effect for when Supplier Summary data is loaded.
     */
    useEffect(() => {
        if (apiLoadPaymentNotifications.callApiState === CallApiState.DataAvailable) {
            setPayments(apiLoadPaymentNotifications.payments);
            setColumnsPayments(resetColumnSorting(columnsPayments));
        }
    }, [columnsPayments, apiLoadPaymentNotifications.callApiState, apiLoadPaymentNotifications.payments]);

    /**
     * On column click event handler for payments grid.
     * @param ev The mouse event.
     * @param column The column header clicked on.
     */
    const onColumnClickPayments = useCallback((ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        if (payments) {
            const columnsAndItems: ColumnsAndItems<Payment> = sortOnColumn<Payment>(column, columnsPayments, payments, undefined);
            setPayments(columnsAndItems.items);
            setColumnsPayments(columnsAndItems.columns);
        }
    }, [columnsPayments, payments]);

    /**
     * Effect to update onColumnClick handler. Otherwise the component state referenced in onColumnClick
     * would be stale from the render pass it was created on.
     */
    useEffect(() => {
        assignOnColumnClick(columnsPayments, onColumnClickPayments);
    }, [columnsPayments, onColumnClickPayments]);

    return (
        <PageWrapper {...props}>
            <ErrorBar errors={errors} onDismiss={(index: number) => {
                setErrors(clearErrorByIndex(errors, index));
            }} />

            <div className="ms-Grid" dir="ltr">
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <div className={commonStyles.pageHeader}>
                            <Text variant="xLarge" role="heading" aria-level={1}>Payment Notifications</Text>
                        </div>
                    </div>
                </div>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <SearchFilter
                            programs={props.programs || []}
                            searchFilterOptions={{
                                showFiscalRange: false,
                                fiscalRangeFieldsRequired: false,
                                showCalendarYearMonth: true,
                                showProgram: true,
                                showSupplierName: true,
                                showComboInputGroup: ComboInputOption.None,
                                showSupplierJobId: false,
                                showSupplierPoNumber: false,
                                showSupplierInvoiceNumber: false,
                                showCorrelationId: false,
                                showMsInvoiceNumber: true,
                                showSupplierUnitType: false,
                                showJobUnit: false,
                                showCountry: false,
                                showComboAssetTagAndSerialNumber: false,
                                showUnitDispositionType: false,
                                showUnitStatus: false
                            }}
                            onSearchClicked={searchClicked}
                            onSearchFilterLoadError={searchFilterLoadError} />
                    </div>
                </div>
                <div className="ms-Grid-row" style={{ marginTop: '24px' }}>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        { apiLoadPaymentNotifications.callApiState !== CallApiState.Initial && (
                            <>
                                { apiLoadPaymentNotifications.callApiState === CallApiState.Running && (
                                    <Spinner size={SpinnerSize.medium} className={commonStyles.spinner} />
                                )}

                                { apiLoadPaymentNotifications.callApiState === CallApiState.Completed && (
                                    <CustomDetailsList
                                    showExcelExport={true}
                                        exportExcelSheetName="Payments"
                                        ariaLabelForGrid="Payments"
                                        displayTotalItems={true}
                                        showPaginator={true}
                                        showPageSize={true}
                                        selectedPage={selectedPaymentsPage}
                                        onSelectedPageChange={(page) => {
                                            setSelectedPaymentsPage(page);
                                        }}
                                        showNoDataFoundMsg={!payments || payments.length === 0}
                                        items={payments || []}
                                        compact={false}
                                        columns={columnsPayments}
                                        selectionMode={SelectionMode.none}
                                        getKey={(item: Payment) => item.clientRowKey}
                                        setKey="none"
                                        layoutMode={DetailsListLayoutMode.fixedColumns}
                                        isHeaderVisible={true}
                                        constrainMode={ConstrainMode.horizontalConstrained} />
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </PageWrapper>
    );
};
