import React, { useCallback, useEffect, useState } from 'react';
import {
    ConstrainMode,
    DefaultButton,
    DetailsListLayoutMode,
    IColumn,
    SelectionMode,
    Spinner,
    SpinnerSize,
    Stack,
    TextField
} from '@fluentui/react';
import { IAdminTabProps } from '../../common/common.types';
import { commonColumnProps, useMountEffect } from '../../common/common.func';
import { clearErrorByIndex, ErrorBar, prepErrorMsg } from '../../components/ErrorBar/ErrorBar';
import { CallApiState } from '../../store/actions/generic.action';
import { CustomDetailsList } from '../../components/CustomDetailsList/CustomDetailsList';
import { commonStyles, stackTokens } from '../../common/common.styles';
import { ComboInputOption, ISearchCriteria, SearchFilter } from '../../components/SearchFilter/SearchFilter';
import {
    loadUnitDispositionTypes,
    updateUnitDispositionType,
    addUnitDispositionType,
    deleteUnitDispositionType,
    resetApiCallState,
    IApiLoadUnitDispositionTypes,
    IApiUpdateUnitDispositionType,
    IApiAddUnitDispositionType,
    IApiDeleteUnitDispositionType
} from '../../store/actions/adminTabActions/adminTabUnitDispositionTypes.action';
import { UnitDispositionType } from '../../models/domainData/unitDispositionType';
import { addOrRemoveError, checkForInputError, checkItemForAnyInputError, InputError } from './AdminCommon';
import { appConstants } from '../../common/appConstants';
import { ReloadingDialog } from './ReloadingDialog';
import { ConfirmDeleteDialog } from './ConfirmDeleteDialog';
import { useAppSelector, useAppDispatch } from '../../store/hooks';

interface IAdminTabUnitDispositionTypesProps extends IAdminTabProps {
}

export const AdminTabUnitDispositionTypes: React.FunctionComponent<IAdminTabUnitDispositionTypesProps> = (props: IAdminTabUnitDispositionTypesProps): JSX.Element => {
    const [errors, setErrors] = useState<string[]>([]);
    const [unitDispositionTypes, setUnitDispositionTypes] = useState<UnitDispositionType[]>([]);
    const [inputErrors, setInputErrors] = useState<InputError[]>([]);
    const [successIndicator, setSuccessIndicator] = useState<boolean>(false);
    const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
    const [currentSearchCriteria, setCurrentSearchCriteria] = useState<ISearchCriteria>();
    const [itemBeingUpdatedOrAdded, setItemBeingUpdatedOrAdded] = useState<UnitDispositionType>();
    const [itemBeingDeleted, setItemBeingDeleted] = useState<UnitDispositionType>();
    const [itemBeingEdited, setItemBeingEdited] = useState<UnitDispositionType>();

    // Redux store selectors to get state from the store when it changes.
    const apiLoadUnitDispositionTypes: IApiLoadUnitDispositionTypes =
        useAppSelector<IApiLoadUnitDispositionTypes>((state) => state.adminTabUnitDispositionTypesReducer.apiLoadUnitDispositionTypes);
    const apiUpdateUnitDispositionType: IApiUpdateUnitDispositionType =
        useAppSelector<IApiUpdateUnitDispositionType>((state) => state.adminTabUnitDispositionTypesReducer.apiUpdateUnitDispositionType);
    const apiAddUnitDispositionType: IApiAddUnitDispositionType =
        useAppSelector<IApiAddUnitDispositionType>((state) => state.adminTabUnitDispositionTypesReducer.apiAddUnitDispositionType);
    const apiDeleteUnitDispositionType: IApiDeleteUnitDispositionType =
        useAppSelector<IApiDeleteUnitDispositionType>((state) => state.adminTabUnitDispositionTypesReducer.apiDeleteUnitDispositionType);

    // Redux store dispatch to send actions to the store.
    const dispatch = useAppDispatch();

    /**
     * Setup columns to display for unit disposition types.
     */
    const [columnsUnitDispositionTypes, setColumnsUnitDispositionTypes] = useState<IColumn[]>(
        [
            {
                ...commonColumnProps,
                key: 'column1',
                name: '',
                minWidth: 180,
                data: {
                    addActionButtons: true
                }
            },
            {
                ...commonColumnProps,
                key: 'column2',
                name: 'Unit Disposition Type',
                fieldName: 'dispositionType',
                minWidth: 350,
                data: {
                    addOnColumnRenderTextField: true,
                    pattern: /^[a-zA-Z0-9- _,#'"]{5,500}$/,
                    inputErrorMsg: 'Alphanumeric, dashes, spaces, underscore, comma, hash, single quote, double quote, min 5, max 500 length'
                }
            }
        ]
    );

    /**
     * Rerender columns by setting the columns to a new array of the same columns.
     * This will cause the columns to re-render including the action buttons column... the buttons state may
     * change such as becoming disabled or enabled, or buttons showing or hiding, etc.
     */
    const rerenderColumns = useCallback(() => {
        // Use a setTimeout to queue this on the UI thread after the current render pass.
        setTimeout(() => {
            setColumnsUnitDispositionTypes([...columnsUnitDispositionTypes]);
        });
    }, [columnsUnitDispositionTypes]);

    /**
     * This effect does nothing on mount, but will return a cleanup function that runs when the component unmounts.
     */
    useEffect(() => {
        return function cleanup() {
            resetApiCallState();
        }
    }, []);

    /**
     * Handle error.
     * @param errMsg Error message.
     */
    const handleError = useCallback((errMsg: string) => {
        setErrors((prevErrors) => {
            // This will prevent the same error from being displayed if already displayed.
            // ex: Multiple page data load failures might occur if the api is not working,
            // and this page makes multiple load calls for various data.
            if (!prevErrors.includes(errMsg)) {
                return [...prevErrors, errMsg];
            }
            return prevErrors;
        });
    }, []);

    /**
     * Effect for when errors occur in any api call.
     */
    useEffect(() => {
        if (apiLoadUnitDispositionTypes.errMsg) {
            handleError(apiLoadUnitDispositionTypes.errMsg);
        }
        if (apiUpdateUnitDispositionType.errMsg) {
            handleError(apiUpdateUnitDispositionType.errMsg);
        }
        if (apiAddUnitDispositionType.errMsg) {
            handleError(apiAddUnitDispositionType.errMsg);
        }
        if (apiDeleteUnitDispositionType.errMsg) {
            handleError(apiDeleteUnitDispositionType.errMsg);
        }
    }, [apiAddUnitDispositionType.errMsg, apiDeleteUnitDispositionType.errMsg, handleError, apiLoadUnitDispositionTypes.errMsg, apiUpdateUnitDispositionType.errMsg]);

    /**
     * This effect is run once during page load.
     */
    useMountEffect(() => {
        // Nothing at this time.
    });

    /**
     * Effect for when unit disposition types is loaded.
     */
    useEffect(() => {
        if (apiLoadUnitDispositionTypes.callApiState === CallApiState.DataAvailable) {
            setUnitDispositionTypes(apiLoadUnitDispositionTypes.unitDispositionTypes || []);
        }
    }, [apiLoadUnitDispositionTypes.callApiState, apiLoadUnitDispositionTypes.unitDispositionTypes]);

    /**
     * Effect for when unit disposition type is updated or added or deleted.
     */
    useEffect(() => {
        if (apiUpdateUnitDispositionType.callApiState === CallApiState.DataAvailable ||
            apiAddUnitDispositionType.callApiState === CallApiState.DataAvailable ||
            apiDeleteUnitDispositionType.callApiState === CallApiState.DataAvailable) {
            setSuccessIndicator(true);
            rerenderColumns();
            // Wait for 2 seconds and then reload data.
            setTimeout(() => {
                setSuccessIndicator(false);
                // Reload the unit disposition types using current search criteria.
                if (currentSearchCriteria && currentSearchCriteria.programType) {
                    dispatch(loadUnitDispositionTypes(
                        currentSearchCriteria.programType
                    ));
                }
            }, 2000);
        }
    }, [apiAddUnitDispositionType.callApiState, currentSearchCriteria, apiDeleteUnitDispositionType.callApiState, dispatch, rerenderColumns, apiUpdateUnitDispositionType.callApiState]);

    /**
     * Edit button clicked event handler.
     * @param unitDispositionType Unit disposition type being edited.
     */
    const editButtonClicked = useCallback((unitDispositionType: UnitDispositionType): void => {
        setItemBeingEdited(unitDispositionType);
        rerenderColumns();
    }, [rerenderColumns]);

    /**
     * Save (update or add) button clicked event handler.
     * @param unitDispositionType Unit disposition type being saved.
     */
    const saveButtonClicked = useCallback((unitDispositionType: UnitDispositionType): void => {
        setItemBeingUpdatedOrAdded(unitDispositionType);
        if (unitDispositionType.isNew) {
            if (currentSearchCriteria && currentSearchCriteria.programType) {
                dispatch(addUnitDispositionType(unitDispositionType, currentSearchCriteria.programType));
            }
        } else {
            dispatch(updateUnitDispositionType(unitDispositionType));
        }
        rerenderColumns();
    }, [currentSearchCriteria, dispatch, rerenderColumns]);

    /**
     * Delete button clicked event handler.
     * @param unitDispositionType Unit disposition type being saved.
     */
    const deleteButtonClicked = useCallback((unitDispositionType: UnitDispositionType): void => {
        setItemBeingDeleted(unitDispositionType);
        setConfirmDelete(true);
    }, []);

    /**
     * Confirm delete button confirmed clicked event handler.
     * @param unitDispositionType Unit disposition type being saved.
     */
    const confirmDeleteButtonClicked = useCallback((unitDispositionType: UnitDispositionType | undefined): void => {
        setConfirmDelete(false);
        if (unitDispositionType) {
            dispatch(deleteUnitDispositionType(unitDispositionType));
            rerenderColumns();
        }
    }, [dispatch, rerenderColumns]);

    /**
     * Cancel delete button confirmed clicked event handler.
     */
    const cancelDeleteButtonClicked = useCallback((): void => {
        setConfirmDelete(false);
        setItemBeingDeleted(undefined);
    }, []);

    /**
     * On column render for action buttons.
     * @param item The item (row) being rendered in the details list. 
     * @param index The index of the row.
     * @param column The column being rendered.
     */
    const onColumnRenderActionButtons = useCallback((item: UnitDispositionType, index: number | undefined, column: IColumn | undefined): JSX.Element => {
        return (
            <div>
                {
                    itemBeingEdited !== item && (
                        <Stack horizontal tokens={stackTokens}>
                            <DefaultButton
                                onClick={event => editButtonClicked(item)}
                                disabled={
                                    apiUpdateUnitDispositionType.callApiState === CallApiState.Running ||
                                    apiAddUnitDispositionType.callApiState === CallApiState.Running ||
                                    apiDeleteUnitDispositionType.callApiState === CallApiState.Running ||
                                    successIndicator}>
                                <span style={{ whiteSpace: 'nowrap' }}>
                                    Edit
                                </span>
                            </DefaultButton>
                            <DefaultButton
                                onClick={event => deleteButtonClicked(item)}
                                disabled={
                                    checkItemForAnyInputError(inputErrors, item) ||
                                    apiUpdateUnitDispositionType.callApiState === CallApiState.Running ||
                                    apiAddUnitDispositionType.callApiState === CallApiState.Running ||
                                    apiDeleteUnitDispositionType.callApiState === CallApiState.Running ||
                                    successIndicator}>
                                { itemBeingDeleted === item &&
                                    apiDeleteUnitDispositionType.callApiState === CallApiState.Running ? (
                                    <div style={{ display: 'flex' }}>
                                        <span style={{ marginRight: '6px', whiteSpace: 'nowrap' }}>Deleting</span>
                                        <Spinner size={SpinnerSize.medium} className={commonStyles.spinner} style={{ display: 'inline-block' }} />
                                    </div>
                                ) : (
                                    <span style={{ whiteSpace: 'nowrap' }}>
                                        { itemBeingDeleted === item && successIndicator ? 'Deleted' : 'Delete' }
                                    </span>
                                )}
                            </DefaultButton>
                        </Stack>
                    )
                }
                {
                    itemBeingEdited === item && (
                        <Stack horizontal tokens={stackTokens}>
                            <DefaultButton
                                onClick={event => saveButtonClicked(item)}
                                disabled={
                                    checkItemForAnyInputError(inputErrors, item) ||
                                    apiUpdateUnitDispositionType.callApiState === CallApiState.Running ||
                                    apiAddUnitDispositionType.callApiState === CallApiState.Running ||
                                    apiDeleteUnitDispositionType.callApiState === CallApiState.Running ||
                                    successIndicator}>
                                { itemBeingUpdatedOrAdded === item &&
                                    (apiUpdateUnitDispositionType.callApiState === CallApiState.Running ||
                                    apiAddUnitDispositionType.callApiState === CallApiState.Running) ? (
                                    <div style={{ display: 'flex' }}>
                                        <span style={{ marginRight: '6px', whiteSpace: 'nowrap' }}>Saving</span>
                                        <Spinner size={SpinnerSize.medium} className={commonStyles.spinner} style={{ display: 'inline-block' }} />
                                    </div>
                                ) : (
                                    <span style={{ whiteSpace: 'nowrap' }}>
                                        { itemBeingUpdatedOrAdded === item && successIndicator ? 'Saved' : 'Save' }
                                    </span>
                                )}
                            </DefaultButton>
                        </Stack>
                    )
                }
            </div>
        );
    }, [apiAddUnitDispositionType.callApiState, deleteButtonClicked, apiDeleteUnitDispositionType.callApiState, editButtonClicked, inputErrors, itemBeingDeleted, itemBeingEdited, itemBeingUpdatedOrAdded, saveButtonClicked, successIndicator, apiUpdateUnitDispositionType.callApiState]);

    /**
     * On text field column render.
     * @param item The item (row) being rendered in the details list. 
     * @param index The index of the row.
     * @param column The column being rendered.
     */
    const onColumnRenderTextField = useCallback((item: UnitDispositionType, index: number | undefined, column: IColumn | undefined): JSX.Element => {
        const fieldName: string = column!.fieldName!;
        return (
            <>
                <TextField
                    defaultValue={item[fieldName]}
                    onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                        newValue = newValue || '';
                        item[fieldName] = newValue;
                        const newInputErrors: InputError[] = addOrRemoveError(inputErrors, column!, item, fieldName, newValue);
                        setInputErrors(newInputErrors);
                        setUnitDispositionTypes([...unitDispositionTypes]);
                    }}
                    disabled={
                        itemBeingEdited !== item ||
                        apiUpdateUnitDispositionType.callApiState === CallApiState.Running ||
                        apiAddUnitDispositionType.callApiState === CallApiState.Running ||
                        apiDeleteUnitDispositionType.callApiState === CallApiState.Running ||
                        successIndicator
                    }
                    errorMessage={checkForInputError(inputErrors, item, fieldName)}>
                </TextField>
            </>
        );
    }, [apiAddUnitDispositionType.callApiState, apiDeleteUnitDispositionType.callApiState, inputErrors, itemBeingEdited, successIndicator, unitDispositionTypes, apiUpdateUnitDispositionType.callApiState]);

    /**
     * Effect to update onRender handler. Otherwise the component state referenced in onRender
     * would be stale from the render pass it was created on.
     */
    useEffect(() => {
        columnsUnitDispositionTypes.filter(x => x.data?.addActionButtons).forEach(c => c.onRender = onColumnRenderActionButtons);
        columnsUnitDispositionTypes.filter(x => x.data?.addOnColumnRenderTextField).forEach(c => c.onRender = onColumnRenderTextField);
    }, [columnsUnitDispositionTypes, onColumnRenderActionButtons, onColumnRenderTextField]);

    /**
     * Add button clicked event handler.
     */
    const addButtonClicked = (): void => {
        const newUnitDispositionType: UnitDispositionType = new UnitDispositionType({
            partitionKey: '',
            rowKey: '',
            dispositionType: ''
        });
        newUnitDispositionType.isNew = true;
        newUnitDispositionType.clientRowKey = unitDispositionTypes.length.toString();
        let currentInputErrors: InputError[] = [...inputErrors];
        columnsUnitDispositionTypes.forEach((column) => {
            const value: string = newUnitDispositionType[column.fieldName!];
            currentInputErrors = addOrRemoveError(currentInputErrors, column, newUnitDispositionType, column.fieldName!, value);
        });
        setInputErrors(currentInputErrors);
        setUnitDispositionTypes([...unitDispositionTypes, newUnitDispositionType]);
        setItemBeingEdited(newUnitDispositionType);
    }

    /**
     * Search clicked event handler.
     * @param searchCriteria Search criteria.
     */
    const searchClicked = (searchCriteria: ISearchCriteria) => {
        setCurrentSearchCriteria(searchCriteria);
        if (searchCriteria.programType) {
            dispatch(loadUnitDispositionTypes(
                searchCriteria.programType
            ));
        }
    };

    /**
     * Search filter load error handler.
     * @param err Error
     */
    const searchFilterLoadError = (err: any) => {
        handleError(prepErrorMsg(appConstants.dataLoadFailed, err));
    };

    return (
        <>
            <ErrorBar errors={errors} onDismiss={(index: number) => {
                setErrors(clearErrorByIndex(errors, index));
            }} />

            <div className="ms-Grid" dir="ltr">
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{ margin: '12px 12px 12px 12px' }}>
                        <SearchFilter
                            programs={props.programs || []}
                            searchFilterOptions={{
                                showFiscalRange: false,
                                fiscalRangeFieldsRequired: false,
                                showCalendarYearMonth: false,
                                showProgram: true,
                                showSupplierName: false,
                                showComboInputGroup: ComboInputOption.None,
                                showSupplierJobId: false,
                                showSupplierPoNumber: false,
                                showSupplierInvoiceNumber: false,
                                showCorrelationId: false,
                                showMsInvoiceNumber: false,
                                showSupplierUnitType: false,
                                showJobUnit: false,
                                showCountry: false,
                                showComboAssetTagAndSerialNumber: false,
                                showUnitDispositionType: false,
                                showUnitStatus: false
                            }}
                            onSearchClicked={searchClicked}
                            onSearchFilterLoadError={searchFilterLoadError}
                            disabled={
                                apiUpdateUnitDispositionType.callApiState === CallApiState.Running ||
                                apiAddUnitDispositionType.callApiState === CallApiState.Running ||
                                apiDeleteUnitDispositionType.callApiState === CallApiState.Running ||
                                successIndicator} />
                    </div>
                </div>
                {apiLoadUnitDispositionTypes.callApiState === CallApiState.Running && (
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{ margin: '12px 12px 12px 12px' }}>
                            <Spinner size={SpinnerSize.medium} className={commonStyles.spinner} />
                        </div>
                    </div>
                )}
                {apiLoadUnitDispositionTypes.callApiState === CallApiState.Completed && (
                    <>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <CustomDetailsList
                                    showExcelExport={true}
                                    exportExcelSheetName="Unit Disposition Types"
                                    ariaLabelForGrid="Unit Disposition Types"
                                    displayTotalItems={true}
                                    showPaginator={false}
                                    showPageSize={false}
                                    selectedPage={1}
                                    showNoDataFoundMsg={unitDispositionTypes.length === 0}
                                    items={unitDispositionTypes}
                                    compact={false}
                                    columns={columnsUnitDispositionTypes}
                                    selectionMode={SelectionMode.none}
                                    getKey={(item: UnitDispositionType) => item.clientRowKey}
                                    setKey="none"
                                    layoutMode={DetailsListLayoutMode.fixedColumns}
                                    isHeaderVisible={true}
                                    constrainMode={ConstrainMode.horizontalConstrained} />
                            </div>
                        </div>

                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <Stack horizontal wrap tokens={stackTokens}>
                                    <DefaultButton
                                        disabled={
                                            apiUpdateUnitDispositionType.callApiState === CallApiState.Running ||
                                            apiAddUnitDispositionType.callApiState === CallApiState.Running ||
                                            apiDeleteUnitDispositionType.callApiState === CallApiState.Running ||
                                            successIndicator}
                                        onClick={event => addButtonClicked()}>
                                        <span>Add</span>
                                    </DefaultButton>
                                </Stack>
                            </div>
                        </div>
                    </>
                )}
            </div>

            <ReloadingDialog
                successIndicator={successIndicator} />
            <ConfirmDeleteDialog
                confirmDelete={confirmDelete}
                itemBeingDeleted={itemBeingDeleted}
                confirmDeleteButtonClicked={confirmDeleteButtonClicked}
                cancelDeleteButtonClicked={cancelDeleteButtonClicked} />     
        </>
    );
};
