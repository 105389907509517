import React, { useCallback, useEffect, useState } from 'react';
import { Stack, Text, Spinner } from '@fluentui/react';
import { IMsalContext, useMsal } from '@azure/msal-react';
import { componentStyles } from './CopilotPanel.styles';
import { appConfig } from '../appConfig';

interface ICopilotPanelProps {
    isOpen: boolean;
    onDismissCopilotPanel: () => void;
}

export const CopilotPanel: React.FunctionComponent<ICopilotPanelProps> = (
    props: ICopilotPanelProps
): JSX.Element => {
    const msal: IMsalContext = useMsal();
    const [scriptLoading, setScriptLoading] = useState<boolean>(false);
    const [scriptLoadFailed, setScriptLoadFailed] = useState<boolean>(false);

    /**
     * Load a script by creating a script element on the page.
     * @param url Url to script to load.
     * @param scriptLoaded Callback to call after script loads.
     * @param scriptLoadError Callback to call if script load fails.
     */
    const loadScript = (url: string, scriptLoaded: () => void, scriptLoadError: () => void) => {
        const script: HTMLScriptElement = document.createElement('script');
        script.type = 'text/javascript';
        script.onload = () => {
            scriptLoaded();
        };
        script.onerror = () => {
            scriptLoadError();
        };
        script.src = url;
        document.getElementsByTagName('head')[0].appendChild(script);
    };

    /**
     * Create copilot web component.
     */
    const createCopilotWebComponent = useCallback(() => {
        const copilotContainer: HTMLElement | null = document.getElementById('copilot-container');
        if (!copilotContainer) {
            console.error('Copilot container not found');
            return;
        }

        // If the copilot container already has a child node then the copilot web component has already been created.
        if (copilotContainer.childNodes.length === 0) {
            // Create the copilot web component element.
            const copilotElem: HTMLElement = document.createElement('crystal-copilot');

            // Set attributes on the element.
            copilotElem.setAttribute('api-base-url', appConfig.current.copilot.copilotApiBaseUrl);
            copilotElem.setAttribute('api-scope', appConfig.current.copilot.copilotApiScope);
            copilotElem.setAttribute('copilot-app-id', appConfig.current.copilot.copilotAppId);
            // Set the height of the copilot component to the view height minus 48px, which accounts for the app header.
            // This will make it take up the full height of the panel.
            copilotElem.setAttribute('height', 'calc(100vh - 48px)');
            copilotElem.setAttribute('show-privacy-link', 'internal');
            copilotElem.setAttribute('theme', 'Modern');
            // copilotElem.setAttribute('enable-theme-picker', ''); // Not using theme picker.
            copilotElem.setAttribute('enable-history', '');
            copilotElem.setAttribute('enable-header-close-icon', '');
            copilotElem.setAttribute('load-prev-chat', '');
            // copilotElem.setAttribute('enable-message-heading-user-photo', ''); // Not using user photo in message heading.
            // copilotElem.setAttribute('enable-message-heading-user-name', ''); // Not using user name in message heading.
            copilotElem.setAttribute('fce-app-insights-instrumentation-key', appConfig.current.copilot.fceAppInsightsInstrumentationKey);
            copilotElem.setAttribute('tenant-app-insights-instrumentation-key', appConfig.current.instrumentation.appInsightsInstrumentationKey);

            // Add the component to the copilot container div.
            copilotContainer.appendChild(copilotElem);

            // Attach the MSAL instance from Recycling UI to the copilot web component by setting the msalInstance property.
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (copilotElem as any).msalInstance = msal.instance;

            // Handle the onClose event.
            copilotElem.addEventListener('onClose', (event) => {
                props.onDismissCopilotPanel();
            });
        }
    }, [msal.instance, props]);

    /**
     * Load copilot web component script and create the element.
     */
    const loadCopilotWebComponentAndCreate = useCallback(() => {
        // If the crystal-copilot is not yet defined in the browsers custom element registry.
        if (customElements.get('crystal-copilot') === undefined) {
            // Load the script to load the web component.
            // The cb is for cache busting to ensure we always get latest.
            setScriptLoading(true);
            loadScript(
                appConfig.current.copilot.copilotWebComponentUrl + '?cb=' + Date.now(),
                () => {
                    setScriptLoading(false);
                    createCopilotWebComponent();
                },
                () => {
                    setScriptLoading(false);
                    setScriptLoadFailed(true);
                }
            );
        } else {
            createCopilotWebComponent();
        }
    }, [createCopilotWebComponent]);

    /**
     * Effect that runs when the dialog opens.
     */
    useEffect(() => {
        if (props.isOpen) {
            setTimeout(() => {
                loadCopilotWebComponentAndCreate();
            });
        }
    }, [loadCopilotWebComponentAndCreate, props.isOpen]);

    return (
        <div style={!props.isOpen ? { display: 'none' } : { display: 'block' }}>
        <Stack verticalAlign="start">
            {(scriptLoading || scriptLoadFailed) && (
                <div className={componentStyles.loadingContainer}>
                    {scriptLoading && (
                        <Spinner labelPosition="bottom" label="Loading Copilot..." />
                    )}
                    {scriptLoadFailed && (
                        <Text variant="medium">
                            Failed to load Copilot.
                        </Text>
                    )}
                </div>
            )}

            <div
                id="copilot-container"
                className={componentStyles.copilotContainer}
            />
        </Stack>
    </div>
    );
};
