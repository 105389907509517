export interface IPackagingMaterialType {
    partitionKey: string;
    rowKey: string;
    materialType: string;
}

export class PackagingMaterialType implements IPackagingMaterialType {
    public partitionKey: string;
    public rowKey: string;
    public materialType: string;

    // Below are properties NOT returned from the server but are used for client side purposes.
    public clientRowKey: string = '';
    public isNew?: boolean;

    constructor(jsonData: IPackagingMaterialType) {
        this.partitionKey = jsonData.partitionKey;
        this.rowKey = jsonData.rowKey;
        this.materialType = jsonData.materialType;
    }
}
