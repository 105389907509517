import { CertificateSummary } from '../../../models/certificates/certificateSummary';
import { SupplierDocumentDetails } from '../../../models/certificates/supplierDocumentDetails';
import { serviceApiClient } from '../../../services/api/serviceApiClient';
import * as actionTypes from '../actionTypes';
import { DocumentType } from '../../../common/appConstants';
import { callApi, CallApiState, ICallApiBase } from '../generic.action';
import { AppDispatch, reduxStore } from '../../reduxStore';

/**
 * Load recycling certificates action payload.
 */
export interface IApiLoadRecyclingCertificates extends ICallApiBase {
    recyclingCertificates?: CertificateSummary[] | null;
}

/**
 * Load recycling certificates.
 * @param programType Program type.
 * @param supplierId Supplier id.
 * @param supplierJobId Supplier job id.
 * @returns Redux dispatch function.
 */
export const callApiLoadRecyclingCertificates = (programType: string | undefined, supplierId: string | undefined, supplierJobId: string | undefined): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<CertificateSummary[]>(
        actionTypes.API_LOAD_RECYCLING_CERTIFICATES,
        async () => {
            return await serviceApiClient.recyclingCertificates(programType, supplierId, supplierJobId) || [];
        },
        (payload: IApiLoadRecyclingCertificates, data) => {
            if (data) {
                // Set the clientRowKey in each object so it can be bound into the DetailsList.
                data.forEach((value: CertificateSummary, index: number) => {
                    value.clientRowKey = index.toString()
                });
            }

            payload.recyclingCertificates = data;
        }
    );
};

/**
 * Load PO documents action payload.
 */
export interface IApiLoadPoDocuments extends ICallApiBase {
    poDocuments?: SupplierDocumentDetails[] | null;
}

/**
 * Load PO documents.
 * @param programType Program type.
 * @param supplierId Supplier id.
 * @param supplierJobId Supplier job id.
 * @param supplierPoNumber Supplier PO number,
 * @returns Redux dispatch function.
 */
export const callApiLoadPoDocuments = (programType: string | undefined, supplierId: string | undefined, supplierJobId: string | undefined, supplierPoNumber: string | undefined): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<SupplierDocumentDetails[]>(
        actionTypes.API_LOAD_PO_DOCUMENTS,
        async () => {
            return await serviceApiClient.poDocuments(programType, supplierId, supplierJobId, supplierPoNumber) || [];
        },
        (payload: IApiLoadPoDocuments, data) => {
            if (data) {
                // Set the clientRowKey in each object so it can be bound into the DetailsList.
                data.forEach((value: SupplierDocumentDetails, index: number) => {
                    value.clientRowKey = index.toString()
                });
            }

            payload.poDocuments = data;
        }
    );
};

/**
 * Load invoice documents action payload.
 */
export interface IApiLoadInvoiceDocuments extends ICallApiBase {
    invoiceDocuments?: SupplierDocumentDetails[] | null;
}

/**
 * Load invoice documents.
 * @param programType Program type.
 * @param supplierId Supplier id.
 * @param supplierJobId Supplier job id.
 * @param supplierInvoiceNumber Supplier invoice number.
 * @returns Redux dispatch function.
 */
export const callApiLoadInvoiceDocuments = (programType: string | undefined, supplierId: string | undefined, supplierJobId: string | undefined, supplierInvoiceNumber: string | undefined): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<SupplierDocumentDetails[]>(
        actionTypes.API_LOAD_INVOICE_DOCUMENTS,
        async () => {
            return await serviceApiClient.invoiceDocuments(programType, supplierId, supplierJobId, supplierInvoiceNumber) || [];
        },
        (payload: IApiLoadInvoiceDocuments, data) => {
            if (data) {
                // Set the clientRowKey in each object so it can be bound into the DetailsList.
                data.forEach((value: SupplierDocumentDetails, index: number) => {
                    value.clientRowKey = index.toString()
                });
            }

            payload.invoiceDocuments = data;
        }
    );
};

/**
 * Load data erasure certificates action payload.
 */
export interface IApiLoadDataErasureCertificates extends ICallApiBase {
    dataErasureCertificates?: CertificateSummary[] | null;
}

/**
 * Load data erasure certificates.
 * @param programType Program type.
 * @param supplierId Supplier id.
 * @param supplierJobId Supplier job id.
 * @param supplierUnitId Supplier unit id.
 * @param supplierUnitType Supplier unit type.
 * @param assetTag Asset tag.
 * @param serialNumber Serial number.
 * @returns Redux dispatch function.
 */
export const callApiLoadDataErasureCertificates = (programType: string | undefined, supplierId: string | undefined, supplierJobId: string | undefined, supplierUnitId: string | undefined, supplierUnitType: string | undefined, assetTag: string | undefined, serialNumber: string | undefined): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<CertificateSummary[]>(
        actionTypes.API_LOAD_DATA_ERASURE_CERTIFICATES,
        async () => {
            return await serviceApiClient.dataErasureCertificates(programType, supplierId, supplierJobId, supplierUnitId, supplierUnitType, assetTag, serialNumber) || [];
        },
        (payload: IApiLoadDataErasureCertificates, data) => {
            if (data) {
                // Set the clientRowKey in each object so it can be bound into the DetailsList.
                data.forEach((value: CertificateSummary, index: number) => {
                    value.clientRowKey = index.toString()
                });
            }

            payload.dataErasureCertificates = data;
        }
    );
};

/**
 * Download blob stream action payload.
 */
export interface IApiDownloadBlobStream extends ICallApiBase {
    blobStream?: string | null;
    item?: CertificateSummary | SupplierDocumentDetails;
    documentType?: DocumentType;
}

/**
 * Download blob stream.
 * @param item Item to download.
 * @param documentType Document type.
 * @returns Redux dispatch function.
 */
export const callApiDownloadBlobStream = (item: CertificateSummary | SupplierDocumentDetails, documentType: DocumentType): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<string | null>(
        actionTypes.API_DOWNLOAD_BLOB_STREAM,
        async () => {
            return await serviceApiClient.downloadBlobStream(item.fileBlobUrl!);
        },
        (payload: IApiDownloadBlobStream, data) => {
            payload.blobStream = data;
            payload.item = item;
            payload.documentType = documentType;
        }
    );
};

/**
 * Reset the page action call state for all api calls.
 * This clear all data from the Redux store for these calls, including any error state for failed calls.
 * This makes it so if there was a failed call and an error was shown on the page, if the user navigates
 * to another page and back, the prior error would not be shown.
 */
export const resetApiCallState = () => {
    const apiLoadRecyclingCertificates: IApiLoadRecyclingCertificates = {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        recyclingCertificates: undefined
    };
    reduxStore.dispatch({
        type: actionTypes.API_LOAD_RECYCLING_CERTIFICATES,
        payload: apiLoadRecyclingCertificates
    });

    const apiLoadPoDocuments: IApiLoadPoDocuments = {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        poDocuments: undefined
    };
    reduxStore.dispatch({
        type: actionTypes.API_LOAD_PO_DOCUMENTS,
        payload: apiLoadPoDocuments
    });

    const apiLoadInvoiceDocuments: IApiLoadInvoiceDocuments = {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        invoiceDocuments: undefined
    };
    reduxStore.dispatch({
        type: actionTypes.API_LOAD_INVOICE_DOCUMENTS,
        payload: apiLoadInvoiceDocuments
    });

    const apiLoadDataErasureCertificates: IApiLoadDataErasureCertificates = {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        dataErasureCertificates: undefined
    };
    reduxStore.dispatch({
        type: actionTypes.API_LOAD_DATA_ERASURE_CERTIFICATES,
        payload: apiLoadDataErasureCertificates
    });

    const apiDownloadBlobStream: IApiDownloadBlobStream = {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        blobStream: undefined,
        item: undefined,
        documentType: undefined
    };
    reduxStore.dispatch({
        type: actionTypes.API_DOWNLOAD_BLOB_STREAM,
        payload: apiDownloadBlobStream
    });
};
