import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Template } from '@coherence-design-system/controls';
import { FeedbackCenter } from '@coherence-design-system/feedback-center';
import { appConfig } from '../appConfig';
import { graphApiClient } from '../../services/api/graphApiClient';
import { getUserFullName } from '../../services/auth/msalHelper';
import { componentStyles, headerStyles } from './AppHeader.styles';
import { FontIcon, IPanel, Image, ResizeGroup, TooltipHost } from '@fluentui/react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { setCopilotPanelIsOpen, setNavIsOpen } from '../../store/actions/app.action';
import { Header, HeaderButton, IHeaderButtonProps, usePanels, useResizeGroup } from '@coherence-design-system/header';
import { getOcvOptions } from './ocvOptions';
import _ from 'lodash';
import { ProfilePanel } from '../ProfilePanel/ProfilePanel';

// See Coherence demo header here:
// https://microsoftit.visualstudio.com/OneITVSO/_git/SE-DES-Coher-Package?path=/apps/demo/src/components/DemoHeader/index.tsx

export interface AppHeaderProps {
}

const AppHeader: React.FunctionComponent<AppHeaderProps> = (props: AppHeaderProps): JSX.Element => {
    const [userImage, setUserImage] = useState<string | undefined>(undefined);
    const { currentPanel, openPanel, closePanel } = usePanels();
    const { onReduceData, onGrowData, onRenderData } = useResizeGroup({ closePanel, currentPanel });
    const ocvFeedbackPanelRef = useRef<IPanel>(null);
    const profilePanelRef = useRef<IPanel>(null);

    // Redux store selectors to get state from the store when it changes.
    const navIsOpen: boolean =
        useAppSelector<boolean>((state) => state.appReducer.navIsOpen);
    const copilotPanelIsOpen: boolean =
        useAppSelector<boolean>((state) => state.appReducer.copilotPanelIsOpen);

    // Redux store dispatch to send actions to the store.
    const dispatch = useAppDispatch();

    const ocvOptions = getOcvOptions();
    ocvOptions.callbackFunctions!.onDismiss = () => {
        closePanel();
    };

    /**
     * Effect for when the component loads.
     */
    useEffect(() => {
        // Only make the call to get the user image if it has not been set yet.
        if (userImage === undefined) {
            (async () => {
                setUserImage(await graphApiClient.getUserPhoto());
            })();
        }
    }, [userImage] /* Empty array ensures that effect is only run on mount and unmount. */);

    /**
     * Renders site label.
     * @returns JSX element.
     */
    const renderSiteLabel = (): JSX.Element => {
        return (
            <>
                <span className={componentStyles.siteLabel}>MS Recycling</span>
                {appConfig.current.settings.displayEnvNameInHeader && (
                    <span className={componentStyles.envName}>[{appConfig.current.settings.environmentName}]</span>
                )}
            </>
        );
    };

    
    /**
     * Header buttons to display in the resize group. Not including the profile button, as it is a static trigger.
     */
    const resizeGroupHeaderButtons = useMemo<IHeaderButtonProps[]>(() => {
        const buttons: IHeaderButtonProps[] = [];

        if (appConfig.current.featureFlighting.copilot) {
            buttons.push(
                {
                    key: 'copilot',
                    onRenderIcon: () => {
                        return (
                            <div>
                                <Image src="/images/copilotWhite.svg" width="20px" alt="Copilot" />
                            </div>
                        );
                    },
                    ariaLabel: 'Copilot',
                    text: 'Copilot',
                    checked: copilotPanelIsOpen,
                    onClick: () => {
                        dispatch(setCopilotPanelIsOpen(!copilotPanelIsOpen));
                    }
                } as IHeaderButtonProps
            );
        }

        buttons.push(
            {
                key: 'ocvFeedback',
                onRenderIcon: () => {
                    return (
                        <div className={componentStyles.headerIconContainer}>
                            <FontIcon iconName='Feedback' />
                        </div>
                    );
                },
                ariaLabel: 'Feedback',
                text: 'Feedback',
                checked: _.isEqual(currentPanel, ocvFeedbackPanelRef),
                onClick: () => {
                    openPanel(ocvFeedbackPanelRef);
                }
            } as IHeaderButtonProps
        );

        return buttons;
    }, [copilotPanelIsOpen, currentPanel, dispatch, openPanel]);

    return (
        <div>
            <Header
                title={{ href: '/', children: renderSiteLabel() }}
                showHamburger={true}
                styles={headerStyles}
                toggleNavCollapsed={() => {
                    dispatch(setNavIsOpen(!navIsOpen));
                }}
                isNavCollapsed={!navIsOpen}
            >
                <Template slot="search">
                    <div>{/* Placeholder. Not using this feature. */}</div>
                </Template>

                <Template slot="collapsible-triggers">
                    <ResizeGroup
                        className={componentStyles.resizeGroup}
                        data={{
                            primary: resizeGroupHeaderButtons,
                            overflow: []
                        }}
                        onReduceData={onReduceData}
                        onGrowData={onGrowData}
                        onRenderData={onRenderData}
                    />
                </Template>
                
                <Template slot="static-triggers">
                    {/* Using my own profile panel, not using the one in Coherence, as I want more customizability. */}
                    <TooltipHost content={`Profile for ${getUserFullName()}`} id={'profile'}>
                        <HeaderButton
                            onRenderIcon={() => {
                                return (
                                    <div className={componentStyles.headerIconContainer}>
                                        {userImage && (
                                            <Image src={userImage} height={32} width={32} className={componentStyles.userImage} />
                                        )}
                                        {!userImage && (
                                            <FontIcon iconName='Contact' />
                                        )}
                                    </div>
                                );
                            }}
                            onClick={() => {
                                openPanel(profilePanelRef);
                            }}
                            checked={_.isEqual(currentPanel, profilePanelRef)}
                            aria-describedby={'profile'}
                        />
                    </TooltipHost>
                </Template>
            </Header>

            <FeedbackCenter
                componentRef={ocvFeedbackPanelRef}
                isOpen={Boolean(currentPanel === ocvFeedbackPanelRef)}
                onDismiss={() => closePanel()}
                ocvOptions={ocvOptions}
            />

            <ProfilePanel
                componentRef={profilePanelRef}
                isOpen={Boolean(currentPanel === profilePanelRef)}
                onDismiss={() => closePanel()}
                userImage={userImage}
            />
        </div>
    );
}

export default AppHeader;
