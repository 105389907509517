import { AnyAction, Reducer } from 'redux';
import * as actionTypes from '../actions/actionTypes';

export interface IAppReducerState {
    copilotPanelIsOpen: boolean;
    navIsOpen: boolean;
}

const initialAppReducerState: IAppReducerState = {
    copilotPanelIsOpen: false,
    navIsOpen: false
};

export const appReducer: Reducer<IAppReducerState, AnyAction> = (
    state: IAppReducerState = initialAppReducerState, action: AnyAction
): IAppReducerState => {
    switch (action.type) {
        case actionTypes.COPILOT_PANEL_IS_OPEN: {
            const payload: boolean = action.payload as boolean;
            return {
                ...state,
                copilotPanelIsOpen: payload
            }
        }
        case actionTypes.NAV_IS_OPEN: {
            const payload: boolean = action.payload as boolean;
            return {
                ...state,
                navIsOpen: payload
            }
        }
        default:
    }

    return state;
};
