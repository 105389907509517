export interface IDbdDetails {
    correlationId: string;
    supplierReportingDate: Date;
    supplierUnitId: string;
    supplierUnitType: string;
    description: string;
    assetTagNumber: string;
    assetTagNumber2: string;
    serialNumber: string;
    manufacturer: string;
    model: string;
    partNumber: string;
    quantity: number;
    weightInKilos: number;
    dataDeviceFormFactor: string;
    hddCount: number;
    cpuSpeed: string;
    ramSize: string;
    parentUnitId: string;
    parentUnitType: string;
    parentUnitSerialNumber: string;
    parentUnitAssetTagNumber: string;
}

export class DbdDetails implements IDbdDetails {
    public correlationId: string;
    public supplierReportingDate: Date;
    public supplierUnitId: string;
    public supplierUnitType: string;
    public description: string;
    public assetTagNumber: string;
    public assetTagNumber2: string;
    public serialNumber: string;
    public manufacturer: string;
    public model: string;
    public partNumber: string;
    public quantity: number;
    public weightInKilos: number;
    public dataDeviceFormFactor: string;
    public hddCount: number;
    public cpuSpeed: string;
    public ramSize: string;
    public parentUnitId: string;
    public parentUnitType: string;
    public parentUnitSerialNumber: string;
    public parentUnitAssetTagNumber: string;

    // Below are properties NOT returned from the server but are used for client side purposes.
    public clientRowKey: string = '';

    constructor(jsonData: IDbdDetails) {
        this.correlationId = jsonData.correlationId;
        this.supplierReportingDate = new Date(jsonData.supplierReportingDate);
        this.supplierUnitId = jsonData.supplierUnitId;
        this.supplierUnitType = jsonData.supplierUnitType;
        this.description = jsonData.description;
        this.assetTagNumber = jsonData.assetTagNumber;
        this.assetTagNumber2 = jsonData.assetTagNumber2;
        this.serialNumber = jsonData.serialNumber;
        this.manufacturer = jsonData.manufacturer;
        this.model = jsonData.model;
        this.partNumber = jsonData.partNumber;
        this.quantity = jsonData.quantity;
        this.weightInKilos = jsonData.weightInKilos;
        this.dataDeviceFormFactor = jsonData.dataDeviceFormFactor;
        this.hddCount = jsonData.hddCount;
        this.cpuSpeed = jsonData.cpuSpeed;
        this.ramSize = jsonData.ramSize;
        this.parentUnitId = jsonData.parentUnitId;
        this.parentUnitType = jsonData.parentUnitType;
        this.parentUnitSerialNumber = jsonData.parentUnitSerialNumber;
        this.parentUnitAssetTagNumber = jsonData.parentUnitAssetTagNumber;
    }
}
