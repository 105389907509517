import { AnyAction, Reducer } from 'redux';
import * as actionTypes from '../../actions/actionTypes';
import { IApiAddProgram, IApiCheckIsMsRecyclingTeamMember, IApiLoadPrograms, IApiUpdateProgram } from '../../actions/adminTabActions/adminTabPrograms.action';
import { CallApiState, StateUpdateOption } from '../../actions/generic.action';

export interface IAdminTabProgramsReducerState {
    apiLoadPrograms: IApiLoadPrograms;
    apiUpdateProgram: IApiUpdateProgram;
    apiAddProgram: IApiAddProgram;
    apiCheckIsMsRecyclingTeamMember: IApiCheckIsMsRecyclingTeamMember;
}

const initialAdminTabProgramsReducerState: IAdminTabProgramsReducerState = {
    apiLoadPrograms: {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        programs: undefined
    },
    apiUpdateProgram: {
        callApiState: CallApiState.Initial,
        errMsg: undefined
    },
    apiAddProgram: {
        callApiState: CallApiState.Initial,
        errMsg: undefined
    },
    apiCheckIsMsRecyclingTeamMember: {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        isMsRecyclingTeamMember: undefined 
    }
};

export const adminTabProgramsReducer: Reducer<IAdminTabProgramsReducerState, AnyAction> = (
    state: IAdminTabProgramsReducerState = initialAdminTabProgramsReducerState, action: AnyAction
): IAdminTabProgramsReducerState => {
    switch (action.type) {
        case actionTypes.API_LOAD_PROGRAMS: {
            const payload: IApiLoadPrograms = action.payload as IApiLoadPrograms;
            switch (payload.stateUpdateOption) {
                case StateUpdateOption.ErrorOnly:
                    return {
                        ...state,
                        // Keep the prior payload except for the errMsg which will be set to undefined.
                        apiLoadPrograms: {
                            ...state.apiLoadPrograms,
                            errMsg: undefined
                        }
                    }
                default:
                    return {
                        ...state,
                        apiLoadPrograms: { ...payload }
                    }
            }
        }
        case actionTypes.API_UPDATE_PROGRAM: {
            const payload: IApiUpdateProgram = action.payload as IApiUpdateProgram;
            switch (payload.stateUpdateOption) {
                case StateUpdateOption.ErrorOnly:
                    return {
                        ...state,
                        // Keep the prior payload except for the errMsg which will be set to undefined.
                        apiUpdateProgram: {
                            ...state.apiUpdateProgram,
                            errMsg: undefined
                        }
                    }
                default:
                    return {
                        ...state,
                        apiUpdateProgram: { ...payload }
                    }
            }
        }
        case actionTypes.API_ADD_PROGRAM: {
            const payload: IApiAddProgram = action.payload as IApiAddProgram;
            switch (payload.stateUpdateOption) {
                case StateUpdateOption.ErrorOnly:
                    return {
                        ...state,
                        // Keep the prior payload except for the errMsg which will be set to undefined.
                        apiAddProgram: {
                            ...state.apiAddProgram,
                            errMsg: undefined
                        }
                    }
                default:
                    return {
                        ...state,
                        apiAddProgram: { ...payload }
                    }
            }
        }
        case actionTypes.API_CHECK_IS_MSRECYCLING_TEAM_MEMBER: {
            const payload: IApiCheckIsMsRecyclingTeamMember = action.payload as IApiCheckIsMsRecyclingTeamMember;
            switch (payload.stateUpdateOption) {
                case StateUpdateOption.ErrorOnly:
                    return {
                        ...state,
                        // Keep the prior payload except for the errMsg which will be set to undefined.
                        apiCheckIsMsRecyclingTeamMember: {
                            ...state.apiCheckIsMsRecyclingTeamMember,
                            errMsg: undefined
                        }
                    }
                default:
                    return {
                        ...state,
                        apiCheckIsMsRecyclingTeamMember: { ...payload }
                    }
            }
        }
        default:
    }

    return state;
};
