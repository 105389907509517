import React, { Component, ErrorInfo, ReactNode } from 'react';
import { telemetryService } from '../services/TelemetryService/TelemetryService';

/**
 * This class is used as a wrapper to catch any errors that may occur on its children.
 * See: https://reactjs.org/docs/error-boundaries.html
 *      https://techdoma.in/tutorial/how-to-use-error-boundary-in-react/
 *      https://www.javatpoint.com/react-error-boundaries
 *      https://www.cheezycode.com/2018/08/componentdidcatch-error-boundary-react-16.html
 * 
 * Note that this error boundary cannot catch errors in async code, see above link from reactjs.org:
 * "Error boundaries do not catch errors for ... Asynchronous code"
 */
class AppErrorBoundry extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = { hasError: false };
    }

    /**
     * It will update the state so the next render shows the fallback UI.
     * @param error Error object.
     */
    static getDerivedStateFromError(error: any): any {
        return { hasError: true };  
    }

    /**
     * Catch any errors that occured in our application.
     * @param error Error object.
     * @param info Info object.
     */
    public componentDidCatch(error: Error, info: ErrorInfo): void {
        console.error(error);
        console.log(info);

        telemetryService.trackException({ exception: error });
    }

    render(): ReactNode {
        if (this.state.hasError) {
            // todo: Display errors in a better way.
            return <div>Something went wrong.</div>;
        }

        return this.props.children;
    }
}

export default AppErrorBoundry;
