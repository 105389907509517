export interface ICollection {
    correlationId: string;
    supplierReportingDate?: Date;
    supplierReceivedDate?: Date;
    collectionDate?: Date;
    collectionSiteName?: string;
    collectionSiteCode?: string;
    msCollectionTicketNumber?: string;
    collectionAddress: string;
    collectionAddressNumber: string;
    collectionCity: string;
    collectionCountry: string;
    collectionZipCode: string;
    supplierProcessingSite: string;
    supplierProcessingCountry: string;
}

export class Collection implements ICollection {
    public correlationId: string;
    public supplierReportingDate?: Date;
    public supplierReceivedDate?: Date;
    public collectionDate?: Date;
    public collectionSiteName?: string;
    public collectionSiteCode?: string;
    public msCollectionTicketNumber?: string;
    public collectionAddress: string;
    public collectionAddressNumber: string;
    public collectionCity: string;
    public collectionCountry: string;
    public collectionZipCode: string;
    public supplierProcessingSite: string;
    public supplierProcessingCountry: string;

    constructor(jsonData: ICollection) {
        this.correlationId = jsonData.correlationId;
        this.supplierReportingDate = jsonData.supplierReportingDate ? new Date(jsonData.supplierReportingDate) : undefined;
        this.supplierReceivedDate = jsonData.supplierReceivedDate ? new Date(jsonData.supplierReceivedDate) : undefined;
        this.collectionDate = jsonData.collectionDate ? new Date(jsonData.collectionDate) : undefined;
        this.collectionSiteName = jsonData.collectionSiteName;
        this.collectionSiteCode = jsonData.collectionSiteCode;
        this.msCollectionTicketNumber = jsonData.msCollectionTicketNumber;
        this.collectionAddress = jsonData.collectionAddress;
        this.collectionAddressNumber = jsonData.collectionAddressNumber;
        this.collectionCity = jsonData.collectionCity;
        this.collectionCountry = jsonData.collectionCountry;
        this.collectionZipCode = jsonData.collectionZipCode;
        this.supplierProcessingSite = jsonData.supplierProcessingSite;
        this.supplierProcessingCountry = jsonData.supplierProcessingCountry;
    }
}
