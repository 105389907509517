export interface ISupplierJob {
    supplierJobId: string;
    supplierJobType: string;
}

export class SupplierJob implements ISupplierJob {
    public supplierJobId: string;
    public supplierJobType: string;

    constructor(jsonData: ISupplierJob) {
        this.supplierJobId = jsonData.supplierJobId;
        this.supplierJobType = jsonData.supplierJobType;
    }
}
