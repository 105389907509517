import { Configuration, LogLevel } from '@azure/msal-browser';
import { appConfig } from '../../shell/appConfig';

/**
 * Initialize the MSAL configuration.
 * @returns MSAL configuration object.
 */
export const initMsalConfig = (): Configuration => {
    if (!appConfig.current) {
        throw new Error('Cannot initialize MSAL until app config is initialized.');
    }

    const msalConfig: Configuration = {
        auth: {
            clientId: appConfig.current.msal.clientId,
            authority: appConfig.current.msal.authority,
            redirectUri: appConfig.current.msal.redirectUri,
            postLogoutRedirectUri: appConfig.current.msal.postLogoutRedirectUri,
            navigateToLoginRequestUrl: false
        },
        system: {
            loggerOptions: {
                logLevel: LogLevel.Warning, // Use LogLevel.Verbose to see more diagnostic info.
                piiLoggingEnabled: false,
                loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
                    console.log('[MSAL]', message);
                }
            },
            loadFrameTimeout: 6000
        },
        cache: {
            cacheLocation: appConfig.current.msal.cacheLocation,
            storeAuthStateInCookie: appConfig.current.msal.storeAuthStateInCookie
        }
    }

    return msalConfig;
}
