import { AnyAction, Reducer } from 'redux';
import * as actionTypes from '../../actions/actionTypes';
import { CallApiState, StateUpdateOption } from '../../actions/generic.action';
import { IApiDownloadBlobStream, IApiLoadDbd } from '../../actions/pageActions/dbdPage.action';

export interface IDbdPageReducerState {
    apiLoadDbd: IApiLoadDbd;
    apiDownloadBlobStream: IApiDownloadBlobStream;
}

const initialDbdPageReducerState: IDbdPageReducerState = {
    apiLoadDbd: {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        dbdReport: undefined
    },
    apiDownloadBlobStream: {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        blobStream: undefined,
        item: undefined
    }
};

export const dbdPageReducer: Reducer<IDbdPageReducerState, AnyAction> = (
    state: IDbdPageReducerState = initialDbdPageReducerState, action: AnyAction
): IDbdPageReducerState => {
    switch (action.type) {
        case actionTypes.API_LOAD_DBD_REPORT: {
            const payload: IApiLoadDbd = action.payload as IApiLoadDbd;
            switch (payload.stateUpdateOption) {
                case StateUpdateOption.ErrorOnly:
                    return {
                        ...state,
                        // Keep the prior payload except for the errMsg which will be set to undefined.
                        apiLoadDbd: {
                            ...state.apiLoadDbd,
                            errMsg: undefined
                        }
                    }
                default:
                    return {
                        ...state,
                        apiLoadDbd: { ...payload }
                    }
            }
        }
        case actionTypes.API_DOWNLOAD_BLOB_STREAM: {
            const payload: IApiDownloadBlobStream = action.payload as IApiDownloadBlobStream;
            return {
                ...state,
                apiDownloadBlobStream: { ...payload }
            }
        }
        default:
    }

    return state;
};
