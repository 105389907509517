export interface ISlaJobUnits {
    metJobUnits: number;
    notMetJobUnits: number;
}

export class SlaJobUnits {
    public metJobUnits: number;
    public notMetJobUnits: number;

    constructor(jsonData: ISlaJobUnits) {
        this.metJobUnits = jsonData.metJobUnits;
        this.notMetJobUnits = jsonData.notMetJobUnits;
    }
}
