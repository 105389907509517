export interface IJobUnit {
    supplierUnitId: string;
    supplierUnitType: string;
}

export class JobUnit implements IJobUnit {
    public supplierUnitId: string;
    public supplierUnitType: string;

    constructor(jsonData: IJobUnit) {
        this.supplierUnitId = jsonData.supplierUnitId;
        this.supplierUnitType = jsonData.supplierUnitType;
    }
}
