export interface IValidations {
    msCollectionTicketNumber?: boolean;
    checkJobExistence?: boolean;
    checkJobUnitExistence?: boolean;
    checkJobStatus?: boolean;
    jobExpiryDays?: number;
    collectionSiteCode?: boolean;
    msCompanyCodeValidation?: boolean;
    assetTagNumberRegex?: string;
    serialNumberRegex?: string;
    apiMaxTotalUnits?: number;
}

export class Validations implements IValidations {
    public msCollectionTicketNumber?: boolean;
    public checkJobExistence?: boolean;
    public checkJobUnitExistence?: boolean;
    public checkJobStatus?: boolean;
    public jobExpiryDays?: number;
    public collectionSiteCode?: boolean;
    public msCompanyCodeValidation?: boolean;
    public assetTagNumberRegex?: string;
    public serialNumberRegex?: string;
    public apiMaxTotalUnits?: number;

    constructor(jsonData: IValidations) {
        this.msCollectionTicketNumber = jsonData.msCollectionTicketNumber;
        this.checkJobExistence = jsonData.checkJobExistence;
        this.checkJobUnitExistence = jsonData.checkJobUnitExistence;
        this.checkJobStatus = jsonData.checkJobStatus;
        this.jobExpiryDays = jsonData.jobExpiryDays;
        this.collectionSiteCode = jsonData.collectionSiteCode;
        this.msCompanyCodeValidation = jsonData.msCompanyCodeValidation;
        this.assetTagNumberRegex = jsonData.assetTagNumberRegex;
        this.serialNumberRegex = jsonData.serialNumberRegex;
        this.apiMaxTotalUnits = jsonData.apiMaxTotalUnits;
    }
}
