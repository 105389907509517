import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    IColumn,
    Stack,
    Text,
    TooltipHost,
    DefaultButton,
    DetailsList,
    IDetailsListProps,
    FontIcon,
    Spinner,
    SpinnerSize
} from '@fluentui/react';
import {
    CoherencePagination,
    CoherencePageSize,
    ICoherencePaginationProps,
    ICoherencePageSizeProps
} from '@coherence-design-system/controls';
import { coherencePageSizeStyles, coherencePaginationStyles, controlStyles } from './CustomDetailsList.styles';
import ReactExport from 'react-data-export';
import { commonStyles } from '../../common/common.styles';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

/**
 * Excel column. Used with Excel export feature.
 */
export interface IExcelCol {
    colName: string;
    dataField: string;
}

/**
 * Excel data. Used with Excel export feature.
 */
export interface IExcelData {
    items: any[];
    cols: IExcelCol[];
}

export interface ICustomDetailsListProps extends IDetailsListProps {
    /**
     * Show paginator.
     */
    showPaginator?: boolean;

     /**
      * Show page size next to paginator.
      */
    showPageSize?: boolean;
 
    /**
     * Page sizes.
     */
    pageSizes?: number[];

     /**
      * On page size changed callback.
      */
    onPageSizeChange?: (pageSize: number) => void;
     
     /**
      * Selected page. Not 0 based, starts at page 1.
      */
    selectedPage?: number;
 
     /**
      * Selected page changed callback.
      * @param page Page number. Not 0 based, starts at page 1.
      */
    onSelectedPageChange?: (page: number) => void;
     
     /**
      * Show no data found message.
      */
    showNoDataFoundMsg?: boolean;
 
     /**
      * Display total items on bottom of grid.
      */
    displayTotalItems?: boolean;
     
     /**
      * Aria label for grid.
      */
    ariaLabelForGrid?: string;
 
     /**
      * Show excel export option. Exports data currently shown in grid. When using local paging this includes
      * all data. When using server side paging this is only data currently loaded in the grid.
      */
    showExcelExport?: boolean;
 
     /**
      * Export to Excel worksheet name.
      */
    exportExcelSheetName?: string;
 
    /**
     * Alternate data to export to Excel. Normally the props.items array will be exported.
     */
    exportAltExcelData?: IExcelData;

     /**
      * Used to implement server side paging. Normally the item count is based on items.length
      * which is good for client side paging. But when using srever side paging we know the amount
      * of total items from search results metadata. We can then handle retriving the next page of
      * data using the onSelectedPageChange and onPageSizeChange callbacks.
      */
    totalItemsAtServer?: number;
 
     /**
      * Indicates if data is loading. Will display a busy spinner if true.
      * The grid and paginator will still show, but with no data in it. This can help create a better
      * looking UI rather than replacing the entire grid with a spinner from outside this control.
      */
    isLoading?: boolean;

    /**
     * Optional id to use on root element of the control.
     */
    id?: string;

    /**
     * Optional text to display in the footer on the bottom right.
     */
    footerText?: string;
}

const defaultPageSizes: number[] = [10, 25, 100];

/**
 * Custom details list.
 * Wraps usage of Fluent DetailsList with Coherence CoherencePagination and CoherencePageSize, among other features.
 * 
 * Note there is a data grid control in Coherence that also wraps the Fluent DetailsList,
 * see: https://coherence-portal.azurewebsites.net/Controls/DataGrid
 * It does not wrap CoherencePagination and CoherencePageSize, and has some behaviors that are not desirable for this
 * app (as of version @coherence-design-system/controls 4.3.0). So opting to not use that control and to make our own
 * control here for maximum flexibility.
 */
export const CustomDetailsList: React.FunctionComponent<ICustomDetailsListProps> = (props: ICustomDetailsListProps): JSX.Element => {
    const [selectedPage, setSelectedPage] = useState<number | undefined>(props.selectedPage);
    const [pageCount, setPageCount] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(props.pageSizes ? props.pageSizes[0] : defaultPageSizes[0]);
    const [pageData, setPageData] = useState<any[]>([]);

    // Excel data used for export.
    const excelData: IExcelData = useMemo<IExcelData>(() => {
        // If props.exportAltExcelData present then use that. Otherwise use props.items.
        if (props.exportAltExcelData) {
            return props.exportAltExcelData;
        } else {
            return {
                items: props.items,
                cols: props.columns?.map((col: IColumn) => {
                    return {
                        colName: col.name,
                        dataField: col.fieldName
                    } as IExcelCol
                })
            } as IExcelData;
        }
    }, [props.columns, props.exportAltExcelData, props.items]);

    useEffect(() => {
        // If the page changes from props, then update our local state for the selected page.
        setSelectedPage(props.selectedPage)
    }, [props.selectedPage])

    useEffect(() => {
        const data: any[] = [];

        if (props.showPaginator) {
            if (typeof props.totalItemsAtServer === 'number') {
                setPageCount(Math.ceil(props.totalItemsAtServer / pageSize));
                data.push(...props.items);
            } else {
                if (selectedPage) {
                    for (let i: number = (selectedPage - 1) * pageSize;
                        (i < selectedPage * pageSize) && i < props.items.length;
                        i++) {
                        data.push(props.items[i]);
                    }
                }

                setPageCount(Math.ceil(props.items.length / pageSize));
            }
        } else {
            data.push(...props.items);
        }

        setPageData(data);
    }, [pageSize, props.items, props.showPaginator, props.totalItemsAtServer, selectedPage]);

    const onPageChange = useCallback((startItemIndex: number, endItemIndex: number, newSelectedPage: number): void => {
        if (newSelectedPage !== selectedPage) {
            setSelectedPage(newSelectedPage);

            if (props.onSelectedPageChange) {
                props.onSelectedPageChange(newSelectedPage);
            }
        }
    }, [props, selectedPage]);

    const onPageSizeChange = useCallback((newPageSize: string | number): void => {
        setPageSize(newPageSize as number);
        setSelectedPage(1);

        if (props.onPageSizeChange) {
            props.onPageSizeChange(Number(newPageSize));
        }
    }, [props]);

    const paginationProps: ICoherencePaginationProps = useMemo<ICoherencePaginationProps>(() => {
        return {
            pageCount: pageCount,
            selectedPage: selectedPage || 0,
            previousPageAriaLabel: 'previous page',
            nextPageAriaLabel: 'next page',
            inputFieldAriaLabel: `page number ${selectedPage} of ${pageCount}`,
            onPageChange: onPageChange,
            styles: coherencePaginationStyles
        } as ICoherencePaginationProps;
    }, [onPageChange, pageCount, selectedPage]);

    const paginationPageSizeProps: ICoherencePageSizeProps = useMemo<ICoherencePageSizeProps>(() => {
        return {
            pageSize: pageSize,
            pageSizeList: (props.pageSizes || defaultPageSizes).map((x) => {
                return { key: x, text: x.toString() }
            }),
            comboBoxAriaLabel: 'page size',
            onPageSizeChange: onPageSizeChange,
            styles: coherencePageSizeStyles
        } as ICoherencePageSizeProps;
    }, [onPageSizeChange, pageSize, props.pageSizes]);

    /**
     * Renders the excel file export element.
     * See: https://www.npmjs.com/package/react-data-export
     * @returns Excel file export element.
     */
    const renderExcelFileExport = (): JSX.Element => {
        const exportExcelTooltip: string = 'Export to Excel';
        return (
            <ExcelFile filename="export" element={
                <TooltipHost content={exportExcelTooltip}
                    id='exportExcelTooltip' calloutProps={{ gapSpace: 0 }} styles={{ root: { display: 'inline-block' } }}>
                    <DefaultButton
                        aria-label={exportExcelTooltip}
                        aria-describedby={exportExcelTooltip}
                        className={controlStyles.exportButton}>
                        <FontIcon iconName="ExcelDocument" className={controlStyles.excelIcon} />
                    </DefaultButton>
                </TooltipHost>
            }>
                <ExcelSheet data={excelData.items} name={props.exportExcelSheetName}>
                    {
                        props.columns?.map((col: IColumn, index: number) => {
                            return <ExcelColumn key={index} label={col.name} value={col.fieldName} />
                        })
                    }
                </ExcelSheet>
            </ExcelFile>
        );
    };

    return (
        <div id={props.id}>
            <DetailsList
                { ...props }
                items={props.isLoading ? [] : pageData}               
            />

            {props.isLoading && (
                <div>
                    <Text variant='mediumPlus'>Loading...</Text>
                    <Spinner size={SpinnerSize.medium} className={commonStyles.spinner} />
                </div>
            )}

            {!props.isLoading && props.showNoDataFoundMsg && <Text variant="medium">No data found</Text>}

            {!props.isLoading && !props.showNoDataFoundMsg &&
                <>
                    {(props.showPaginator || props.showPageSize || props.displayTotalItems || props.showExcelExport) && (
                        <Stack horizontal horizontalAlign="start" className={controlStyles.paginatorContainer} wrap>
                            {props.showPaginator && (
                                <CoherencePagination {...paginationProps} />
                            )}
                            {props.showPageSize && (
                                <div style={{ whiteSpace: 'nowrap' }}>
                                    {/* Using nowrap will force the 'items per page' text inside the CoherencePageSize to always appear on one line and not wrap when the screen size is small. */}
                                    <CoherencePageSize {...paginationPageSizeProps} />
                                </div>
                            )}
                            {props.displayTotalItems && (
                                <div className={controlStyles.footerTextContainer}>
                                    <Text variant="medium" className={controlStyles.footerText}>Total items: {props.totalItemsAtServer !== undefined ? props.totalItemsAtServer : props.items.length}</Text>
                                </div>
                            )}
                            {props.showExcelExport && (
                                <div className={controlStyles.excelExport}>
                                    {renderExcelFileExport()}
                                </div>
                            )}
                            {props.footerText && (
                                <div className={controlStyles.footerTextContainer}>
                                    <Text variant="medium" className={controlStyles.footerText}>{props.footerText}</Text>
                                </div>
                            )}
                        </Stack>
                    )}
                </>
            }
        </div>
    );
};
