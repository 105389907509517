import { IStyle, mergeStyleSets } from '@fluentui/react';

/**
 * Styles used on this page.
 */
export const pageStyles = mergeStyleSets({
    errorText: {
        color: 'red'
    } as IStyle
});
