export interface ISupplierPurchaseOrderInfo {
    programType: string;
    supplierId: string;
    supplierJobId: string;
    msCompanyCode: string;
    billingCountry: string;
    msCustomerNumber: string;
    poType: string;
    supplierPoNumber: string;
    supplierPoCurrency: string;
    totalMsRevenueShare?: number;
    totalMsRevenueShareUSD?: number;
    totalSupplierCommission?: number;
    totalSupplierCommissionUSD?: number;
}

export class SupplierPurchaseOrderInfo implements ISupplierPurchaseOrderInfo {
    public programType: string;
    public supplierId: string;
    public supplierJobId: string;
    public msCompanyCode: string;
    public billingCountry: string;
    public msCustomerNumber: string;
    public poType: string;
    public supplierPoNumber: string;
    public supplierPoCurrency: string;
    public totalMsRevenueShare?: number;
    public totalMsRevenueShareUSD?: number;
    public totalSupplierCommission?: number;
    public totalSupplierCommissionUSD?: number;

    // Below are properties NOT returned from the server but are used for client side purposes.
    public clientRowKey: string = '';

    constructor(jsonData: ISupplierPurchaseOrderInfo) {
        this.programType = jsonData.programType;
        this.supplierId = jsonData.supplierId;
        this.supplierJobId = jsonData.supplierJobId;
        this.msCompanyCode = jsonData.msCompanyCode;
        this.billingCountry = jsonData.billingCountry;
        this.msCustomerNumber = jsonData.msCustomerNumber;
        this.poType = jsonData.poType;
        this.supplierPoNumber = jsonData.supplierPoNumber;
        this.supplierPoCurrency = jsonData.supplierPoCurrency;
        this.totalMsRevenueShare = jsonData.totalMsRevenueShare;
        this.totalMsRevenueShareUSD = jsonData.totalMsRevenueShareUSD;
        this.totalSupplierCommission = jsonData.totalSupplierCommission;
        this.totalSupplierCommissionUSD = jsonData.totalSupplierCommissionUSD;
    }
}
