export interface IInvoice {
    correlationId: string;
    supplierReportingDate?: Date;
    supplierInvoiceNumber: string;
    unitFee1?: number;
    unitFee1USD?: number;
    unitFee2?: number;
    unitFee2USD?: number;
    unitFee3?: number;
    unitFee3USD?: number;
    totalUnitFees?: number;
    totalUnitFeesUSD?: number;
}

export class Invoice implements IInvoice {
    public correlationId: string;
    public supplierReportingDate?: Date;
    public supplierInvoiceNumber: string;
    public unitFee1?: number;
    public unitFee1USD?: number;
    public unitFee2?: number;
    public unitFee2USD?: number;
    public unitFee3?: number;
    public unitFee3USD?: number;
    public totalUnitFees?: number;
    public totalUnitFeesUSD?: number;

    constructor(jsonData: IInvoice) {
        this.correlationId = jsonData.correlationId;
        this.supplierReportingDate = jsonData.supplierReportingDate ? new Date(jsonData.supplierReportingDate) : undefined;
        this.supplierInvoiceNumber = jsonData.supplierInvoiceNumber;
        this.unitFee1 = jsonData.unitFee1;
        this.unitFee1USD = jsonData.unitFee1USD;
        this.unitFee2 = jsonData.unitFee2;
        this.unitFee2USD = jsonData.unitFee2USD;
        this.unitFee3 = jsonData.unitFee3;
        this.unitFee3USD = jsonData.unitFee3USD;
        this.totalUnitFees = jsonData.totalUnitFees;
        this.totalUnitFeesUSD = jsonData.totalUnitFeesUSD;
    }
}
