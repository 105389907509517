import { AnyAction, Reducer } from 'redux';
import * as actionTypes from '../../actions/actionTypes';
import { CallApiState, StateUpdateOption } from '../../actions/generic.action';
import { IApiLoadTransportAndPackaging } from '../../actions/pageActions/transportAndPackagingPage.action';

export interface ITransportAndPackagingPageReducerState {
    apiLoadTransportAndPackaging: IApiLoadTransportAndPackaging;
}

const initialTransportAndPackagingPageReducerState: ITransportAndPackagingPageReducerState = {
    apiLoadTransportAndPackaging: {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        transportAndPackaging: undefined
    }
}

export const transportAndPackagingPageReducer: Reducer<ITransportAndPackagingPageReducerState, AnyAction> = (
    state: ITransportAndPackagingPageReducerState = initialTransportAndPackagingPageReducerState, action: AnyAction
): ITransportAndPackagingPageReducerState => {
    switch (action.type) {
        case actionTypes.API_LOAD_TRANSPORT_AND_PACKAGING: {
            const payload: IApiLoadTransportAndPackaging = action.payload as IApiLoadTransportAndPackaging;
            switch (payload.stateUpdateOption) {
                case StateUpdateOption.ErrorOnly:
                    return {
                        ...state,
                        // Keep the prior payload except for the errMsg which will be set to undefined.
                        apiLoadTransportAndPackaging: {
                            ...state.apiLoadTransportAndPackaging,
                            errMsg: undefined
                        }
                    }
                default:
                    return {
                        ...state,
                        apiLoadTransportAndPackaging: { ...payload }
                    }
            }
        }
        default:
    }

    return state;
}
