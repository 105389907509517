export interface ICountryStates {
    stateCode: string;
    stateName: string;
    countryCode: string;
}

export class CountryStates implements ICountryStates {
    public stateCode: string;
    public stateName: string;
    public countryCode: string;

    constructor(jsonData: ICountryStates) {
        this.stateCode = jsonData.stateCode;
        this.stateName = jsonData.stateName;
        this.countryCode = jsonData.countryCode;
    }
}
