export interface ISupplier {
    supplierId: string;
    supplierName: string;
    supplierContactMailingGroup?: string;
    emailNotification?: boolean;
    isEnabled?: boolean;
}

export class Supplier implements ISupplier {
    public supplierId: string;
    public supplierName: string;
    public supplierContactMailingGroup?: string;
    public emailNotification?: boolean;
    public isEnabled?: boolean;

    // Below are properties NOT returned from the server but are used for client side purposes.
    public clientRowKey: string = '';
    public isNew?: boolean;

    constructor(jsonData: ISupplier) {
        this.supplierId = jsonData.supplierId;
        this.supplierName = jsonData.supplierName;
        this.supplierContactMailingGroup = jsonData.supplierContactMailingGroup;
        this.emailNotification = jsonData.emailNotification;
        this.isEnabled = jsonData.isEnabled;
    }
}
