/**
 * Fields to display in the detail view.
 * This is outside the component as it isn't needed to be reinitialized on each render pass.
 * 
 * Job ('job' property below) data from jobSearchSummary.supplierJobDetails (SupplierJobDetails object which is the
 * result of the /jobSearch api) for the selected job row in the job search result table. All other data is from the
 * JobUnitDetails object which is the result of the /jobUnitDetails api when a job unit is clicked.
 */
export const detailsFieldDisplay = {
    job: [
        { name: 'supplierId', text: 'Supplier ID', isDate: false, isCurrency: false, needLocaleNumberFormat: false },
        { name: 'supplierName', text: 'Supplier Name', isDate: false, isCurrency: false, needLocaleNumberFormat: false },
        { name: 'supplierJobId', text: 'Supplier Job ID', isDate: false, isCurrency: false, needLocaleNumberFormat: false },
        { name: 'supplierJobType', text: 'Supplier Job Type', isDate: false, isCurrency: false, needLocaleNumberFormat: false },
        { name: 'jobStatus', text: 'Job Status', isDate: false, isCurrency: false, needLocaleNumberFormat: false },
        { name: 'collectionSiteName', text: 'Collection Site Name', isDate: false, isCurrency: false, needLocaleNumberFormat: false },
        { name: 'collectionSiteCode', text: 'Collection Site Code', isDate: false, isCurrency: false, needLocaleNumberFormat: false },
        { name: 'msCollectionTicketNumber', text: 'Collection Ticket Number', isDate: false, isCurrency: false, needLocaleNumberFormat: false },
        { name: 'msPoNumber', text: 'MS PO Number', isDate: false, isCurrency: false, needLocaleNumberFormat: false },
        { name: 'msPoCurrency', text: 'MS PO Currency', isDate: false, isCurrency: false, needLocaleNumberFormat: false },
        { name: 'supplierBillingEntity', text: 'Supplier Billing Entity', isDate: false, isCurrency: false, needLocaleNumberFormat: false },
        { name: 'billingCountry', text: 'Billing Country', isDate: false, isCurrency: false, needLocaleNumberFormat: false },
        { name: 'totalSupplierUnits', text: 'Total Supplier Units', isDate: false, isCurrency: false, needLocaleNumberFormat: true },
        { name: 'totalQuantity', text: 'Total Quantity', isDate: false, isCurrency: false, needLocaleNumberFormat: true },
        { name: 'totalWeightInKilos', text: 'Total Weight In Kilos', isDate: false, isCurrency: false, needLocaleNumberFormat: true },
        { name: 'totalInvoiceAmount', text: 'Total Invoice Amount', isDate: false, isCurrency: true, needLocaleNumberFormat: false },
        { name: 'totalInvoiceAmountUSD', text: 'Total Invoice Amount USD', isDate: false, isCurrency: true, needLocaleNumberFormat: false },
        { name: 'totalMsRevenueShare', text: 'Total MS Revenue Share', isDate: false, isCurrency: true, needLocaleNumberFormat: false },
        { name: 'totalMsRevenueShareUSD', text: 'Total MS Revenue Share USD', isDate: false, isCurrency: true, needLocaleNumberFormat: false },
        { name: 'totalSupplierCommission', text: 'Total Supplier Commission', isDate: false, isCurrency: true, needLocaleNumberFormat: false },
        { name: 'totalSupplierCommissionUSD', text: 'Total Supplier Commission USD', isDate: false, isCurrency: true, needLocaleNumberFormat: false },
        { name: 'logisticsFee', text: 'Logistics Fee', isDate: false, isCurrency: true, needLocaleNumberFormat: false },
        { name: 'logisticsFeeUSD', text: 'Logistics Fee USD', isDate: false, isCurrency: true, needLocaleNumberFormat: false },
        { name: 'minimumServiceFee', text: 'Minimum Service Fee', isDate: false, isCurrency: true, needLocaleNumberFormat: false },
        { name: 'minimumServiceFeeUSD', text: 'Minimum Service Fee USD', isDate: false, isCurrency: true, needLocaleNumberFormat: false }
    ],
    unit: [
        { name: 'supplierUnitId', text: 'Supplier Unit ID', isDate: false, isCurrency: false, needLocaleNumberFormat: false },
        { name: 'supplierUnitType', text: 'Supplier Unit Type', isDate: false, isCurrency: false, needLocaleNumberFormat: false },
        { name: 'parentUnitId', text: 'Parent Unit ID', isDate: false, isCurrency: false, needLocaleNumberFormat: false },
        { name: 'createdDate', text: 'Created Date', isDate: true, isCurrency: false, needLocaleNumberFormat: false },
        { name: 'modifiedDate', text: 'Modified Date', isDate: true, isCurrency: false, needLocaleNumberFormat: false }
    ],
    asset: [
        { name: 'assetTagNumber', text: 'Asset Tag Number', isDate: false, isCurrency: false, needLocaleNumberFormat: false },
        { name: 'assetTagNumber2', text: 'Asset Tag Number 2', isDate: false, isCurrency: false, needLocaleNumberFormat: false },
        { name: 'serialNumber', text: 'Serial Number', isDate: false, isCurrency: false, needLocaleNumberFormat: false },
        { name: 'description', text: 'Description', isDate: false, isCurrency: false, needLocaleNumberFormat: false },
        { name: 'manufacturer', text: 'Manufacturer', isDate: false, isCurrency: false, needLocaleNumberFormat: false },
        { name: 'model', text: 'Model', isDate: false, isCurrency: false, needLocaleNumberFormat: false },
        { name: 'partNumber', text: 'Part Number', isDate: false, isCurrency: false, needLocaleNumberFormat: false },
        { name: 'quantity', text: 'Quantity', isDate: false, isCurrency: false, needLocaleNumberFormat: true },
        { name: 'weightInKilos', text: 'Weight In Kilos', isDate: false, isCurrency: false, needLocaleNumberFormat: true },
        { name: 'dataDeviceFormFactor', text: 'Data Device Form Factor', isDate: false, isCurrency: false, needLocaleNumberFormat: false },
        { name: 'hddCount', text: 'HDD Count', isDate: false, isCurrency: false, needLocaleNumberFormat: true },
        { name: 'cpuSpeed', text: 'CPU Speed', isDate: false, isCurrency: false, needLocaleNumberFormat: false },
        { name: 'ramSize', text: 'RAM Size', isDate: false, isCurrency: false, needLocaleNumberFormat: false }
    ],
    collection: [
        { name: 'correlationId', text: 'Correlation ID', isDate: false, isCurrency: false, needLocaleNumberFormat: false },
        { name: 'supplierReportingDate', text: 'Supplier Reporting Date', isDate: true, isCurrency: false, needLocaleNumberFormat: false },
        { name: 'supplierReceivedDate', text: 'Supplier Received Date', isDate: true, isCurrency: false, needLocaleNumberFormat: false },
        { name: 'collectionDate', text: 'Collection Date', isDate: true, isCurrency: false, needLocaleNumberFormat: false },
        { name: 'collectionAddress', text: 'Collection Address', isDate: false, isCurrency: false, needLocaleNumberFormat: false },
        { name: 'collectionAddressNumber', text: 'Collection Address Number', isDate: false, isCurrency: false, needLocaleNumberFormat: false },
        { name: 'collectionCity', text: 'Collection City', isDate: false, isCurrency: false, needLocaleNumberFormat: false },
        { name: 'collectionCountry', text: 'Collection Country', isDate: false, isCurrency: false, needLocaleNumberFormat: false },
        { name: 'collectionZipCode', text: 'Collection Zip Code', isDate: false, isCurrency: false, needLocaleNumberFormat: false },
        { name: 'supplierProcessingSite', text: 'Supplier Processing Site', isDate: false, isCurrency: false, needLocaleNumberFormat: false },
        { name: 'supplierProcessingCountry', text: 'Supplier Processing Country', isDate: false, isCurrency: false, needLocaleNumberFormat: false }
    ],
    disposition: [
        { name: 'correlationId', text: 'Correlation ID', isDate: false, isCurrency: false, needLocaleNumberFormat: false },
        { name: 'supplierReportingDate', text: 'Supplier Reporting Date', isDate: true, isCurrency: false, needLocaleNumberFormat: false },
        { name: 'unitStatus', text: 'Unit Status', isDate: false, isCurrency: false, needLocaleNumberFormat: false },
        { name: 'dispositionDate', text: 'Disposition Date', isDate: true, isCurrency: false, needLocaleNumberFormat: false },
        { name: 'dispositionType', text: 'Disposition Type', isDate: false, isCurrency: false, needLocaleNumberFormat: false },
        { name: 'returnType', text: 'Return Type', isDate: false, isCurrency: false, needLocaleNumberFormat: false },
        { name: 'wipeDate', text: 'Wipe Date', isDate: true, isCurrency: false, needLocaleNumberFormat: false },
        { name: 'wipeCertificateNumber', text: 'Wipe Certificate Number', isDate: false, isCurrency: false, needLocaleNumberFormat: false },
        { name: 'recycleCertificateNumber', text: 'Recycle Certificate Number', isDate: false, isCurrency: false, needLocaleNumberFormat: false },
        { name: 'triageCompleteDate', text: 'Triage Complete Date', isDate: true, isCurrency: false, needLocaleNumberFormat: false },
        { name: 'triageFailureReasons', text: 'Triage Failure Reasons', isDate: false, isCurrency: false, needLocaleNumberFormat: false },
        { name: 'salePrice', text: 'Disposition Sale Price', isDate: false, isCurrency: false, needLocaleNumberFormat: true },
        { name: 'totalUnitFees', text: 'Disposition Unit Price', isDate: false, isCurrency: false, needLocaleNumberFormat: true },
        { name: 'salePriceUSD', text: 'Disposition Sale Price USD', isDate: false, isCurrency: false, needLocaleNumberFormat: true },
        { name: 'totalUnitFeesUSD', text: 'Disposition Unit Price USD', isDate: false, isCurrency: false, needLocaleNumberFormat: true }
    ],
    credit: [
        { name: 'correlationId', text: 'Correlation ID', isDate: false, isCurrency: false, needLocaleNumberFormat: false },
        { name: 'supplierReportingDate', text: 'Supplier Reporting Date', isDate: true, isCurrency: false, needLocaleNumberFormat: false },
        { name: 'supplierPoNumber', text: 'Supplier PO Number', isDate: false, isCurrency: false, needLocaleNumberFormat: false },
        { name: 'dateSold', text: 'Date Sold', isDate: true, isCurrency: false, needLocaleNumberFormat: false },
        { name: 'salePrice', text: 'Sale Price', isDate: false, isCurrency: true, needLocaleNumberFormat: false },
        { name: 'salePriceUSD', text: 'Sale Price USD', isDate: false, isCurrency: true, needLocaleNumberFormat: false },
        { name: 'supplierCommission', text: 'Supplier Commission', isDate: false, isCurrency: true, needLocaleNumberFormat: false },
        { name: 'supplierCommissionUSD', text: 'Supplier Commission USD', isDate: false, isCurrency: true, needLocaleNumberFormat: false },
        { name: 'msRevenueShare', text: 'MS Revenue Share', isDate: false, isCurrency: true, needLocaleNumberFormat: false },
        { name: 'msRevenueShareUSD', text: 'MS Revenue Share USD', isDate: false, isCurrency: true, needLocaleNumberFormat: false }
    ],
    invoice: [
        { name: 'correlationId', text: 'Correlation ID', isDate: false, isCurrency: false, needLocaleNumberFormat: false },
        { name: 'supplierReportingDate', text: 'Supplier Reporting Date', isDate: true, isCurrency: false, needLocaleNumberFormat: false },
        { name: 'supplierInvoiceNumber', text: 'Supplier Invoice Number', isDate: false, isCurrency: false, needLocaleNumberFormat: false },
        { name: 'unitFee1', text: 'Unit Fee 1', isDate: false, isCurrency: true, needLocaleNumberFormat: false },
        { name: 'unitFee1USD', text: 'Unit Fee 1 USD', isDate: false, isCurrency: true, needLocaleNumberFormat: false },
        { name: 'unitFee2', text: 'Unit Fee 2', isDate: false, isCurrency: true, needLocaleNumberFormat: false },
        { name: 'unitFee2USD', text: 'Unit Fee 2 USD', isDate: false, isCurrency: true, needLocaleNumberFormat: false },
        { name: 'unitFee3', text: 'Unit Fee 3', isDate: false, isCurrency: true, needLocaleNumberFormat: false },
        { name: 'unitFee3USD', text: 'Unit Fee 3 USD', isDate: false, isCurrency: true, needLocaleNumberFormat: false },
        { name: 'totalUnitFees', text: 'Total Unit Fees', isDate: false, isCurrency: true, needLocaleNumberFormat: false },
        { name: 'totalUnitFeesUSD', text: 'Total Unit Fees USD', isDate: false, isCurrency: true, needLocaleNumberFormat: false }
    ]
}
