import React from 'react';
import { ScrollablePane, Separator, Link, Image, Stack } from '@fluentui/react';
import { ICommonPageProps } from '../../common/common.types';
import { componentStyles } from './PageWrapper.styles';
import { appConstants } from '../../common/appConstants';
import { stackTokens } from '../../common/common.styles';
import { useAppSelector } from '../../store/hooks';

interface IPageWrapperProps extends ICommonPageProps {
    onScroll?: () => void;
}

export const PageWrapper: React.FunctionComponent<IPageWrapperProps> = (props: IPageWrapperProps): JSX.Element => {
    // The CoherenceNav in AppNav will display in a mobile mode if the window width is 1024 or less.
    // If in this mode, then do not adjust the left offset of the scrollable pane. This will cause the
    // nav menu to float over the content with absolute positioning.
    const isNavInMobileMode: boolean = window.innerWidth <= 1024;

    // Redux store selectors to get state from the store when it changes.
    const copilotPanelIsOpen: boolean =
        useAppSelector<boolean>((state) => state.appReducer.copilotPanelIsOpen);
    const navIsOpen: boolean =
        useAppSelector<boolean>((state) => state.appReducer.navIsOpen);

    const scrollablePaneClass = () => {
        if (!navIsOpen || isNavInMobileMode) {
            if (!copilotPanelIsOpen) {
                return componentStyles.scrollablePaneNavCollapsedCopilotCollapsed;
            } else {
                return componentStyles.scrollablePaneNavCollapsedCopilotExpanded;
            }
        } else {
            if (!copilotPanelIsOpen) {
                return componentStyles.scrollablePaneNavExpandedCopilotCollapsed;
            } else {
                return componentStyles.scrollablePaneNavExpandedCopilotExpanded;
            }
        }
    };

    return (
        <ScrollablePane
            className={scrollablePaneClass()}
            onScroll={()=>{
                if (props.onScroll) {
                    props.onScroll();
                }
            }}>

            {props.children}

            <footer className={componentStyles.footer}>
                <Separator/>

                <Stack horizontal tokens={stackTokens}>
                    <Image src={`${appConstants.publicUrl}/images/logo_microsoft.svg`} alt="Microsoft logo" height="20px" className={componentStyles.msLogo} />
                    <Link href="http://go.microsoft.com/fwlink/?LinkId=521839" target="_blank">Privacy & Cookies</Link>
                </Stack>
            </footer>
        </ScrollablePane>
    )
};
