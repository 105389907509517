import React, { useState, useCallback, useEffect, useRef } from 'react';
import {
    Text,
    Spinner,
    SpinnerSize,
    SelectionMode,
    DetailsListLayoutMode,
    ConstrainMode,
    IColumn,
    Stack,
    TooltipHost,
    IconButton,
    Dialog,
    PrimaryButton,
    DialogFooter,
    DefaultButton
} from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import mime from 'mime-types';
import { commonStyles, stackTokens } from '../../common/common.styles';
import { ICommonPageProps } from '../../common/common.types';
import { assignOnColumnClick, ColumnsAndItems, commonColumnProps, resetColumnSorting, sortOnColumn } from '../../common/common.func';
import { clearErrorByIndex, ErrorBar, prepErrorMsg } from '../../components/ErrorBar/ErrorBar';
import { CustomDetailsList } from '../../components/CustomDetailsList/CustomDetailsList';
import { ComboInputOption, ISearchCriteria, SearchFilter } from '../../components/SearchFilter/SearchFilter';
import { appConstants } from '../../common/appConstants';
import { resetApiCallState, callApiLoadDbd, callApiDownloadBlobStream, IApiLoadDbd, IApiDownloadBlobStream } from '../../store/actions/pageActions/dbdPage.action';
import { DbdReport } from '../../models/supplier/dbdReport';
import { DbdDetails } from '../../models/supplier/dbdDetails';
import { documentDialogContentProps, documentDialogModelProps, documentTooltipHostStyles, pageStyles, viewIcon } from './DbdPage.styles';
import { DbdImageLink } from '../../models/supplier/dbdImageLink';
import { CallApiState } from '../../store/actions/generic.action';
import { pdfjs, Document, Page } from 'react-pdf';
import { useAppSelector, useAppDispatch } from '../../store/hooks';
import { PageWrapper } from '../../components/PageWrapper/PageWrapper';

// Set the react-pdf / pdfjs worker.
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

/*
The "SupplierWebMicroFrontend" project has a variation of this page. If changes are made here, consider if
equivalent changes are needed in that project as well. There are some differences between the projects, such as
localization and SupplierWeb integration in that project, so be careful when copying/integrating changes.
*/

interface IPageProps extends ICommonPageProps {
}

export const DbdPage: React.FunctionComponent<IPageProps> = (props: IPageProps): JSX.Element => {
    const [errors, setErrors] = useState<string[]>([]);
    const [selectedPageDbdDetailsTable, setSelectedPageDbdDetailsTable] = useState<number>(1);
    const [selectedPageDbdImagesTable, setSelectedPageDbdImagesTable] = useState<number>(1);
    const [dbdReport, setDbdReport] = useState<DbdReport | null>();

    const [showDocDialog, setShowDocDialog] = useState<boolean>(false);
    const [docItem, setDocItem] = useState<DbdImageLink>();
    const [docData, setDocData] = useState<string | undefined>();
    const [docExt, setDocExt] = useState<'pdf' | 'image' | undefined>();
    const [docIsLoading, setDocIsLoading] = useState<boolean>(false);

    const [docLoadErrorMsg, setDocLoadErrorMsg] = useState<string | undefined>('');
    const [numPages, setNumPages] = useState<number>(0);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const documentIconTooltip = useId('documentIconTooltip');
    
    const blobObjUrl = useRef<string>();

    // Redux store selectors to get state from the store when it changes.
    const apiLoadDbd: IApiLoadDbd =
        useAppSelector<IApiLoadDbd>((state) => state.dbdPageReducer.apiLoadDbd);
    const apiDownloadBlobStream: IApiDownloadBlobStream =
        useAppSelector<IApiDownloadBlobStream>((state) => state.dbdPageReducer.apiDownloadBlobStream);

    // Redux store dispatch to send actions to the store.
    const dispatch = useAppDispatch();

    /**
     * This effect does nothing on mount, but will return a cleanup function that runs when the component unmounts.
     */
    useEffect(() => {
        return function cleanup() {
            resetApiCallState();
        }
    }, []);

    /**
     * Handle error.
     * @param errMsg Error message.
     */
    const handleError = useCallback((errMsg: string) => {
        setErrors((prevErrors) => {
            // This will prevent the same error from being displayed if already displayed.
            // ex: Multiple page data load failures might occur if the api is not working,
            // and this page makes multiple load calls for various data.
            if (!prevErrors.includes(errMsg)) {
                return [...prevErrors, errMsg];
            }
            return prevErrors;
        });
    }, []);

    /**
     * Effect for when errors occur in any api call.
     */
    useEffect(() => {
        if (apiLoadDbd.errMsg) {
            handleError(apiLoadDbd.errMsg);
        }
    }, [handleError, apiLoadDbd.errMsg]);

    /**
     * Effect for when blob stream is downloaded.
     */
    useEffect(() => {
        if (apiDownloadBlobStream.callApiState === CallApiState.DataAvailable) {
            const blobFileBase64: string | undefined | null = apiDownloadBlobStream.blobStream;
            const item: DbdImageLink | undefined = apiDownloadBlobStream.item;

            if (blobFileBase64 && item && item.fileBlobUrl) {
                // Convert the base 64 string to a byte array.
                const byteCharacters: string = atob(blobFileBase64);
                const byteNumbers: any[] = new Array(byteCharacters.length);
                for (let i: number = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
    
                // Get the file extension.
                const parts: string[] = item.fileBlobUrl.split('.');
                const ext: string = parts.length > 0 ? parts.pop()!.trim().toLowerCase() : '';
    
                // Get the mime type for the file based on the extension.
                const fileMimeType: string = mime.lookup(ext);
                
                // Convert the byte array to a blob.
                const blob: Blob = new Blob([byteArray], { type: fileMimeType });
    
                // Create a "object url" to be used with the download button.
                blobObjUrl.current = URL.createObjectURL(blob);

                // Create a "data url" for the blob and set it into the docData.
                const displayData = async () => {
                    await new Promise<void>((resolve) => {
                        const reader = new FileReader();
                        reader.onloadend = () => {
                            setDocItem(item);
                            setDocData(reader.result as string);
                            if (ext === 'pdf') {
                                setDocExt('pdf');
                            } else if (ext === 'png' || ext === 'jpg' || ext === 'jpeg' || ext === 'bmp') {
                                setDocExt('image');
                            } else {
                                setDocLoadErrorMsg('Unsupported document type');
                            }
                            setDocIsLoading(false);
                            resolve();
                        }
                        reader.onerror = () => {
                            setDocLoadErrorMsg('Failed to load document');
                            setDocIsLoading(false);
                            resolve();
                        }
                        reader.readAsDataURL(blob);
                    });
                }
                displayData(); // Execute the inline async function.
            }
        }
    }, [apiDownloadBlobStream.blobStream, apiDownloadBlobStream.callApiState, apiDownloadBlobStream.item])

    /**
     * Columns for the DBD Details table.
     */
    const [columnsDbdDetailsTable, setColumnsDbdDetailsTable] = useState<IColumn[]>(
        [
            {
                ...commonColumnProps,
                key: 'column1',
                name: 'Supplier Unit ID',
                fieldName: 'supplierUnitId',
                minWidth: 80
            },
            {
                ...commonColumnProps,
                key: 'column2',
                name: 'Supplier Unit Type',
                fieldName: 'supplierUnitType',
                minWidth: 100
            },
            {
                ...commonColumnProps,
                key: 'column3',
                name: 'Asset Tag Number',
                fieldName: 'assetTagNumber',
                minWidth: 100
            },
            {
                ...commonColumnProps,
                key: 'column4',
                name: 'Asset Tag Number 2',
                fieldName: 'assetTagNumber2',
                minWidth: 110
            },
            {
                ...commonColumnProps,
                key: 'column5',
                name: 'Serial Number',
                fieldName: 'serialNumber',
                minWidth: 100
            },
            {
                ...commonColumnProps,
                key: 'column6',
                name: 'Manufacturer',
                fieldName: 'manufacturer',
                minWidth: 90
            },
            {
                ...commonColumnProps,
                key: 'column7',
                name: 'Model',
                fieldName: 'model',
                minWidth: 90
            },
            {
                ...commonColumnProps,
                key: 'column8',
                name: 'Description',
                fieldName: 'description',
                minWidth: 140
            },
            {
                ...commonColumnProps,
                key: 'column9',
                name: 'Part Number',
                fieldName: 'partNumber',
                minWidth: 90
            },
            {
                ...commonColumnProps,
                key: 'column10',
                name: 'Quantity',
                fieldName: 'quantity',
                minWidth: 40
            },
            {
                ...commonColumnProps,
                key: 'column11',
                name: 'Weight In Kilos',
                fieldName: 'weightInKilos',
                minWidth: 80
            },
            {
                ...commonColumnProps,
                key: 'column12',
                name: 'Data Device Form Factor',
                fieldName: 'dataDeviceFormFactor',
                minWidth: 135
            },
            {
                ...commonColumnProps,
                key: 'column13',
                name: 'HDD Count',
                fieldName: 'hddCount',
                minWidth: 60
            },
            {
                ...commonColumnProps,
                key: 'column14',
                name: 'CPU Speed',
                fieldName: 'cpuSpeed',
                minWidth: 60
            },
            {
                ...commonColumnProps,
                key: 'column15',
                name: 'RAM Size',
                fieldName: 'ramSize',
                minWidth: 60
            },
            {
                ...commonColumnProps,
                key: 'column16',
                name: 'Parent Unit ID',
                fieldName: 'parentUnitId',
                minWidth: 80
            },
            {
                ...commonColumnProps,
                key: 'column17',
                name: 'Parent Unit Type',
                fieldName: 'parentUnitType',
                minWidth: 100
            },
            {
                ...commonColumnProps,
                key: 'column18',
                name: 'Parent Unit Serial Number',
                fieldName: 'parentUnitSerialNumber',
                minWidth: 150
            },
            {
                ...commonColumnProps,
                key: 'column19',
                name: 'Parent Unit Asset Tag Number',
                fieldName: 'parentUnitAssetTagNumber',
                minWidth: 170
            },
            {
                ...commonColumnProps,
                key: 'column20',
                name: 'Supplier Reporting Date',
                fieldName: 'supplierReportingDate',
                minWidth: 150,
                onRender: (item: DbdDetails) => {
                    return <span>{item.supplierReportingDate?.toLocaleDateString()}</span>;
                }
            }
        ]
    );

    /**
     * On column click event handler for DBD Details table.
     * @param ev Mouse event.
     * @param column Column clicked.
     */
    const onColumnClickDbdDetailsTable = useCallback((ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        if (dbdReport && dbdReport.dbdDetails) {
            const columnsAndItems: ColumnsAndItems<DbdDetails> = sortOnColumn<DbdDetails>(column, columnsDbdDetailsTable, dbdReport.dbdDetails, undefined);
            dbdReport.dbdDetails = columnsAndItems.items;
            setDbdReport(dbdReport);
            setColumnsDbdDetailsTable(columnsAndItems.columns);
        }
    }, [columnsDbdDetailsTable, dbdReport]);

    /**
     * Effect to update onColumnClick handler. Otherwise the component state referenced in onColumnClick
     * would be stale from the render pass it was created on.
     */
    useEffect(() => {
        assignOnColumnClick(columnsDbdDetailsTable, onColumnClickDbdDetailsTable);
    }, [columnsDbdDetailsTable, onColumnClickDbdDetailsTable]);

    /**
     * Columns for the DBD Images table.
     */
     const [columnsDbdImagesTable, setColumnsDbdImagesTable] = useState<IColumn[]>(
        [
            {
                ...commonColumnProps,
                key: 'column1',
                name: 'File Name',
                fieldName: 'fileName',
                minWidth: 200
            },
            {
                ...commonColumnProps,
                key: 'column2',
                name: 'Image',
                fieldName: '', // Leave blank as the view icon will render here.
                minWidth: 40,
                data: {
                    // This column will get a onRender handler added to it in a useEffect (see elsewhere in this file).
                    addOnDocumentColumnRender: true
                }
            }
        ]
    );

    /**
     * On column click event handler for DBD Images table.
     * @param ev Mouse event.
     * @param column Column clicked.
     */
    const onColumnClickDbdImagesTable = useCallback((ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        if (dbdReport && dbdReport.dbdImageLinks) {
            const columnsAndItems: ColumnsAndItems<DbdImageLink> = sortOnColumn<DbdImageLink>(column, columnsDbdImagesTable, dbdReport.dbdImageLinks, undefined);
            dbdReport.dbdImageLinks = columnsAndItems.items;
            setDbdReport(dbdReport);
            setColumnsDbdImagesTable(columnsAndItems.columns);
        }
    }, [columnsDbdImagesTable, dbdReport]);

    /**
     * Effect to update onColumnClick handler. Otherwise the component state referenced in onColumnClick
     * would be stale from the render pass it was created on.
     */
    useEffect(() => {
        assignOnColumnClick(columnsDbdImagesTable, onColumnClickDbdImagesTable);
    }, [columnsDbdImagesTable, onColumnClickDbdImagesTable]);

    /**
     * Show document dialog.
     * @param item DBD image link.
     */
    const showDocumentDialog = useCallback((item: DbdImageLink): void => {
        if (!item || !item.fileBlobUrl) {
            return;
        }

        setDocLoadErrorMsg(undefined);
        setDocItem(undefined);
        setDocData(undefined);
        setDocExt(undefined);
        setDocIsLoading(true);
        setShowDocDialog(true);

        // Download the blob file for the certificate. The file is returned from the API as a base 64 encoded string.
        dispatch(callApiDownloadBlobStream(item));
    }, [dispatch]);

    /**
     * Dismiss document dialog.
     */
    const dismissDocumentDialog = (): void => {
        setShowDocDialog(false);

        // We are closing the dialog so we need to revoke the blob object URL to manage memory.
        if (blobObjUrl.current) {
            URL.revokeObjectURL(blobObjUrl.current);
        }
    }

    /**
     * Document on column render event handler.
     * @param item The item (row) being rendered. 
     * @param index The index of the row.
     * @param column The column being rendered.
     */
    const onDocumentColumnRender = useCallback((item: DbdImageLink, index: number | undefined, column: IColumn | undefined): JSX.Element => {
        return (
            <div style={{ textAlign: 'center' }}>
                <TooltipHost
                    content={`View/Download ${item.fileName}`}
                    // This id is used on the tooltip itself, not the host
                    // (so an element with this id only exists when the tooltip is shown).
                    id={documentIconTooltip}
                    styles={documentTooltipHostStyles}>
                    <IconButton
                        iconProps={viewIcon}
                        ariaLabel={`View/Download ${item.fileName}`}
                        onClick={event => showDocumentDialog(item)} />
                </TooltipHost>
            </div>
        );
    }, [documentIconTooltip, showDocumentDialog]);

    /**
     * Effect to update onRender handler. Otherwise the component state referenced in onRender
     * would be stale from the render pass it was created on.
     */
    useEffect(() => {
        columnsDbdImagesTable.filter(x => x.data?.addOnDocumentColumnRender).forEach(c => c.onRender = onDocumentColumnRender);
    }, [columnsDbdImagesTable, onDocumentColumnRender]);

    /**
     * Search clicked event handler.
     * @param searchCriteria Search criteria.
     */
    const searchClicked = (searchCriteria: ISearchCriteria) => {
        setDbdReport(undefined);

        setSelectedPageDbdDetailsTable(1);
        setSelectedPageDbdImagesTable(1);

        if (searchCriteria.programType && searchCriteria.supplierId) {
            dispatch(callApiLoadDbd(
                searchCriteria.programType,
                searchCriteria.supplierId,
                searchCriteria.supplierJobId,
                searchCriteria.correlationId,
                searchCriteria.supplierUnitId,
                searchCriteria.supplierUnitType
            ));
        }
    };

    /**
     * Search filter load error handler.
     * @param err Error
     */
    const searchFilterLoadError = (err: any) => {
        handleError(prepErrorMsg(appConstants.dataLoadFailed, err));
    };

    /**
     * Effect for when DBD data is loaded.
     */
    useEffect(() => {
        if (apiLoadDbd.callApiState === CallApiState.DataAvailable) {
            setDbdReport(apiLoadDbd.dbdReport);
            setColumnsDbdDetailsTable(resetColumnSorting(columnsDbdDetailsTable));
        }
    }, [columnsDbdDetailsTable, apiLoadDbd.callApiState, apiLoadDbd.dbdReport]);

    /**
     * PDF document load success handler.
     * @param pdfDocument PDF document.
     */
    const onDocumentLoadSuccess = (pdfDocument: any) => {
        setNumPages(pdfDocument.numPages);
        setDocLoadErrorMsg('');
    };

    /**
     * PDF document load error handler.
     * @param error Error.
     */
    const onDocumentLoadError = (error: Error) => {
        setNumPages(0);
        setDocLoadErrorMsg(error.message);
    }

    /**
     * Render the document dialog JSX element.
     */
     const renderDocumentDialogElement = (): JSX.Element => {
        const documentDownloadFileName: string = docItem?.fileName || '';
        const dialogTitle: string = 'DBD Image';

        return (
            <Dialog
                hidden={!showDocDialog}
                onDismiss={() => dismissDocumentDialog()}
                dialogContentProps={documentDialogContentProps(dialogTitle)}
                modalProps={documentDialogModelProps}
            >
                <Stack tokens={stackTokens} className={pageStyles.stackContainer}>
                    <Stack.Item className={pageStyles.stackItemContainer}>
                        <>
                            { docIsLoading &&
                                <Spinner size={SpinnerSize.medium} className={commonStyles.spinner} />
                            }
                            { !docIsLoading && docLoadErrorMsg &&
                                <div className={pageStyles.errorMsg}>{docLoadErrorMsg}</div>
                            }
                            { !docIsLoading && !docLoadErrorMsg && docExt === 'pdf' && docData &&
                                <div className={pageStyles.pdfDocContainer}>
                                    <Document file={docData} onLoadSuccess={onDocumentLoadSuccess} onLoadError={onDocumentLoadError}>
                                        <Page pageNumber={pageNumber} />
                                    </Document>
                                </div>
                            }
                            { !docIsLoading && !docLoadErrorMsg && docExt === 'image' && docData &&
                                <div className={pageStyles.imageDocContainer}>
                                    <img height="100%" src={docData} alt={dialogTitle} />
                                </div>
                            }
                        </>
                    </Stack.Item>
                </Stack>
                <DialogFooter>
                    { docExt === 'pdf' && 
                        <>
                            <div className={pageStyles.pageCount}>
                                Page {pageNumber} / {numPages}
                            </div>
                            <PrimaryButton text="Previous page" onClick={() => setPageNumber(pageNumber - 1)} disabled={pageNumber <= 1} style={{ marginRight: '8px' }} />
                            <PrimaryButton text="Next page"onClick={() => setPageNumber(pageNumber + 1)} disabled={pageNumber >= (numPages || 0)} />
                        </>
                    }
                    <PrimaryButton text="Download" href={blobObjUrl.current} download={documentDownloadFileName} target="_blank"/>
                    <DefaultButton text="Close" onClick={() => dismissDocumentDialog()} />
                </DialogFooter>
            </Dialog>
        );
    }

    return (
        <PageWrapper {...props}>
            <ErrorBar errors={errors} onDismiss={(index: number) => {
                setErrors(clearErrorByIndex(errors, index));
            }} />

            <div className='ms-Grid' dir="ltr">
                <div className='ms-Grid-row'>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <div className={commonStyles.pageHeader}>
                            <Text variant="xLarge" role="heading" aria-level={1}>Data Bearing Device</Text>
                        </div>
                    </div>
                </div>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <SearchFilter
                            programs={props.programs || []}
                            searchFilterOptions={{
                                showFiscalRange: true,
                                fiscalRangeFieldsRequired: false,
                                showCalendarYearMonth: false,
                                showProgram: true,
                                showSupplierName: true,
                                showComboInputGroup: ComboInputOption.None,
                                showSupplierJobId: true,
                                showSupplierPoNumber: false,
                                showSupplierInvoiceNumber: false,
                                showCorrelationId: true,
                                showMsInvoiceNumber: false,
                                showSupplierUnitType: true,
                                showJobUnit: true,
                                showCountry: false,
                                showComboAssetTagAndSerialNumber: false,
                                showUnitDispositionType: false,
                                showUnitStatus: false
                            }}
                            onSearchClicked={searchClicked}
                            onSearchFilterLoadError={searchFilterLoadError} />
                    </div>
                </div>
                <div className="ms-Grid-row" style={{ marginTop: '24px' }}>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        {
                            (apiLoadDbd.callApiState !== CallApiState.Initial) && (
                                <>
                                    {apiLoadDbd.callApiState === CallApiState.Running && (
                                        <Spinner size={SpinnerSize.medium} className={commonStyles.spinner} />
                                    )}
                                    {apiLoadDbd.callApiState === CallApiState.Completed && (
                                        <>
                                            <div className={commonStyles.headingTextContainer}>
                                                <Text variant="mediumPlus" className={commonStyles.headingText} role="heading" aria-level={2}>Job Details</Text>
                                            </div>
                                            {
                                                dbdReport && (
                                                    <Stack horizontal tokens={stackTokens}>
                                                        <Stack.Item>
                                                            <table className={pageStyles.dbdSectionFieldsTable}>
                                                                <tbody>
                                                                    <tr>
                                                                        <td><Text variant="medium" className={pageStyles.dbdSectionLabel}>Supplier Job Id</Text></td>
                                                                        <td><Text variant="medium">{dbdReport?.supplierJobId}</Text></td>

                                                                    </tr>
                                                                    <tr>
                                                                        <td><Text variant="medium" className={pageStyles.dbdSectionLabel}>Supplier Job Type</Text></td>
                                                                        <td><Text variant="medium">{dbdReport?.supplierJobType}</Text></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><Text variant="medium" className={pageStyles.dbdSectionLabel}>Supplier Received Date</Text></td>
                                                                        <td><Text variant="medium">{dbdReport?.supplierReceivedDate?.toLocaleDateString()}</Text></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </Stack.Item>
                                                        <Stack.Item>
                                                            <table className={pageStyles.dbdSectionFieldsTable}>
                                                                <tbody>
                                                                    <tr>
                                                                        <td><Text variant="medium" className={pageStyles.dbdSectionLabel}>Collection Country</Text></td>
                                                                        <td><Text variant="medium">{dbdReport?.collectionCountry}</Text></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><Text variant="medium" className={pageStyles.dbdSectionLabel}>Collection Site Name</Text></td>
                                                                        <td><Text variant="medium">{dbdReport?.collectionSiteName}</Text></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><Text variant="medium" className={pageStyles.dbdSectionLabel}>Collection Site Code</Text></td>
                                                                        <td><Text variant="medium">{dbdReport?.collectionSiteCode}</Text></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><Text variant="medium" className={pageStyles.dbdSectionLabel}>Collection Date</Text></td>
                                                                        <td><Text variant="medium">{dbdReport?.collectionDate?.toLocaleDateString()}</Text></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </Stack.Item>
                                                    </Stack>
                                                )
                                            }
                                            {
                                                !dbdReport && (
                                                    <Stack horizontal tokens={stackTokens}>
                                                        <Stack.Item>
                                                            <Text variant='medium'>No data found</Text>
                                                        </Stack.Item>
                                                    </Stack>
                                                )
                                            }

                                            <div className={pageStyles.dbdDetailsSection}>
                                                <div className={commonStyles.headingTextContainer}>
                                                    <Text variant="mediumPlus" className={commonStyles.headingText} role="heading" aria-level={2}>DBD Details</Text>
                                                </div>
                                                <CustomDetailsList
                                                    showExcelExport={true}
                                                    exportExcelSheetName="DBD Details"
                                                    ariaLabelForGrid="DBD Details"
                                                    displayTotalItems={true}
                                                    showPaginator={true}
                                                    showPageSize={true}
                                                    selectedPage={selectedPageDbdDetailsTable}
                                                    onSelectedPageChange={(page) => {
                                                        setSelectedPageDbdDetailsTable(page);
                                                    }}
                                                    showNoDataFoundMsg={!dbdReport || !dbdReport.dbdDetails || dbdReport.dbdDetails.length === 0}
                                                    items={dbdReport?.dbdDetails || []}
                                                    compact={false}
                                                    columns={columnsDbdDetailsTable}
                                                    selectionMode={SelectionMode.none}
                                                    getKey={(item: DbdDetails) => item.clientRowKey}
                                                    setKey="none"
                                                    layoutMode={DetailsListLayoutMode.fixedColumns}
                                                    isHeaderVisible={true}
                                                    constrainMode={ConstrainMode.horizontalConstrained} />
                                            </div>

                                            <div className={pageStyles.dbdImageSection}>
                                                <div className={commonStyles.headingTextContainer}>
                                                    <Text variant="mediumPlus" className={commonStyles.headingText} role="heading" aria-level={2}>DBD Images</Text>
                                                </div>
                                                <CustomDetailsList
                                                    showExcelExport={true}
                                                    exportExcelSheetName="DBD Images"
                                                    ariaLabelForGrid="DBD Images"
                                                    displayTotalItems={true}
                                                    showPaginator={true}
                                                    showPageSize={true}
                                                    selectedPage={selectedPageDbdImagesTable}
                                                    onSelectedPageChange={(page) => {
                                                        setSelectedPageDbdImagesTable(page);
                                                    }}
                                                    showNoDataFoundMsg={!dbdReport || !dbdReport.dbdImageLinks || dbdReport.dbdImageLinks.length === 0}
                                                    items={dbdReport?.dbdImageLinks || []}
                                                    compact={false}
                                                    columns={columnsDbdImagesTable}
                                                    selectionMode={SelectionMode.none}
                                                    getKey={(item: DbdImageLink) => item.clientRowKey}
                                                    setKey="none"
                                                    layoutMode={DetailsListLayoutMode.fixedColumns}
                                                    isHeaderVisible={true}
                                                    constrainMode={ConstrainMode.horizontalConstrained} />
                                            </div>
                                        </>
                                    )}
                                </>
                            )
                        }
                        {
                            renderDocumentDialogElement()
                        }
                    </div>
                </div>
            </div>
        </PageWrapper>
    );
};
