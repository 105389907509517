export interface ISupplierApiInteraction {
    programType: string;
    supplierId: string;
    supplierName: string;
    lastPublishedDate: Date;
}

export class SupplierApiInteraction implements ISupplierApiInteraction {
    public programType: string;
    public supplierId: string;
    public supplierName: string;
    public lastPublishedDate: Date;

    // Below are properties NOT returned from the server but are used for client side purposes.
    public clientRowKey: string = '';

    constructor(jsonData: ISupplierApiInteraction) {
        this.programType = jsonData.programType;
        this.supplierId = jsonData.supplierId;
        this.supplierName = jsonData.supplierName;
        this.lastPublishedDate = new Date(jsonData.lastPublishedDate);
    }
}
