import React, { useCallback, useEffect, useState } from 'react';
import {
    Checkbox,
    ConstrainMode,
    DefaultButton,
    DetailsListLayoutMode,
    IColumn,
    SelectionMode,
    Spinner,
    SpinnerSize,
    Stack,
    TextField
} from '@fluentui/react';
import { IAdminTabProps } from '../../common/common.types';
import { commonColumnProps, useMountEffect } from '../../common/common.func';
import { clearErrorByIndex, ErrorBar, prepErrorMsg } from '../../components/ErrorBar/ErrorBar';
import { CallApiState } from '../../store/actions/generic.action';
import { CustomDetailsList } from '../../components/CustomDetailsList/CustomDetailsList';
import { commonStyles, stackTokens } from '../../common/common.styles';
import { ComboInputOption, ISearchCriteria, SearchFilter } from '../../components/SearchFilter/SearchFilter';
import {
    loadSuppliers,
    updateSupplier,
    addSupplier,
    resetApiCallState,
    IApiLoadSuppliers,
    IApiUpdateSupplier,
    IApiAddSupplier
} from '../../store/actions/adminTabActions/adminTabSuppliers.action';
import { Supplier } from '../../models/supplier/supplier';
import { addOrRemoveError, checkForInputError, checkItemForAnyInputError, InputError, renderColumnHeader } from './AdminCommon';
import { appConstants } from '../../common/appConstants';
import { ReloadingDialog } from './ReloadingDialog';
import { useAppSelector, useAppDispatch } from '../../store/hooks';

interface IAdminTabSuppliersProps extends IAdminTabProps {
}

const supplierIdTooltip: string = 'The official supplier id for this supplier as mastered on the MyOrder system.';
const supplierNameTooltip: string = 'The company name for the recycling supplier.';
const supplierContactMailingGroupTooltip: string = 'Suppliers single email address for a group of users.';
const emailNotificationTooltip: string = 'Whether to send out failure transaction emails to the supplier mailing group.';
const isEnabledTooltip: string = 'Enable or disable this supplier within the Recycling system.';

export const AdminTabSuppliers: React.FunctionComponent<IAdminTabSuppliersProps> = (props: IAdminTabSuppliersProps): JSX.Element => {
    const [errors, setErrors] = useState<string[]>([]);
    const [suppliers, setSuppliers] = useState<Supplier[]>([]);
    const [inputErrors, setInputErrors] = useState<InputError[]>([]);
    const [successIndicator, setSuccessIndicator] = useState<boolean>(false);
    const [currentSearchCriteria, setCurrentSearchCriteria] = useState<ISearchCriteria>();
    const [itemBeingUpdatedOrAdded, setItemBeingUpdatedOrAdded] = useState<Supplier>();
    const [itemBeingEdited, setItemBeingEdited] = useState<Supplier>();
    
    // Redux store selectors to get state from the store when it changes.
    const apiLoadSuppliers: IApiLoadSuppliers =
        useAppSelector<IApiLoadSuppliers>((state) => state.adminTabSuppliersReducer.apiLoadSuppliers);
    const apiUpdateSupplier: IApiUpdateSupplier =
        useAppSelector<IApiUpdateSupplier>((state) => state.adminTabSuppliersReducer.apiUpdateSupplier);
    const apiAddSupplier: IApiAddSupplier =
        useAppSelector<IApiAddSupplier>((state) => state.adminTabSuppliersReducer.apiAddSupplier);

    // Redux store dispatch to send actions to the store.
    const dispatch = useAppDispatch();

    /**
     * Setup columns to display for suppliers.
     */
    const [columnsSuppliers, setColumnsSuppliers] = useState<IColumn[]>(
        [
            {
                ...commonColumnProps,
                key: 'column1',
                name: '',
                minWidth: 80,
                data: {
                    addActionButtons: true
                }
            },
            {
                ...commonColumnProps,
                key: 'column2',
                name: 'Supplier ID',
                fieldName: 'supplierId',
                minWidth: 150,
                data: {
                    // The addOnColumnRenderTextFieldConditionally will conditionally add a text field or a
                    // read only field based upon item data.
                    addOnColumnRenderTextFieldConditionally: true,
                    pattern: /^[0-9]{3,20}$/,
                    inputErrorMsg: 'Numeric, min 3, max 20 length'
                },
                onRenderHeader: (props) => {
                    return renderColumnHeader(props!.column.name, 'supplierIdTooltip', supplierIdTooltip)
                }
            },
            {
                ...commonColumnProps,
                key: 'column3',
                name: 'Supplier Name',
                fieldName: 'supplierName',
                minWidth: 150,
                data: {
                    // The addOnColumnRenderTextFieldConditionally will conditionally add a text field or a
                    // read only field based upon item data.
                    addOnColumnRenderTextFieldConditionally: true,
                    pattern: /^[a-zA-Z -]{5,100}$/,
                    inputErrorMsg: 'Alpha, dashes, spaces, min 5, max 100 length'
                },
                onRenderHeader: (props) => {
                    return renderColumnHeader(props!.column.name, 'supplierNameTooltip', supplierNameTooltip)
                }
            },
            {
                ...commonColumnProps,
                key: 'column4',
                name: 'Supplier Contact Mailing Group',
                fieldName: 'supplierContactMailingGroup',
                minWidth: 150,
                data: {
                    addOnColumnRenderTextField: true,
                    pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    inputErrorMsg: 'Must be valid email'
                },
                onRenderHeader: (props) => {
                    return renderColumnHeader(props!.column.name, 'supplierContactMailingGroupTooltip', supplierContactMailingGroupTooltip)
                }
            },
            {
                ...commonColumnProps,
                key: 'column5',
                name: 'Email Notification',
                fieldName: 'emailNotification',
                minWidth: 80,
                data: {
                    addOnColumnRenderCheckbox: true
                },
                onRenderHeader: (props) => {
                    return renderColumnHeader(props!.column.name, 'emailNotificationTooltip', emailNotificationTooltip)
                }
            },
            {
                ...commonColumnProps,
                key: 'column6',
                name: 'Is Enabled',
                fieldName: 'isEnabled',
                minWidth: 80,
                data: {
                    addOnColumnRenderCheckbox: true
                },
                onRenderHeader: (props) => {
                    return renderColumnHeader(props!.column.name, 'isEnabledTooltip', isEnabledTooltip)
                }
            }
        ]
    );

    /**
     * Rerender columns by setting the columns to a new array of the same columns.
     * This will cause the columns to re-render including the action buttons column... the buttons state may
     * change such as becoming disabled or enabled, or buttons showing or hiding, etc.
     */
    const rerenderColumns = useCallback(() => {
        // Use a setTimeout to queue this on the UI thread after the current render pass.
        setTimeout(() => {
            setColumnsSuppliers([...columnsSuppliers]);
        });
    }, [columnsSuppliers]);

    /**
     * This effect does nothing on mount, but will return a cleanup function that runs when the component unmounts.
     */
    useEffect(() => {
        return function cleanup() {
            resetApiCallState();
        }
    }, []);

    /**
     * Handle error.
     * @param errMsg Error message.
     */
    const handleError = useCallback((errMsg: string) => {
        setErrors((prevErrors) => {
            // This will prevent the same error from being displayed if already displayed.
            // ex: Multiple page data load failures might occur if the api is not working,
            // and this page makes multiple load calls for various data.
            if (!prevErrors.includes(errMsg)) {
                return [...prevErrors, errMsg];
            }
            return prevErrors;
        });
    }, []);

    /**
     * Effect for when errors occur in any api call.
     */
    useEffect(() => {
        if (apiLoadSuppliers.errMsg) {
            handleError(apiLoadSuppliers.errMsg);
        }
        if (apiUpdateSupplier.errMsg) {
            handleError(apiUpdateSupplier.errMsg);
        }
        if (apiAddSupplier.errMsg) {
            handleError(apiAddSupplier.errMsg);
        }
    }, [apiAddSupplier.errMsg, handleError, apiLoadSuppliers.errMsg, apiUpdateSupplier.errMsg]);

    /**
     * This effect is run once during page load.
     */
    useMountEffect(() => {
        // Nothing at this time.
    });

    /**
     * Effect for when suppliers is loaded.
     */
    useEffect(() => {
        if (apiLoadSuppliers.callApiState === CallApiState.DataAvailable) {
            setSuppliers(apiLoadSuppliers.suppliers || []);
        }
    }, [apiLoadSuppliers.callApiState, apiLoadSuppliers.suppliers]);

    /**
     * Effect for when supplier is updated or added.
     */
    useEffect(() => {
        if (apiUpdateSupplier.callApiState === CallApiState.DataAvailable ||
            apiAddSupplier.callApiState === CallApiState.DataAvailable) {
            setSuccessIndicator(true);
            rerenderColumns();
            // Wait for 2 seconds and then reload data.
            setTimeout(() => {
                setSuccessIndicator(false);
                // Reload the suppliers using current search criteria.
                if (currentSearchCriteria && currentSearchCriteria.programType) {
                    dispatch(loadSuppliers(
                        currentSearchCriteria.programType
                    ));
                }
            }, 2000);
        }
    }, [apiAddSupplier.callApiState, currentSearchCriteria, dispatch, rerenderColumns, apiUpdateSupplier.callApiState]);

    /**
     * Edit button clicked event handler.
     * @param supplier Supplier being edited.
     */
    const editButtonClicked = useCallback((supplier: Supplier): void => {
        setItemBeingEdited(supplier);
        rerenderColumns();
    }, [rerenderColumns]);

    /**
     * Save (update or add) button clicked event handler.
     * @param supplier Supplier being saved.
     */
    const saveButtonClicked = useCallback((supplier: Supplier): void => {
        setItemBeingUpdatedOrAdded(supplier);
        if (supplier.isNew) {
            if (currentSearchCriteria && currentSearchCriteria.programType) {
                dispatch(addSupplier(supplier, currentSearchCriteria.programType));
            }
        } else {
            dispatch(updateSupplier(supplier));
        }
        rerenderColumns();
    }, [currentSearchCriteria, dispatch, rerenderColumns]);

    /**
     * On column render for action buttons.
     * @param item The item (row) being rendered in the details list. 
     * @param index The index of the row.
     * @param column The column being rendered.
     */
    const onColumnRenderActionButtons = useCallback((item: Supplier, index: number | undefined, column: IColumn | undefined): JSX.Element => {
        return (
            <div>
                {
                    itemBeingEdited !== item && (
                        <Stack horizontal tokens={stackTokens}>
                            <DefaultButton
                                onClick={event => editButtonClicked(item)}
                                disabled={
                                    apiUpdateSupplier.callApiState === CallApiState.Running ||
                                    apiAddSupplier.callApiState === CallApiState.Running ||
                                    successIndicator}>
                                <span style={{ whiteSpace: 'nowrap' }}>
                                    Edit
                                </span>
                            </DefaultButton>
                        </Stack>
                    )
                }
                {
                    itemBeingEdited === item && (
                        <Stack horizontal tokens={stackTokens}>
                            <DefaultButton
                                onClick={event => saveButtonClicked(item)}
                                disabled={
                                    checkItemForAnyInputError(inputErrors, item) ||
                                    apiUpdateSupplier.callApiState === CallApiState.Running ||
                                    apiAddSupplier.callApiState === CallApiState.Running ||
                                    successIndicator}>
                                { itemBeingUpdatedOrAdded === item &&
                                    (apiUpdateSupplier.callApiState === CallApiState.Running ||
                                    apiAddSupplier.callApiState === CallApiState.Running) ? (
                                    <div style={{ display: 'flex' }}>
                                        <span style={{ marginRight: '6px', whiteSpace: 'nowrap' }}>Saving</span>
                                        <Spinner size={SpinnerSize.medium} className={commonStyles.spinner} style={{ display: 'inline-block' }} />
                                    </div>
                                ) : (
                                    <span style={{ whiteSpace: 'nowrap' }}>
                                        { itemBeingUpdatedOrAdded === item && successIndicator ? 'Saved' : 'Save' }
                                    </span>
                                )}
                            </DefaultButton>
                        </Stack>
                    )
                }
            </div>
        );
    }, [apiAddSupplier.callApiState, editButtonClicked, inputErrors, itemBeingEdited, itemBeingUpdatedOrAdded, saveButtonClicked, successIndicator, apiUpdateSupplier.callApiState]);

    /**
     * On text field column render.
     * @param item The item (row) being rendered in the details list. 
     * @param index The index of the row.
     * @param column The column being rendered.
     */
    const onColumnRenderTextField = useCallback((item: Supplier, index: number | undefined, column: IColumn | undefined): JSX.Element => {
        const fieldName: string = column!.fieldName!;
        return (
            <>
                <TextField
                    defaultValue={item[fieldName]}
                    onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                        newValue = newValue || '';
                        item[fieldName] = newValue;
                        const newInputErrors: InputError[] = addOrRemoveError(inputErrors, column!, item, fieldName, newValue);
                        setInputErrors(newInputErrors);
                        setSuppliers([...suppliers]);
                    }}
                    disabled={
                        itemBeingEdited !== item ||
                        apiUpdateSupplier.callApiState === CallApiState.Running ||
                        apiAddSupplier.callApiState === CallApiState.Running ||
                        successIndicator
                    }
                    errorMessage={checkForInputError(inputErrors, item, fieldName)}>
                </TextField>
            </>
        );
    }, [apiAddSupplier.callApiState, inputErrors, itemBeingEdited, successIndicator, suppliers, apiUpdateSupplier.callApiState]);
    
    /**
     * On text field column render - conditionally based on item data.
     * @param item The item (row) being rendered in the details list. 
     * @param index The index of the row.
     * @param column The column being rendered.
     */
    const onColumnRenderTextFieldConditionally = useCallback((item: Supplier, index: number | undefined, column: IColumn | undefined): JSX.Element => {
        const fieldName: string = column!.fieldName!;
        return (
            <>
                { item.isNew /* Only allow editing this is a new record. */ ? (
                    <TextField
                        defaultValue={item[fieldName]}
                        onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                            newValue = newValue || '';
                            item[fieldName] = newValue;
                            const newInputErrors: InputError[] = addOrRemoveError(inputErrors, column!, item, fieldName, newValue);
                            setInputErrors(newInputErrors);
                            setSuppliers([...suppliers]);
                        }}
                        disabled={
                            itemBeingEdited !== item ||
                            apiUpdateSupplier.callApiState === CallApiState.Running ||
                            apiAddSupplier.callApiState === CallApiState.Running ||
                            successIndicator
                        }
                        errorMessage={checkForInputError(inputErrors, item, fieldName)}>
                    </TextField>
                ) : (
                    <div>{item[fieldName]}</div>
                )}
            </>
        );
    }, [apiAddSupplier.callApiState, inputErrors, itemBeingEdited, successIndicator, suppliers, apiUpdateSupplier.callApiState]);

    /**
     * On column render for checkbox field.
     * @param item The item (row) being rendered in the details list. 
     * @param index The index of the row.
     * @param column The column being rendered.
     */
    const onColumnRenderCheckbox = useCallback((item: Supplier, index: number | undefined, column: IColumn | undefined): JSX.Element => {
        const fieldName: string = column!.fieldName!;
        return (
            <div style={{ margin: '5px 0 0 0', height: '100%' }}>
                <Checkbox
                    checked={item[fieldName]}
                    onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
                        item[fieldName] = checked || false;
                        setSuppliers([...suppliers]);
                    }}
                    disabled={
                        itemBeingEdited !== item ||
                        apiUpdateSupplier.callApiState === CallApiState.Running ||
                        apiAddSupplier.callApiState === CallApiState.Running ||
                        successIndicator
                    }>
                </Checkbox>
            </div>
        );
    }, [apiAddSupplier.callApiState, itemBeingEdited, successIndicator, suppliers, apiUpdateSupplier.callApiState]);

    /**
     * Effect to update onRender handler. Otherwise the component state referenced in onRender
     * would be stale from the render pass it was created on.
     */
    useEffect(() => {
        columnsSuppliers.filter(x => x.data?.addActionButtons).forEach(c => c.onRender = onColumnRenderActionButtons);
        columnsSuppliers.filter(x => x.data?.addOnColumnRenderTextField).forEach(c => c.onRender = onColumnRenderTextField);
        columnsSuppliers.filter(x => x.data?.addOnColumnRenderTextFieldConditionally).forEach(c => c.onRender = onColumnRenderTextFieldConditionally);
        columnsSuppliers.filter(x => x.data?.addOnColumnRenderCheckbox).forEach(c => c.onRender = onColumnRenderCheckbox);
    }, [columnsSuppliers, onColumnRenderActionButtons, onColumnRenderCheckbox, onColumnRenderTextField, onColumnRenderTextFieldConditionally]);

    /**
     * Add button clicked event handler.
     */
    const addButtonClicked = (): void => {
        const newSupplier: Supplier = new Supplier({
            supplierId: '',
            supplierName: '',
            supplierContactMailingGroup: '',
            emailNotification: true,
            isEnabled: true
        });
        newSupplier.isNew = true;
        newSupplier.clientRowKey = suppliers.length.toString();
        let currentInputErrors: InputError[] = [...inputErrors];
        columnsSuppliers.forEach((column) => {
            const value: string = newSupplier[column.fieldName!];
            currentInputErrors = addOrRemoveError(currentInputErrors, column, newSupplier, column.fieldName!, value);
        });
        setInputErrors(currentInputErrors);
        setSuppliers([...suppliers, newSupplier]);
        setItemBeingEdited(newSupplier);
    }

    /**
     * Search clicked event handler.
     * @param searchCriteria Search criteria.
     */
    const searchClicked = (searchCriteria: ISearchCriteria) => {
        setCurrentSearchCriteria(searchCriteria);
        if (searchCriteria.programType) {
            dispatch(loadSuppliers(
                searchCriteria.programType
            ));
        }
    };

    /**
     * Search filter load error handler.
     * @param err Error
     */
    const searchFilterLoadError = (err: any) => {
        handleError(prepErrorMsg(appConstants.dataLoadFailed, err));
    };

    return (
        <>
            <ErrorBar errors={errors} onDismiss={(index: number) => {
                setErrors(clearErrorByIndex(errors, index));
            }} />

            <div className="ms-Grid" dir="ltr">
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{ margin: '12px 12px 12px 12px' }}>
                        <SearchFilter
                            programs={props.programs || []}
                            searchFilterOptions={{
                                showFiscalRange: false,
                                fiscalRangeFieldsRequired: false,
                                showCalendarYearMonth: false,
                                showProgram: true,
                                showSupplierName: false,
                                showComboInputGroup: ComboInputOption.None,
                                showSupplierJobId: false,
                                showSupplierPoNumber: false,
                                showSupplierInvoiceNumber: false,
                                showCorrelationId: false,
                                showMsInvoiceNumber: false,
                                showSupplierUnitType: false,
                                showJobUnit: false,
                                showCountry: false,
                                showComboAssetTagAndSerialNumber: false,
                                showUnitDispositionType: false,
                                showUnitStatus: false
                            }}
                            onSearchClicked={searchClicked}
                            onSearchFilterLoadError={searchFilterLoadError}
                            disabled={
                                apiUpdateSupplier.callApiState === CallApiState.Running ||
                                apiAddSupplier.callApiState === CallApiState.Running ||
                                successIndicator} />
                    </div>
                </div>
                {apiLoadSuppliers.callApiState === CallApiState.Running && (
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{ margin: '12px 12px 12px 12px' }}>
                            <Spinner size={SpinnerSize.medium} className={commonStyles.spinner} />
                        </div>
                    </div>
                )}
                {apiLoadSuppliers.callApiState === CallApiState.Completed && (
                    <>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <CustomDetailsList
                                    showExcelExport={true}
                                    exportExcelSheetName="Suppliers"
                                    ariaLabelForGrid="Suppliers"
                                    displayTotalItems={true}
                                    showPaginator={false}
                                    showPageSize={false}
                                    selectedPage={1}
                                    showNoDataFoundMsg={suppliers.length === 0}
                                    items={suppliers}
                                    compact={false}
                                    columns={columnsSuppliers}
                                    selectionMode={SelectionMode.none}
                                    getKey={(item: Supplier) => item.clientRowKey}
                                    setKey="none"
                                    layoutMode={DetailsListLayoutMode.fixedColumns}
                                    isHeaderVisible={true}
                                    constrainMode={ConstrainMode.horizontalConstrained} />
                            </div>
                        </div>

                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <Stack horizontal wrap tokens={stackTokens}>
                                    <DefaultButton
                                        disabled={
                                            apiUpdateSupplier.callApiState === CallApiState.Running ||
                                            apiAddSupplier.callApiState === CallApiState.Running ||
                                            successIndicator}
                                        onClick={event => addButtonClicked()}>
                                        <span>Add</span>
                                    </DefaultButton>
                                </Stack>
                            </div>
                        </div>
                    </>
                )}
            </div>

            <ReloadingDialog
                successIndicator={successIndicator} />
        </>
    );
};
