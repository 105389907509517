import { AnyAction, Reducer } from 'redux';
import * as actionTypes from '../../actions/actionTypes';
import { IApiLoadUnitDispositionTypes, IApiUpdateUnitDispositionType, IApiAddUnitDispositionType, IApiDeleteUnitDispositionType } from '../../actions/adminTabActions/adminTabUnitDispositionTypes.action';
import { CallApiState, StateUpdateOption } from '../../actions/generic.action';

export interface IAdminTabUnitDispositionTypesReducerState {
    apiLoadUnitDispositionTypes: IApiLoadUnitDispositionTypes;
    apiUpdateUnitDispositionType: IApiUpdateUnitDispositionType;
    apiAddUnitDispositionType: IApiAddUnitDispositionType;
    apiDeleteUnitDispositionType: IApiDeleteUnitDispositionType;
}

const initialAdminTabSupplierReducerState: IAdminTabUnitDispositionTypesReducerState = {
    apiLoadUnitDispositionTypes: {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        unitDispositionTypes: undefined
    },
    apiUpdateUnitDispositionType: {
        callApiState: CallApiState.Initial,
        errMsg: undefined
    },
    apiAddUnitDispositionType: {
        callApiState: CallApiState.Initial,
        errMsg: undefined
    },
    apiDeleteUnitDispositionType: {
        callApiState: CallApiState.Initial,
        errMsg: undefined
    }
};

export const adminTabUnitDispositionTypesReducer: Reducer<IAdminTabUnitDispositionTypesReducerState, AnyAction> = (
    state: IAdminTabUnitDispositionTypesReducerState = initialAdminTabSupplierReducerState, action: AnyAction
): IAdminTabUnitDispositionTypesReducerState => {
    switch (action.type) {
        case actionTypes.API_LOAD_UNIT_DISPOSITION_TYPES: {
            const payload: IApiLoadUnitDispositionTypes = action.payload as IApiLoadUnitDispositionTypes;
            switch (payload.stateUpdateOption) {
                case StateUpdateOption.ErrorOnly:
                    return {
                        ...state,
                        // Keep the prior payload except for the errMsg which will be set to undefined.
                        apiLoadUnitDispositionTypes: {
                            ...state.apiLoadUnitDispositionTypes,
                            errMsg: undefined
                        }
                    }
                default:
                    return {
                        ...state,
                        apiLoadUnitDispositionTypes: { ...payload }
                    }
            }
        }
        case actionTypes.API_UPDATE_UNIT_DISPOSITION_TYPE: {
            const payload: IApiUpdateUnitDispositionType = action.payload as IApiUpdateUnitDispositionType;
            switch (payload.stateUpdateOption) {
                case StateUpdateOption.ErrorOnly:
                    return {
                        ...state,
                        // Keep the prior payload except for the errMsg which will be set to undefined.
                        apiUpdateUnitDispositionType: {
                            ...state.apiUpdateUnitDispositionType,
                            errMsg: undefined
                        }
                    }
                default:
                    return {
                        ...state,
                        apiUpdateUnitDispositionType: { ...payload }
                    }
            }
        }
        case actionTypes.API_ADD_UNIT_DISPOSITION_TYPE: {
            const payload: IApiAddUnitDispositionType = action.payload as IApiAddUnitDispositionType;
            switch (payload.stateUpdateOption) {
                case StateUpdateOption.ErrorOnly:
                    return {
                        ...state,
                        // Keep the prior payload except for the errMsg which will be set to undefined.
                        apiAddUnitDispositionType: {
                            ...state.apiAddUnitDispositionType,
                            errMsg: undefined
                        }
                    }
                default:
                    return {
                        ...state,
                        apiAddUnitDispositionType: { ...payload }
                    }
            }
        }
        case actionTypes.API_DELETE_UNIT_DISPOSITION_TYPE: {
            const payload: IApiDeleteUnitDispositionType = action.payload as IApiDeleteUnitDispositionType;
            switch (payload.stateUpdateOption) {
                case StateUpdateOption.ErrorOnly:
                    return {
                        ...state,
                        // Keep the prior payload except for the errMsg which will be set to undefined.
                        apiDeleteUnitDispositionType: {
                            ...state.apiDeleteUnitDispositionType,
                            errMsg: undefined
                        }
                    }
                default:
                    return {
                        ...state,
                        apiDeleteUnitDispositionType: { ...payload }
                    }
            }
        }
        default:
    }

    return state;
};
