import React, { useCallback, useEffect, useState } from 'react';
import {
    ConstrainMode,
    DefaultButton,
    DetailsListLayoutMode,
    IColumn,
    SelectionMode,
    Spinner,
    SpinnerSize,
    Stack,
    TextField
} from '@fluentui/react';
import { IAdminTabProps } from '../../common/common.types';
import { commonColumnProps, useMountEffect } from '../../common/common.func';
import { clearErrorByIndex, ErrorBar, prepErrorMsg } from '../../components/ErrorBar/ErrorBar';
import { CallApiState } from '../../store/actions/generic.action';
import { CustomDetailsList } from '../../components/CustomDetailsList/CustomDetailsList';
import { commonStyles, stackTokens } from '../../common/common.styles';
import { ComboInputOption, ISearchCriteria, SearchFilter } from '../../components/SearchFilter/SearchFilter';
import {
    loadSupplierUnitTypes,
    updateSupplierUnitType,
    addSupplierUnitType,
    deleteSupplierUnitType,
    resetApiCallState,
    IApiLoadSupplierUnitTypes,
    IApiUpdateSupplierUnitType,
    IApiAddSupplierUnitType,
    IApiDeleteSupplierUnitType
} from '../../store/actions/adminTabActions/adminTabSupplierUnitTypes.action';
import { SupplierUnitType } from '../../models/domainData/supplierUnitType';
import { addOrRemoveError, checkForInputError, checkItemForAnyInputError, InputError } from './AdminCommon';
import { appConstants } from '../../common/appConstants';
import { ReloadingDialog } from './ReloadingDialog';
import { ConfirmDeleteDialog } from './ConfirmDeleteDialog';
import { useAppSelector, useAppDispatch } from '../../store/hooks';

interface IAdminTabSupplierUnitTypesProps extends IAdminTabProps {
}

export const AdminTabSupplierUnitTypes: React.FunctionComponent<IAdminTabSupplierUnitTypesProps> = (props: IAdminTabSupplierUnitTypesProps): JSX.Element => {
    const [errors, setErrors] = useState<string[]>([]);
    const [supplierUnitTypes, setSupplierUnitTypes] = useState<SupplierUnitType[]>([]);
    const [inputErrors, setInputErrors] = useState<InputError[]>([]);
    const [successIndicator, setSuccessIndicator] = useState<boolean>(false);
    const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
    const [currentSearchCriteria, setCurrentSearchCriteria] = useState<ISearchCriteria>();
    const [itemBeingUpdatedOrAdded, setItemBeingUpdatedOrAdded] = useState<SupplierUnitType>();
    const [itemBeingDeleted, setItemBeingDeleted] = useState<SupplierUnitType>();
    const [itemBeingEdited, setItemBeingEdited] = useState<SupplierUnitType>();

    // Redux store selectors to get state from the store when it changes.
    const apiLoadSupplierUnitTypes: IApiLoadSupplierUnitTypes =
        useAppSelector<IApiLoadSupplierUnitTypes>((state) => state.adminTabSupplierUnitTypesReducer.apiLoadSupplierUnitTypes);
    const apiUpdateSupplierUnitType: IApiUpdateSupplierUnitType =
        useAppSelector<IApiUpdateSupplierUnitType>((state) => state.adminTabSupplierUnitTypesReducer.apiUpdateSupplierUnitType);
    const apiAddSupplierUnitType: IApiAddSupplierUnitType =
        useAppSelector<IApiAddSupplierUnitType>((state) => state.adminTabSupplierUnitTypesReducer.apiAddSupplierUnitType);
    const apiDeleteSupplierUnitType: IApiDeleteSupplierUnitType =
        useAppSelector<IApiDeleteSupplierUnitType>((state) => state.adminTabSupplierUnitTypesReducer.apiDeleteSupplierUnitType);

    // Redux store dispatch to send actions to the store.
    const dispatch = useAppDispatch();

    /**
     * Setup columns to display for supplier unit types.
     */
    const [columnsSupplierUnitTypes, setColumnsSupplierUnitTypes] = useState<IColumn[]>(
        [
            {
                ...commonColumnProps,
                key: 'column1',
                name: '',
                minWidth: 180,
                data: {
                    addActionButtons: true
                }
            },
            {
                ...commonColumnProps,
                key: 'column2',
                name: 'Business Comments',
                fieldName: 'businessComments',
                minWidth: 150,
                data: {
                    addOnColumnRenderTextField: true,
                    pattern: /^[a-zA-Z-,. ]{0,500}$/,
                    inputErrorMsg: 'Alpha, dashes, spaces, commas, periods, min 0, max 500 length'
                }
            },
            {
                ...commonColumnProps,
                key: 'column3',
                name: 'Microsoft Unit Type',
                fieldName: 'microsoftUnitType',
                minWidth: 150,
                data: {
                    addOnColumnRenderTextField: true,
                    pattern: /^[a-zA-Z0-9- _,#'"]{5,500}$/,
                    inputErrorMsg: 'Alphanumeric, dashes, spaces, underscore, comma, hash, single quote, double quote, min 5, max 500 length'
                }
            },
            {
                ...commonColumnProps,
                key: 'column4',
                name: 'Microsoft Unit Type Mapping',
                fieldName: 'microsoftUnitTypeMapping',
                minWidth: 150,
                data: {
                    addOnColumnRenderTextField: true,
                    pattern: /^[a-zA-Z0-9- _,#'"]{5,500}$/,
                    inputErrorMsg: 'Alphanumeric, dashes, spaces, underscore, comma, hash, single quote, double quote, min 5, max 500 length'
                }
            },
            {
                ...commonColumnProps,
                key: 'column5',
                name: 'Supplier Unit Type',
                fieldName: 'supplierUnitType',
                minWidth: 150,
                data: {
                    addOnColumnRenderTextField: true,
                    pattern: /^[a-zA-Z0-9- _,#'"]{5,500}$/,
                    inputErrorMsg: 'Alphanumeric, dashes, spaces, underscore, comma, hash, single quote, double quote, min 5, max 500 length'
                }
            }
        ]
    );

    /**
     * Rerender columns by setting the columns to a new array of the same columns.
     * This will cause the columns to re-render including the action buttons column... the buttons state may
     * change such as becoming disabled or enabled, or buttons showing or hiding, etc.
     */
    const rerenderColumns = useCallback(() => {
        // Use a setTimeout to queue this on the UI thread after the current render pass.
        setTimeout(() => {
            setColumnsSupplierUnitTypes([...columnsSupplierUnitTypes]);
        });
    }, [columnsSupplierUnitTypes]);

    /**
     * This effect does nothing on mount, but will return a cleanup function that runs when the component unmounts.
     */
    useEffect(() => {
        return function cleanup() {
            resetApiCallState();
        }
    }, []);

    /**
     * Handle error.
     * @param errMsg Error message.
     */
    const handleError = useCallback((errMsg: string) => {
        setErrors((prevErrors) => {
            // This will prevent the same error from being displayed if already displayed.
            // ex: Multiple page data load failures might occur if the api is not working,
            // and this page makes multiple load calls for various data.
            if (!prevErrors.includes(errMsg)) {
                return [...prevErrors, errMsg];
            }
            return prevErrors;
        });
    }, []);

    /**
     * Effect for when errors occur in any api call.
     */
    useEffect(() => {
        if (apiLoadSupplierUnitTypes.errMsg) {
            handleError(apiLoadSupplierUnitTypes.errMsg);
        }
        if (apiUpdateSupplierUnitType.errMsg) {
            handleError(apiUpdateSupplierUnitType.errMsg);
        }
        if (apiAddSupplierUnitType.errMsg) {
            handleError(apiAddSupplierUnitType.errMsg);
        }
        if (apiDeleteSupplierUnitType.errMsg) {
            handleError(apiDeleteSupplierUnitType.errMsg);
        }
    }, [apiAddSupplierUnitType.errMsg, apiDeleteSupplierUnitType.errMsg, handleError, apiLoadSupplierUnitTypes.errMsg, apiUpdateSupplierUnitType.errMsg]);

    /**
     * This effect is run once during page load.
     */
    useMountEffect(() => {
        // Nothing at this time.
    });

    /**
     * Effect for when supplier unit types is loaded.
     */
    useEffect(() => {
        if (apiLoadSupplierUnitTypes.callApiState === CallApiState.DataAvailable) {
            setSupplierUnitTypes(apiLoadSupplierUnitTypes.supplierUnitTypes || []);
        }
    }, [apiLoadSupplierUnitTypes.callApiState, apiLoadSupplierUnitTypes.supplierUnitTypes]);

    /**
     * Effect for when supplier unit type is updated or added or deleted.
     */
    useEffect(() => {
        if (apiUpdateSupplierUnitType.callApiState === CallApiState.DataAvailable ||
            apiAddSupplierUnitType.callApiState === CallApiState.DataAvailable ||
            apiDeleteSupplierUnitType.callApiState === CallApiState.DataAvailable) {
            setSuccessIndicator(true);
            rerenderColumns();
            // Wait for 2 seconds and then reload data.
            setTimeout(() => {
                setSuccessIndicator(false);
                // Reload the supplier unit types using current search criteria.
                if (currentSearchCriteria && currentSearchCriteria.programType) {
                    dispatch(loadSupplierUnitTypes(
                        currentSearchCriteria.programType
                    ));
                }
            }, 2000);
        }
    }, [apiAddSupplierUnitType.callApiState, currentSearchCriteria, apiDeleteSupplierUnitType.callApiState, dispatch, rerenderColumns, apiUpdateSupplierUnitType.callApiState]);

    /**
     * Edit button clicked event handler.
     * @param supplierUnitType Supplier unit type being edited.
     */
    const editButtonClicked = useCallback((supplierUnitType: SupplierUnitType): void => {
        setItemBeingEdited(supplierUnitType);
        rerenderColumns();
    }, [rerenderColumns]);

    /**
     * Save (update or add) button clicked event handler.
     * @param supplierUnitType Supplier unit type being saved.
     */
    const saveButtonClicked = useCallback((supplierUnitType: SupplierUnitType): void => {
        setItemBeingUpdatedOrAdded(supplierUnitType);
        if (supplierUnitType.isNew) {
            if (currentSearchCriteria && currentSearchCriteria.programType) {
                dispatch(addSupplierUnitType(supplierUnitType, currentSearchCriteria.programType));
            }
        } else {
            dispatch(updateSupplierUnitType(supplierUnitType));
        }
        rerenderColumns();
    }, [currentSearchCriteria, dispatch, rerenderColumns]);

    /**
     * Delete button clicked event handler.
     * @param supplierUnitType Supplier unit type being saved.
     */
    const deleteButtonClicked = useCallback((supplierUnitType: SupplierUnitType): void => {
        setItemBeingDeleted(supplierUnitType);
        setConfirmDelete(true);
    }, []);

    /**
     * Confirm delete button confirmed clicked event handler.
     * @param supplierUnitType Supplier unit type being saved.
     */
    const confirmDeleteButtonClicked = useCallback((supplierUnitType: SupplierUnitType | undefined): void => {
        setConfirmDelete(false);
        if (supplierUnitType) {
            dispatch(deleteSupplierUnitType(supplierUnitType));
            rerenderColumns();
        }
    }, [dispatch, rerenderColumns]);

    /**
     * Cancel delete button confirmed clicked event handler.
     */
    const cancelDeleteButtonClicked = useCallback((): void => {
        setConfirmDelete(false);
        setItemBeingDeleted(undefined);
    }, []);

    /**
     * On column render for action buttons.
     * @param item The item (row) being rendered in the details list. 
     * @param index The index of the row.
     * @param column The column being rendered.
     */
    const onColumnRenderActionButtons = useCallback((item: SupplierUnitType, index: number | undefined, column: IColumn | undefined): JSX.Element => {
        return (
            <div>
                {
                    itemBeingEdited !== item && (
                        <Stack horizontal tokens={stackTokens}>
                            <DefaultButton
                                onClick={event => editButtonClicked(item)}
                                disabled={
                                    apiUpdateSupplierUnitType.callApiState === CallApiState.Running ||
                                    apiAddSupplierUnitType.callApiState === CallApiState.Running ||
                                    apiDeleteSupplierUnitType.callApiState === CallApiState.Running ||
                                    successIndicator}>
                                <span style={{ whiteSpace: 'nowrap' }}>
                                    Edit
                                </span>
                            </DefaultButton>
                            <DefaultButton
                                onClick={event => deleteButtonClicked(item)}
                                disabled={
                                    checkItemForAnyInputError(inputErrors, item) ||
                                    apiUpdateSupplierUnitType.callApiState === CallApiState.Running ||
                                    apiAddSupplierUnitType.callApiState === CallApiState.Running ||
                                    apiDeleteSupplierUnitType.callApiState === CallApiState.Running ||
                                    successIndicator}>
                                { itemBeingDeleted === item &&
                                    apiDeleteSupplierUnitType.callApiState === CallApiState.Running ? (
                                    <div style={{ display: 'flex' }}>
                                        <span style={{ marginRight: '6px', whiteSpace: 'nowrap' }}>Deleting</span>
                                        <Spinner size={SpinnerSize.medium} className={commonStyles.spinner} style={{ display: 'inline-block' }} />
                                    </div>
                                ) : (
                                    <span style={{ whiteSpace: 'nowrap' }}>
                                        { itemBeingDeleted === item && successIndicator ? 'Deleted' : 'Delete' }
                                    </span>
                                )}
                            </DefaultButton>
                        </Stack>
                    )
                }
                {
                    itemBeingEdited === item && (
                        <Stack horizontal tokens={stackTokens}>
                            <DefaultButton
                                onClick={event => saveButtonClicked(item)}
                                disabled={
                                    checkItemForAnyInputError(inputErrors, item) ||
                                    apiUpdateSupplierUnitType.callApiState === CallApiState.Running ||
                                    apiAddSupplierUnitType.callApiState === CallApiState.Running ||
                                    apiDeleteSupplierUnitType.callApiState === CallApiState.Running ||
                                    successIndicator}>
                                { itemBeingUpdatedOrAdded === item &&
                                    (apiUpdateSupplierUnitType.callApiState === CallApiState.Running ||
                                    apiAddSupplierUnitType.callApiState === CallApiState.Running) ? (
                                    <div style={{ display: 'flex' }}>
                                        <span style={{ marginRight: '6px', whiteSpace: 'nowrap' }}>Saving</span>
                                        <Spinner size={SpinnerSize.medium} className={commonStyles.spinner} style={{ display: 'inline-block' }} />
                                    </div>
                                ) : (
                                    <span style={{ whiteSpace: 'nowrap' }}>
                                        { itemBeingUpdatedOrAdded === item && successIndicator ? 'Saved' : 'Save' }
                                    </span>
                                )}
                            </DefaultButton>
                        </Stack>
                    )
                }
            </div>
        );
    }, [apiAddSupplierUnitType.callApiState, deleteButtonClicked, apiDeleteSupplierUnitType.callApiState, editButtonClicked, inputErrors, itemBeingDeleted, itemBeingEdited, itemBeingUpdatedOrAdded, saveButtonClicked, successIndicator, apiUpdateSupplierUnitType.callApiState]);

    /**
     * On text field column render.
     * @param item The item (row) being rendered in the details list. 
     * @param index The index of the row.
     * @param column The column being rendered.
     */
    const onColumnRenderTextField = useCallback((item: SupplierUnitType, index: number | undefined, column: IColumn | undefined): JSX.Element => {
        const fieldName: string = column!.fieldName!;
        return (
            <>
                <TextField
                    defaultValue={item[fieldName]}
                    onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                        newValue = newValue || '';
                        item[fieldName] = newValue;
                        const newInputErrors: InputError[] = addOrRemoveError(inputErrors, column!, item, fieldName, newValue);
                        setInputErrors(newInputErrors);
                        setSupplierUnitTypes([...supplierUnitTypes]);
                    }}
                    disabled={
                        itemBeingEdited !== item ||
                        apiUpdateSupplierUnitType.callApiState === CallApiState.Running ||
                        apiAddSupplierUnitType.callApiState === CallApiState.Running ||
                        apiDeleteSupplierUnitType.callApiState === CallApiState.Running ||
                        successIndicator
                    }
                    errorMessage={checkForInputError(inputErrors, item, fieldName)}>
                </TextField>
            </>
        );
    }, [apiAddSupplierUnitType.callApiState, apiDeleteSupplierUnitType.callApiState, inputErrors, itemBeingEdited, successIndicator, supplierUnitTypes, apiUpdateSupplierUnitType.callApiState]);

    /**
     * Effect to update onRender handler. Otherwise the component state referenced in onRender
     * would be stale from the render pass it was created on.
     */
    useEffect(() => {
        columnsSupplierUnitTypes.filter(x => x.data?.addActionButtons).forEach(c => c.onRender = onColumnRenderActionButtons);
        columnsSupplierUnitTypes.filter(x => x.data?.addOnColumnRenderTextField).forEach(c => c.onRender = onColumnRenderTextField);
    }, [columnsSupplierUnitTypes, onColumnRenderActionButtons, onColumnRenderTextField]);

    /**
     * Add button clicked event handler.
     */
    const addButtonClicked = (): void => {
        const newSupplierUnitType: SupplierUnitType = new SupplierUnitType({
            partitionKey: '',
            rowKey: '',
            businessComments: '',
            supplierUnitType: '',
            microsoftUnitType: '',
            microsoftUnitTypeMapping: ''
        });
        newSupplierUnitType.isNew = true;
        newSupplierUnitType.clientRowKey = supplierUnitTypes.length.toString();
        let currentInputErrors: InputError[] = [...inputErrors];
        columnsSupplierUnitTypes.forEach((column) => {
            const value: string = newSupplierUnitType[column.fieldName!];
            currentInputErrors = addOrRemoveError(currentInputErrors, column, newSupplierUnitType, column.fieldName!, value);
        });
        setInputErrors(currentInputErrors);
        setSupplierUnitTypes([...supplierUnitTypes, newSupplierUnitType]);
        setItemBeingEdited(newSupplierUnitType);
    }

    /**
     * Search clicked event handler.
     * @param searchCriteria Search criteria.
     */
    const searchClicked = (searchCriteria: ISearchCriteria) => {
        setCurrentSearchCriteria(searchCriteria);
        if (searchCriteria.programType) {
            dispatch(loadSupplierUnitTypes(
                searchCriteria.programType
            ));
        }
    };

    /**
     * Search filter load error handler.
     * @param err Error
     */
    const searchFilterLoadError = (err: any) => {
        handleError(prepErrorMsg(appConstants.dataLoadFailed, err));
    };

    return (
        <>
            <ErrorBar errors={errors} onDismiss={(index: number) => {
                setErrors(clearErrorByIndex(errors, index));
            }} />

            <div className="ms-Grid" dir="ltr">
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{ margin: '12px 12px 12px 12px' }}>
                        <SearchFilter
                            programs={props.programs || []}
                            searchFilterOptions={{
                                showFiscalRange: false,
                                fiscalRangeFieldsRequired: false,
                                showCalendarYearMonth: false,
                                showProgram: true,
                                showSupplierName: false,
                                showComboInputGroup: ComboInputOption.None,
                                showSupplierJobId: false,
                                showSupplierPoNumber: false,
                                showSupplierInvoiceNumber: false,
                                showCorrelationId: false,
                                showMsInvoiceNumber: false,
                                showSupplierUnitType: false,
                                showJobUnit: false,
                                showCountry: false,
                                showComboAssetTagAndSerialNumber: false,
                                showUnitDispositionType: false,
                                showUnitStatus: false
                            }}
                            onSearchClicked={searchClicked}
                            onSearchFilterLoadError={searchFilterLoadError}
                            disabled={
                                apiUpdateSupplierUnitType.callApiState === CallApiState.Running ||
                                apiAddSupplierUnitType.callApiState === CallApiState.Running ||
                                apiDeleteSupplierUnitType.callApiState === CallApiState.Running ||
                                successIndicator} />
                    </div>
                </div>
                {apiLoadSupplierUnitTypes.callApiState === CallApiState.Running && (
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{ margin: '12px 12px 12px 12px' }}>
                            <Spinner size={SpinnerSize.medium} className={commonStyles.spinner} />
                        </div>
                    </div>
                )}
                {apiLoadSupplierUnitTypes.callApiState === CallApiState.Completed && (
                    <>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <CustomDetailsList
                                    showExcelExport={true}
                                    exportExcelSheetName="Supplier Unit Types"
                                    ariaLabelForGrid="Supplier Unit Types"
                                    displayTotalItems={true}
                                    showPaginator={false}
                                    showPageSize={false}
                                    selectedPage={1}
                                    showNoDataFoundMsg={supplierUnitTypes.length === 0}
                                    items={supplierUnitTypes}
                                    compact={false}
                                    columns={columnsSupplierUnitTypes}
                                    selectionMode={SelectionMode.none}
                                    getKey={(item: SupplierUnitType) => item.clientRowKey}
                                    setKey="none"
                                    layoutMode={DetailsListLayoutMode.fixedColumns}
                                    isHeaderVisible={true}
                                    constrainMode={ConstrainMode.horizontalConstrained} />
                            </div>
                        </div>

                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <Stack horizontal wrap tokens={stackTokens}>
                                    <DefaultButton
                                        disabled={
                                            apiUpdateSupplierUnitType.callApiState === CallApiState.Running ||
                                            apiAddSupplierUnitType.callApiState === CallApiState.Running ||
                                            apiDeleteSupplierUnitType.callApiState === CallApiState.Running ||
                                            successIndicator}
                                        onClick={event => addButtonClicked()}>
                                        <span>Add</span>
                                    </DefaultButton>
                                </Stack>
                            </div>
                        </div>
                    </>
                )}
            </div>

            <ReloadingDialog
                successIndicator={successIndicator} />
            <ConfirmDeleteDialog
                confirmDelete={confirmDelete}
                itemBeingDeleted={itemBeingDeleted}
                confirmDeleteButtonClicked={confirmDeleteButtonClicked}
                cancelDeleteButtonClicked={cancelDeleteButtonClicked} />     
        </>
    );
};
