import React from 'react';
import { Text } from '@fluentui/react';
import { commonStyles } from '../../common/common.styles';
import { pageStyles } from './UnauthorizedPage.styles';
import { PageWrapper } from '../../components/PageWrapper/PageWrapper';

interface UnauthorizedPageProps {
    error?: any;
}

export const UnauthorizedPage: React.FunctionComponent<UnauthorizedPageProps> = (props: UnauthorizedPageProps): JSX.Element => {
    return (
        <PageWrapper>
            <div className='ms-Grid' dir="ltr">
                <div className='ms-Grid-row'>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <div className={commonStyles.pageHeader}>
                            <Text variant="xLarge" role="heading" aria-level={1}>Not authorized to view this page</Text>
                        </div>
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        {props.error && (
                            <Text className={pageStyles.errorText}>
                                {typeof props.error === 'string' ? props.error : JSON.stringify(props.error)}
                            </Text>
                        )}
                    </div>
                </div>
            </div>
        </PageWrapper>
    );
}
