import { RecyclingIdentity } from './recyclingIdentity';
import { Collection } from './collection';
import { Disposition } from './disposition';
import { AssetDetails } from './assetDetails';
import { Invoice } from './invoice';
import { Credit } from './credit';

export interface IRecyclingDocument {
    createdDate?: Date;
    modifiedDate?: Date;
    collection?: Collection;
    disposition?: Disposition;
    invoice?: Invoice;
    credit?: Credit;
    assetDetails?: AssetDetails;
}

export class RecyclingDocument extends RecyclingIdentity implements IRecyclingDocument {
    public createdDate?: Date;
    public modifiedDate?: Date;
    public collection?: Collection;
    public disposition?: Disposition;
    public invoice?: Invoice;
    public credit?: Credit;
    public assetDetails?: AssetDetails;

    constructor(jsonData: IRecyclingDocument) {
        super((jsonData as unknown) as RecyclingIdentity);
  
        this.createdDate = jsonData.createdDate ? new Date(jsonData.createdDate) : undefined;
        this.modifiedDate = jsonData.modifiedDate ? new Date(jsonData.modifiedDate) : undefined;
        this.collection = jsonData.collection ? new Collection(jsonData.collection) : undefined;
        this.disposition = jsonData.disposition ? new Disposition(jsonData.disposition) : undefined;
        this.invoice = jsonData.invoice ? new Invoice(jsonData.invoice) : undefined;
        this.credit = jsonData.credit ? new Credit(jsonData.credit) : undefined;
        this.assetDetails = jsonData.assetDetails ? new AssetDetails(jsonData.assetDetails) : undefined;
    }
}
