import React from 'react';
import { IColumn } from '@fluentui/react';
import { commonColumnProps } from '../../common/common.func';
import { ISupplierApiInteraction } from '../../models/supplier/supplierApiInteraction';
import { ISupplierTrend } from '../../models/supplier/supplierTrend';
import { ISupplierActivity } from '../../models/supplier/supplierActivity';

export const getSupplierApiInteractionsColumns = (): IColumn[] => [
    {
        ...commonColumnProps,
        key: 'column1',
        name: 'Supplier Name',
        fieldName: 'supplierName',
        // Setting both minWidth and maxWidth on these columns has the effect of them aligning better when
        // DetailsListLayoutMode.justified is also being used. The columns can still be resized (maxWidth doesn't stop that).
        minWidth: 90,
        maxWidth: 90
    },
    {
        ...commonColumnProps,
        key: 'column2',
        name: 'Supplier ID',
        fieldName: 'supplierId',
        minWidth: 70,
        maxWidth: 70
    },
    {
        ...commonColumnProps,
        key: 'column3',
        name: 'Program Type',
        fieldName: 'programType',
        minWidth: 110,
        maxWidth: 110
    },
    {
        ...commonColumnProps,
        key: 'column4',
        name: 'Published On',
        fieldName: 'lastPublishedDate',
        minWidth: 85,
        maxWidth: 85,
        onRender: (item: ISupplierApiInteraction) => {
            return <span>{item.lastPublishedDate?.toLocaleDateString()}</span>;
        }
    }
];

export const getSupplierTrendsColumns = (): IColumn[] => [
    {
        ...commonColumnProps,
        key: 'column1',
        name: 'Supplier Name',
        fieldName: 'supplierName',
        minWidth: 110
    },
    {
        ...commonColumnProps,
        key: 'column2',
        name: 'Program Type',
        fieldName: 'programType',
        minWidth: 80
    },
    {
        ...commonColumnProps,
        key: 'column3',
        name: 'Job ID',
        fieldName: 'supplierJobId',
        minWidth: 75
    },
    {
        ...commonColumnProps,
        key: 'column4',
        name: 'Type',
        fieldName: 'type',
        minWidth: 75
    },
    {
        ...commonColumnProps,
        key: 'column5',
        name: 'Total',
        fieldName: 'totalCount',
        minWidth: 20
    },
    {
        ...commonColumnProps,
        key: 'column6',
        name: 'Published',
        fieldName: 'reportingDate',
        minWidth: 60,
        onRender: (item: ISupplierTrend) => {
            return <span>{item.reportingDate?.toLocaleDateString()}</span>;
        }
    }
];

export const getLatestSupplierActivityByProgramTypeColumns = (): IColumn[] => [
    {
        ...commonColumnProps,
        key: 'column1',
        name: 'Supplier Name',
        fieldName: 'supplierName',
        minWidth: 110
    },
    {
        ...commonColumnProps,
        key: 'column2',
        name: 'Supplier ID',
        fieldName: 'supplierId',
        minWidth: 70
    },
    {
        ...commonColumnProps,
        key: 'column3',
        name: 'Program Type',
        fieldName: 'programType',
        minWidth: 110
    },
    {
        ...commonColumnProps,
        key: 'column4',
        name: 'Type',
        fieldName: 'type',
        minWidth: 70
    },
    {
        ...commonColumnProps,
        key: 'column5',
        name: 'Published On',
        fieldName: 'lastPublishedDate',
        minWidth: 85,
        onRender: (item: ISupplierActivity) => {
            return <span>{item.lastPublishedDate?.toLocaleDateString()}</span>;
        }
    }
];
