export interface IAssetDetails {
    assetTagNumber: string;
    assetTagNumber2: string;
    serialNumber: string;
    description: string;
    manufacturer: string;
    model: string;
    partNumber: string;
    quantity?: number;
    weightInKilos?: number;
    dataDeviceFormFactor: string;
    hddCount?: number;
    cpuSpeed: string;
    ramSize: string;
}

export class AssetDetails implements IAssetDetails {
    public assetTagNumber: string;
    public assetTagNumber2: string;
    public serialNumber: string;
    public description: string;
    public manufacturer: string;
    public model: string;
    public partNumber: string;
    public quantity?: number;
    public weightInKilos?: number;
    public dataDeviceFormFactor: string;
    public hddCount?: number;
    public cpuSpeed: string;
    public ramSize: string;

    constructor(jsonData: IAssetDetails) {
        this.assetTagNumber = jsonData.assetTagNumber;
        this.assetTagNumber2 = jsonData.assetTagNumber2;
        this.serialNumber = jsonData.serialNumber;
        this.description = jsonData.description;
        this.manufacturer = jsonData.manufacturer;
        this.model = jsonData.model;
        this.partNumber = jsonData.partNumber;
        this.quantity = jsonData.quantity;
        this.weightInKilos = jsonData.weightInKilos;
        this.dataDeviceFormFactor = jsonData.dataDeviceFormFactor;
        this.hddCount = jsonData.hddCount;
        this.cpuSpeed = jsonData.cpuSpeed;
        this.ramSize = jsonData.ramSize;
    }
}
