import React, { useState, useCallback, useEffect, useMemo } from 'react';
import {
    DetailsList,
    DefaultPalette,
    IColumn,
    ComboBox,
    IComboBox,
    IComboBoxOption,
    SelectableOptionMenuItemType,
    DefaultButton,
    SelectionMode,
    DetailsListLayoutMode,
    ConstrainMode,
    Stack,
    Text,
    TextField,
    Link,
    Image,
    Spinner,
    SpinnerSize
} from '@fluentui/react';
import {
    IChartProps,
    IChartDataPoint
} from '@uifabric/charting';
import { 
    commonStyles,
    stackTokens,
    comboBoxStyles,
    textFieldStyles 
} from '../../common/common.styles';
import { ICommonPageProps } from '../../common/common.types';
import {
    commonColumnProps,
    useMountEffect,
    colors,
    nextColorIndex,
    sortOnColumn,
    ColumnsAndItems,
    resetColumnSorting,
    assignOnColumnClick
} from '../../common/common.func';
import { clearErrorByIndex, ErrorBar } from '../../components/ErrorBar/ErrorBar';
import { CarbonFootprint } from '../../models/carbonFootprint/carbonFootprint';
import { appConstants } from '../../common/appConstants';
import { CarbonFootprintCategory } from '../../models/carbonFootprint/carbonFootprintCategory';
import { pageStyles/* , totalKilogramsPerAttributeHorizontalBarChartStyles */ } from './CarbonFootprintPage.styles';
import { IApiLoadCarbonFootprint, IApiLoadCarbonFootprintCategories, loadCarbonFootprint, loadCarbonFootprintCategories, resetApiCallState } from '../../store/actions/pageActions/carbonFootprintPage.action';
import { CallApiState } from '../../store/actions/generic.action';
import { useAppSelector, useAppDispatch } from '../../store/hooks';
import { PageWrapper } from '../../components/PageWrapper/PageWrapper';

interface IPageProps extends ICommonPageProps {
}

export const CarbonFootprintPage: React.FunctionComponent<IPageProps> = (props: IPageProps): JSX.Element => {
    const [errors, setErrors] = useState<string[]>([]);
    const [carbonFootprint, setCarbonFootprint] = useState<CarbonFootprint[] | undefined | null>();
    
    // Redux store selectors to get state from the store when it changes.
    const apiLoadCarbonFootprintCategories: IApiLoadCarbonFootprintCategories =
        useAppSelector<IApiLoadCarbonFootprintCategories>((state) => state.carbonFootprintPageReducer.apiLoadCarbonFootprintCategories);
    const apiLoadCarbonFootprint: IApiLoadCarbonFootprint =
        useAppSelector<IApiLoadCarbonFootprint>((state) => state.carbonFootprintPageReducer.apiLoadCarbonFootprint);

    // Redux store dispatch to send actions to the store.
    const dispatch = useAppDispatch();

    const nothingSelectedOption: IComboBoxOption = useMemo<IComboBoxOption>(() => {
        return {
            key: 0,
            text: appConstants.nothingSelected
        } as IComboBoxOption
    }, []);

    const [unitQuantity, setUnitQuantity] = useState<number>(1);
    const [categoriesDropdownOptions, setCategoriesDropdownOptions] = useState<IComboBoxOption[]>([nothingSelectedOption]);
    const [selectedCategory, setSelectedCategory] = useState<IComboBoxOption>(nothingSelectedOption);
    // const [totalKilogramsPerAttributeHorizontalBarChartData, setTotalKilogramsPerAttributeHorizontalBarChartData] = useState<IChartProps[]>();
    // const [impactFactorMultiStackedBarChartData, setImpactFactorMultiStackedBarChartData] = useState<IChartProps[]>();

    /**
     * This effect does nothing on mount, but will return a cleanup function that runs when the component unmounts.
     */
    useEffect(() => {
        return function cleanup() {
            resetApiCallState();
        }
    }, []);
    
    /**
     * Handle error.
     * @param errMsg Error message.
     */
    const handleError = useCallback((errMsg: string) => {
        setErrors((prevErrors) => {
            // This will prevent the same error from being displayed if already displayed.
            // ex: Multiple page data load failures might occur if the api is not working,
            // and this page makes multiple load calls for various data.
            if (!prevErrors.includes(errMsg)) {
                return [...prevErrors, errMsg];
            }
            return prevErrors;
        });
    }, []);

    /**
     * Effect for when errors occur in any api call.
     */
    useEffect(() => {
        if (apiLoadCarbonFootprintCategories.errMsg) {
            handleError(apiLoadCarbonFootprintCategories.errMsg);
        }
        if (apiLoadCarbonFootprint.errMsg) {
            handleError(apiLoadCarbonFootprint.errMsg);
        }
    }, [handleError, apiLoadCarbonFootprint.errMsg, apiLoadCarbonFootprintCategories.errMsg]);

    /**
     * Effect for when carbon footprint categories data is loaded.
     */
    useEffect(() => {
        if (apiLoadCarbonFootprintCategories.callApiState === CallApiState.DataAvailable) {
            const categories: CarbonFootprintCategory[] = apiLoadCarbonFootprintCategories.carbonFootprintCategories || [];

            const extractedCategoriesDropdownOptions: IComboBoxOption[] = categories.map<IComboBoxOption>((item: CarbonFootprintCategory) => {
                return {
                    key: item.category,
                    text: item.category,
                    itemType: SelectableOptionMenuItemType.Normal
                };
            });

            setCategoriesDropdownOptions([
                ...extractedCategoriesDropdownOptions
            ]);

            // Auto-select the first option.
            setSelectedCategory(extractedCategoriesDropdownOptions.length > 0 ? extractedCategoriesDropdownOptions[0] : nothingSelectedOption);
        }
    }, [apiLoadCarbonFootprintCategories.callApiState, apiLoadCarbonFootprintCategories.carbonFootprintCategories, nothingSelectedOption])

    /**
     * This effect is run once during page load.
     */
    useMountEffect(() => {
        dispatch(loadCarbonFootprintCategories());
    });

    /**
     * Get the data point for the total kilograms per attribute horizontal bar chart.
     */
    const getTotalKilogramsPerAttributeDataPoint = (item: CarbonFootprint, highestAmount: number, colorIndex: number): IChartProps => {
        return {
            chartTitle: item.attribute,
            chartData: [
                {
                    legend: item.attribute,
                    horizontalBarChartdata: { 
                        x: item.amountInKG,
                        y: highestAmount
                    },
                    color: colors[nextColorIndex(colorIndex)],
                    xAxisCalloutData: `${item.attribute} ${item.type} ${item.factor}`,
                    yAxisCalloutData: `${item.amountInKG} kgs`
                }
            ]
        }
    };

    /**
     * Get the data point for the total kilograms per attribute by impact factor multi stacked bar chart.
     */
    const getImpactFactorDataPoint = (item: CarbonFootprint, colorIndex: number): IChartDataPoint => {
        return {
            legend: item.attribute,
            data: item.amountInKG,
            color: colors[nextColorIndex(colorIndex)],
            xAxisCalloutData: `${item.attribute} ${item.type} ${item.factor}`,
            yAxisCalloutData: `${item.amountInKG} kgs`
        }
    }

    /**
     * Build charts.
     */
    const buildCharts = useCallback((): void => {
        // setTotalKilogramsPerAttributeHorizontalBarChartData(undefined);
        // setImpactFactorMultiStackedBarChartData(undefined);        

        const totalKilogramsPerAttributeChartData: IChartProps[] = [];
        const impactFactorChartData: IChartProps[] = [];

        // An array of IChartDataPoint[]. Each IChartDataPoint[] is one of the multiple charts. Each IChartDataPoint is a segment in the chart.
        const impactFactorChartDataPoints: IChartDataPoint[][] = [];
        const dataClone: CarbonFootprint[] = JSON.parse(JSON.stringify(apiLoadCarbonFootprint.carbonFootprint));

        // Sort the data by amount in kg.
        dataClone.sort((a: CarbonFootprint, b: CarbonFootprint) => {
            const primaryA: number = a.amountInKG;
            const primaryB: number = b.amountInKG;

            // First, sort by the primary.
            // If A > B, move the item down. If A < B, move the item up.
            if (primaryA !== primaryB) {
                return (primaryA > primaryB) ? -1 : 1;
            }

            return 0; // If the primary valies match, keep the item in place.
        });

        const highestAmountInKg: number = dataClone[0].amountInKG;

        const startingColorIndex: number = colors.findIndex(color => color === DefaultPalette.blueLight);
        let currentColorIndex: number = startingColorIndex < 1 ? -1 : startingColorIndex - 1;

        for (let i: number = 0; i < dataClone.length; i++) {
            const item: CarbonFootprint = dataClone[i];
            const impactFactorText: string = `${item.type} ${item.factor}`;

            const totalKilogramsPerAttributeDataPoint: IChartProps = getTotalKilogramsPerAttributeDataPoint(item, highestAmountInKg, currentColorIndex);
            const impactFactorDataPoint: IChartDataPoint = getImpactFactorDataPoint(item, currentColorIndex);
            
            totalKilogramsPerAttributeChartData.push(totalKilogramsPerAttributeDataPoint);

            // If the item's criteria doesn't have a IChartDataPoint[] chart yet, create one.
            const foundImpactFactorChart: IChartDataPoint[][] = impactFactorChartDataPoints.filter(chart => (chart[0].xAxisCalloutData)?.includes(impactFactorText));
            if (foundImpactFactorChart.length === 0) {
                impactFactorChartDataPoints.push([impactFactorDataPoint]);
                impactFactorChartData.push({
                    chartTitle: impactFactorText,
                    chartData: impactFactorChartDataPoints[impactFactorChartDataPoints.length - 1]
                })
            } else {
                foundImpactFactorChart[0].push(impactFactorDataPoint); // This also updates the value within impactFactorChartDataPoints.
            }

            currentColorIndex = currentColorIndex < colors.length ? currentColorIndex + 1 : 0;
        }

        // Add placeholder values at the end of each impact factor multi stacked chart.
        // Without a placeholder added, the MultiStackedBarChart displays a "0/0" text if the total amount is 0, for some odd reason.
        for (let i: number = 0; i < impactFactorChartDataPoints.length; i++) {
            impactFactorChartDataPoints[i].push({
                data: .0000,
                placeHolder: true
            })
        }

        // setTotalKilogramsPerAttributeHorizontalBarChartData(totalKilogramsPerAttributeChartData);
        // setImpactFactorMultiStackedBarChartData(impactFactorChartData);
    }, [apiLoadCarbonFootprint.carbonFootprint]);

    /**
     * Calculate clicked event handler.
     */
    const calculateClicked = async () => {
        // If the value is 0 or empty string for any dropdown or input, then that is falsey
        // and will result in the variable being set to undefined below.
        const unitCategory: string | undefined = selectedCategory.key ? String(selectedCategory.key) : undefined;
        const unitCount: number | undefined = unitQuantity ? Number(unitQuantity) : undefined;

        console.log(`Calculate clicked. Category: ${unitCategory}, Unit Quantity: ${unitCount}.`);

        dispatch(loadCarbonFootprint(unitCategory, unitCount));
    };

    /**
     * Category on change event handler.
     * @param event Form event.
     * @param option ComboBox option.
     * @param index The index of the selected combo box option. Undefined if the option is manually submitted.
     * @param value The value of a manually submitted option. Undefined if the option already exists in the combo box.
     */
    const onCategoryComboBoxChange = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string): void => {
        if (option) {
            setSelectedCategory(option);
        }
        // We are not appending any manually submitted options to the combo box.
        // If an option is manually submitted, the currently selected option is kept.
    };

    /**
     * Unit quantity on change event handler.
     * @param event The text field input event.
     * @param newValue The new value entered into the text field.
     */
    const onUnitQuantityTextFieldChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setUnitQuantity(Number(newValue));
    }

    /**
     * The details list columns.
     */
    const [columns, setColumns] = useState<IColumn[]>(
        [
            {
                ...commonColumnProps,
                key: 'column1',
                name: 'Criteria',
                fieldName: 'type',
                // Setting both minWidth and maxWidth on these columns has the effect of them aligning better when
                // DetailsListLayoutMode.justified is also being used. The columns can still be resized (maxWidth doesn't stop that).
                minWidth: 90,
                maxWidth: 90,
                isResizable: true,
                isRowHeader: true
            },
            {
                ...commonColumnProps,
                key: 'column2',
                name: 'Attribute (Averages per unit)',
                fieldName: 'attribute',
                minWidth: 160,
                maxWidth: 160,
                isResizable: true
            },
            {
                ...commonColumnProps,
                key: 'column3',
                name: 'Amount (lbs)',
                fieldName: 'amountInLB',
                minWidth: 90,
                maxWidth: 90,
                isResizable: true
            },
            {
                ...commonColumnProps,
                key: 'column4',
                name: 'Amount (kg)',
                fieldName: 'amountInKG',
                minWidth: 90,
                maxWidth: 90,
                isResizable: true
            },
            {
                ...commonColumnProps,
                key: 'column5',
                name: 'Impact',
                fieldName: 'factor',
                minWidth: 90,
                maxWidth: 90,
                isResizable: true
            }
        ]
    );

    /**
     * On column click event handler.
     * @param ev The mouse event.
     * @param column The column header clicked on.
     */
    const onColumnClick = useCallback((ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        if (carbonFootprint) {
            const columnsAndItems: ColumnsAndItems<CarbonFootprint> = sortOnColumn<CarbonFootprint>(column, columns, carbonFootprint, undefined);
            setCarbonFootprint(columnsAndItems.items);
            setColumns(columnsAndItems.columns);
        }
    }, [carbonFootprint, columns])

    /**
     * Effect to update onColumnClick handler. Otherwise the component state referenced in onColumnClick
     * would be stale from the render pass it was created on.
     */
    useEffect(() => {
        assignOnColumnClick(columns, onColumnClick);
    }, [columns, onColumnClick]);

    /**
     * Effect for when carbon footprint data is loaded.
     */
    useEffect(() => {
        if (apiLoadCarbonFootprint.callApiState === CallApiState.DataAvailable) {
            setCarbonFootprint(apiLoadCarbonFootprint.carbonFootprint);
            setColumns(resetColumnSorting(columns));
            buildCharts();
        }
    }, [buildCharts, columns, apiLoadCarbonFootprint.callApiState, apiLoadCarbonFootprint.carbonFootprint]);

    return (
        <PageWrapper {...props}>
            <ErrorBar errors={errors} onDismiss={(index: number) => {
                setErrors(clearErrorByIndex(errors, index));
            }} />

            <div className="ms-Grid" dir="ltr">
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <div className={commonStyles.pageHeader}>
                            <Text variant="xLarge" role="heading" aria-level={1}>Carbon Footprint Calculator</Text>
                        </div>
                    </div>
                </div>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                        <Stack tokens={stackTokens}>
                            <Stack.Item>
                                <Text className={pageStyles.carbonFootprintNote}>
                                    <b>Note:</b> Carbon footprint is historically defined as the total greenhouse gas emissions caused by an individual, event, organization, or product, expressed as carbon dioxide (CO2) equivalent. 
                                    Greenhouse gases, including the carbon-containing gases (CO2 and methane), can be emitted through the burning of metals, land clearance and the production of manufactured goods, electronics, transportation and other services.
                                </Text>
                            </Stack.Item>
                            <Stack.Item>
                                <Text variant="mediumPlus" role="heading" aria-level={2}>Calculate Carbon Footprint Impact</Text>
                            </Stack.Item>
                            <Stack.Item>
                                <Stack horizontal wrap tokens={stackTokens}>
                                    <ComboBox
                                        label="Category"
                                        options={categoriesDropdownOptions}
                                        className={`${comboBoxStyles} ${pageStyles.categoriesComboBoxStyles}`}
                                        disabled={apiLoadCarbonFootprintCategories.callApiState === CallApiState.Running}
                                        onChange={onCategoryComboBoxChange}
                                        selectedKey={selectedCategory.key}
                                        allowFreeform
                                        autoComplete="on"
                                        useComboBoxAsMenuWidth
                                    />
                                    <TextField
                                        label="Unit Quantity"
                                        placeholder="Quantity"
                                        styles={textFieldStyles}
                                        defaultValue={unitQuantity.toString()}
                                        onChange={onUnitQuantityTextFieldChange}>
                                    </TextField>
                                </Stack>
                            </Stack.Item>
                            <Stack.Item>
                                <DefaultButton
                                    className={pageStyles.calculateButton}
                                    text="Calculate"
                                    onClick={calculateClicked}
                                    disabled={apiLoadCarbonFootprint.callApiState === CallApiState.Running} />
                            </Stack.Item>
                        </Stack>
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                        <Stack tokens={stackTokens}>
                            <Stack.Item>
                                <Image src="/images/CarbonNegativePathway.png" width="100%" alt="Microsoft's pathway to carbon negative by 2030. Image displays a timeline showing that by 2030 the annual carbon emissions by Microsoft and its supply chain will become carbon negative." />
                            </Stack.Item>
                            <Stack.Item>
                                <Text variant="mediumPlus">
                                    <Link href="https://blogs.microsoft.com/blog/2020/01/16/microsoft-will-be-carbon-negative-by-2030/" target="_blank">
                                        Microsoft will be carbon negative by 2030.
                                    </Link>
                                </Text>
                            </Stack.Item>
                        </Stack>
                    </div>
                </div>

                { /*
                Removed charts from this UI due to accessibility bugs with Fluent charting controls. The accessibility
                bug relates to the screen reader not announcing labels when using keyboard navigation.

                Bugs:
                Bug 8581018: A11y_Recycling - Internal UI(PPE)_Carbon Footprint Calculator_Impact Factors_ScreenReader : In scan mode , when narrator is announcing the value of 'toxicity reduction' it is also announcing the previous 'metals recovered ' value .
                Bug 8588233: A11y_Recycling - Internal UI(PPE)_Carbon Footprint Calculator_Impact Factors_ScreenReader : while navigating in scan mode, narrator is not announcing anything for 'copper' and 'Aluminum' images .

                Commented out this feature. Can check later if a future version of "@uifabric/charting" has a fix for these issues.

                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                        {
                            (props.apiLoadCarbonFootprint.callApiState === CallApiState.Running ||
                                props.apiLoadCarbonFootprint.callApiState === CallApiState.Completed) && (
                                <Stack tokens={stackTokens}>
                                    <Stack.Item>
                                        <Text variant="mediumPlus" role="heading" aria-level={2}>Total Kilograms Per Attribute</Text>
                                    </Stack.Item>
                                    {props.apiLoadCarbonFootprint.callApiState === CallApiState.Running &&
                                        <Stack.Item>
                                            <Spinner size={SpinnerSize.medium} className={commonStyles.spinner} />
                                        </Stack.Item>
                                    }
                                    {props.apiLoadCarbonFootprint.callApiState === CallApiState.Completed && carbonFootprint && carbonFootprint.length > 0 
                                     && totalKilogramsPerAttributeHorizontalBarChartData &&
                                        <Stack.Item>
                                            <HorizontalBarChart
                                                data={totalKilogramsPerAttributeHorizontalBarChartData || []}
                                                styles={totalKilogramsPerAttributeHorizontalBarChartStyles}
                                            />
                                        </Stack.Item>
                                    }
                                </Stack>
                            )
                        }
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                        {
                            (props.apiLoadCarbonFootprint.callApiState === CallApiState.Running ||
                                props.apiLoadCarbonFootprint.callApiState === CallApiState.Completed) && (
                                <Stack tokens={stackTokens}>
                                    <Stack.Item>
                                        <Text variant="mediumPlus" role="heading" aria-level={2}>Impact Factors</Text>
                                    </Stack.Item>
                                    {props.apiLoadCarbonFootprint.callApiState === CallApiState.Running &&
                                        <Stack.Item>
                                            <Spinner size={SpinnerSize.medium} className={commonStyles.spinner} />
                                        </Stack.Item>
                                    }
                                    {props.apiLoadCarbonFootprint.callApiState === CallApiState.Completed && carbonFootprint && carbonFootprint.length > 0 
                                     && impactFactorMultiStackedBarChartData &&
                                        <Stack.Item>
                                            <MultiStackedBarChart data={impactFactorMultiStackedBarChartData || []} />
                                        </Stack.Item>
                                    }
                                </Stack>
                            )
                        }
                    </div>
                </div>
                */ }

                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        {
                            (apiLoadCarbonFootprint.callApiState === CallApiState.Running ||
                                apiLoadCarbonFootprint.callApiState === CallApiState.Completed) && (
                                <Stack tokens={stackTokens}>
                                    <Stack.Item>
                                        <Text variant="mediumPlus" role="heading" aria-level={2}>Carbon Footprint Impact</Text>
                                    </Stack.Item>
                                    <Stack.Item>
                                        {apiLoadCarbonFootprint.callApiState === CallApiState.Running &&
                                            <Spinner size={SpinnerSize.medium} className={commonStyles.spinner} />
                                        }
                                        {apiLoadCarbonFootprint.callApiState === CallApiState.Completed && carbonFootprint && carbonFootprint.length === 0 &&
                                            <Text className={pageStyles.carbonFootprintNote}>
                                                No carbon footprint data available for this category.
                                            </Text>
                                        }
                                        {apiLoadCarbonFootprint.callApiState === CallApiState.Completed && carbonFootprint && carbonFootprint.length > 0 &&
                                            <DetailsList
                                                items={apiLoadCarbonFootprint.callApiState === CallApiState.Completed && carbonFootprint ? carbonFootprint : []}
                                                compact={false}
                                                columns={columns}
                                                selectionMode={SelectionMode.none}
                                                getKey={(item: CarbonFootprint) => item.clientRowKey}
                                                setKey="none"
                                                layoutMode={DetailsListLayoutMode.justified}
                                                isHeaderVisible={true}
                                                constrainMode={ConstrainMode.unconstrained}
                                            />
                                        }
                                    </Stack.Item>
                                </Stack>
                            )
                        }
                    </div>
                </div>
            </div>
        </PageWrapper>
    );
};
