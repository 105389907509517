import { IStyle, mergeStyleSets } from '@fluentui/react';

/**
 * Styles used on this page.
 */
export const pageStyles = mergeStyleSets({
    diagnosticDisplay: {
        borderCollapse: 'collapse',
        tableLayout: 'fixed',
        width: '100%',
        marginBottom: '20px',
        marginRight: '14px',
        td: {
            border: '1px solid lightgray'
        },
        'td:first-child': {
            color: 'darkslateblue',
            fontSize: '16px',
            width: '110px',
            textAlign: 'center'
        },
        'td:last-child': {
            color: 'blue',
            overflow: 'auto'
        }
    } as IStyle,
    jsonBlock: {
        display: 'inline-block'
    } as IStyle
});
