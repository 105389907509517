import { AnyAction, Reducer } from 'redux';

export interface IAdminTabActiveCloseReducerState {
}

const initialAdminPageReducerState: IAdminTabActiveCloseReducerState = {
}

export const adminTabActiveCloseReducer: Reducer<IAdminTabActiveCloseReducerState, AnyAction> = (
    state: IAdminTabActiveCloseReducerState = initialAdminPageReducerState, action: AnyAction
): IAdminTabActiveCloseReducerState => {
    switch (action.type) {
        default:
    }

    return state;
}
