import { DbdDetails } from '../../../models/supplier/dbdDetails';
import { DbdImageLink } from '../../../models/supplier/dbdImageLink';
import { DbdReport } from '../../../models/supplier/dbdReport';
import { serviceApiClient } from '../../../services/api/serviceApiClient';
import { AppDispatch, reduxStore } from '../../reduxStore';
import * as actionTypes from '../actionTypes';
import { callApi, CallApiState, ICallApiBase } from '../generic.action';

/**
 * Load DBD action payload.
 */
export interface IApiLoadDbd extends ICallApiBase {
    dbdReport?: DbdReport | null;
}

/**
 * Load DBD data.
 * @param programType Program type.
 * @param supplierId Supplier id.
 * @param supplierJobId Supplier job id.
 * @param correlationId Correlation id.
 * @param supplierUnitId Supplier unit id.
 * @param supplierUnitType Supplier unit type.
 * @returns Redux dispatch function.
 */
export const callApiLoadDbd = (programType: string, supplierId: string, supplierJobId?: string, correlationId?: string, supplierUnitId?: string, supplierUnitType?: string): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<DbdReport | null>(
        actionTypes.API_LOAD_DBD_REPORT,
        async () => {
            return await serviceApiClient.dbd(programType, supplierId, supplierJobId, correlationId, supplierUnitId, supplierUnitType);
        },
        (payload: IApiLoadDbd, data) => {
            if (data && data.dbdDetails) {
                // Set the clientRowKey in each object so it can be bound into the DetailsList.
                data.dbdDetails.forEach((value: DbdDetails, index: number) => {
                    value.clientRowKey = index.toString()
                });
            }
            if (data && data.dbdImageLinks) {
                // Set the clientRowKey in each object so it can be bound into the DetailsList.
                data.dbdImageLinks.forEach((value: DbdImageLink, index: number) => {
                    value.clientRowKey = index.toString()
                });
            }

            payload.dbdReport = data;
        }
    );
};

/**
 * Download blob stream action payload.
 */
export interface IApiDownloadBlobStream extends ICallApiBase {
    blobStream?: string | null;
    item?: DbdImageLink;
}

/**
 * Download blob stream.
 * @param item Item to download.
 * @returns Redux dispatch function.
 */
export const callApiDownloadBlobStream = (item: DbdImageLink): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<string | null>(
        actionTypes.API_DOWNLOAD_BLOB_STREAM,
        async () => {
            return await serviceApiClient.downloadBlobStream(item.fileBlobUrl!);
        },
        (payload: IApiDownloadBlobStream, data) => {
            payload.blobStream = data;
            payload.item = item;
        }
    );
};

/**
 * Reset the page action call state for all api calls.
 * This clear all data from the Redux store for these calls, including any error state for failed calls.
 * This makes it so if there was a failed call and an error was shown on the page, if the user navigates
 * to another page and back, the prior error would not be shown.
 */
export const resetApiCallState = () => {
    const apiLoadDbd: IApiLoadDbd = {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        dbdReport: undefined
    };
    reduxStore.dispatch({
        type: actionTypes.API_LOAD_DBD_REPORT,
        payload: apiLoadDbd
    });

    const apiDownloadBlobStream: IApiDownloadBlobStream = {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        blobStream: undefined,
        item: undefined
    };
    reduxStore.dispatch({
        type: actionTypes.API_DOWNLOAD_BLOB_STREAM,
        payload: apiDownloadBlobStream
    });
};
