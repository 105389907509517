import { combineReducers } from 'redux';
import { configureStore, Reducer } from '@reduxjs/toolkit';
import { carbonFootprintPageReducer, ICarbonFootprintPageReducerState } from './reducers/pageReducers/carbonFootprintPage.reducer';
import { certificatesPageReducer, ICertificatesPageReducerState } from './reducers/pageReducers/certificatesPage.reducer';
import { connectedDataPageReducer, IConnectedDataPageReducerState } from './reducers/pageReducers/connectedDataPage.reducer';
import { dbdPageReducer as dbdReducer, IDbdPageReducerState } from './reducers/pageReducers/dbdPage.reducer';
import { homePageReducer, IHomePageReducerState } from './reducers/pageReducers/homePage.reducer';
import { IPaymentNotificationPageReducerState, paymentNotificationPageReducer } from './reducers/pageReducers/paymentNotificationPage.reducer';
import { IReportsPageReducerState, reportsPageReducer } from './reducers/pageReducers/reportsPage.reducer';
import { IInvoiceSummaryPageReducerState, invoiceSummaryPageReducer } from './reducers/pageReducers/invoiceSummaryPage.reducer';
import { ICreditSummaryPageReducerState, creditSummaryPageReducer } from './reducers/pageReducers/creditSummaryPage.reducer';
import { ITransportAndPackagingPageReducerState, transportAndPackagingPageReducer } from './reducers/pageReducers/transportAndPackagingPage.reducer';
import { adminTabActiveCloseReducer, IAdminTabActiveCloseReducerState } from './reducers/adminTabReducers/adminTabActiveClose.reducer';
import { adminTabDestructionTypesReducer, IAdminTabDestructionTypesReducerState } from './reducers/adminTabReducers/adminTabDestructionTypes.reducer';
import { adminTabUnitStatusesReducer, IAdminTabUnitStatusesReducerState } from './reducers/adminTabReducers/adminTabUnitStatuses.reducer'
import { adminTabPackagingMaterialTypesReducer, IAdminTabPackagingMaterialTypesReducerState } from './reducers/adminTabReducers/adminTabPackagingMaterialTypes.reducer';
import { adminTabProgramsReducer, IAdminTabProgramsReducerState } from './reducers/adminTabReducers/adminTabPrograms.reducer';
import { adminTabSupplierJobTypesReducer, IAdminTabSupplierJobTypesReducerState } from './reducers/adminTabReducers/adminTabSupplierJobTypes.reducer';
import { adminTabSuppliersReducer, IAdminTabSuppliersReducerState } from './reducers/adminTabReducers/adminTabSuppliers.reducer';
import { adminTabSupplierUnitTypesReducer, IAdminTabSupplierUnitTypesReducerState } from './reducers/adminTabReducers/adminTabSupplierUnitTypes.reducer';
import { adminTabUnitDispositionTypesReducer, IAdminTabUnitDispositionTypesReducerState } from './reducers/adminTabReducers/adminTabUnitDispositionTypes.reducer';
import { appReducer, IAppReducerState } from './reducers/app.reducer';

// Useful links:
// https://redux.js.org/
// https://react-redux.js.org/
// https://redux-toolkit.js.org/
// https://www.npmjs.com/package/redux-thunk
// https://github.com/reduxjs/redux-devtools

// This CombinedReducerStates type contains all the reducers used in the app.
export type CombinedReducerStates = {
    appReducer: Reducer<IAppReducerState>;
    // Page reducers
    homePageReducer: Reducer<IHomePageReducerState>;
    reportsPageReducer: Reducer<IReportsPageReducerState>;
    carbonFootprintPageReducer: Reducer<ICarbonFootprintPageReducerState>;
    certificatesPageReducer: Reducer<ICertificatesPageReducerState>;
    connectedDataPageReducer: Reducer<IConnectedDataPageReducerState>;
    paymentNotificationPageReducer: Reducer<IPaymentNotificationPageReducerState>;
    dbdPageReducer: Reducer<IDbdPageReducerState>;
    invoiceSummaryPageReducer: Reducer<IInvoiceSummaryPageReducerState>;
    creditSummaryPageReducer: Reducer<ICreditSummaryPageReducerState>;
    transportAndPackagingPageReducer: Reducer<ITransportAndPackagingPageReducerState>;
    // Admin page/tab reducers
    adminTabActiveCloseReducer: Reducer<IAdminTabActiveCloseReducerState>;
    adminTabDestructionTypesReducer: Reducer<IAdminTabDestructionTypesReducerState>;
    adminTabUnitStatusesReducer: Reducer<IAdminTabUnitStatusesReducerState>;
    adminTabPackagingMaterialTypesReducer: Reducer<IAdminTabPackagingMaterialTypesReducerState>;
    adminTabProgramsReducer: Reducer<IAdminTabProgramsReducerState>;
    adminTabSupplierJobTypesReducer: Reducer<IAdminTabSupplierJobTypesReducerState>;
    adminTabSuppliersReducer: Reducer<IAdminTabSuppliersReducerState>;
    adminTabSupplierUnitTypesReducer: Reducer<IAdminTabSupplierUnitTypesReducerState>;
    adminTabUnitDispositionTypesReducer: Reducer<IAdminTabUnitDispositionTypesReducerState>;
}

const combinedReducers: CombinedReducerStates = {
    appReducer: appReducer,
    // Page reducers
    homePageReducer: homePageReducer,
    reportsPageReducer: reportsPageReducer,
    carbonFootprintPageReducer: carbonFootprintPageReducer,
    certificatesPageReducer: certificatesPageReducer,
    connectedDataPageReducer: connectedDataPageReducer,
    paymentNotificationPageReducer: paymentNotificationPageReducer,
    dbdPageReducer: dbdReducer,
    invoiceSummaryPageReducer: invoiceSummaryPageReducer,
    creditSummaryPageReducer: creditSummaryPageReducer,
    transportAndPackagingPageReducer: transportAndPackagingPageReducer,
    // Admin page/tab reducers
    adminTabActiveCloseReducer: adminTabActiveCloseReducer,
    adminTabDestructionTypesReducer: adminTabDestructionTypesReducer,
    adminTabUnitStatusesReducer: adminTabUnitStatusesReducer,
    adminTabPackagingMaterialTypesReducer: adminTabPackagingMaterialTypesReducer,
    adminTabProgramsReducer: adminTabProgramsReducer,
    adminTabSupplierJobTypesReducer: adminTabSupplierJobTypesReducer,
    adminTabSuppliersReducer: adminTabSuppliersReducer,
    adminTabSupplierUnitTypesReducer: adminTabSupplierUnitTypesReducer,
    adminTabUnitDispositionTypesReducer: adminTabUnitDispositionTypesReducer
}

const rootReducer = combineReducers(combinedReducers);

export const reduxStore = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false
        })
});

// See: https://redux-toolkit.js.org/tutorials/typescript#define-root-state-and-dispatch-types
// Infer the `RootState` and `AppDispatch` types from the store itself.
export type RootState = ReturnType<typeof reduxStore.getState>;
export type AppDispatch = typeof reduxStore.dispatch;
