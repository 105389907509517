import { ReportProgram } from '../../../models/reports/reportProgram';
import { serviceApiClient } from '../../../services/api/serviceApiClient';
import { IReportsPageReducerState } from '../../reducers/pageReducers/reportsPage.reducer';
import { AppDispatch, reduxStore } from '../../reduxStore';
import * as actionTypes from '../actionTypes';
import { callApi, CallApiState, ICallApiBase, StateUpdateOption } from '../generic.action';

/**
 * Load reports action payload.
 */
export interface IApiLoadReports extends ICallApiBase {
    reportPrograms?: ReportProgram[] | null;
}

/**
 * Load reports.
 * @param reducerState Optional pass in existing reducer state. If the existing reducer state has the data already loaded
 * successfully then a dispatch will be done with the existing payload from the reducer state. This will prevent reloading
 * when not needed to reload.
 * @returns Redux dispatch function.
 */
export const loadReports = (reducerState?: IReportsPageReducerState): (dispatch: AppDispatch) => Promise<void> | void => {
    if (reducerState && reducerState.apiLoadReports.reportPrograms && reducerState.apiLoadReports.callApiState === CallApiState.Completed) {
        return (dispatch: AppDispatch) => {
            dispatch({
                type: actionTypes.API_LOAD_REPORTS,
                payload: reducerState.apiLoadReports
            })
        }
    } else {
        return callApi<ReportProgram[]>(
            actionTypes.API_LOAD_REPORTS,
            async () => {
                const reportPrograms: ReportProgram[] = await serviceApiClient.reports() || [];
                if (reportPrograms.length === 0) {
                    return reportPrograms;
                }
                // Load security group membership for each report.
                const promises: any = [];
                reportPrograms.forEach(async (reportProgram) => {
                    const p: Promise<boolean> = serviceApiClient.sgCheck(reportProgram.sgName);
                    promises.push(p);
                    reportProgram.isMember = await p;
                });
                await Promise.all(promises);
                return reportPrograms;
            },
            (payload: IApiLoadReports, data) => {
                payload.reportPrograms = data;
            }
        );
    }
};

/**
 * Reset the page action call state for all api calls.
 * This clear all data from the Redux store for these calls, including any error state for failed calls.
 * This makes it so if there was a failed call and an error was shown on the page, if the user navigates
 * to another page and back, the prior error would not be shown.
 * @param stateUpdateOption State update option.
 */
export const resetApiCallState = (stateUpdateOption: StateUpdateOption) => {
    const apiLoadReports: IApiLoadReports = {
        callApiState: CallApiState.Initial,
        stateUpdateOption: stateUpdateOption,
        errMsg: undefined,
        reportPrograms: undefined
    };
    reduxStore.dispatch({
        type: actionTypes.API_LOAD_REPORTS,
        payload: apiLoadReports
    });
};
