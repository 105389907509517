import axios, { AxiosRequestConfig } from 'axios';

/**
 * Interface that should match the structure of the environment config file.
 */
export interface IAppConfig {
    settings: {
        environmentName: string;
        displayEnvNameInHeader: boolean;
        displayDiagnosticsPage: boolean;
        invoiceSlaDays: number;
        creditSlaDays: number;
        dispositionSlaDays: number;
        collectionSlaDays: number;
    };
    copilot: {
        copilotWebComponentUrl: string;
        copilotApiBaseUrl: string;
        copilotApiScope: string;
        copilotAppId: string;
        fceAppInsightsInstrumentationKey: string;
    }
    ocv: {
        isProdEnv: boolean;
        appId: number;
    };
    instrumentation: {
        appInsightsInstrumentationKey: string;
    };
    service: {
        baseUrl: string;
        useLocalMockData: boolean;
        useLocalMockDataSimulatedDelay: number;
    };
    graph: {
        baseUrl: string;
    };
    msal: {
        clientId: string;
        authority: string;
        redirectUri: string;
        postLogoutRedirectUri: string;
        storeAuthStateInCookie: boolean;
        cacheLocation: 'localStorage' | 'sessionStorage' | undefined;
        graphOpenIdScope: string;
        graphUserReadScope: string;
        recyclingApiScope: string;
    };
    featureFlighting: {
        homePageSupplierSummary: boolean;
        homePageSupplierApiInteractions: boolean;
        homePageSupplierTrends: boolean;
        homePageLatestSupplierActivityByProgramType: boolean;
        displayAdministrationPage: boolean;
        copilot: boolean;
    };
    build: string;
}

class AppConfig {
    private config?: IAppConfig = undefined;
    private afterLoadCallbacks: (() => void)[] = [];

    /**
     * Gets the current config.
     */
    public get current(): IAppConfig {
        if (!this.config) {
            throw new Error('App config is undefined.');
        }
        return (this.config as unknown) as IAppConfig;
    }

    /**
     * Loads config json file for current environment.
     * Uses environment variable set in environment files such as .env.development and .env.production.
     * See: https://create-react-app.dev/docs/adding-custom-environment-variables
     * Example value: REACT_APP_UI_ENV=dev
     * Note that when running the project using 'npm start' this will cause the .env.development to be used
     * while building with npm run build will cause the .env.production to be used.
     * Based on this environment variable, this function will load the corresponding app config json file
     * from the /public/config folder (such as dev.json or prod.json).
     */
    public async loadConfig(): Promise<void> {
        const env: string = process.env['REACT_APP_UI_ENV']?.trim() || '';

        const config: AxiosRequestConfig = {
            headers: { 'Content-Type': 'application/json' }
        }

        try {
            const response = await axios.get(`/config/${env}.json`, config);
            this.config = response.data as IAppConfig;
        } catch (error) {
            // If the response is not valid json then the response.json() will throw an exception. This could happen
            // if the config json didn't exist on the web server, in which case the React app will be loaded with that
            // path as the page route. For example, if the file ${envConfigFile}.json is not in the public folder then
            // the attempted route would be (example): https://siteroot/config/configfilemissing.json).
            // Of which the page is html and not json, thus the response.json() will throw an exception.
            console.error(error);
            throw new Error(`Failed to load config. ${JSON.stringify(error)}`);
        }

        // If the local storage useLocalMockData is true, then override what is set in the config file.
        // The diagnostics page has a feature to toggle the mock data setting.
        // If the diagnostics page is not shown, then always set the useLocalMockData to false.
        const storedUseLocalMockData: string | null = window.localStorage.getItem('useLocalMockData');
        if (storedUseLocalMockData === 'true' && this.config.settings.displayDiagnosticsPage) {
            this.config.service.useLocalMockData = true;
        } else if (storedUseLocalMockData === 'false' || !this.config.settings.displayDiagnosticsPage) {
            this.config.service.useLocalMockData = false;
        }

        this.callRegisteredCallbacks();
    }

    /**
     * Register a callback to be called after config is loaded.
     * If config is already loaded, callback will be called immediately.
     * @param afterLoadCallback Callback function to be called after config is loaded.
     */
    public async registerConfigLoadedCallback(afterLoadCallback: () => void) {
        if (this.config) {
            afterLoadCallback();
        } else {
            this.afterLoadCallbacks.push(afterLoadCallback);
        }
    }

    /**
     * Calls registered callback functions.
     */
    private callRegisteredCallbacks(): void {
        this.afterLoadCallbacks.forEach(callback => {
            callback();
        });

        // Reset the array.
        this.afterLoadCallbacks = [];
    }
}

/**
 * AppConfig singleton instance.
 */
export const appConfig: AppConfig = new AppConfig();
