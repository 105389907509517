import { AnyAction, Reducer } from 'redux';
import * as actionTypes from '../../actions/actionTypes';
import { CallApiState } from '../../actions/generic.action';
import {
    IApiLoadLatestSupplierActivityByProgramType,
    IApiLoadSupplierApiInteractions,
    IApiLoadSupplierTrends,
    IApiLoadTotalJobsReported
} from '../../actions/pageActions/homePage.action';

export interface IHomePageReducerState {
    apiLoadTotalJobsReported: IApiLoadTotalJobsReported;
    apiLoadSupplierApiInteractions: IApiLoadSupplierApiInteractions;
    apiLoadSupplierTrends: IApiLoadSupplierTrends;
    apiLoadLatestSupplierActivityByProgramType: IApiLoadLatestSupplierActivityByProgramType;
}

const initialHomePageReducerState: IHomePageReducerState = {
    apiLoadTotalJobsReported: {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        totalJobsReported: 0
    },
    apiLoadSupplierApiInteractions: {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        supplierApiInteractions: undefined
    },
    apiLoadSupplierTrends: {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        supplierTrends: undefined
    },
    apiLoadLatestSupplierActivityByProgramType: {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        latestSupplierActivityByProgramType: undefined
    }
};

export const homePageReducer: Reducer<IHomePageReducerState, AnyAction> = (
    state: IHomePageReducerState = initialHomePageReducerState, action: AnyAction
): IHomePageReducerState => {
    switch (action.type) {
        case actionTypes.API_LOAD_TOTAL_JOBS_REPORTED: {
            const payload: IApiLoadTotalJobsReported = action.payload as IApiLoadTotalJobsReported;
            return {
                ...state,
                apiLoadTotalJobsReported: { ...payload }
            }
        }
        case actionTypes.API_LOAD_SUPPLIER_API_INTERACTIONS: {
            const payload: IApiLoadSupplierApiInteractions = action.payload as IApiLoadSupplierApiInteractions;
            return {
                ...state,
                apiLoadSupplierApiInteractions: { ...payload }
            }
        }
        case actionTypes.API_LOAD_SUPPLIER_TRENDS: {
            const payload: IApiLoadSupplierTrends = action.payload as IApiLoadSupplierTrends;
            return {
                ...state,
                apiLoadSupplierTrends: { ...payload }
            }
        }
        case actionTypes.API_LOAD_LATEST_SUPPLIER_ACTIVITY_BY_PROGRAM_TYPE: {
            const payload: IApiLoadLatestSupplierActivityByProgramType = action.payload as IApiLoadLatestSupplierActivityByProgramType;
            return {
                ...state,
                apiLoadLatestSupplierActivityByProgramType: { ...payload }
            }
        }
        default:
    }

    return state;
};
