import {
    ContextualMenu,
    DialogType,
    IDialogContentProps,
    IIconProps,
    IModalProps,
    IStyle,
    ITooltipHostStyles,
    mergeStyleSets
} from '@fluentui/react';

/**
 * Styles used on this page.
 */
export const pageStyles = mergeStyleSets({
    dialogContainer: {
        height: '100%'
    } as IStyle,
    dbdSectionFieldsTable: {
        margin: '10px 20px 0 12px',
        borderCollapse: 'collapse',
        'td': {
            padding: '2px 4px 2px 4px',
            border: '.1px solid lightgray',
            minWidth: '100px'
        }
    } as IStyle,
    dbdSectionLabel: {
        fontWeight: '600'
    } as IStyle,
    dbdDetailsSection: {
        margin: '24px 0 0 0'
    } as IStyle,
    dbdImageSection: {
        margin: '24px 0 0 0'
    } as IStyle,
    pdfDocContainer: {
        overflow: 'scroll',
        height: '100%'
    } as IStyle,
    imageDocContainer: {
        height: '100%'
    } as IStyle,
    stackContainer: {
        height: '100%'
    } as IStyle,
    stackItemContainer: {
        height: '100%'
    } as IStyle,
    pageCount: {
        display: 'inline-block',
        fontSize: '14px',
        margin: '0 10px 0 10px'
    } as IStyle,
    errorMsg: {
        color: 'red'
    } as IStyle
});

export const documentDialogContentProps = (title: string): IDialogContentProps => {
    return ({
        type: DialogType.largeHeader,
        title: title,
        showCloseButton: true,
        styles: {
            content: {
                borderTop: '0px',
                height: '100%'
            } as IStyle,
            inner: {
                height: '100%'
            } as IStyle,
            innerContent: {
                height: 'calc(100% - 124px)'
            } as IStyle
        }
    });
}

export const documentDialogModelProps: IModalProps = {
    isBlocking: false,
    containerClassName: `${pageStyles.dialogContainer}`,
    dragOptions: {
        moveMenuItemText: 'Move',
        closeMenuItemText: 'Close',
        menu: ContextualMenu
    },
    styles: {
        root: {
            height: '100%'
        } as IStyle,
        main: {
            width: '80% !important',
            maxWidth: '80% !important', 
            height: '90% !important',
            maxHeight: '90% !important'
        } as IStyle
    }
};

/**
 * Used with document button.
 */
export const viewIcon: IIconProps = { iconName: 'OpenFile' };
export const documentTooltipHostStyles: Partial<ITooltipHostStyles> = {
     root: {
         display: 'inline-block',
         width: 'fit-content'
     } as IStyle
 };
 