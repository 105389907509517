import { AnyAction, Reducer } from 'redux';
import * as actionTypes from '../../actions/actionTypes';
import { IApiLoadDestructionTypes, IApiUpdateDestructionType, IApiAddDestructionType, IApiDeleteDestructionType } from '../../actions/adminTabActions/adminTabDestructionTypes.action';
import { CallApiState, StateUpdateOption } from '../../actions/generic.action';

export interface IAdminTabDestructionTypesReducerState {
    apiLoadDestructionTypes: IApiLoadDestructionTypes;
    apiUpdateDestructionType: IApiUpdateDestructionType;
    apiAddDestructionType: IApiAddDestructionType;
    apiDeleteDestructionType: IApiDeleteDestructionType;
}

const initialAdminTabSupplierReducerState: IAdminTabDestructionTypesReducerState = {
    apiLoadDestructionTypes: {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        destructionTypes: undefined
    },
    apiUpdateDestructionType: {
        callApiState: CallApiState.Initial,
        errMsg: undefined
    },
    apiAddDestructionType: {
        callApiState: CallApiState.Initial,
        errMsg: undefined
    },
    apiDeleteDestructionType: {
        callApiState: CallApiState.Initial,
        errMsg: undefined
    }
};

export const adminTabDestructionTypesReducer: Reducer<IAdminTabDestructionTypesReducerState, AnyAction> = (
    state: IAdminTabDestructionTypesReducerState = initialAdminTabSupplierReducerState, action: AnyAction
): IAdminTabDestructionTypesReducerState => {
    switch (action.type) {
        case actionTypes.API_LOAD_DESTRUCTION_TYPES: {
            const payload: IApiLoadDestructionTypes = action.payload as IApiLoadDestructionTypes;
            switch (payload.stateUpdateOption) {
                case StateUpdateOption.ErrorOnly:
                    return {
                        ...state,
                        // Keep the prior payload except for the errMsg which will be set to undefined.
                        apiLoadDestructionTypes: {
                            ...state.apiLoadDestructionTypes,
                            errMsg: undefined
                        }
                    }
                default:
                    return {
                        ...state,
                        apiLoadDestructionTypes: { ...payload }
                    }
            }
        }
        case actionTypes.API_UPDATE_DESTRUCTION_TYPE: {
            const payload: IApiUpdateDestructionType = action.payload as IApiUpdateDestructionType;
            switch (payload.stateUpdateOption) {
                case StateUpdateOption.ErrorOnly:
                    return {
                        ...state,
                        // Keep the prior payload except for the errMsg which will be set to undefined.
                        apiUpdateDestructionType: {
                            ...state.apiUpdateDestructionType,
                            errMsg: undefined
                        }
                    }
                default:
                    return {
                        ...state,
                        apiUpdateDestructionType: { ...payload }
                    }
            }
        }
        case actionTypes.API_ADD_DESTRUCTION_TYPE: {
            const payload: IApiAddDestructionType = action.payload as IApiAddDestructionType;
            switch (payload.stateUpdateOption) {
                case StateUpdateOption.ErrorOnly:
                    return {
                        ...state,
                        // Keep the prior payload except for the errMsg which will be set to undefined.
                        apiAddDestructionType: {
                            ...state.apiAddDestructionType,
                            errMsg: undefined
                        }
                    }
                default:
                    return {
                        ...state,
                        apiAddDestructionType: { ...payload }
                    }
            }
        }
        case actionTypes.API_DELETE_DESTRUCTION_TYPE: {
            const payload: IApiDeleteDestructionType = action.payload as IApiDeleteDestructionType;
            switch (payload.stateUpdateOption) {
                case StateUpdateOption.ErrorOnly:
                    return {
                        ...state,
                        // Keep the prior payload except for the errMsg which will be set to undefined.
                        apiDeleteDestructionType: {
                            ...state.apiDeleteDestructionType,
                            errMsg: undefined
                        }
                    }
                default:
                    return {
                        ...state,
                        apiDeleteDestructionType: { ...payload }
                    }
            }
        }
        default:
    }

    return state;
};
