import { AnyAction, Reducer } from 'redux';
import * as actionTypes from '../../actions/actionTypes';
import { CallApiState, StateUpdateOption } from '../../actions/generic.action';
import { IApiLoadInvoiceSummary } from '../../actions/pageActions/invoiceSummaryPage.action';

export interface IInvoiceSummaryPageReducerState {
    apiLoadInvoiceSummary: IApiLoadInvoiceSummary;
}

const initialInvoiceSummaryPageReducerState: IInvoiceSummaryPageReducerState = {
    apiLoadInvoiceSummary: {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        invoiceSummaries: undefined
    }
};

export const invoiceSummaryPageReducer: Reducer<IInvoiceSummaryPageReducerState, AnyAction> = (
    state: IInvoiceSummaryPageReducerState = initialInvoiceSummaryPageReducerState, action: AnyAction
): IInvoiceSummaryPageReducerState => {
    switch (action.type) {
        case actionTypes.API_LOAD_INVOICE_SUMMARY: {
            const payload: IApiLoadInvoiceSummary = action.payload as IApiLoadInvoiceSummary;
            switch (payload.stateUpdateOption) {
                case StateUpdateOption.ErrorOnly:
                    return {
                        ...state,
                        // Keep the prior payload except for the errMsg which will be set to undefined.
                        apiLoadInvoiceSummary: {
                            ...state.apiLoadInvoiceSummary,
                            errMsg: undefined
                        }
                    }
                default:
                    return {
                        ...state,
                        apiLoadInvoiceSummary: { ...payload }
                    }
            }
        }
        default:
    }

    return state;
};
