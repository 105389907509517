import { PeripheralUser } from './peripheralUser';

export interface IPeripheralHistory {
    assetTag: string;
    serialNumber: string;
    peripheralType: string;
    donators: PeripheralUser[];
    takers: PeripheralUser[];
}

export class PeripheralHistory implements IPeripheralHistory {
    public assetTag: string;
    public serialNumber: string;
    public peripheralType: string;
    public donators: PeripheralUser[] = [];
    public takers: PeripheralUser[] = [];

    // Below are properties NOT returned from the server but are used for client side purposes.
    public clientRowKey: string = '';

    constructor(jsonData: IPeripheralHistory) {
        this.assetTag = jsonData.assetTag;
        this.serialNumber = jsonData.serialNumber;
        this.peripheralType = jsonData.peripheralType;

        for (let i: number = 0; i < jsonData.donators.length; i++) {
            this.donators.push(new PeripheralUser(jsonData.donators[i]));
        }

        for (let i: number = 0; i < jsonData.takers.length; i++) {
            this.takers.push(new PeripheralUser(jsonData.takers[i]));
        }
    }
}