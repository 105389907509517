export interface IPeripheralUser {
    alias: string;
    date: Date;
}

export class PeripheralUser implements IPeripheralUser {
    public alias: string;
    public date: Date;

    constructor(jsonData: IPeripheralUser) {
        this.alias = jsonData.alias;
        this.date = new Date(jsonData.date);
    }
}