export interface ISupplierJobType {
    partitionKey: string;
    rowKey: string;
    supplierJobType: string;
}

export class SupplierJobType implements ISupplierJobType {
    public partitionKey: string;
    public rowKey: string;
    public supplierJobType: string;

    // Below are properties NOT returned from the server but are used for client side purposes.
    public clientRowKey: string = '';
    public isNew?: boolean;

    constructor(jsonData: ISupplierJobType) {
        this.partitionKey = jsonData.partitionKey;
        this.rowKey = jsonData.rowKey;
        this.supplierJobType = jsonData.supplierJobType;
    }
}
