export interface MonthMap {
    month: string;
    index: number;
}

export const month = {
    jan: 'January',
    feb: 'February',
    mar: 'March',
    apr: 'April',
    may: 'May',
    jun: 'June',
    jul: 'July',
    aug: 'August',
    sep: 'September',
    oct: 'October',
    nov: 'November',
    dec: 'December'
}

export const quarterMonths = {
    q1Months: [{ month: month.jul, index: 7 }, { month: month.aug, index: 8 }, { month: month.sep, index: 9 }],
    q2Months: [{ month: month.oct, index: 10 }, { month: month.nov, index: 11 }, { month: month.dec, index: 12 }],
    q3Months: [{ month: month.jan, index: 1 }, { month: month.feb, index: 2 }, { month: month.mar, index: 3 }],
    q4Months: [{ month: month.apr, index: 4 }, { month: month.may, index: 5 }, { month: month.jun, index: 6 }]
}

export const fiscalQuarter = {
    q1: 'Q1',
    q2: 'Q2',
    q3: 'Q3',
    q4: 'Q4'
}
