import { AnyAction, Reducer } from 'redux';
import * as actionTypes from '../../actions/actionTypes';
import {
    IApiLoadCarbonFootprintCategories,
    IApiLoadCarbonFootprint
} from '../../actions/pageActions/carbonFootprintPage.action';
import { CallApiState } from '../../actions/generic.action';

export interface ICarbonFootprintPageReducerState {
    apiLoadCarbonFootprintCategories: IApiLoadCarbonFootprintCategories;
    apiLoadCarbonFootprint: IApiLoadCarbonFootprint;
}

const initialCarbonFootprintPageReducerState: ICarbonFootprintPageReducerState = {
    apiLoadCarbonFootprintCategories: {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        carbonFootprintCategories: undefined
    },
    apiLoadCarbonFootprint: {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        carbonFootprint: undefined
    }
};

export const carbonFootprintPageReducer: Reducer<ICarbonFootprintPageReducerState, AnyAction> = (
    state: ICarbonFootprintPageReducerState = initialCarbonFootprintPageReducerState, action: AnyAction
): ICarbonFootprintPageReducerState => {
    switch (action.type) {
        case actionTypes.API_LOAD_CARBON_FOOTPRINT_CATEGORIES: {
            const payload: IApiLoadCarbonFootprintCategories = action.payload as IApiLoadCarbonFootprintCategories;
            return {
                ...state,
                apiLoadCarbonFootprintCategories: { ...payload }
            }
        }
        case actionTypes.API_LOAD_CARBON_FOOTPRINT: {
            const payload: IApiLoadCarbonFootprint = action.payload as IApiLoadCarbonFootprint;
            return {
                ...state,
                apiLoadCarbonFootprint: { ...payload }
            }
        }
        default:
    }

    return state;
};
