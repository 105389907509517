import { IStyle, mergeStyleSets } from '@fluentui/react';

/**
 * Styles used on this page.
 */
export const pageStyles = mergeStyleSets({
    detailsList: {
        '.ms-DetailsHeader': {
            paddingTop: '0px'
        }
    } as IStyle
});
