import { SupplierActivity } from '../../../models/supplier/supplierActivity';
import { SupplierApiInteraction } from '../../../models/supplier/supplierApiInteraction';
import { SupplierTrend } from '../../../models/supplier/supplierTrend';
import { serviceApiClient } from '../../../services/api/serviceApiClient';
import { AppDispatch, reduxStore } from '../../reduxStore';
import * as actionTypes from '../actionTypes';
import { callApi, CallApiState, ICallApiBase } from '../generic.action';

/**
 * Load total jobs reported action payload.
 */
export interface IApiLoadTotalJobsReported extends ICallApiBase {
    totalJobsReported?: number | null;
}

/**
 * Load total jobs reported.
 * @param startDateUtc Start date utc.
 * @param endDateUtc end date utc.
 * @returns Redux dispatch function.
 */
export const callApiLoadTotalJobsReported = (startDateUtc: Date, endDateUtc: Date): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<number>(
        actionTypes.API_LOAD_TOTAL_JOBS_REPORTED,
        async () => {
            return await serviceApiClient.totalJobsReported(startDateUtc, endDateUtc);
        },
        (payload: IApiLoadTotalJobsReported, data) => {
            payload.totalJobsReported = data;
        }
    );
};

/**
 * Load supplier api interactions action payload.
 */
export interface IApiLoadSupplierApiInteractions extends ICallApiBase {
    supplierApiInteractions?: SupplierApiInteraction[] | null;
}

/**
 * Load supplier api interactions.
 * @param startDateUtc Start date utc.
 * @param endDateUtc End date utc.
 * @returns Redux dispatch function.
 */
export const callApiLoadSupplierApiInteractions = (startDateUtc: Date, endDateUtc: Date): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<SupplierApiInteraction[]>(
        actionTypes.API_LOAD_SUPPLIER_API_INTERACTIONS,
        async () => {
            return await serviceApiClient.supplierApiInteractions(startDateUtc, endDateUtc) || [];
        },
        (payload: IApiLoadSupplierApiInteractions, data) => {
            if (data) {
                // Set the clientRowKey in each object so it can be bound into the DetailsList.
                data.forEach((value: SupplierApiInteraction, index: number) => {
                    value.clientRowKey = index.toString()
                });
            }

            payload.supplierApiInteractions = data;
        }
    );
};

/**
 * Load supplier trends action payload.
 */
export interface IApiLoadSupplierTrends extends ICallApiBase {
    supplierTrends?: SupplierTrend[] | null;
}

/**
 * Load supplier trends.
 * @param startDateUtc Start date utc.
 * @param endDateUtc End date utc.
 * @returns Redux dispatch function.
 */
export const callApiLoadSupplierTrends = (startDateUtc: Date, endDateUtc: Date): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<SupplierTrend[]>(
        actionTypes.API_LOAD_SUPPLIER_TRENDS,
        async () => {
            return await serviceApiClient.supplierTrends(startDateUtc, endDateUtc) || [];
        },
        (payload: IApiLoadSupplierTrends, data) => {
            if (data) {
                // Set the clientRowKey in each object so it can be bound into the DetailsList.
                data.forEach((value: SupplierTrend, index: number) => {
                    value.clientRowKey = index.toString()
                });
            }

            payload.supplierTrends = data;
        }
    );
};

/**
 * Load latest supplier activity by program type action payload.
 */
export interface IApiLoadLatestSupplierActivityByProgramType extends ICallApiBase {
    latestSupplierActivityByProgramType?: SupplierActivity[] | null;
}

/**
 * Load latest supplier activity by program type.
 * @param startDateUtc Start date utc.
 * @param endDateUtc End date utc.
 * @returns Redux dispatch function.
 */
export const callApiLoadLatestSupplierActivityByProgramType = (startDateUtc: Date, endDateUtc: Date): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<SupplierActivity[]>(
        actionTypes.API_LOAD_LATEST_SUPPLIER_ACTIVITY_BY_PROGRAM_TYPE,
        async () => {
            return await serviceApiClient.latestSupplierActivityByProgramType(startDateUtc, endDateUtc) || [];
        },
        (payload: IApiLoadLatestSupplierActivityByProgramType, data) => {
            if (data) {
                // Set the clientRowKey in each object so it can be bound into the DetailsList.
                data.forEach((value: SupplierActivity, index: number) => {
                    value.clientRowKey = index.toString()
                });
            }

            payload.latestSupplierActivityByProgramType = data;
        }
    );
};

/**
 * Reset the page action call state for all api calls.
 * This clear all data from the Redux store for these calls, including any error state for failed calls.
 * This makes it so if there was a failed call and an error was shown on the page, if the user navigates
 * to another page and back, the prior error would not be shown.
 */
export const resetApiCallState = () => {
    const apiLoadTotalJobsReported: IApiLoadTotalJobsReported = {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        totalJobsReported: undefined
    };
    reduxStore.dispatch({
        type: actionTypes.API_LOAD_TOTAL_JOBS_REPORTED,
        payload: apiLoadTotalJobsReported
    });

    const apiLoadSupplierApiInteractions: IApiLoadSupplierApiInteractions = {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        supplierApiInteractions: undefined
    };
    reduxStore.dispatch({
        type: actionTypes.API_LOAD_SUPPLIER_API_INTERACTIONS,
        payload: apiLoadSupplierApiInteractions
    });

    const loadSupplierTrendsPayload: IApiLoadSupplierTrends = {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        supplierTrends: undefined
    };
    reduxStore.dispatch({
        type: actionTypes.API_LOAD_SUPPLIER_TRENDS,
        payload: loadSupplierTrendsPayload
    });

    const loadLatestSupplierActivityByProgramTypePayload: IApiLoadLatestSupplierActivityByProgramType = {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        latestSupplierActivityByProgramType: undefined
    };
    reduxStore.dispatch({
        type: actionTypes.API_LOAD_LATEST_SUPPLIER_ACTIVITY_BY_PROGRAM_TYPE,
        payload: loadLatestSupplierActivityByProgramTypePayload
    });
};
