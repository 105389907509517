export interface IPackagingDetails {
    packagingMaterialType: string;
    packagingWeightInKilos: number;
    recycledMaterialContentPercentage: number;
    reusedPackagingPercentage: number;
    reusedPackagingWeightInKilos: number;
    recycledPackagingWeightInKilos: number;
    compostedPackagingWeightInKilos: number;
    landfillPackagingWeightInKilos: number;
    incineratedPackagingWeightInKilos: number;
}

export class PackagingDetails implements IPackagingDetails {
    public packagingMaterialType: string;
    public packagingWeightInKilos: number;
    public recycledMaterialContentPercentage: number;
    public reusedPackagingPercentage: number;
    public reusedPackagingWeightInKilos: number;
    public recycledPackagingWeightInKilos: number;
    public compostedPackagingWeightInKilos: number;
    public landfillPackagingWeightInKilos: number;
    public incineratedPackagingWeightInKilos: number;

    // Below are properties NOT returned from the server but are used for client side purposes.
    public clientRowKey: string = '';

    constructor(jsonData: IPackagingDetails) {
        this.packagingMaterialType = jsonData.packagingMaterialType;
        this.packagingWeightInKilos = jsonData.packagingWeightInKilos;
        this.recycledMaterialContentPercentage = jsonData.recycledMaterialContentPercentage;
        this.reusedPackagingPercentage = jsonData.reusedPackagingPercentage;
        this.reusedPackagingWeightInKilos = jsonData.reusedPackagingWeightInKilos;
        this.recycledPackagingWeightInKilos = jsonData.recycledPackagingWeightInKilos;
        this.compostedPackagingWeightInKilos = jsonData.compostedPackagingWeightInKilos;
        this.landfillPackagingWeightInKilos = jsonData.landfillPackagingWeightInKilos;
        this.incineratedPackagingWeightInKilos = jsonData.incineratedPackagingWeightInKilos;
    }
}
