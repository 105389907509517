import React from 'react';
import {
    Dialog,
    DialogType,
    Spinner,
    SpinnerSize,
    Text
} from '@fluentui/react';
import { commonStyles } from '../../common/common.styles';

interface IReloadingDialogProps {
    successIndicator: boolean;
}

export const ReloadingDialog: React.FunctionComponent<IReloadingDialogProps> = (props: IReloadingDialogProps): JSX.Element => {
    return (
        <Dialog
            hidden={!props.successIndicator}
            dialogContentProps={{
                type: DialogType.normal,
                showCloseButton: false,
                title: 'Please wait'
            }}
            modalProps={{
                isBlocking: true
            }}
            styles={{
                main: {
                    minHeight: '115px'
                }
            }}
            minWidth={160}
        >
            <Text variant="large">Reloading...</Text>
            <Spinner size={SpinnerSize.medium} className={commonStyles.spinner} style={{ display: 'inline-block', marginLeft: '10px' }} />
        </Dialog>
    );
}
