import { PackagingDetails } from './packagingDetails';

export interface ISupplierTransportation {
    correlationId: string;
    supplierReportingDate: Date;
    supplierId: string;
    supplierName: string;
    supplierJobId: string;
    supplierJobType: string;
    transportSupplierName: string;
    loadWeightInKilos: number;
    palletQuantity: number;
    vehicleStartPointAddress: string;
    pointToPoint: string;
    approvedSecureLocationAddress: string;
    mileageInMiles: number;
    vehicleQuantity: number;
    vehicleTonnage: number;
    fuelType: string;
    totalPackagingWeightInKilos: number;
    packagingDetails?: PackagingDetails[];
}

export class SupplierTransportation implements ISupplierTransportation {
    public correlationId: string;
    public supplierReportingDate: Date;
    public supplierId: string;
    public supplierName: string;
    public supplierJobId: string;
    public supplierJobType: string;
    public transportSupplierName: string;
    public loadWeightInKilos: number;
    public palletQuantity: number;
    public vehicleStartPointAddress: string;
    public pointToPoint: string;
    public approvedSecureLocationAddress: string;
    public mileageInMiles: number;
    public vehicleQuantity: number;
    public vehicleTonnage: number;
    public fuelType: string;
    public totalPackagingWeightInKilos: number;
    public packagingDetails?: PackagingDetails[] = [];

    constructor(jsonData: ISupplierTransportation) {
        this.correlationId = jsonData.correlationId;
        this.supplierReportingDate = new Date(jsonData.supplierReportingDate);
        this.supplierId = jsonData.supplierId;
        this.supplierName = jsonData.supplierName;
        this.supplierJobId = jsonData.supplierJobId;
        this.supplierJobType = jsonData.supplierJobType;
        this.transportSupplierName = jsonData.transportSupplierName;
        this.loadWeightInKilos = jsonData.loadWeightInKilos;
        this.palletQuantity = jsonData.palletQuantity;
        this.vehicleStartPointAddress = jsonData.vehicleStartPointAddress;
        this.pointToPoint = jsonData.pointToPoint;
        this.approvedSecureLocationAddress = jsonData.approvedSecureLocationAddress;
        this.mileageInMiles = jsonData.mileageInMiles;
        this.vehicleQuantity = jsonData.vehicleQuantity;
        this.vehicleTonnage = jsonData.vehicleTonnage;
        this.fuelType = jsonData.fuelType;
        this.totalPackagingWeightInKilos = jsonData.totalPackagingWeightInKilos;

        this.packagingDetails = jsonData.packagingDetails ?
            jsonData.packagingDetails.map((value: PackagingDetails) => {
                return new PackagingDetails(value)
            })
            : undefined;
    }
}
