import React, { useState, useCallback, useEffect } from 'react';
import {
    Text,
    Spinner,
    SpinnerSize,
    SelectionMode,
    DetailsListLayoutMode,
    ConstrainMode,
    IColumn,
    Stack
} from '@fluentui/react';
import { commonStyles, stackTokens } from '../../common/common.styles';
import { ICommonPageProps } from '../../common/common.types';
import { assignOnColumnClick, ColumnsAndItems, commonColumnProps, resetColumnSorting, sortOnColumn } from '../../common/common.func';
import { clearErrorByIndex, ErrorBar, prepErrorMsg } from '../../components/ErrorBar/ErrorBar';
import { CustomDetailsList } from '../../components/CustomDetailsList/CustomDetailsList';
import { ComboInputOption, ISearchCriteria, SearchFilter } from '../../components/SearchFilter/SearchFilter';
import { appConstants } from '../../common/appConstants';
import { resetApiCallState, loadTransportAndPackaging, IApiLoadTransportAndPackaging } from '../../store/actions/pageActions/transportAndPackagingPage.action';
import { PackagingDetails } from '../../models/supplier/packagingDetails';
import { pageStyles } from './TransportAndPackagingPage.styles';
import { CallApiState } from '../../store/actions/generic.action';
import { SupplierTransportation } from '../../models/supplier/supplierTransportation';
import { useAppSelector, useAppDispatch } from '../../store/hooks';
import { PageWrapper } from '../../components/PageWrapper/PageWrapper';

/*
The "SupplierWebMicroFrontend" project has a variation of this page. If changes are made here, consider if
equivalent changes are needed in that project as well. There are some differences between the projects, such as
localization and SupplierWeb integration in that project, so be careful when copying/integrating changes.
*/

interface IPageProps extends ICommonPageProps {
}

export const TransportAndPackagingPage: React.FunctionComponent<IPageProps> = (props: IPageProps): JSX.Element => {
    const [errors, setErrors] = useState<string[]>([]);
    const [selectedPagePackagingDetailsTable, setSelectedPagePackagingDetailsTable] = useState<number>(1);
    const [supplierTransportation, setSupplierTransportation] = useState<SupplierTransportation | null>();

    // Redux store selectors to get state from the store when it changes.
    const apiLoadTransportAndPackaging: IApiLoadTransportAndPackaging =
        useAppSelector<IApiLoadTransportAndPackaging>((state) => state.transportAndPackagingPageReducer.apiLoadTransportAndPackaging);

    // Redux store dispatch to send actions to the store.
    const dispatch = useAppDispatch();

    /**
     * This effect does nothing on mount, but will return a cleanup function that runs when the component unmounts.
     */
    useEffect(() => {
        return function cleanup() {
            resetApiCallState();
        }
    }, []);

    /**
     * Handle error.
     * @param errMsg Error message.
     */
    const handleError = useCallback((errMsg: string) => {
        setErrors((prevErrors) => {
            // This will prevent the same error from being displayed if already displayed.
            // ex: Multiple page data load failures might occur if the api is not working,
            // and this page makes multiple load calls for various data.
            if (!prevErrors.includes(errMsg)) {
                return [...prevErrors, errMsg];
            }
            return prevErrors;
        });
    }, []);

    /**
     * Effect for when errors occur in any api call.
     */
    useEffect(() => {
        if (apiLoadTransportAndPackaging.errMsg) {
            handleError(apiLoadTransportAndPackaging.errMsg);
        }
    }, [handleError, apiLoadTransportAndPackaging.errMsg]);

    /**
     * Columns for the Packaging Details table.
     */
    const [columnsPackagingDetailsTable, setColumnsPackagingDetailsTable] = useState<IColumn[]>(
        [
            {
                ...commonColumnProps,
                key: 'column1',
                name: 'Packaging Material Type',
                fieldName: 'packagingMaterialType',
                minWidth: 100
            },
            {
                ...commonColumnProps,
                key: 'column2',
                name: 'Packaging Weight In Kilos',
                fieldName: 'packagingWeightInKilos',
                minWidth: 100
            },
            {
                ...commonColumnProps,
                key: 'column3',
                name: 'Recycled Material Content Percentage',
                fieldName: 'recycledMaterialContentPercentage',
                minWidth: 115
            },
            {
                ...commonColumnProps,
                key: 'column4',
                name: 'Reused Packaging Percentage',
                fieldName: 'reusedPackagingPercentage',
                minWidth: 100
            },
            {
                ...commonColumnProps,
                key: 'column5',
                name: 'Reused Packaging Weight In Kilos',
                fieldName: 'reusedPackagingWeightInKilos',
                minWidth: 100
            },
            {
                ...commonColumnProps,
                key: 'column6',
                name: 'Recycled Packaging Weight In Kilos',
                fieldName: 'recycledPackagingWeightInKilos',
                minWidth: 115
            },
            {
                ...commonColumnProps,
                key: 'column7',
                name: 'Composted Packaging Weight In Kilos',
                fieldName: 'compostedPackagingWeightInKilos',
                minWidth: 125
            },
            {
                ...commonColumnProps,
                key: 'column8',
                name: 'Landfill Packaging Weight In Kilos',
                fieldName: 'landfillPackagingWeightInKilos',
                minWidth: 100
            },
            {
                ...commonColumnProps,
                key: 'column9',
                name: 'Incinerated Packaging Weight In Kilos',
                fieldName: 'incineratedPackagingWeightInKilos',
                minWidth: 125
            }
        ]
    );

    /**
     * On column click event handler for Packaging Details table.
     * @param ev Mouse event.
     * @param column Column clicked.
     */
    const onColumnClickPackagingDetailsTable = useCallback((ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        if (supplierTransportation && supplierTransportation.packagingDetails) {
            const columnsAndItems: ColumnsAndItems<PackagingDetails> = sortOnColumn<PackagingDetails>(column, columnsPackagingDetailsTable, supplierTransportation.packagingDetails, undefined);
            supplierTransportation.packagingDetails = columnsAndItems.items;
            setSupplierTransportation(supplierTransportation);
            setColumnsPackagingDetailsTable(columnsAndItems.columns);
        }
    }, [columnsPackagingDetailsTable, supplierTransportation]);

    /**
     * Effect to update onColumnClick handler. Otherwise the component state referenced in onColumnClick
     * would be stale from the render pass it was created on.
     */
    useEffect(() => {
        assignOnColumnClick(columnsPackagingDetailsTable, onColumnClickPackagingDetailsTable);
    }, [columnsPackagingDetailsTable, onColumnClickPackagingDetailsTable]);

    /**
     * Search clicked event handler.
     * @param searchCriteria Search criteria.
     */
    const searchClicked = (searchCriteria: ISearchCriteria) => {
        setSupplierTransportation(undefined);

        setSelectedPagePackagingDetailsTable(1);

        if (searchCriteria.programType && searchCriteria.supplierId) {
            dispatch(loadTransportAndPackaging(
                searchCriteria.programType,
                searchCriteria.supplierId,
                searchCriteria.supplierJobId,
                searchCriteria.correlationId
            ));
        }
    };

    /**
     * Search filter load error handler.
     * @param err Error
     */
    const searchFilterLoadError = (err: any) => {
        handleError(prepErrorMsg(appConstants.dataLoadFailed, err));
    };

    /**
     * Effect for when Transportation And Packaging data is loaded.
     */
    useEffect(() => {
        if (apiLoadTransportAndPackaging.callApiState === CallApiState.DataAvailable) {
            setSupplierTransportation(apiLoadTransportAndPackaging.transportAndPackaging);
            setColumnsPackagingDetailsTable(resetColumnSorting(columnsPackagingDetailsTable));
        }
    }, [columnsPackagingDetailsTable, apiLoadTransportAndPackaging.callApiState, apiLoadTransportAndPackaging.transportAndPackaging]);

    return (
        <PageWrapper {...props}>
            <ErrorBar errors={errors} onDismiss={(index: number) => {
                setErrors(clearErrorByIndex(errors, index));
            }} />

            <div className='ms-Grid' dir="ltr">
                <div className='ms-Grid-row'>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <div className={commonStyles.pageHeader}>
                            <Text variant="xLarge" role="heading" aria-level={1}>Transport And Packaging</Text>
                        </div>
                    </div>
                </div>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <SearchFilter
                            programs={props.programs || []}
                            searchFilterOptions={{
                                showFiscalRange: true,
                                fiscalRangeFieldsRequired: false,
                                showCalendarYearMonth: false,
                                showProgram: true,
                                showSupplierName: true,
                                showComboInputGroup: ComboInputOption.None,
                                showSupplierJobId: true,
                                showSupplierPoNumber: false,
                                showSupplierInvoiceNumber: false,
                                showCorrelationId: true,
                                showMsInvoiceNumber: false,
                                showSupplierUnitType: false,
                                showJobUnit: false,
                                showCountry: false,
                                showComboAssetTagAndSerialNumber: false,
                                showUnitDispositionType: false,
                                showUnitStatus: false
                            }}
                            onSearchClicked={searchClicked}
                            onSearchFilterLoadError={searchFilterLoadError} />
                    </div>
                </div>
                <div className="ms-Grid-row" style={{ marginTop: '24px' }}>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        {
                            (apiLoadTransportAndPackaging.callApiState !== CallApiState.Initial) && (
                                <>
                                    {apiLoadTransportAndPackaging.callApiState === CallApiState.Running && (
                                        <Spinner size={SpinnerSize.medium} className={commonStyles.spinner} />
                                    )}
                                    {apiLoadTransportAndPackaging.callApiState === CallApiState.Completed && (
                                        <>
                                            <div className={commonStyles.headingTextContainer}>
                                                <Text variant="mediumPlus" className={commonStyles.headingText} role="heading" aria-level={2}>Transport And Packaging Details</Text>
                                            </div>
                                            {
                                                supplierTransportation && (
                                                    <Stack horizontal tokens={stackTokens}>
                                                        <Stack.Item>
                                                            <table className={pageStyles.transportationAndPackagingSectionFieldsTable}>
                                                                <tbody>
                                                                    <tr>
                                                                        <td><Text variant="medium" className={pageStyles.transportationAndPackagingSectionLabel}>Transport Supplier Name</Text></td>
                                                                        <td><Text variant="medium">{supplierTransportation?.transportSupplierName}</Text></td>

                                                                    </tr>
                                                                    <tr>
                                                                        <td><Text variant="medium" className={pageStyles.transportationAndPackagingSectionLabel}>Load Weight In Kilos</Text></td>
                                                                        <td><Text variant="medium">{supplierTransportation?.loadWeightInKilos}</Text></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><Text variant="medium" className={pageStyles.transportationAndPackagingSectionLabel}>Pallet Quantity</Text></td>
                                                                        <td><Text variant="medium">{supplierTransportation?.palletQuantity}</Text></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><Text variant="medium" className={pageStyles.transportationAndPackagingSectionLabel}>Vehicle Start Point Address</Text></td>
                                                                        <td><Text variant="medium">{supplierTransportation?.vehicleStartPointAddress}</Text></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><Text variant="medium" className={pageStyles.transportationAndPackagingSectionLabel}>Point To Point</Text></td>
                                                                        <td><Text variant="medium">{supplierTransportation?.pointToPoint}</Text></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><Text variant="medium" className={pageStyles.transportationAndPackagingSectionLabel}>Approved Secure Location Address</Text></td>
                                                                        <td><Text variant="medium">{supplierTransportation?.approvedSecureLocationAddress}</Text></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </Stack.Item>
                                                        <Stack.Item>
                                                            <table className={pageStyles.transportationAndPackagingSectionFieldsTable}>
                                                                <tbody>
                                                                    <tr>
                                                                        <td><Text variant="medium" className={pageStyles.transportationAndPackagingSectionLabel}>Mileage In Miles</Text></td>
                                                                        <td><Text variant="medium">{supplierTransportation?.mileageInMiles}</Text></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><Text variant="medium" className={pageStyles.transportationAndPackagingSectionLabel}>Vehicle Quantity</Text></td>
                                                                        <td><Text variant="medium">{supplierTransportation?.vehicleQuantity}</Text></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><Text variant="medium" className={pageStyles.transportationAndPackagingSectionLabel}>Vehicle Tonnage</Text></td>
                                                                        <td><Text variant="medium">{supplierTransportation?.vehicleTonnage}</Text></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><Text variant="medium" className={pageStyles.transportationAndPackagingSectionLabel}>Fuel Type</Text></td>
                                                                        <td><Text variant="medium">{supplierTransportation?.fuelType}</Text></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><Text variant="medium" className={pageStyles.transportationAndPackagingSectionLabel}>Total Packaging Weight In Kilos</Text></td>
                                                                        <td><Text variant="medium">{supplierTransportation?.totalPackagingWeightInKilos}</Text></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><Text variant="medium" className={pageStyles.transportationAndPackagingSectionLabel}>Supplier Reporting Date</Text></td>
                                                                        <td><Text variant="medium">{supplierTransportation?.supplierReportingDate?.toLocaleDateString()}</Text></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </Stack.Item>
                                                    </Stack>
                                                )
                                            }
                                            {
                                                !supplierTransportation && (
                                                    <Stack horizontal tokens={stackTokens}>
                                                        <Stack.Item>
                                                            <Text variant='medium'>No data found</Text>
                                                        </Stack.Item>
                                                    </Stack>
                                                )
                                            }

                                            <div className={pageStyles.packagingDetailsSection}>
                                                <div className={commonStyles.headingTextContainer}>
                                                    <Text variant="mediumPlus" className={commonStyles.headingText} role="heading" aria-level={2}>Packaging Details</Text>
                                                </div>
                                                <CustomDetailsList
                                                    showExcelExport={true}
                                                    exportExcelSheetName="Packaging Details"
                                                    ariaLabelForGrid="Packaging Details"
                                                    displayTotalItems={true}
                                                    showPaginator={true}
                                                    showPageSize={true}
                                                    selectedPage={selectedPagePackagingDetailsTable}
                                                    onSelectedPageChange={(page) => {
                                                        setSelectedPagePackagingDetailsTable(page);
                                                    }}
                                                    showNoDataFoundMsg={!supplierTransportation || !supplierTransportation.packagingDetails || supplierTransportation.packagingDetails.length === 0}
                                                    items={supplierTransportation?.packagingDetails || []}
                                                    compact={false}
                                                    columns={columnsPackagingDetailsTable}
                                                    selectionMode={SelectionMode.none}
                                                    getKey={(item: PackagingDetails) => item.clientRowKey}
                                                    setKey="none"
                                                    layoutMode={DetailsListLayoutMode.fixedColumns}
                                                    isHeaderVisible={true}
                                                    constrainMode={ConstrainMode.horizontalConstrained} />
                                            </div>
                                        </>
                                    )}
                                </>
                            )
                        }
                    </div>
                </div>
            </div>
        </PageWrapper>
    );
};
