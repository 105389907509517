export interface ICountry {
    countryCode: string;
    countryName: string;
}

export class Country implements ICountry {
    public countryCode: string;
    public countryName: string;

    constructor(jsonData: ICountry) {
        this.countryCode = jsonData.countryCode;
        this.countryName = jsonData.countryName;
    }
}
