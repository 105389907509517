import { AnyAction, Reducer } from 'redux';
import * as actionTypes from '../../actions/actionTypes';
import { IApiLoadSuppliers, IApiUpdateSupplier, IApiAddSupplier } from '../../actions/adminTabActions/adminTabSuppliers.action';
import { CallApiState, StateUpdateOption } from '../../actions/generic.action';

export interface IAdminTabSuppliersReducerState {
    apiLoadSuppliers: IApiLoadSuppliers;
    apiUpdateSupplier: IApiUpdateSupplier;
    apiAddSupplier: IApiAddSupplier;
}

const initialAdminTabSupplierReducerState: IAdminTabSuppliersReducerState = {
    apiLoadSuppliers: {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        suppliers: undefined
    },
    apiUpdateSupplier: {
        callApiState: CallApiState.Initial,
        errMsg: undefined
    },
    apiAddSupplier: {
        callApiState: CallApiState.Initial,
        errMsg: undefined
    }
}

export const adminTabSuppliersReducer: Reducer<IAdminTabSuppliersReducerState, AnyAction> = (
    state: IAdminTabSuppliersReducerState = initialAdminTabSupplierReducerState, action: AnyAction
): IAdminTabSuppliersReducerState => {
    switch (action.type) {
        case actionTypes.API_LOAD_SUPPLIERS: {
            const payload: IApiLoadSuppliers = action.payload as IApiLoadSuppliers;
            switch (payload.stateUpdateOption) {
                case StateUpdateOption.ErrorOnly:
                    return {
                        ...state,
                        // Keep the prior payload except for the errMsg which will be set to undefined.
                        apiLoadSuppliers: {
                            ...state.apiLoadSuppliers,
                            errMsg: undefined
                        }
                    }
                default:
                    return {
                        ...state,
                        apiLoadSuppliers: { ...payload }
                    }
            }
        }
        case actionTypes.API_UPDATE_SUPPLIER: {
            const payload: IApiUpdateSupplier = action.payload as IApiUpdateSupplier;
            switch (payload.stateUpdateOption) {
                case StateUpdateOption.ErrorOnly:
                    return {
                        ...state,
                        // Keep the prior payload except for the errMsg which will be set to undefined.
                        apiUpdateSupplier: {
                            ...state.apiUpdateSupplier,
                            errMsg: undefined
                        }
                    }
                default:
                    return {
                        ...state,
                        apiUpdateSupplier: { ...payload }
                    }
            }
        }
        case actionTypes.API_ADD_SUPPLIER: {
            const payload: IApiAddSupplier = action.payload as IApiAddSupplier;
            switch (payload.stateUpdateOption) {
                case StateUpdateOption.ErrorOnly:
                    return {
                        ...state,
                        // Keep the prior payload except for the errMsg which will be set to undefined.
                        apiAddSupplier: {
                            ...state.apiAddSupplier,
                            errMsg: undefined
                        }
                    }
                default:
                    return {
                        ...state,
                        apiAddSupplier: { ...payload }
                    }
            }
        }
        default:
    }

    return state;
};
