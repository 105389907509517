export interface IOfficeLocation {
    building: string;
    floor?: string;
    office?: string;
}

export class OfficeLocation implements IOfficeLocation {
    public building: string;
    public floor?: string;
    public office?: string;

    constructor(jsonData: IOfficeLocation) {
        this.building = jsonData.building;
        this.floor = jsonData.floor;
        this.office = jsonData.office;
    }
}