import { IHeaderStyles } from '@coherence-design-system/header';
import { IStyle, mergeStyleSets } from '@fluentui/react';

/**
 * Styles used on this component.
 */
export const componentStyles = mergeStyleSets({
    headerIconContainer: {
        color: 'white',
        fontSize: '20px !important',
        position: 'relative'
    } as IStyle,
    siteLabel: {
        fontSize: '16px',
        fontWeight: '600',
        textDecoration: 'none',
        color: 'rgb(255, 255, 255) !important',
        height: '46px',
        lineHeight: '46px',
        whiteSpace: 'nowrap'
    } as IStyle,
    envName: {
        margin: '0 0 0 20px',
        color: 'white'
    } as IStyle,
    userImage: {
        borderRadius: '50%'
    } as IStyle,
    resizeGroup: {
        root: {
            background: 'transparent',
            height: '100%'
        } as IStyle
    } as IStyle
});

export const headerStyles = {
    hamburgerContainer: {
        button: {
            color: 'white'
        } as IStyle,
        'button:hover': {
            color: 'white'
        } as IStyle
    } as IStyle
} as IHeaderStyles;
