import { SupplierJobType } from '../../../models/domainData/supplierJobType';
import { serviceApiClient } from '../../../services/api/serviceApiClient';
import { AppDispatch, reduxStore } from '../../reduxStore';
import * as actionTypes from '../actionTypes';
import { callApi, CallApiState, ICallApiBase } from '../generic.action';

/**
 * Load supplier job types action payload.
 */
export interface IApiLoadSupplierJobTypes extends ICallApiBase {
    supplierJobTypes?: SupplierJobType[] | null;
}

/**
 * Load supplier job types.
 * @returns Redux dispatch function.
 */
export const callApiLoadSupplierJobTypes = (programType: string): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<SupplierJobType[]>(
        actionTypes.API_LOAD_SUPPLIER_JOB_TYPES,
        async () => {
            return await serviceApiClient.supplierJobTypes(programType) || [];
        },
        (payload: IApiLoadSupplierJobTypes, data) => {
            if (data) {
                // Set the clientRowKey in each object so it can be bound into the DetailsList.
                data.forEach((value: SupplierJobType, index: number) => {
                    value.clientRowKey = index.toString()
                });
            }

            payload.supplierJobTypes = data;
        }
    );
};

/**
 * Update supplier job type action payload.
 */
export interface IApiUpdateSupplierJobType extends ICallApiBase {
    // No data returned from this api.
}

/**
 * Update supplier job type.
 * @param supplierJobType Supplier job type to update.
 * @returns Redux dispatch function.
 */
export const callApiUpdateSupplierJobType = (supplierJobType: SupplierJobType): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<null>(
        actionTypes.API_UPDATE_SUPPLIER_JOB_TYPE,
        async () => {
            return await serviceApiClient.updateSupplierJobType(supplierJobType);
        },
        (payload: IApiUpdateSupplierJobType, data) => {
            // No data returned from this api.
        }
    );
};

/**
 * Add supplier job type action payload.
 */
export interface IApiAddSupplierJobType extends ICallApiBase {
    // No data returned from this api.
}

/**
 * Add supplier job type.
 * @param supplierJobType Supplier job type to add.
 * @param programType Program type.
 * @returns Redux dispatch function.
 */
export const callApiAddSupplierJobType = (supplierJobType: SupplierJobType, programType: string): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<null>(
        actionTypes.API_ADD_SUPPLIER_JOB_TYPE,
        async () => {
            return await serviceApiClient.addSupplierJobType(supplierJobType, programType);
        },
        (payload: IApiAddSupplierJobType, data) => {
            // No data returned from this api.
        }
    );
};

/**
 * Delete supplier job type action payload.
 */
export interface IApiDeleteSupplierJobType extends ICallApiBase {
    // No data returned from this api.
}

/**
 * Delete supplier job type.
 * @param supplierJobType Supplier job type to delete.
 * @param programType Program type.
 * @returns Redux dispatch function.
 */
export const callApiDeleteSupplierJobType = (supplierJobType: SupplierJobType): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<null>(
        actionTypes.API_DELETE_SUPPLIER_JOB_TYPE,
        async () => {
            return await serviceApiClient.deleteSupplierJobType(supplierJobType);
        },
        (payload: IApiDeleteSupplierJobType, data) => {
            // No data returned from this api.
        }
    );
};

/**
 * Reset the page action call state for all api calls.
 * This clear all data from the Redux store for these calls, including any error state for failed calls.
 * This makes it so if there was a failed call and an error was shown on the page, if the user navigates
 * to another page and back, the prior error would not be shown.
 */
export const resetApiCallState = () => {
    const apiLoadSupplierJobTypes: IApiLoadSupplierJobTypes = {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        supplierJobTypes: undefined
    };
    reduxStore.dispatch({
        type: actionTypes.API_LOAD_SUPPLIER_JOB_TYPES,
        payload: apiLoadSupplierJobTypes
    });

    const apiUpdateSupplierJobTypes: IApiUpdateSupplierJobType = {
        callApiState: CallApiState.Initial,
        errMsg: undefined
    };
    reduxStore.dispatch({
        type: actionTypes.API_UPDATE_SUPPLIER_JOB_TYPE,
        payload: apiUpdateSupplierJobTypes
    });

    const apiAddSupplierJobTypes: IApiAddSupplierJobType = {
        callApiState: CallApiState.Initial,
        errMsg: undefined
    };
    reduxStore.dispatch({
        type: actionTypes.API_ADD_SUPPLIER_JOB_TYPE,
        payload: apiAddSupplierJobTypes
    });

    const apiDeleteSupplierJobTypes: IApiDeleteSupplierJobType = {
        callApiState: CallApiState.Initial,
        errMsg: undefined
    };
    reduxStore.dispatch({
        type: actionTypes.API_DELETE_SUPPLIER_JOB_TYPE,
        payload: apiDeleteSupplierJobTypes
    });
};
