import { AnyAction, Reducer } from 'redux';
import * as actionTypes from '../../actions/actionTypes';
import {
    IApiJobSearch,
    IApiLoadJobUnitDetails,
    IApiLoadSupplierSummary
} from '../../actions/pageActions/connectedDataPage.action';
import { CallApiState } from '../../actions/generic.action';

export interface IConnectedDataPageReducerState {
    apiLoadSupplierSummary: IApiLoadSupplierSummary;
    apiLoadJobUnitDetails: IApiLoadJobUnitDetails;
    apiJobSearch: IApiJobSearch;
}

const initialConnectedDataPageReducerState: IConnectedDataPageReducerState = {
    apiLoadSupplierSummary: {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        supplierSummaries: undefined
    },
    apiLoadJobUnitDetails: {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        recyclingDocument: undefined
    },
    apiJobSearch: {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        jobSearchSummary: undefined
    }
};

export const connectedDataPageReducer: Reducer<IConnectedDataPageReducerState, AnyAction> = (
    state: IConnectedDataPageReducerState = initialConnectedDataPageReducerState, action: AnyAction
): IConnectedDataPageReducerState => {
    switch (action.type) {
        case actionTypes.API_LOAD_SUPPLIER_SUMMARY: {
            const payload: IApiLoadSupplierSummary = action.payload as IApiLoadSupplierSummary;
            return {
                ...state,
                apiLoadSupplierSummary: { ...payload }
            }
        }
        case actionTypes.API_LOAD_JOB_UNIT_DETAILS: {
            const payload: IApiLoadJobUnitDetails = action.payload as IApiLoadJobUnitDetails;
            return {
                ...state,
                apiLoadJobUnitDetails: { ...payload }
            }
        }
        case actionTypes.API_JOB_SEARCH: {
            const payload: IApiJobSearch = action.payload as IApiJobSearch;
            return {
                ...state,
                apiJobSearch: { ...payload }
            }
        }
        default:
    }

    return state;
};
