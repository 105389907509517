import { PeripheralHistory } from './peripheralHistory';
import { OfficeLocation } from './officeLocation';

export interface IRecycledPeripheral {
    assetTag: string;
    serialNumber: string;
    peripheralType: string;
    isAvailable: boolean;
    pickupLocation: OfficeLocation;
    donorAlias: string;
    takerAlias?: string;
    donatedDate: Date;
    takenDate?: Date;
    peripheralCondition?: string;
    peripheralImageLinks: string[];
    peripheralHistory: PeripheralHistory[]; // The same peripheral could be recycled multiple times.
}

export class RecycledPeripheral implements IRecycledPeripheral {
    public assetTag: string;
    public serialNumber: string;
    public peripheralType: string;
    public isAvailable: boolean;
    public pickupLocation: OfficeLocation;
    public donorAlias: string;
    public takerAlias?: string;
    public donatedDate: Date;
    public takenDate?: Date;
    public peripheralCondition?: string;
    public peripheralImageLinks: string[] = [];
    public peripheralHistory: PeripheralHistory[] = [];

    // Below are properties NOT returned from the server but are used for client side purposes.
    public clientRowKey: string = '';

    constructor(jsonData: IRecycledPeripheral) {
        this.assetTag = jsonData.assetTag;
        this.serialNumber = jsonData.serialNumber;
        this.peripheralType = jsonData.peripheralType;
        this.isAvailable = jsonData.isAvailable;
        this.pickupLocation = new OfficeLocation(jsonData.pickupLocation);
        this.donorAlias = jsonData.donorAlias;
        this.takerAlias = jsonData.takerAlias;
        this.donatedDate = new Date(jsonData.donatedDate);
        this.takenDate = jsonData.takenDate ? new Date(jsonData.takenDate) : undefined;
        this.peripheralCondition = jsonData.peripheralCondition;

        for (let i: number = 0; i < jsonData.peripheralImageLinks.length; i++) {
            this.peripheralImageLinks.push(jsonData.peripheralImageLinks[i]);
        }

        for (let i: number = 0; i < jsonData.peripheralHistory.length; i++) {
            this.peripheralHistory.push(new PeripheralHistory(jsonData.peripheralHistory[i]));
        }
    }
}