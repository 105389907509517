import React, { useCallback, useEffect, useState } from 'react';
import {
    ConstrainMode,
    DefaultButton,
    DetailsListLayoutMode,
    IColumn,
    SelectionMode,
    Spinner,
    SpinnerSize,
    Stack,
    TextField
} from '@fluentui/react';
import { IAdminTabProps } from '../../common/common.types';
import { commonColumnProps, useMountEffect } from '../../common/common.func';
import { clearErrorByIndex, ErrorBar, prepErrorMsg } from '../../components/ErrorBar/ErrorBar';
import { CallApiState } from '../../store/actions/generic.action';
import { CustomDetailsList } from '../../components/CustomDetailsList/CustomDetailsList';
import { commonStyles, stackTokens } from '../../common/common.styles';
import { ComboInputOption, ISearchCriteria, SearchFilter } from '../../components/SearchFilter/SearchFilter';
import {
    callApiLoadPackagingMaterialTypes,
    callApiUpdatePackagingMaterialType,
    callApiAddPackagingMaterialType,
    callApiDeletePackagingMaterialType,
    resetApiCallState,
    IApiLoadPackagingMaterialTypes,
    IApiUpdatePackagingMaterialType,
    IApiAddPackagingMaterialType,
    IApiDeletePackagingMaterialType
} from '../../store/actions/adminTabActions/adminTabPackagingMaterialTypes.action';
import { PackagingMaterialType } from '../../models/domainData/packagingMaterialType';
import { addOrRemoveError, checkForInputError, checkItemForAnyInputError, InputError } from './AdminCommon';
import { appConstants } from '../../common/appConstants';
import { ReloadingDialog } from './ReloadingDialog';
import { ConfirmDeleteDialog } from './ConfirmDeleteDialog';
import { useAppSelector, useAppDispatch } from '../../store/hooks';

interface IAdminTabPackagingMaterialTypesProps extends IAdminTabProps {
}

export const AdminTabPackagingMaterialTypes: React.FunctionComponent<IAdminTabPackagingMaterialTypesProps> = (props: IAdminTabPackagingMaterialTypesProps): JSX.Element => {
    const [errors, setErrors] = useState<string[]>([]);
    const [packagingMaterialTypes, setPackagingMaterialTypes] = useState<PackagingMaterialType[]>([]);
    const [inputErrors, setInputErrors] = useState<InputError[]>([]);
    const [successIndicator, setSuccessIndicator] = useState<boolean>(false);
    const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
    const [currentSearchCriteria, setCurrentSearchCriteria] = useState<ISearchCriteria>();
    const [itemBeingUpdatedOrAdded, setItemBeingUpdatedOrAdded] = useState<PackagingMaterialType>();
    const [itemBeingDeleted, setItemBeingDeleted] = useState<PackagingMaterialType>();
    const [itemBeingEdited, setItemBeingEdited] = useState<PackagingMaterialType>();

    // Redux store selectors to get state from the store when it changes.
    const apiLoadPackagingMaterialTypes: IApiLoadPackagingMaterialTypes =
        useAppSelector<IApiLoadPackagingMaterialTypes>((state) => state.adminTabPackagingMaterialTypesReducer.apiLoadPackagingMaterialTypes);
    const apiUpdatePackagingMaterialType: IApiUpdatePackagingMaterialType =
        useAppSelector<IApiUpdatePackagingMaterialType>((state) => state.adminTabPackagingMaterialTypesReducer.apiUpdatePackagingMaterialType);
    const apiAddPackagingMaterialType: IApiAddPackagingMaterialType =
        useAppSelector<IApiAddPackagingMaterialType>((state) => state.adminTabPackagingMaterialTypesReducer.apiAddPackagingMaterialType);
    const apiDeletePackagingMaterialType: IApiDeletePackagingMaterialType =
        useAppSelector<IApiDeletePackagingMaterialType>((state) => state.adminTabPackagingMaterialTypesReducer.apiDeletePackagingMaterialType);

    // Redux store dispatch to send actions to the store.
    const dispatch = useAppDispatch();

    /**
     * Setup columns to display for packaging material types.
     */
    const [columnsPackagingMaterialTypes, setColumnsPackagingMaterialTypes] = useState<IColumn[]>(
        [
            {
                ...commonColumnProps,
                key: 'column1',
                name: '',
                minWidth: 180,
                data: {
                    addActionButtons: true
                }
            },
            {
                ...commonColumnProps,
                key: 'column2',
                name: 'Packaging Material Type',
                fieldName: 'materialType',
                minWidth: 350,
                data: {
                    addOnColumnRenderTextField: true,
                    pattern: /^[a-zA-Z0-9- _,#'"]{5,500}$/,
                    inputErrorMsg: 'Alphanumeric, dashes, spaces, underscore, comma, hash, single quote, double quote, min 5, max 500 length'
                }
            }
        ]
    );

    /**
     * Rerender columns by setting the columns to a new array of the same columns.
     * This will cause the columns to re-render including the action buttons column... the buttons state may
     * change such as becoming disabled or enabled, or buttons showing or hiding, etc.
     */
    const rerenderColumns = useCallback(() => {
        // Use a setTimeout to queue this on the UI thread after the current render pass.
        setTimeout(() => {
            setColumnsPackagingMaterialTypes([...columnsPackagingMaterialTypes]);
        });
    }, [columnsPackagingMaterialTypes]);

    /**
     * This effect does nothing on mount, but will return a cleanup function that runs when the component unmounts.
     */
    useEffect(() => {
        return function cleanup() {
            resetApiCallState();
        }
    }, []);

    /**
     * Handle error.
     * @param errMsg Error message.
     */
    const handleError = useCallback((errMsg: string) => {
        setErrors((prevErrors) => {
            // This will prevent the same error from being displayed if already displayed.
            // ex: Multiple page data load failures might occur if the api is not working,
            // and this page makes multiple load calls for various data.
            if (!prevErrors.includes(errMsg)) {
                return [...prevErrors, errMsg];
            }
            return prevErrors;
        });
    }, []);

    /**
     * Effect for when errors occur in any api call.
     */
    useEffect(() => {
        if (apiLoadPackagingMaterialTypes.errMsg) {
            handleError(apiLoadPackagingMaterialTypes.errMsg);
        }
        if (apiUpdatePackagingMaterialType.errMsg) {
            handleError(apiUpdatePackagingMaterialType.errMsg);
        }
        if (apiAddPackagingMaterialType.errMsg) {
            handleError(apiAddPackagingMaterialType.errMsg);
        }
        if (apiDeletePackagingMaterialType.errMsg) {
            handleError(apiDeletePackagingMaterialType.errMsg);
        }
    }, [apiAddPackagingMaterialType.errMsg, apiDeletePackagingMaterialType.errMsg, handleError, apiLoadPackagingMaterialTypes.errMsg, apiUpdatePackagingMaterialType.errMsg]);

    /**
     * This effect is run once during page load.
     */
    useMountEffect(() => {
        // Nothing at this time.
    });

    /**
     * Effect for when packaging material types is loaded.
     */
    useEffect(() => {
        if (apiLoadPackagingMaterialTypes.callApiState === CallApiState.DataAvailable) {
            setPackagingMaterialTypes(apiLoadPackagingMaterialTypes.packagingMaterialTypes || []);
        }
    }, [apiLoadPackagingMaterialTypes.callApiState, apiLoadPackagingMaterialTypes.packagingMaterialTypes]);

    /**
     * Effect for when packaging material type is updated or added or deleted.
     */
    useEffect(() => {
        if (apiUpdatePackagingMaterialType.callApiState === CallApiState.DataAvailable ||
            apiAddPackagingMaterialType.callApiState === CallApiState.DataAvailable ||
            apiDeletePackagingMaterialType.callApiState === CallApiState.DataAvailable) {
            setSuccessIndicator(true);
            rerenderColumns();
            // Wait for 2 seconds and then reload data.
            setTimeout(() => {
                setSuccessIndicator(false);
                // Reload the packaging material types using current search criteria.
                if (currentSearchCriteria && currentSearchCriteria.programType) {
                    dispatch(callApiLoadPackagingMaterialTypes(
                        currentSearchCriteria.programType
                    ));
                }
            }, 2000);
        }
    }, [apiAddPackagingMaterialType.callApiState, currentSearchCriteria, apiDeletePackagingMaterialType.callApiState, dispatch, rerenderColumns, apiUpdatePackagingMaterialType.callApiState]);

    /**
     * Edit button clicked event handler.
     * @param packagingMaterialType Packaging material type being edited.
     */
    const editButtonClicked = useCallback((packagingMaterialType: PackagingMaterialType): void => {
        setItemBeingEdited(packagingMaterialType);
        rerenderColumns();
    }, [rerenderColumns]);

    /**
     * Save (update or add) button clicked event handler.
     * @param packagingMaterialType Packaging material type being saved.
     */
    const saveButtonClicked = useCallback((packagingMaterialType: PackagingMaterialType): void => {
        setItemBeingUpdatedOrAdded(packagingMaterialType);
        if (packagingMaterialType.isNew) {
            if (currentSearchCriteria && currentSearchCriteria.programType) {
                dispatch(callApiAddPackagingMaterialType(packagingMaterialType, currentSearchCriteria.programType));
            }
        } else {
            dispatch(callApiUpdatePackagingMaterialType(packagingMaterialType));
        }
        rerenderColumns();
    }, [currentSearchCriteria, dispatch, rerenderColumns]);

    /**
     * Delete button clicked event handler.
     * @param packagingMaterialType Packaging material type being saved.
     */
    const deleteButtonClicked = useCallback((packagingMaterialType: PackagingMaterialType): void => {
        setItemBeingDeleted(packagingMaterialType);
        setConfirmDelete(true);
    }, []);

    /**
     * Confirm delete button confirmed clicked event handler.
     * @param packagingMaterialType Packaging material type being saved.
     */
    const confirmDeleteButtonClicked = useCallback((packagingMaterialType: PackagingMaterialType | undefined): void => {
        setConfirmDelete(false);
        if (packagingMaterialType) {
            dispatch(callApiDeletePackagingMaterialType(packagingMaterialType));
            rerenderColumns();
        }
    }, [dispatch, rerenderColumns]);

    /**
     * Cancel delete button confirmed clicked event handler.
     */
    const cancelDeleteButtonClicked = useCallback((): void => {
        setConfirmDelete(false);
        setItemBeingDeleted(undefined);
    }, []);

    /**
     * On column render for action buttons.
     * @param item The item (row) being rendered in the details list. 
     * @param index The index of the row.
     * @param column The column being rendered.
     */
    const onColumnRenderActionButtons = useCallback((item: PackagingMaterialType, index: number | undefined, column: IColumn | undefined): JSX.Element => {
        return (
            <div>
                {
                    itemBeingEdited !== item && (
                        <Stack horizontal tokens={stackTokens}>
                            <DefaultButton
                                onClick={event => editButtonClicked(item)}
                                disabled={
                                    apiUpdatePackagingMaterialType.callApiState === CallApiState.Running ||
                                    apiAddPackagingMaterialType.callApiState === CallApiState.Running ||
                                    apiDeletePackagingMaterialType.callApiState === CallApiState.Running ||
                                    successIndicator}>
                                <span style={{ whiteSpace: 'nowrap' }}>
                                    Edit
                                </span>
                            </DefaultButton>
                            <DefaultButton
                                onClick={event => deleteButtonClicked(item)}
                                disabled={
                                    checkItemForAnyInputError(inputErrors, item) ||
                                    apiUpdatePackagingMaterialType.callApiState === CallApiState.Running ||
                                    apiAddPackagingMaterialType.callApiState === CallApiState.Running ||
                                    apiDeletePackagingMaterialType.callApiState === CallApiState.Running ||
                                    successIndicator}>
                                { itemBeingDeleted === item &&
                                    apiDeletePackagingMaterialType.callApiState === CallApiState.Running ? (
                                    <div style={{ display: 'flex' }}>
                                        <span style={{ marginRight: '6px', whiteSpace: 'nowrap' }}>Deleting</span>
                                        <Spinner size={SpinnerSize.medium} className={commonStyles.spinner} style={{ display: 'inline-block' }} />
                                    </div>
                                ) : (
                                    <span style={{ whiteSpace: 'nowrap' }}>
                                        { itemBeingDeleted === item && successIndicator ? 'Deleted' : 'Delete' }
                                    </span>
                                )}
                            </DefaultButton>
                        </Stack>
                    )
                }
                {
                    itemBeingEdited === item && (
                        <Stack horizontal tokens={stackTokens}>
                            <DefaultButton
                                onClick={event => saveButtonClicked(item)}
                                disabled={
                                    checkItemForAnyInputError(inputErrors, item) ||
                                    apiUpdatePackagingMaterialType.callApiState === CallApiState.Running ||
                                    apiAddPackagingMaterialType.callApiState === CallApiState.Running ||
                                    apiDeletePackagingMaterialType.callApiState === CallApiState.Running ||
                                    successIndicator}>
                                { itemBeingUpdatedOrAdded === item &&
                                    (apiUpdatePackagingMaterialType.callApiState === CallApiState.Running ||
                                    apiAddPackagingMaterialType.callApiState === CallApiState.Running) ? (
                                    <div style={{ display: 'flex' }}>
                                        <span style={{ marginRight: '6px', whiteSpace: 'nowrap' }}>Saving</span>
                                        <Spinner size={SpinnerSize.medium} className={commonStyles.spinner} style={{ display: 'inline-block' }} />
                                    </div>
                                ) : (
                                    <span style={{ whiteSpace: 'nowrap' }}>
                                        { itemBeingUpdatedOrAdded === item && successIndicator ? 'Saved' : 'Save' }
                                    </span>
                                )}
                            </DefaultButton>
                        </Stack>
                    )
                }
            </div>
        );
    }, [apiAddPackagingMaterialType.callApiState, deleteButtonClicked, apiDeletePackagingMaterialType.callApiState, editButtonClicked, inputErrors, itemBeingDeleted, itemBeingEdited, itemBeingUpdatedOrAdded, saveButtonClicked, successIndicator, apiUpdatePackagingMaterialType.callApiState]);

    /**
     * On text field column render.
     * @param item The item (row) being rendered in the details list. 
     * @param index The index of the row.
     * @param column The column being rendered.
     */
    const onColumnRenderTextField = useCallback((item: PackagingMaterialType, index: number | undefined, column: IColumn | undefined): JSX.Element => {
        const fieldName: string = column!.fieldName!;
        return (
            <>
                <TextField
                    defaultValue={item[fieldName]}
                    onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                        newValue = newValue || '';
                        item[fieldName] = newValue;
                        const newInputErrors: InputError[] = addOrRemoveError(inputErrors, column!, item, fieldName, newValue);
                        setInputErrors(newInputErrors);
                        setPackagingMaterialTypes([...packagingMaterialTypes]);
                    }}
                    disabled={
                        itemBeingEdited !== item ||
                        apiUpdatePackagingMaterialType.callApiState === CallApiState.Running ||
                        apiAddPackagingMaterialType.callApiState === CallApiState.Running ||
                        apiDeletePackagingMaterialType.callApiState === CallApiState.Running ||
                        successIndicator
                    }
                    errorMessage={checkForInputError(inputErrors, item, fieldName)}>
                </TextField>
            </>
        );
    }, [apiAddPackagingMaterialType.callApiState, apiDeletePackagingMaterialType.callApiState, inputErrors, itemBeingEdited, packagingMaterialTypes, successIndicator, apiUpdatePackagingMaterialType.callApiState]);

    /**
     * Effect to update onRender handler. Otherwise the component state referenced in onRender
     * would be stale from the render pass it was created on.
     */
    useEffect(() => {
        columnsPackagingMaterialTypes.filter(x => x.data?.addActionButtons).forEach(c => c.onRender = onColumnRenderActionButtons);
        columnsPackagingMaterialTypes.filter(x => x.data?.addOnColumnRenderTextField).forEach(c => c.onRender = onColumnRenderTextField);
    }, [columnsPackagingMaterialTypes, onColumnRenderActionButtons, onColumnRenderTextField]);

    /**
     * Add button clicked event handler.
     */
    const addButtonClicked = (): void => {
        const newPackagingMaterialType: PackagingMaterialType = new PackagingMaterialType({
            partitionKey: '',
            rowKey: '',
            materialType: ''
        });
        newPackagingMaterialType.isNew = true;
        newPackagingMaterialType.clientRowKey = packagingMaterialTypes.length.toString();
        let currentInputErrors: InputError[] = [...inputErrors];
        columnsPackagingMaterialTypes.forEach((column) => {
            const value: string = newPackagingMaterialType[column.fieldName!];
            currentInputErrors = addOrRemoveError(currentInputErrors, column, newPackagingMaterialType, column.fieldName!, value);
        });
        setInputErrors(currentInputErrors);
        setPackagingMaterialTypes([...packagingMaterialTypes, newPackagingMaterialType]);
        setItemBeingEdited(newPackagingMaterialType);
    }

    /**
     * Search clicked event handler.
     * @param searchCriteria Search criteria.
     */
    const searchClicked = (searchCriteria: ISearchCriteria) => {
        setCurrentSearchCriteria(searchCriteria);
        if (searchCriteria.programType) {
            dispatch(callApiLoadPackagingMaterialTypes(
                searchCriteria.programType
            ));
        }
    };

    /**
     * Search filter load error handler.
     * @param err Error
     */
    const searchFilterLoadError = (err: any) => {
        handleError(prepErrorMsg(appConstants.dataLoadFailed, err));
    };

    return (
        <>
            <ErrorBar errors={errors} onDismiss={(index: number) => {
                setErrors(clearErrorByIndex(errors, index));
            }} />

            <div className="ms-Grid" dir="ltr">
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{ margin: '12px 12px 12px 12px' }}>
                        <SearchFilter
                            programs={props.programs || []}
                            searchFilterOptions={{
                                showFiscalRange: false,
                                fiscalRangeFieldsRequired: false,
                                showCalendarYearMonth: false,
                                showProgram: true,
                                showSupplierName: false,
                                showComboInputGroup: ComboInputOption.None,
                                showSupplierJobId: false,
                                showSupplierPoNumber: false,
                                showSupplierInvoiceNumber: false,
                                showMsInvoiceNumber: false,
                                showCorrelationId: false,
                                showSupplierUnitType: false,
                                showJobUnit: false,
                                showCountry: false,
                                showComboAssetTagAndSerialNumber: false,
                                showUnitDispositionType: false,
                                showUnitStatus: false
                            }}
                            onSearchClicked={searchClicked}
                            onSearchFilterLoadError={searchFilterLoadError}
                            disabled={
                                apiUpdatePackagingMaterialType.callApiState === CallApiState.Running ||
                                apiAddPackagingMaterialType.callApiState === CallApiState.Running ||
                                apiDeletePackagingMaterialType.callApiState === CallApiState.Running ||
                                successIndicator} />
                    </div>
                </div>
                {apiLoadPackagingMaterialTypes.callApiState === CallApiState.Running && (
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{ margin: '12px 12px 12px 12px' }}>
                            <Spinner size={SpinnerSize.medium} className={commonStyles.spinner} />
                        </div>
                    </div>
                )}
                {apiLoadPackagingMaterialTypes.callApiState === CallApiState.Completed && (
                    <>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <CustomDetailsList
                                    showExcelExport={true}
                                    exportExcelSheetName="Packaging Material Types"
                                    ariaLabelForGrid="Packaging Material Types"
                                    displayTotalItems={true}
                                    showPaginator={false}
                                    showPageSize={false}
                                    selectedPage={1}
                                    showNoDataFoundMsg={packagingMaterialTypes.length === 0}
                                    items={packagingMaterialTypes}
                                    compact={false}
                                    columns={columnsPackagingMaterialTypes}
                                    selectionMode={SelectionMode.none}
                                    getKey={(item: PackagingMaterialType) => item.clientRowKey}
                                    setKey="none"
                                    layoutMode={DetailsListLayoutMode.fixedColumns}
                                    isHeaderVisible={true}
                                    constrainMode={ConstrainMode.horizontalConstrained} />
                            </div>
                        </div>

                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <Stack horizontal wrap tokens={stackTokens}>
                                    <DefaultButton
                                        disabled={
                                            apiUpdatePackagingMaterialType.callApiState === CallApiState.Running ||
                                            apiAddPackagingMaterialType.callApiState === CallApiState.Running ||
                                            apiDeletePackagingMaterialType.callApiState === CallApiState.Running ||
                                            successIndicator}
                                        onClick={event => addButtonClicked()}>
                                        <span>Add</span>
                                    </DefaultButton>
                                </Stack>
                            </div>
                        </div>
                    </>
                )}
            </div>

            <ReloadingDialog
                successIndicator={successIndicator} />
            <ConfirmDeleteDialog
                confirmDelete={confirmDelete}
                itemBeingDeleted={itemBeingDeleted}
                confirmDeleteButtonClicked={confirmDeleteButtonClicked}
                cancelDeleteButtonClicked={cancelDeleteButtonClicked} />     
        </>
    );
};
