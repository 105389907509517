import React, { useState, useCallback, useRef } from 'react';
import { Text, Spinner, SpinnerSize, Stack } from '@fluentui/react';
import { commonStyles, stackTokens } from '../../common/common.styles';
import { ICommonPageProps } from '../../common/common.types';
import { clearErrorByIndex, ErrorBar, prepErrorMsg } from '../../components/ErrorBar/ErrorBar';
import { serviceApiClient } from '../../services/api/serviceApiClient';
import { appConstants, DocumentType } from '../../common/appConstants';
import { useMountEffect } from '../../common/common.func';
import qs from 'qs';
import { PageWrapper } from '../../components/PageWrapper/PageWrapper';

export const DocLinkPage: React.FunctionComponent<ICommonPageProps> = (props: ICommonPageProps): JSX.Element => {
    const [isPageDataLoading, setIsPageDataLoading] = useState<boolean>(true);
    const [pageDataLoadFailed, setPageDataLoadFailed] = useState<boolean>();
    const [errors, setErrors] = useState<string[]>([]);

    const documentType = useRef<DocumentType>();
    const programType = useRef<string>();
    const supplierId = useRef<string>();
    const supplierJobId = useRef<string>();
    const supplierUnitId = useRef<string>();
    const supplierPoNumber = useRef<string>();
    const supplierInvoiceNumber = useRef<string>();

    const [downloadFileName, setDownloadFileName] = useState<string>();

    /**
     * Parse the query string. Example urls look like these:
     * 
     * http://recycling-ui-prod.azurewebsites.net/DocLink?type=RecyclingCertificate&ProgramType=123&SupplierId=123&SupplierJobId=123
     * http://recycling-ui-prod.azurewebsites.net/DocLink?type=DestructionCertificate&ProgramType=123&SupplierId=123&SupplierJobId=123
     * http://recycling-ui-prod.azurewebsites.net/DocLink?type=ErasureCertificate&SupplierId=123&SupplierJobId=123&SupplierUnitId=123
     * http://recycling-ui-prod.azurewebsites.net/DocLink?type=PoDocument&ProgramType=123&SupplierId=123&SupplierPONumber=123
     * http://recycling-ui-prod.azurewebsites.net/DocLink?type=InvoiceDocument&ProgramType=123&SupplierId=123&SupplierInvoiceNumber=123
     */
    const parseQueryString = useCallback(() => {
        const qsOpt: any = { ignoreQueryPrefix: true };

        const docType: string = qs.parse(window.location.search, qsOpt).type as string;
        if (!docType) {
            return;
        }

        switch (docType.toLowerCase()) {
            case 'recyclingcertificate':
                documentType.current = DocumentType.RecyclingCertificate;
                break;
            case 'destructioncertificate':
                documentType.current = DocumentType.DestructionCertificate;
                break;
            case 'erasurecertificate':
                documentType.current = DocumentType.DataErasureCertificate;
                break;
            case 'podocument':
                documentType.current = DocumentType.PoDocument;
                break;
            case 'invoicedocument':
                documentType.current = DocumentType.InvoiceDocument;
                break;
            default:
                break;
        }

        let queryString: string = (window.location.search || '');

        // Make the querystring keys lowercase so they can be found in a case insensitive way below.
        // Important to not modify the values as they are case sensitive.
        queryString = queryString.replace(/programtype/gi, 'programtype')
            .replace(/supplierid/gi, 'supplierid')
            .replace(/supplierjobid/gi, 'supplierjobid')
            .replace(/supplierunitid/gi, 'supplierunitid')
            .replace(/supplierponumber/gi, 'supplierponumber')
            .replace(/supplierinvoicenumber/gi, 'supplierinvoicenumber');

        programType.current = qs.parse(queryString, qsOpt)['programtype'] as string;
        supplierId.current = qs.parse(queryString, qsOpt)['supplierid'] as string;
        supplierJobId.current = qs.parse(queryString, qsOpt)['supplierjobid'] as string;
        supplierUnitId.current = qs.parse(queryString, qsOpt)['supplierunitid'] as string;
        supplierPoNumber.current = qs.parse(queryString, qsOpt)['supplierponumber'] as string;
        supplierInvoiceNumber.current = qs.parse(queryString, qsOpt)['supplierinvoicenumber'] as string;
    }, [])

    /**
     * Load page data.
     */
    const loadPageData = useCallback(async () => {
        try {
            setPageDataLoadFailed(false);
            setIsPageDataLoading(true);

            // Get requested document type from querystring.
            parseQueryString();

            if (documentType.current === DocumentType.RecyclingCertificate && 
                programType.current && supplierId.current && supplierJobId.current) {
                setDownloadFileName(await serviceApiClient.downloadRecyclingCertificate(programType.current, supplierId.current, supplierJobId.current));
            } else if (documentType.current === DocumentType.DestructionCertificate &&
                programType.current && supplierId.current && supplierJobId.current) {
                setDownloadFileName(await serviceApiClient.downloadDestructionCertificate(programType.current, supplierId.current, supplierJobId.current));
            } else if (documentType.current === DocumentType.DataErasureCertificate &&
                supplierId.current && supplierJobId.current && supplierUnitId.current) {
                setDownloadFileName(await serviceApiClient.downloadDataErasureCertificate(supplierId.current, supplierJobId.current, supplierUnitId.current));
            } else if (documentType.current === DocumentType.PoDocument &&
                programType.current && supplierId.current && supplierPoNumber.current) {
                setDownloadFileName(await serviceApiClient.downloadPoDocument(programType.current, supplierId.current, supplierPoNumber.current));
            } else if (documentType.current === DocumentType.InvoiceDocument &&
                programType.current && supplierId.current && supplierInvoiceNumber.current) {
                setDownloadFileName(await serviceApiClient.downloadInvoiceDocument(programType.current, supplierId.current, supplierInvoiceNumber.current));
            }
        } catch (err: any) {
            setPageDataLoadFailed(true);
            setErrors((prevErrors) => [...prevErrors, prepErrorMsg(appConstants.dataLoadFailed, err)]);
        }

        setIsPageDataLoading(false);
    }, [parseQueryString]);

    /**
     * This effect is run once during page load.
     */
    useMountEffect(() => {
        // Fire off load method asynchronously. Not using await here on purpose.
        loadPageData();
    });

    return (
        <PageWrapper {...props}>
            <ErrorBar errors={errors} onDismiss={(index: number) => {
                setErrors(clearErrorByIndex(errors, index));
            }} />

            {
                !pageDataLoadFailed && isPageDataLoading && (
                    <Stack tokens={stackTokens}>
                        <Stack.Item>
                            <Text variant="large">Fetching data... </Text>
                        </Stack.Item>
                        <Stack.Item>
                            <Spinner size={SpinnerSize.medium} className={commonStyles.spinner} />
                        </Stack.Item>
                    </Stack>
                )
            }

            {
                !pageDataLoadFailed && !isPageDataLoading && downloadFileName && (
                    <Stack tokens={stackTokens}>
                        <Stack.Item>
                            <Text variant="large">Found the fillowing file:</Text>
                        </Stack.Item>
                        <Stack.Item>
                            <Text variant="medium">{downloadFileName}</Text><br/>
                        </Stack.Item>
                    </Stack>
                )
            }

            {
                !pageDataLoadFailed && !isPageDataLoading && !downloadFileName && (
                    <div>
                        <Text variant="large">No files found.</Text>
                    </div>
                )
            }
        </PageWrapper>
    );
}
