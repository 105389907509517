import { appConfig } from '../../shell/appConfig';
import axios, { AxiosRequestConfig } from 'axios';
import { ApiClientBase, ApiService } from './apiClientBase';

/**
 * Graph api client.
 */
class GraphApiClient extends ApiClientBase {
    private graphApiVersion: string = 'v1.0';

    /**
     * Constructor for the Graph api client.
     */
    constructor() {
        super(ApiService.Graph)
    }

    /**
     * Get the graph api endpoint for me.
     */
    private get graphApiEndpointMe(): string {
        return `${appConfig.current.graph.baseUrl}/${this.graphApiVersion}/me/`;
    }

    /**
     * Get user photo.
     * This is not using any generic method in ApiClientBase as it requires special headers and error handling.
     */
    public async getUserPhoto(): Promise<string> {
        try {
            const apiUrl: string = `${this.graphApiEndpointMe}photo/$value`;
            const token = await this.getAccessToken();
            const requestConfig: AxiosRequestConfig = {
                headers: {
                    'Authorization': token,
                    'Accept': 'image/pjpeg',
                    'Content-Type': 'image/jpeg'
                },
                responseType: 'arraybuffer'
            }
            const response = await axios.get(apiUrl, requestConfig);
            const bloblUrl = URL.createObjectURL(new Blob([response.data]));
            return bloblUrl;
        } catch (err: any) {
            // Some users don't have photos, in which case a 404 is returned. Log other errors.
            if (!err.response || err.response.status !== 404) {
                console.error(err);
            }
        }

        // Return empty string if no photo.
        return '';
    }
}

export const graphApiClient = new GraphApiClient();
