import { ReportInfo } from './reportInfo';

export interface IReportProgram {
    programName: string;
    sgName: string;
    sgAlias: string;
    reports: ReportInfo[];
}

export class ReportProgram implements IReportProgram {
    public programName: string;
    public sgName: string;
    public sgAlias: string;
    public reports: ReportInfo[] = [];

    // Below are properties NOT returned from the server but are used for client side purposes.
    public isMember: boolean = false;

    constructor(jsonData: IReportProgram) {
        this.programName = jsonData.programName;
        this.sgName = jsonData.sgName;
        this.sgAlias = jsonData.sgAlias;

        for (let i: number = 0; i < jsonData.reports.length; i++) {
            this.reports.push(new ReportInfo(jsonData.reports[i]));
        }
    }
}
