import { RecyclingCertificate } from './recyclingCertificate';

export interface IJobDocuments {
    recyclingCertificate?: RecyclingCertificate;
}

export class JobDocuments implements IJobDocuments {
    public recyclingCertificate?: RecyclingCertificate;

    constructor(jsonData: IJobDocuments) {
        this.recyclingCertificate = jsonData.recyclingCertificate ? new RecyclingCertificate(jsonData.recyclingCertificate) : undefined;
    }
}
