export interface IPayment {
    id: string;
    partitionKey: string;
    xcv: string;
    programType: string;
    correlationId: string;
    supplierReportingDate: Date;
    week: number;
    supplierId: string;
    supplierName: string;
    supplierPoNumber: string;
    supplierPoCurrency: string;
    msInvoiceNumber: string;
    paymentDate: Date;
    paymentAmount: number;
    paymentAmountUSD: number;
    modifiedDate: Date;
}

export class Payment implements IPayment {
    public id: string;
    public partitionKey: string;
    public xcv: string;
    public programType: string;
    public correlationId: string;
    public supplierReportingDate: Date;
    public week: number;
    public supplierId: string;
    public supplierName: string;
    public supplierPoNumber: string;
    public supplierPoCurrency: string;
    public msInvoiceNumber: string;
    public paymentDate: Date;
    public paymentAmount: number;
    public paymentAmountUSD: number;
    public modifiedDate: Date;

    // Below are properties NOT returned from the server but are used for client side purposes.
    public clientRowKey: string = '';

    constructor(jsonData: IPayment) {
        this.id = jsonData.id;
        this.partitionKey = jsonData.partitionKey;
        this.xcv = jsonData.xcv;
        this.programType = jsonData.programType;
        this.correlationId = jsonData.correlationId;
        this.supplierReportingDate = new Date(jsonData.supplierReportingDate);
        this.week = jsonData.week;
        this.supplierId = jsonData.supplierId;
        this.supplierName = jsonData.supplierName;
        this.supplierPoNumber = jsonData.supplierPoNumber;
        this.supplierPoCurrency = jsonData.supplierPoCurrency;
        this.msInvoiceNumber = jsonData.msInvoiceNumber;
        this.paymentDate = new Date(jsonData.paymentDate);
        this.paymentAmount = jsonData.paymentAmount;
        this.paymentAmountUSD = jsonData.paymentAmountUSD;
        this.modifiedDate = new Date(jsonData.modifiedDate);
    }
}
