import { IStyle, mergeStyleSets } from '@fluentui/react';

/**
 * Styles used on this page.
 */
export const pageStyles = mergeStyleSets({
    reportChildren: {
        marginTop: '10px',
        marginLeft: '30px',
        marginBottom: '10px'
    } as IStyle,
    accessContainer: {
        marginTop: '4px'
    } as IStyle,
    hasAccessIcon: {
        paddingRight: 4,
        fontSize: 16,
        verticalAlign: 'middle',
        color: 'green'
    } as IStyle,
    hasNoAccessIcon: {
        paddingRight: 0,
        fontSize: 22,
        verticalAlign: 'middle',
        color: 'red'
    } as IStyle,
    accessText: {
        verticalAlign: 'middle'
    } as IStyle
});
