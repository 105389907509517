import React, { useState, useCallback, useEffect } from 'react';
import {
    IColumn,
    SelectionMode,
    DetailsListLayoutMode,
    ConstrainMode,
    Stack,
    Text,
    Separator,
    Spinner,
    SpinnerSize,
    Link,
    Image,
    Pivot,
    PivotItem
} from '@fluentui/react';
import { commonStyles, stackTokens } from '../../common/common.styles';
import { ICommonPageProps } from '../../common/common.types';
import {
    useMountEffect,
    nowDate,
    oneMonthAgoDate,
    weekAgoDate,
    monthAndYearString,
    startOfMonthOffset,
    endOfMonthOffset,
    sortOnColumn,
    ColumnsAndItems,
    assignOnColumnClick,
    resetColumnSorting
} from '../../common/common.func';
import { clearErrorByIndex, ErrorBar } from '../../components/ErrorBar/ErrorBar';
import { LoadFailureDisplay } from '../../components/LoadFailureDisplay/LoadFailureDisplay';
import { SupplierTrend } from '../../models/supplier/supplierTrend';
import { SupplierApiInteraction } from '../../models/supplier/supplierApiInteraction';
import { SupplierActivity } from '../../models/supplier/supplierActivity';
import { CustomDetailsList } from '../../components/CustomDetailsList/CustomDetailsList';
import { pageStyles } from './HomePage.styles';
import {
    callApiLoadTotalJobsReported,
    callApiLoadSupplierApiInteractions,
    callApiLoadSupplierTrends,
    callApiLoadLatestSupplierActivityByProgramType,
    resetApiCallState,
    IApiLoadTotalJobsReported,
    IApiLoadSupplierApiInteractions,
    IApiLoadSupplierTrends,
    IApiLoadLatestSupplierActivityByProgramType
} from '../../store/actions/pageActions/homePage.action';
import { getLatestSupplierActivityByProgramTypeColumns, getSupplierApiInteractionsColumns, getSupplierTrendsColumns } from './columns';
import { SummarySelectOption } from './summarySelectOption';
import { appConfig } from '../../shell/appConfig';
import { CallApiState } from '../../store/actions/generic.action';
import { useAppSelector, useAppDispatch } from '../../store/hooks';
import { PageWrapper } from '../../components/PageWrapper/PageWrapper';

interface IPageProps extends ICommonPageProps {
}

export const HomePage: React.FunctionComponent<IPageProps> = (props: IPageProps): JSX.Element => {
    const past7Days: string = 'Past 7 days';

    const [errors, setErrors] = useState<string[]>([]);
    const [summaryRangeSelectionString, setSummaryRangeSelectionString] = useState<string>(past7Days);
    const [summaryRangeStartDate, setSummaryRangeStartDate] = useState<Date>(weekAgoDate());
    const [summaryRangeEndDate, setSummaryRangeEndDate] = useState<Date>(nowDate());
    const [selectedPageSupplierApiInteractions, setSelectedPageSupplierApiInteractions] = useState<number>(1);
    const [selectedPageSupplierTrends, setSelectedPageSupplierTrends] = useState<number>(1);
    const [selectedPageLatestSupplierActivityByProgramType, setSelectedPageLatestSupplierActivityByProgramType] = useState<number>(1);

    const [supplierApiInteractions, setSupplierApiInteractions] = useState<SupplierApiInteraction[] | undefined | null>();
    const [supplierTrends, setSupplierTrends] = useState<SupplierTrend[] | undefined | null>();
    const [latestSupplierActivityByProgramType, setLatestSupplierActivityByProgramType] = useState<SupplierActivity[] | undefined | null>();

    const [supplierApiInteractionsColumns, setSupplierApiInteractionsColumns] = useState<IColumn[]>([]);
    const [supplierTrendsColumns, setSupplierTrendsColumns] = useState<IColumn[]>([]);
    const [latestSupplierActivityByProgramTypeColumns, setLatestSupplierActivityByProgramTypeColumns] = useState<IColumn[]>([]);

    // Redux store selectors to get state from the store when it changes.
    const apiLoadTotalJobsReported: IApiLoadTotalJobsReported =
        useAppSelector<IApiLoadTotalJobsReported>((state) => state.homePageReducer.apiLoadTotalJobsReported);
    const apiLoadSupplierApiInteractions: IApiLoadSupplierApiInteractions =
        useAppSelector<IApiLoadSupplierApiInteractions>((state) => state.homePageReducer.apiLoadSupplierApiInteractions);
    const apiLoadSupplierTrends: IApiLoadSupplierTrends =
        useAppSelector<IApiLoadSupplierTrends>((state) => state.homePageReducer.apiLoadSupplierTrends);
    const apiLoadLatestSupplierActivityByProgramType: IApiLoadLatestSupplierActivityByProgramType =
        useAppSelector<IApiLoadLatestSupplierActivityByProgramType>((state) => state.homePageReducer.apiLoadLatestSupplierActivityByProgramType);

    // Redux store dispatch to send actions to the store.
    const dispatch = useAppDispatch();

    /**
     * This effect does nothing on mount, but will return a cleanup function that runs when the component unmounts.
     */
    useEffect(() => {
        return function cleanup() {
            resetApiCallState();
        }
    }, []);

    /**
     * Handle error.
     * @param errMsg Error message.
     */
    const handleError = useCallback((errMsg: string) => {
        setErrors((prevErrors) => {
            // This will prevent the same error from being displayed if already displayed.
            // ex: Multiple page data load failures might occur if the api is not working,
            // and this page makes multiple load calls for various data.
            if (!prevErrors.includes(errMsg)) {
                return [...prevErrors, errMsg];
            }
            return prevErrors;
        });
    }, []);

    /**
     * Effect for when errors occur in any api call.
     */
    useEffect(() => {
        if (apiLoadTotalJobsReported.errMsg) {
            handleError(apiLoadTotalJobsReported.errMsg);
        }
        if (apiLoadSupplierApiInteractions.errMsg) {
            handleError(apiLoadSupplierApiInteractions.errMsg);
        }
        if (apiLoadSupplierTrends.errMsg) {
            handleError(apiLoadSupplierTrends.errMsg);
        }
        if (apiLoadLatestSupplierActivityByProgramType.errMsg) {
            handleError(apiLoadLatestSupplierActivityByProgramType.errMsg);
        }
    }, [handleError, apiLoadLatestSupplierActivityByProgramType.errMsg, apiLoadSupplierApiInteractions.errMsg, apiLoadSupplierTrends.errMsg, apiLoadTotalJobsReported.errMsg]);

    /**
     * Effect for when Supplier Api Interactions data is loaded.
     */
    useEffect(() => {
        if (apiLoadSupplierApiInteractions.callApiState === CallApiState.DataAvailable) {
            // Store into component state as props are immutable. Needed for sorting capability.
            setSupplierApiInteractions(apiLoadSupplierApiInteractions.supplierApiInteractions);
            setSupplierApiInteractionsColumns(resetColumnSorting(supplierApiInteractionsColumns));
        }
    }, [apiLoadSupplierApiInteractions.callApiState, apiLoadSupplierApiInteractions.supplierApiInteractions, supplierApiInteractionsColumns]);

    /**
     * Effect for when Supplier Trends data is loaded.
     */
    useEffect(() => {
        if (apiLoadSupplierTrends.callApiState === CallApiState.DataAvailable) {
            // Store into component state as props are immutable. Needed for sorting capability.
            setSupplierTrends(apiLoadSupplierTrends.supplierTrends);
            setSupplierTrendsColumns(resetColumnSorting(supplierTrendsColumns));
        }
    }, [apiLoadSupplierTrends.callApiState, apiLoadSupplierTrends.supplierTrends, supplierTrendsColumns]);

    /**
     * Effect for when Supplier Activity By Program Type data is loaded.
     */
    useEffect(() => {
        if (apiLoadLatestSupplierActivityByProgramType.callApiState === CallApiState.DataAvailable) {
            // Store into component state as props are immutable. Needed for sorting capability.
            setLatestSupplierActivityByProgramType(apiLoadLatestSupplierActivityByProgramType.latestSupplierActivityByProgramType);
            setLatestSupplierActivityByProgramTypeColumns(resetColumnSorting(latestSupplierActivityByProgramTypeColumns));
        }
    }, [latestSupplierActivityByProgramTypeColumns, apiLoadLatestSupplierActivityByProgramType.callApiState, apiLoadLatestSupplierActivityByProgramType.latestSupplierActivityByProgramType]);

    /**
     * Resets the page for supplier api interactions to 1 when loading has stopped.
     */
    useEffect(() => {
        if (apiLoadSupplierApiInteractions.callApiState === CallApiState.Completed) {
            setSelectedPageSupplierApiInteractions(1);
        }
    }, [apiLoadSupplierApiInteractions.callApiState]);

    /**
     * Resets the page for supplier trends to 1 when loading has stopped.
     */
    useEffect(() => {
        if (apiLoadSupplierTrends.callApiState === CallApiState.Completed) {
            setSelectedPageSupplierTrends(1);
        }
    }, [apiLoadSupplierTrends.callApiState]);

    /**
     * Resets the page for latest supplier activity to 1 when loading has stopped.
     */
    useEffect(() => {
        if (apiLoadLatestSupplierActivityByProgramType.callApiState === CallApiState.Completed) {
            setSelectedPageLatestSupplierActivityByProgramType(1);
        }
    }, [apiLoadLatestSupplierActivityByProgramType.callApiState]);

    /**
     * Load summary range data.
     */
    const loadSummaryRangeData = useCallback((startDate: Date, endDate: Date) => {
        // Fire off each load method asynchronously. Not using await on purpose, so they all run at the same time.
        if (appConfig.current.featureFlighting.homePageSupplierSummary) {
            dispatch(callApiLoadTotalJobsReported(startDate, endDate));
        }
        if (appConfig.current.featureFlighting.homePageSupplierApiInteractions) {
            dispatch(callApiLoadSupplierApiInteractions(startDate, endDate));
        }
        if (appConfig.current.featureFlighting.homePageSupplierTrends) {
            dispatch(callApiLoadSupplierTrends(startDate, endDate));
        }
    }, [dispatch]);

    /**
     * This effect is run once during page load.
     */
    useMountEffect(() => {
        loadSummaryRangeData(summaryRangeStartDate, summaryRangeEndDate);

        if (appConfig.current.featureFlighting.homePageLatestSupplierActivityByProgramType) {
            dispatch(callApiLoadLatestSupplierActivityByProgramType(oneMonthAgoDate(), nowDate()));
        }

        // Set up the columns for each grid.
        if (appConfig.current.featureFlighting.homePageSupplierApiInteractions) {
            setSupplierApiInteractionsColumns(getSupplierApiInteractionsColumns());
        }
        if (appConfig.current.featureFlighting.homePageSupplierTrends) {
            setSupplierTrendsColumns(getSupplierTrendsColumns());
        }
        if (appConfig.current.featureFlighting.homePageLatestSupplierActivityByProgramType) {
            setLatestSupplierActivityByProgramTypeColumns(getLatestSupplierActivityByProgramTypeColumns());
        }
    });

    /**
     * Supplier api intreactions column click event handler.
     * @param ev Mouse event.
     * @param column Column being sorted on.
     */
    const onSupplierApiInteractionsColumnClick = useCallback((ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        if (supplierApiInteractions) {
            const columnsAndItems: ColumnsAndItems<SupplierApiInteraction> = sortOnColumn<SupplierApiInteraction>(column, supplierApiInteractionsColumns, supplierApiInteractions);
            setSupplierApiInteractions(columnsAndItems.items);
            setSupplierApiInteractionsColumns(columnsAndItems.columns);
        }
    }, [supplierApiInteractions, supplierApiInteractionsColumns]);

    /**
     * Effect to update onColumnClick handler. Otherwise the component state referenced in onColumnClick
     * would be stale from the render pass it was created on.
     */
    useEffect(() => {
        assignOnColumnClick(supplierApiInteractionsColumns, onSupplierApiInteractionsColumnClick);
    }, [supplierApiInteractionsColumns, onSupplierApiInteractionsColumnClick]);

    /**
     * Supplier trends column click event handler.
     * @param ev Mouse event.
     * @param column Column being sorted on.
     */
    const onSupplierTrendsColumnClick = useCallback((ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        if (apiLoadSupplierTrends.supplierTrends) {
            const columnsAndItems: ColumnsAndItems<SupplierTrend> = sortOnColumn<SupplierTrend>(column, supplierTrendsColumns, apiLoadSupplierTrends.supplierTrends);
            setSupplierTrends(columnsAndItems.items);
            setSupplierTrendsColumns(columnsAndItems.columns);
        }
    }, [apiLoadSupplierTrends.supplierTrends, supplierTrendsColumns]);

    /**
     * Effect to update onColumnClick handler. Otherwise the component state referenced in onColumnClick
     * would be stale from the render pass it was created on.
     */
    useEffect(() => {
        assignOnColumnClick(supplierTrendsColumns, onSupplierTrendsColumnClick);
    }, [supplierTrendsColumns, onSupplierTrendsColumnClick]);

    /**
     * Latest supplier activities column click event handler.
     * @param ev Mouse event.
     * @param column Column being sorted on.
     */
    const onLatestSupplierActivityByProgramTypeColumnClick = useCallback((ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        if (apiLoadLatestSupplierActivityByProgramType.latestSupplierActivityByProgramType) {
            const columnsAndItems: ColumnsAndItems<SupplierActivity> = sortOnColumn<SupplierActivity>(column, latestSupplierActivityByProgramTypeColumns, apiLoadLatestSupplierActivityByProgramType.latestSupplierActivityByProgramType);
            setLatestSupplierActivityByProgramType(columnsAndItems.items);
            setLatestSupplierActivityByProgramTypeColumns(columnsAndItems.columns);
        }
    }, [latestSupplierActivityByProgramTypeColumns, apiLoadLatestSupplierActivityByProgramType.latestSupplierActivityByProgramType]);

    /**
     * Effect to update onColumnClick handler. Otherwise the component state referenced in onColumnClick
     * would be stale from the render pass it was created on.
     */
    useEffect(() => {
        assignOnColumnClick(latestSupplierActivityByProgramTypeColumns, onLatestSupplierActivityByProgramTypeColumnClick);
    }, [latestSupplierActivityByProgramTypeColumns, onLatestSupplierActivityByProgramTypeColumnClick]);

    /**
     * On summary range selection click event handler.
     * @param item Selected PivotItem.
     * @param ev Mouse event.
     */
    const onSummaryRangeSelectionClick = (item?: PivotItem, ev?: React.MouseEvent<HTMLElement>): void => {
        let startDate: Date;
        let endDate: Date;

        switch (item?.props['data-summaryselectoption']) {
            case SummarySelectOption.Past7Days:
                setSummaryRangeSelectionString(past7Days);
                startDate = weekAgoDate();
                endDate = nowDate();
                break;
            case SummarySelectOption.CurrentMonth:
                setSummaryRangeSelectionString(monthAndYearString(0));
                startDate = startOfMonthOffset(0);
                endDate = endOfMonthOffset(0);
                break;
            case SummarySelectOption.LastMonth:
                setSummaryRangeSelectionString(monthAndYearString(-1));
                startDate = startOfMonthOffset(-1);
                endDate = endOfMonthOffset(-1);
                break;
            case SummarySelectOption.TwoMonthAgo:
                setSummaryRangeSelectionString(monthAndYearString(-2));
                startDate = startOfMonthOffset(-2);
                endDate = endOfMonthOffset(-2);
                break;
            default:
                return;
        }

        setSummaryRangeStartDate(startDate);
        setSummaryRangeEndDate(endDate);

        loadSummaryRangeData(startDate, endDate);
    }

    return (
        <PageWrapper {...props}>
            <ErrorBar errors={errors} onDismiss={(index: number) => {
                setErrors(clearErrorByIndex(errors, index));
            }} />

            <Stack tokens={stackTokens}>
                <Stack.Item>
                    <div className={commonStyles.pageHeader}>
                        <Text variant="xLarge" role="heading" aria-level={1}>Responsible Recycling at Microsoft</Text>
                    </div>
                </Stack.Item>
                <Stack.Item>
                    <Text variant="mediumPlus">
                        The Responsible Recycling program ensures Microsoft’s redundant IT equipment is recycled in a secure, compliant,
                        and environmentally responsible manner. The equipment includes laptops, desktops, devices, networking equipment,
                        servers, printers, accessories, and phones.
                    </Text>
                </Stack.Item>
                <Stack.Item>
                    <Text variant="mediumPlus">
                        Employees turn in devices at various locations and through different means. Recycling suppliers consolidate all
                        the devices and securely transfers them to the supplier location to start the recycling process. This reduces
                        Microsoft’s carbon footprint while also generating revenue.
                    </Text>
                </Stack.Item>
                <Stack.Item>
                    <Text variant="mediumPlus">
                        On this site you can view data and reports that show how Microsoft works with various recycling suppliers in
                        order to help achieve the goal of becoming carbon negative by 2030.
                    </Text>
                </Stack.Item>
                <Stack.Item>
                    <Separator></Separator>
                </Stack.Item>

                {/* Horizontal wrap stack for sections 1 and 2. */}
                <Stack horizontal wrap tokens={stackTokens}>

                    {/* Section 1: Summary range selection using pivot items, with results for: */}
                    {/* Supplier Summary, Supplier API Interactions, Supplier Trends */}
                    {/* All of which can be enabled or disabled in featureFlighting config. */}
                    <Stack.Item>
                        <Stack>
                            <Stack.Item>
                                <Pivot aria-label="Summary range selection" onLinkClick={onSummaryRangeSelectionClick}>
                                    <PivotItem headerText={past7Days} data-summaryselectoption={SummarySelectOption.Past7Days}></PivotItem>
                                    <PivotItem headerText={monthAndYearString(0)} data-summaryselectoption={SummarySelectOption.CurrentMonth}></PivotItem>
                                    <PivotItem headerText={monthAndYearString(-1)} data-summaryselectoption={SummarySelectOption.LastMonth}></PivotItem>
                                    <PivotItem headerText={monthAndYearString(-2)} data-summaryselectoption={SummarySelectOption.TwoMonthAgo}></PivotItem>
                                </Pivot>
                            </Stack.Item>
                            { appConfig.current.featureFlighting.homePageSupplierSummary && (
                                <Stack.Item>
                                    <div style={{ position: 'relative' }}>
                                        { /* Not using text inside <Separator> as it is not good for accessibility, so using this technique instead. */}
                                        <Separator></Separator>
                                        <Text variant="medium" role="heading" aria-level={2} style={{ fontWeight: 400, textAlign: 'center', display: 'block' }}>Supplier Summary for {summaryRangeSelectionString}</Text>
                                        <Separator></Separator>
                                    </div>
                                    <Text variant="medium">Jobs Reported</Text>
                                    {apiLoadTotalJobsReported.callApiState === CallApiState.Running ?
                                        <Spinner size={SpinnerSize.medium} className={commonStyles.spinner} />
                                        :
                                        <>
                                            {apiLoadTotalJobsReported.callApiState === CallApiState.Completed && apiLoadTotalJobsReported.errMsg ?
                                                <LoadFailureDisplay />
                                                :
                                                <Text variant="xxLarge" block={true}>
                                                    {apiLoadTotalJobsReported.totalJobsReported}
                                                </Text>
                                            }
                                        </>
                                    }
                                </Stack.Item>
                            )}

                            { appConfig.current.featureFlighting.homePageSupplierApiInteractions && (
                                <Stack.Item>
                                    <div style={{ position: 'relative' }}>
                                        <Separator></Separator>
                                        <Text variant="medium" role="heading" aria-level={2} style={{ fontWeight: 400, textAlign: 'center', display: 'block' }}>Supplier API Interactions for {summaryRangeSelectionString}</Text>
                                        <Separator></Separator>
                                    </div>
                                    {apiLoadSupplierApiInteractions.callApiState === CallApiState.Running ?
                                        <Spinner size={SpinnerSize.medium} className={commonStyles.spinner} />
                                        :
                                        <>
                                            {apiLoadSupplierApiInteractions.callApiState === CallApiState.Completed && apiLoadSupplierApiInteractions.errMsg ?
                                                <LoadFailureDisplay />
                                                :
                                                <CustomDetailsList
                                                    className={pageStyles.detailsList}
                                                    showExcelExport={true}
                                                    exportExcelSheetName={'Supplier API Interactions for ' + summaryRangeSelectionString}
                                                    ariaLabelForGrid={'Supplier API Interactions for ' + summaryRangeSelectionString}
                                                    displayTotalItems={false}
                                                    showPaginator={true}
                                                    showPageSize={true}
                                                    selectedPage={selectedPageSupplierApiInteractions}
                                                    onSelectedPageChange={(page) => {
                                                        setSelectedPageSupplierApiInteractions(page);
                                                    }}
                                                    showNoDataFoundMsg={!supplierApiInteractions || supplierApiInteractions.length === 0}
                                                    items={supplierApiInteractions ? supplierApiInteractions : []}
                                                    compact={false}
                                                    columns={supplierApiInteractionsColumns}
                                                    selectionMode={SelectionMode.none}
                                                    getKey={(item: SupplierApiInteraction) => item.clientRowKey}
                                                    setKey="none"
                                                    layoutMode={DetailsListLayoutMode.fixedColumns}
                                                    isHeaderVisible={true}
                                                    constrainMode={ConstrainMode.horizontalConstrained} />
                                            }
                                        </>
                                    }
                                </Stack.Item>
                            )}

                            { appConfig.current.featureFlighting.homePageSupplierTrends && (
                                <Stack.Item>
                                    <div style={{ position: 'relative' }}>
                                        <Separator></Separator>
                                        <Text variant="medium" role="heading" aria-level={2} style={{ fontWeight: 400, textAlign: 'center', display: 'block' }}>Supplier Trends for {summaryRangeSelectionString}</Text>
                                        <Separator></Separator>
                                    </div>
                                    {apiLoadSupplierTrends.callApiState === CallApiState.Running ?
                                        <Spinner size={SpinnerSize.medium} className={commonStyles.spinner} />
                                        :
                                        <>
                                            {apiLoadSupplierTrends.callApiState === CallApiState.Completed && apiLoadSupplierTrends.errMsg ?
                                                <LoadFailureDisplay />
                                                :
                                                <CustomDetailsList
                                                    className={pageStyles.detailsList}
                                                    showExcelExport={true}
                                                    exportExcelSheetName={'Supplier Trends for ' + summaryRangeSelectionString}
                                                    ariaLabelForGrid={'Supplier Trends for ' + summaryRangeSelectionString}
                                                    displayTotalItems={false}
                                                    showPaginator={true}
                                                    showPageSize={true}
                                                    selectedPage={selectedPageSupplierTrends}
                                                    onSelectedPageChange={(page) => {
                                                        setSelectedPageSupplierTrends(page);
                                                    }}
                                                    showNoDataFoundMsg={!supplierTrends || supplierTrends.length === 0}
                                                    items={supplierTrends ? supplierTrends : []}
                                                    compact={false}
                                                    columns={supplierTrendsColumns}
                                                    selectionMode={SelectionMode.none}
                                                    getKey={(item: SupplierTrend) => item.clientRowKey}
                                                    setKey="none"
                                                    layoutMode={DetailsListLayoutMode.fixedColumns}
                                                    isHeaderVisible={true}
                                                    constrainMode={ConstrainMode.horizontalConstrained} />
                                            }
                                        </>
                                    }
                                </Stack.Item>
                            )}
                        </Stack>
                    </Stack.Item>
                </Stack>

                {/* Horizontal wrap stack for section 3. */}
                <Stack horizontal wrap tokens={stackTokens}>

                    {/* Section 3: Latest supplier activity by program type */}
                    { appConfig.current.featureFlighting.homePageLatestSupplierActivityByProgramType && (
                        <Stack.Item>
                            <div style={{ position: 'relative' }}>
                                <Separator></Separator>
                                <Text variant="medium" role="heading" aria-level={2} style={{ fontWeight: 400, textAlign: 'center', display: 'block' }}>Latest supplier activity by program type</Text>
                                <Separator></Separator>
                            </div>
                            {apiLoadLatestSupplierActivityByProgramType.callApiState === CallApiState.Running ?
                                <Spinner size={SpinnerSize.medium} className={commonStyles.spinner} />
                                :
                                <>
                                    {apiLoadLatestSupplierActivityByProgramType.callApiState === CallApiState.Completed && apiLoadLatestSupplierActivityByProgramType.errMsg ?
                                        <LoadFailureDisplay />
                                        :
                                        <CustomDetailsList
                                            className={pageStyles.detailsList}
                                            showExcelExport={true}
                                            exportExcelSheetName="Latest supplier activity by program type"
                                            ariaLabelForGrid="Latest supplier activity by program type"
                                            displayTotalItems={false}
                                            showPaginator={true}
                                            showPageSize={true}
                                            selectedPage={selectedPageLatestSupplierActivityByProgramType}
                                            onSelectedPageChange={(page) => {
                                                setSelectedPageLatestSupplierActivityByProgramType(page);
                                            }}
                                            showNoDataFoundMsg={!latestSupplierActivityByProgramType || latestSupplierActivityByProgramType.length === 0}
                                            items={latestSupplierActivityByProgramType ? latestSupplierActivityByProgramType : []}
                                            compact={false}
                                            columns={latestSupplierActivityByProgramTypeColumns}
                                            selectionMode={SelectionMode.none}
                                            getKey={(item: SupplierActivity) => item.clientRowKey}
                                            setKey="none"
                                            layoutMode={DetailsListLayoutMode.fixedColumns}
                                            isHeaderVisible={true}
                                            constrainMode={ConstrainMode.horizontalConstrained} />
                                    }
                                </>
                            }
                        </Stack.Item>
                    )}

                </Stack>
            </Stack>

            <Separator></Separator>

            <Stack horizontal tokens={{ childrenGap: 30 }}>
                <Stack.Item>
                    <div style={{ marginRight: 1 }}>
                        <Image alt="Microsoft: A carbon disappearing act" src="/images/RecycleProcess.png" style={{ maxHeight: 280, maxWidth: '100%' }} />
                    </div>
                    <Link href="https://microsoft.sharepoint.com/teams/CFETeam/SitePages/Microsoft--A-carbon-disappearing-act.aspx" target="_blank">
                        <Text variant="mediumPlus">Microsoft: A carbon disappearing act</Text>
                    </Link>
                </Stack.Item>
                <Stack.Item>
                    <div>
                        <Image alt="Responsible recycle: Give your old device a new life" src="/images/78749-Recycle-1B.jpg" style={{ maxHeight: 260, maxWidth: '100%' }} />
                    </div>
                    <Link href="https://microsoft.sharepoint.com/teams/MyProcureWeb/SitePages/ResponsibleRecycle.aspx" target="_blank">
                        <Text variant="mediumPlus">Responsible recycle: Give your old device a new life</Text>
                    </Link>
                </Stack.Item>
                <Stack.Item>
                    <div>
                        <Image alt="Microsoft will be carbon negative by 2030" src="/images/MS-Event-2020-01-Carbon_T8A7561-small.jpg" style={{ maxHeight: 260, maxWidth: '100%' }} />
                    </div>
                    <Link href="https://blogs.microsoft.com/blog/2020/01/16/microsoft-will-be-carbon-negative-by-2030" target="_blank">
                        <Text variant="mediumPlus">Microsoft will be carbon negative by 2030</Text>
                    </Link>
                </Stack.Item>
            </Stack>
        </PageWrapper>
    );
};
