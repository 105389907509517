import { UnitStatus } from '../../../models/domainData/unitStatus';
import { serviceApiClient } from '../../../services/api/serviceApiClient';
import { AppDispatch, reduxStore } from '../../reduxStore';
import * as actionTypes from '../actionTypes';
import { callApi, CallApiState, ICallApiBase } from '../generic.action';

/**
 * Load unit statuses action payload.
 */
export interface IApiLoadUnitStatuses extends ICallApiBase {
    unitStatuses?: UnitStatus[] | null;
}

/**
 * Load unit statuses.
 * @returns Redux dispatch function.
 */
export const callApiLoadUnitStatuses = (programType: string): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<UnitStatus[]>(
        actionTypes.API_LOAD_UNIT_STATUSES,
        async () => {
            return await serviceApiClient.unitStatuses(programType) || [];
        },
        (payload: IApiLoadUnitStatuses, data) => {
            if (data) {
                // Set the clientRowKey in each object so it can be bound into the DetailsList.
                data.forEach((value: UnitStatus, index: number) => {
                    value.clientRowKey = index.toString()
                });
            }

            payload.unitStatuses = data;
        }
    );
};

/**
 * Update unit status action payload.
 */
export interface IApiUpdateUnitStatus extends ICallApiBase {
    // No data returned from this api.
}

/**
 * Update unit status.
 * @param unitStatus Unit status to update.
 * @returns Redux dispatch function.
 */
export const callApiUpdateUnitStatus = (unitStatus: UnitStatus): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<null>(
        actionTypes.API_UPDATE_UNIT_STATUS,
        async () => {
            return await serviceApiClient.updateUnitStatus(unitStatus);
        },
        (payload: IApiUpdateUnitStatus, data) => {
            // No data returned from this api.
        }
    );
};

/**
 * Add unit status action payload.
 */
export interface IApiAddUnitStatus extends ICallApiBase {
    // No data returned from this api.
}

/**
 * Add unit status.
 * @param unitStatus Unit status to add.
 * @param programType Program type.
 * @returns Redux dispatch function.
 */
export const callApiAddUnitStatus = (unitStatus: UnitStatus, programType: string): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<null>(
        actionTypes.API_ADD_UNIT_STATUS,
        async () => {
            return await serviceApiClient.addUnitStatus(unitStatus, programType);
        },
        (payload: IApiAddUnitStatus, data) => {
            // No data returned from this api.
        }
    );
};

/**
 * Delete unit status action payload.
 */
export interface IApiDeleteUnitStatus extends ICallApiBase {
    // No data returned from this api.
}

/**
 * Delete unit status.
 * @param unitStatus Unit status to delete.
 * @param programType Program type.
 * @returns Redux dispatch function.
 */
export const callApiDeleteUnitStatus = (unitStatus: UnitStatus): (dispatch: AppDispatch) => Promise<void> => {
    return callApi<null>(
        actionTypes.API_DELETE_UNIT_STATUS,
        async () => {
            return await serviceApiClient.deleteUnitStatus(unitStatus);
        },
        (payload: IApiDeleteUnitStatus, data) => {
            // No data returned from this api.
        }
    );
};

/**
 * Reset the page action call state for all api calls.
 * This clear all data from the Redux store for these calls, including any error state for failed calls.
 * This makes it so if there was a failed call and an error was shown on the page, if the user navigates
 * to another page and back, the prior error would not be shown.
 */
export const resetApiCallState = () => {
    const apiLoadUnitStatuses: IApiLoadUnitStatuses = {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        unitStatuses: undefined
    };
    reduxStore.dispatch({
        type: actionTypes.API_LOAD_UNIT_STATUSES,
        payload: apiLoadUnitStatuses
    });

    const apiUpdateUnitStatuses: IApiUpdateUnitStatus = {
        callApiState: CallApiState.Initial,
        errMsg: undefined
    };
    reduxStore.dispatch({
        type: actionTypes.API_UPDATE_UNIT_STATUS,
        payload: apiUpdateUnitStatuses
    });

    const apiAddUnitStatuses: IApiAddUnitStatus = {
        callApiState: CallApiState.Initial,
        errMsg: undefined
    };
    reduxStore.dispatch({
        type: actionTypes.API_ADD_UNIT_STATUS,
        payload: apiAddUnitStatuses
    });

    const apiDeleteUnitStatuses: IApiDeleteUnitStatus = {
        callApiState: CallApiState.Initial,
        errMsg: undefined
    };
    reduxStore.dispatch({
        type: actionTypes.API_DELETE_UNIT_STATUS,
        payload: apiDeleteUnitStatuses
    });
};
