export interface ISupplierInvoiceInfo {
    programType: string;
    supplierId: string;
    supplierJobId: string;
    msCompanyCode: string;
    msPoNumber: string;
    msPoCurrency: string;
    supplierInvoiceNumber: string;
    supplierBillingEntity: string;
    billingCountry: string;
    totalUnitFees?: number;
    totalUnitFeesUSD?: number;
    logisticsFee?: number;
    logisticsFeeUSD?: number;
    minimumServiceFee?: number;
    minimumServiceFeeUSD?: number;
    invoiceAmount?: number;
    invoiceAmountUSD?: number;
}

export class SupplierInvoiceInfo implements ISupplierInvoiceInfo {
    public programType: string;
    public supplierId: string;
    public supplierJobId: string;
    public msCompanyCode: string;
    public msPoNumber: string;
    public msPoCurrency: string;
    public supplierInvoiceNumber: string;
    public supplierBillingEntity: string;
    public billingCountry: string;
    public totalUnitFees?: number;
    public totalUnitFeesUSD?: number;
    public logisticsFee?: number;
    public logisticsFeeUSD?: number;
    public minimumServiceFee?: number;
    public minimumServiceFeeUSD?: number;
    public invoiceAmount?: number;
    public invoiceAmountUSD?: number;

    // Below are properties NOT returned from the server but are used for client side purposes.
    public clientRowKey: string = '';

    constructor(jsonData: ISupplierInvoiceInfo) {
        this.programType = jsonData.programType;
        this.supplierId = jsonData.supplierId;
        this.supplierJobId = jsonData.supplierJobId;
        this.msCompanyCode = jsonData.msCompanyCode;
        this.msPoNumber = jsonData.msPoNumber;
        this.msPoCurrency = jsonData.msPoCurrency;
        this.supplierInvoiceNumber = jsonData.supplierInvoiceNumber;
        this.supplierBillingEntity = jsonData.supplierBillingEntity;
        this.billingCountry = jsonData.billingCountry;
        this.totalUnitFees = jsonData.totalUnitFees;
        this.totalUnitFeesUSD = jsonData.totalUnitFeesUSD;
        this.logisticsFee = jsonData.logisticsFee;
        this.logisticsFeeUSD = jsonData.logisticsFeeUSD;
        this.minimumServiceFee = jsonData.minimumServiceFee;
        this.minimumServiceFeeUSD = jsonData.minimumServiceFeeUSD;
        this.invoiceAmount = jsonData.invoiceAmount;
        this.invoiceAmountUSD = jsonData.invoiceAmountUSD;
    }
}
