import { AnyAction, Reducer } from 'redux';
import * as actionTypes from '../../actions/actionTypes';
import {
    IApiDownloadBlobStream,
    IApiLoadDataErasureCertificates,
    IApiLoadInvoiceDocuments,
    IApiLoadPoDocuments,
    IApiLoadRecyclingCertificates
} from '../../actions/pageActions/certificatesPage.action';
import { CallApiState } from '../../actions/generic.action';

export interface ICertificatesPageReducerState {
    apiLoadRecyclingCertificates: IApiLoadRecyclingCertificates;
    apiLoadPoDocuments: IApiLoadPoDocuments;
    apiLoadInvoiceDocuments: IApiLoadInvoiceDocuments;
    apiLoadDataErasureCertificates: IApiLoadDataErasureCertificates;
    apiDownloadBlobStream: IApiDownloadBlobStream;
}

const initialCertificatesPageReducerState: ICertificatesPageReducerState = {
    apiLoadRecyclingCertificates: {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        recyclingCertificates: undefined
    },
    apiLoadPoDocuments: {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        poDocuments: undefined
    },
    apiLoadInvoiceDocuments: {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        invoiceDocuments: undefined
    },
    apiLoadDataErasureCertificates: {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        dataErasureCertificates: undefined
    },
    apiDownloadBlobStream: {
        callApiState: CallApiState.Initial,
        errMsg: undefined,
        blobStream: undefined,
        item: undefined,
        documentType: undefined
    }
};

export const certificatesPageReducer: Reducer<ICertificatesPageReducerState, AnyAction> = (
    state: ICertificatesPageReducerState = initialCertificatesPageReducerState, action: AnyAction
): ICertificatesPageReducerState => {
    switch (action.type) {
        case actionTypes.API_LOAD_RECYCLING_CERTIFICATES: {
            const payload: IApiLoadRecyclingCertificates = action.payload as IApiLoadRecyclingCertificates;
            return {
                ...state,
                apiLoadRecyclingCertificates: { ...payload }
            }
        }
        case actionTypes.API_LOAD_PO_DOCUMENTS: {
            const payload: IApiLoadPoDocuments = action.payload as IApiLoadPoDocuments;
            return {
                ...state,
                apiLoadPoDocuments: { ...payload }
            }
        }
        case actionTypes.API_LOAD_INVOICE_DOCUMENTS: {
            const payload: IApiLoadInvoiceDocuments = action.payload as IApiLoadInvoiceDocuments;
            return {
                ...state,
                apiLoadInvoiceDocuments: { ...payload }
            }
        }
        case actionTypes.API_LOAD_DATA_ERASURE_CERTIFICATES: {
            const payload: IApiLoadDataErasureCertificates = action.payload as IApiLoadDataErasureCertificates;
            return {
                ...state,
                apiLoadDataErasureCertificates: { ...payload }
            }
        }
        case actionTypes.API_DOWNLOAD_BLOB_STREAM: {
            const payload: IApiDownloadBlobStream = action.payload as IApiDownloadBlobStream;
            return {
                ...state,
                apiDownloadBlobStream: { ...payload }
            }
        }
        default:
    }

    return state;
};
