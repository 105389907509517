// Common functionality used across admin pages.

import React from 'react';
import { FontIcon, IColumn, TooltipHost } from '@fluentui/react';
import { commonStyles } from '../../common/common.styles';

export interface ErrorField {
    fieldName: string;
    errorMsg: string;
}

export interface InputError {
    item: any,
    errorFields: ErrorField[]
}

/**
 * Renders column header with tooltip.
 * @param columnName Column name.
 * @param tooltipId Tooltip id.
 * @param tooltipText Tooltip text.
 * @returns JSX for column header.
 */
export const renderColumnHeader = (columnName: string, tooltipId: string, tooltipText: string): JSX.Element => {
    return (
        <table style={{ borderCollapse: 'collapse' }}>
            <tbody>
                <tr>
                    <td>
                        {columnName}
                    </td>
                    <td style={{ verticalAlign: 'top' }}>
                        <div style={{ verticalAlign: 'middle', margin: '2px 0 0 5px' }}>
                            <TooltipHost content={tooltipText}
                                id={tooltipId} calloutProps={{ gapSpace: 0 }}>
                                <div tabIndex={0} className={commonStyles.tooltipFontIconContainer}>
                                    <FontIcon iconName="Info" style={{ paddingRight: 4, fontSize: 16 }} aria-describedby={tooltipId} />    
                                </div>
                            </TooltipHost>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    );
}

/**
 * Add input error message.
 * @param currentInputErrors Current input errors array.
 * Note, passing this in rather than using the inputErrors state, because if multiple calls of these functions happen
 * in one render it would use the prior state.
 * @param item The item the error is for.
 * @param fieldName The field name in the item that is in error.
 * @param errMsg Error message.
 * @returns New input errors array.
 */
export const addInputError = (currentInputErrors: InputError[], item: any, fieldName: string, errMsg: string): InputError[] => {
    // Make a new input errors collection.
    const newInputErrors: InputError[] = [...currentInputErrors];

    // See if there is a current input error for this item.
    let inputError: InputError | undefined = newInputErrors.find((inputError) => {
        if (inputError.item === item) { 
            return true;
        } else {
            return false;
        }
    });

    // If not, make a new input error and add it to the list.
    if (!inputError) {
        inputError = {
            item: item,
            errorFields: []
        }
        newInputErrors.push(inputError);
    }

    // See if there is a current input error for this field.
    let errorField: ErrorField | undefined = inputError.errorFields.find((errorField) => {
        if (errorField.fieldName === fieldName) {
            return true;
        } else {
            return false;
        }
    });

    // If not, make a new error field and add it to the list.
    if (!errorField) {
        errorField = {
            fieldName: fieldName,
            errorMsg: errMsg
        }
        inputError.errorFields.push(errorField);
    }

    return newInputErrors;
};

/**
 * Remove input error.
 * @param currentInputErrors Current input errors array.
 * @param item The item the error is for.
 * @param fieldName The field name to remove the error for.
 * @returns New input errors array.
 */
 export const removeInputError = (currentInputErrors: InputError[], item: any, fieldName: string): InputError[] => {
    // Make a new input errors collection.
    const newInputErrors: InputError[] = [...currentInputErrors];

    let inputErrorIndex: number = -1;
    let errorFieldIndex: number = -1;

    // See if there is a current input error for this item.
    const inputError: InputError | undefined = newInputErrors.find((inputError, index) => {
        if (inputError.item === item) { 
            inputErrorIndex = index;
            return true;
        } else {
            return false;
        }
    });

    if (inputError) {
        // See if there is an errorField already for this field name.
        const errorField: ErrorField | undefined = inputError.errorFields.find((errorField, index) => {
            if (errorField.fieldName === fieldName) {
                errorFieldIndex = index;
                return true;
            } else {
                return false;
            }
        });

        // If there was an error for that field, remove it.
        if (errorField && errorFieldIndex > -1) {
            inputError.errorFields.splice(errorFieldIndex, 1);
        }
        // If there are no more fields in error for this item, remove the input error object from the list.
        if (inputError.errorFields.length === 0) {
            newInputErrors.splice(inputErrorIndex, 1);
        }
    }

    return newInputErrors;
};

/**
 * Add or remove an input error.
 * @param currentInputErrors Current input errors array.
 * @param column Column.
 * @param item Item to add or remove the error for.
 * @param fieldName Field name in the item.
 * @param value Value to test for error.
 * @returns Current input errors.
 */
export const addOrRemoveError = (currentInputErrors: InputError[], column: IColumn, item: any, fieldName: string, value: string) => {
    if (column.data && column.data.pattern) {
        const re = new RegExp(column.data.pattern);
        if (re.test(value)) {
            currentInputErrors = removeInputError(currentInputErrors, item, fieldName);
        } else {
            currentInputErrors = addInputError(currentInputErrors, item, fieldName, column.data.inputErrorMsg);
        }
    }

    return currentInputErrors;
};

/**
 * Check for input error.
 * @param currentInputErrors Current input errors array.
 * @param item The item to check for input error.
 * @param fieldName The field name to check.
 * @returns Error message if present, otherwise undefined.
 */
export const checkForInputError = (currentInputErrors: InputError[], item: any, fieldName: string): string | undefined => {
    // See if there is a current input error for this item.
    const inputError: InputError | undefined = currentInputErrors.find((inputError) => {
        if (inputError.item === item) { 
            return true;
        } else {
            return false;
        }
    });

    if (inputError) {
        // See if there is an errorField already for this field name.
        const errorField: ErrorField | undefined = inputError.errorFields.find((errorField) => {
            if (errorField.fieldName === fieldName) {
                return true;
            } else {
                return false;
            }
        });

        // If found, return the error message.
        if (errorField) {
            return errorField.errorMsg;
        }
    }

    return undefined;
};

/**
 * Check for input error.
 * @param currentInputErrors Current input errors array.
 * @param item The item the error is for.
 * @returns True if item has any input error for any field.
 */
 export const checkItemForAnyInputError = (currentInputErrors: InputError[], item: any): boolean => {
    // See if there is a current input error for this item.
    const inputError: InputError | undefined = currentInputErrors.find((inputError) => {
        if (inputError.item === item) { 
            return true;
        } else {
            return false;
        }
    });

    if (inputError && inputError.errorFields.length > 0) {
        return true;
    }

    return false;
};
