import { appConfig } from '../../shell/appConfig';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { JsonObjectFactory } from './jsonObjectFactory';
import { getAccessTokenForRecycling, getAccessTokenForGraph } from '../auth/msalHelper';
import { telemetryService } from '../TelemetryService/TelemetryService';
import { createGuid } from '../../common/common.func';

// This api client is using axios. Note that axios and the browser inbuilt fetch api are very similar.
// The fetch api works only with modern ES2015/ES6 browsers (or use a polyfill). While using axios works
// with older browsers also. Plus axios offers some other advantages such as interceptors and request
// cancellation, and more. See these links:
// https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
// https://github.com/axios/axios
// https://stackoverflow.com/questions/40844297/what-is-difference-between-axios-and-fetch

/**
 * Enum for supported services.
 */
export enum ApiService {
    Recycling,
    Graph
}

export interface IParams {
    [Key: string]: any;
}

/**
 * Api client base class.
 */
export abstract class ApiClientBase {
    protected bearer: string = 'Bearer ';
    protected mockDataFolder: string = 'mockData';
    protected useMockDataOverride: boolean = true;
    protected apiService: ApiService;

    /**
     * Constructor.
     * @param apiService API service this is to be used for. This is used to get the access token for the proper service.
     * For example, Graph api or Receipting api.
     */
    constructor(apiService: ApiService) {
        this.apiService = apiService;
    }

    /**
     * Simulated delay to be used with mock data.
     * @param msDelay Delay in milliseconds. If not supplied the config value is used.
     */
    protected async simulatedDelay(msDelay?: number): Promise<void> {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, msDelay || appConfig.current.service.useLocalMockDataSimulatedDelay);
        });
    }

    /**
     * Gets an access token for the api service.
     */
    protected async getAccessToken(): Promise<string> {
        switch (this.apiService) {
            case ApiService.Recycling:
                return `${this.bearer}${await getAccessTokenForRecycling()}`;
            case ApiService.Graph:
                return `${this.bearer}${await getAccessTokenForGraph()}`;
            default:
                throw new Error('Unexpected service endpoint.')
        }
    }

    /**
     * Get common request config.
     * @param params Params passed on the query string.
     * @param useCacheBuster Use cache buster option.
     */
    protected async getRequestConfig(params: IParams = {}, useCacheBuster: boolean = true): Promise<AxiosRequestConfig> {
        const token = await this.getAccessToken();
        const requestConfig: AxiosRequestConfig = {
            headers: {
                'Authorization': token,
                'Correlation-Id': createGuid()
            },
            params: useCacheBuster ? { 'cb': Math.random().toString() } : undefined
        }

        requestConfig.params = { ...(requestConfig.params ? requestConfig.params : {}), ...params };
        return requestConfig;
    }

    /**
     * Get object of type T.
     * @param t Type to generate.
     * @param apiUrl Api url.
     * @param params Params passed on the query string.
     * @param useCacheBuster Use cache buster option.
     */
    protected async getObject<T>(t: new(jsonData: T) => T, apiUrl: string, params: IParams = {}, useCacheBuster: boolean = true): Promise<T | null> {
        if (appConfig.current.service.useLocalMockData) {
            await this.simulatedDelay();
        }

        try {
            const requestConfig: AxiosRequestConfig = await this.getRequestConfig(params, useCacheBuster);
            const response: AxiosResponse<T> = await axios.get<T>(apiUrl, requestConfig);
            return JsonObjectFactory.instantiateFromJson<T>(response.data, t);
        } catch (err: any) {
            telemetryService.trackException({ exception: err });
            throw err;
        }
    }

    /**
     * Get object array of type T.
     * @param t Type to generate.
     * @param apiUrl Api url.
     * @param params Params passed on the query string.
     * @param useCacheBuster Use cache buster option.
     */
    protected async getObjectArray<T>(t: new(jsonData: T) => T, apiUrl: string, params: IParams = {}, useCacheBuster: boolean = true): Promise<T[] | null> {
        if (appConfig.current.service.useLocalMockData) {
            await this.simulatedDelay();
        }

        try {
            const requestConfig: AxiosRequestConfig = await this.getRequestConfig(params, useCacheBuster);
            const response: AxiosResponse<T[]> = await axios.get<T[]>(apiUrl, requestConfig);
            return JsonObjectFactory.instantiateFromJsonArray<T>(response.data, t);
        } catch (err: any) {
            telemetryService.trackException({ exception: err });
            throw err;
        }
    }

    /**
     * Get object array of strings.
     * @param apiUrl Api url.
     * @param params Params passed on the query string.
     * @param useCacheBuster Use cache buster option.
     */
    protected async getStringArray(apiUrl: string, params: IParams = {}, useCacheBuster: boolean = true): Promise<string[]> {
        if (appConfig.current.service.useLocalMockData) {
            await this.simulatedDelay();
        }

        try {
            const requestConfig: AxiosRequestConfig = await this.getRequestConfig(params, useCacheBuster);
            const response: AxiosResponse<string[]> = await axios.get<string[]>(apiUrl, requestConfig);
            return response.data;
        } catch (err: any) {
            telemetryService.trackException({ exception: err });
            throw err;
        }
    }

    /**
     * Get value of type T.
     * @param apiUrl Api url.
     * @param params Params passed on the query string.
     * @param useCacheBuster Use cache buster option.
     */
    protected async getValue<T>(apiUrl: string, params: IParams = {}, useCacheBuster: boolean = true): Promise<T> {
        if (appConfig.current.service.useLocalMockData) {
            await this.simulatedDelay();
        }

        try {
            const requestConfig: AxiosRequestConfig = await this.getRequestConfig(params, useCacheBuster);
            const response: AxiosResponse<T> = await axios.get<T>(apiUrl, requestConfig);
            return response.data;
        } catch (err: any) {
            telemetryService.trackException({ exception: err });
            throw err;
        }
    }

    /**
     * Download blob file helper. Browser file save should activate, letting user to save the file to disk.
     * @param apiUrl Api url.
     * @param params Params passed on the query string.
     * @param useCacheBuster Use cache buster option.
     * @returns Name of file downloaded.
     */
    protected async downloadBlobFileHelper(apiUrl: string, params: IParams = {}, useCacheBuster: boolean = true): Promise<string> {
        if (appConfig.current.service.useLocalMockData) {
            await this.simulatedDelay();
        }

        try {
            const requestConfig: AxiosRequestConfig = await this.getRequestConfig(params, useCacheBuster);
            requestConfig.responseType = 'blob';
            const response: AxiosResponse<any> = await axios.get(apiUrl, requestConfig);

            // If server returned 204 not found then return empty string.
            if (response.status === 204) {
                return '';
            }

            // Get file name from content-disposition header. Note that for .NET Core a CORS policy needs to expose this
            // header using WithExposedHeaders("Content-Disposition").
            // https://stackoverflow.com/questions/23054475/javascript-regex-for-extracting-filename-from-content-disposition-header?
            const match: RegExpMatchArray | null | undefined = response.headers['content-disposition']?.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
            const fileName: string = match && match.length > 1 ? match[1] : '';

            // Dynamically create an anchor tag anc click it. This will cause the file save prompt to appear.
            // https://stackoverflow.com/questions/41938718/how-to-download-files-using-axios
            const blob: Blob = new Blob([response.data]);
            const aEle: HTMLAnchorElement = document.createElement('a');
            const href: string = window.URL.createObjectURL(blob);
            aEle.href = href;
            aEle.download = fileName;
            document.body.appendChild(aEle);
            aEle.click();
            document.body.removeChild(aEle);
            window.URL.revokeObjectURL(href);

            return fileName;
        } catch (err: any) {
            telemetryService.trackException({ exception: err });
            throw err;
        }
    }

    /**
     * Put an object of type B and return a response of type T.
     * @param t Type to generate from response. Or null if no response data expected.
     * @param apiUrl Api url.
     * @param body Body with object of type B.
     * @param params Params passed on the query string.
     * @param useCacheBuster Use cache buster option.
     * @returns An object of type T, or null if no response data expected.
     */
    protected async putObject<B, T>(t: (new(jsonData: T) => T) | null, apiUrl: string, body: B, params: IParams = {}, useCacheBuster: boolean = true): Promise<T | null> {
        if (appConfig.current.service.useLocalMockData) {
            await this.simulatedDelay();
        }

        try {
            const requestConfig: AxiosRequestConfig = await this.getRequestConfig(params, useCacheBuster);
            const response: AxiosResponse = await axios.put(apiUrl, body, requestConfig);
            if (t) {
                return JsonObjectFactory.instantiateFromJson<T>(response.data, t);
            }
            return null;
        } catch (err: any) {
            telemetryService.trackException({ exception: err });
            throw err;
        }
    }

    /**
     * Post an object of type B and return a response of type T.
     * @param t Type to generate from response. Or null if no response data expected.
     * @param apiUrl Api url.
     * @param body Body with object of type B.
     * @param params Params passed on the query string.
     * @param useCacheBuster Use cache buster option.
     * @returns An object of type T, or null if no response data expected.
     */
    protected async postObject<B, T>(t: (new(jsonData: T) => T) | null, apiUrl: string, body: B, params: IParams = {}, useCacheBuster: boolean = true): Promise<T | null> {
        if (appConfig.current.service.useLocalMockData) {
            await this.simulatedDelay();
        }

        try {
            const requestConfig: AxiosRequestConfig = await this.getRequestConfig(params, useCacheBuster);
            const response: AxiosResponse = await axios.post(apiUrl, body, requestConfig);
            if (t) {
                return JsonObjectFactory.instantiateFromJson<T>(response.data, t);
            }
            return null;
        } catch (err: any) {
            telemetryService.trackException({ exception: err });
            throw err;
        }
    }

    /**
     * Delete api call.
     * @param apiUrl Api url.
     * @param params Params passed on the query string.
     * @param useCacheBuster Use cache buster option.
     */
     protected async delete(apiUrl: string, params: IParams = {}, useCacheBuster: boolean = true): Promise<null> {
        if (appConfig.current.service.useLocalMockData) {
            await this.simulatedDelay();
        }

        try {
            const requestConfig: AxiosRequestConfig = await this.getRequestConfig(params, useCacheBuster);
            await axios.delete(apiUrl, requestConfig);
            return null;
        } catch (err: any) {
            telemetryService.trackException({ exception: err });
            throw err;
        }
    }
}
